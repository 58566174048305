import { SearchResult, } from "interfaces/responses";

export const searchModelMappings: {
  [key: string]: {
    type: string;
    // eslint-disable-next-line no-unused-vars
    buildPath: (result: SearchResult) => string;
  };
} = {
  "App\\Models\\Organization": {
    type: "Organization",
    buildPath: (s) => `/organizations/${s.id}`,
  },
  "App\\Models\\Matter": {
    type: "Matters",
    buildPath: (s) => `/organizations/${s.drilldown.organization_id}/matters/${s.id}`,
  },
  "App\\Models\\Kit": {
    type: "Kits",
    buildPath: (s) => `/organizations/${s.drilldown.organization_id}/matters/${s.drilldown.matter_id}/kits/${s.id}`,
  },
  "App\\Models\\Custodian": {
    type: "Custodians",
    buildPath: (s) => `/organizations/${s.drilldown.organization_id}/matters/${s.drilldown.matter_id}/custodians/${s.id}`,
  },
  "App\\Models\\Collection": {
    type: "Collections",
    buildPath: (s) => `/organizations/${s.drilldown.organization_id}/matters/${s.drilldown.matter_id}/custodians/${s.drilldown.custodian_id}/collections/${s.id}`,
  },
  "App\\Models\\Note": {
    type: "Notes",
    buildPath: (s) => {
      if ("task_id" in s.drilldown) {
        if ("template_id" in s.drilldown) {
          return `/organizations/${s.drilldown.organization_id}/templates/${s.drilldown.template_id}/tasks/${s.drilldown.task_id}`;
        }
        if ("collection_id" in s.drilldown) {
          return `/organizations/${s.drilldown.organization_id}/matters/${s.drilldown.matter_id}/custodians/${s.drilldown.custodian_id}/collections/${s.drilldown.collection_id}/tasks/${s.drilldown.task_id}`;
        }
        if ("custodian_id" in s.drilldown) {
          return `/organizations/${s.drilldown.organization_id}/matters/${s.drilldown.matter_id}/custodians/${s.drilldown.custodian_id}/tasks/${s.drilldown.task_id}`;
        }
        if ("kit_id" in s.drilldown) {
          return `/organizations/${s.drilldown.organization_id}/matters/${s.drilldown.matter_id}/kits/${s.drilldown.kit_id}/tasks/${s.drilldown.task_id}`;
        }
        if ("matter_id" in s.drilldown) {
          return `/organizations/${s.drilldown.organization_id}/matters/${s.drilldown.matter_id}/tasks/${s.drilldown.task_id}`;
        }
      }
      if ("collection_id" in s.drilldown) {
        return `/organizations/${s.drilldown.organization_id}/matters/${s.drilldown.matter_id}/custodians/${s.drilldown.custodian_id}/collections/${s.drilldown.collection_id}`;
      }
      if ("custodian_id" in s.drilldown) {
        return `/organizations/${s.drilldown.organization_id}/matters/${s.drilldown.matter_id}/custodians/${s.drilldown.custodian_id}`;
      }
      if ("kit_id" in s.drilldown) {
        return `/organizations/${s.drilldown.organization_id}/matters/${s.drilldown.matter_id}/kits/${s.drilldown.kit_id}`;
      }
      if ("matter_id" in s.drilldown) {
        return `/organizations/${s.drilldown.organization_id}/matters/${s.drilldown.matter_id}`;
      }
      return "";
    },
  },
};

export const checkDrilldownForNullOrUndefined = (drilldown: SearchResult["drilldown"]) => {
  const all = Object.values(drilldown);
  return all.every((v) => v !== null && v !== undefined);
};

export const parseSearchResultDrilldown = (result: SearchResult) => {
  const module = searchModelMappings[result.model];
  if (!module) {
    return "";
  }
  if (!checkDrilldownForNullOrUndefined(result.drilldown)) {
    return "";
  }
  const builtPath = module.buildPath(result);
  return builtPath;
};
