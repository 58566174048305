/* eslint-disable no-unused-vars */
import React, { useEffect, } from "react";
/** @jsxImportSource @emotion/react */
import { css, } from "@emotion/react";
import { Link, useNavigate, } from "react-router-dom";
import { useUser, } from "hooks";

function PageNotFound() {
  const { isLoggedIn, } = useUser();

  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) { navigate("/home"); }
  }, [isLoggedIn]);

  if (!isLoggedIn) {
    return null;
  }

  return (
    <div css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 70vh;
        width: 100vw;
    `}
    >
      <p>
        This page does not exist. If you are not automatically redirected, please click
        {" "}
        <Link to="/home">here</Link>
        {" "}
        to go back home.
      </p>
    </div>
  );
}

export default PageNotFound;
