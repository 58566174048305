import React, { MouseEventHandler, } from "react";
/** @jsxImportSource @emotion/react */
import { css, } from "@emotion/react";
import {
  Button, Title,
} from "@mantine/core";
import { Loading, } from "components";
import { useSettings, } from "hooks";
import { resolveTheme, } from "themes/main";

interface DetailsViewProps {
  children: React.ReactNode;
  title: string;
  buttons?: {
    text: string;
    handleClick: MouseEventHandler<HTMLButtonElement>;
    color: string;
    variant?:
      | "outline"
      | "white"
      | "light"
      | "default"
      | "filled"
      | "subtle"
      | "gradient";
    disabled?: boolean;
    invisible?: boolean;
  }[];
  loading?: boolean;
}

DetailsView.defaultProps = {
  buttons: [],
  loading: false,
};

function DetailsView({
  children,
  title,
  buttons,
  loading,
}: DetailsViewProps) {
  const { theme: { current: theme, }, } = useSettings();
  const currentTheme = resolveTheme(theme);

  if (loading) {
    return <Loading />;
  }

  return (
    <div
      css={css`
        padding: 36px 24px;

        @media (min-width: 768px) {
          padding: 36px 48px;
        }

        @media (min-width: 1024px) {
          padding: 36px 64px;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: center;
          flex-wrap: wrap;

          @media (min-width: 768px) {
            justify-content: space-between;
          }
        `}
      >
        <Title
          weight={300}
          order={1}
          style={{
            fontFamily: "Roboto, sans-serif",
            fontSize: "32px",
            fontWeight: 300,
          }}
          color={currentTheme.colors.text}
        >
          {title}
        </Title>
        <div
          css={css`
            display: flex;
            justify-content: space-around;
            width: 100%;
            margin-top: 24px;
            flex-wrap: wrap;

            & > * {
              margin-bottom: 8px;
            }

            @media (min-width: 768px) {
              justify-content: flex-end;
              width: initial;
              margin-top: 0;
            }
          `}
        >
          {buttons?.map(
            (button, idx) => !button.invisible && (
            <Button
                  // eslint-disable-next-line react/no-array-index-key
              key={idx + button.text}
              onClick={button.handleClick}
              color={button.color}
              variant={button.variant}
              disabled={button.disabled}
              style={{ marginLeft: "14px", }}
            >
              {button.text}
            </Button>
            )
          )}
        </div>
      </div>
      <br />
      <div>{children}</div>
    </div>
  );
}

export default DetailsView;
