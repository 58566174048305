import {
  Button,
  Checkbox,
  Grid,
  Group,
  NumberInput,
  SegmentedControl,
  Select,
  TextInput,
  Title,
} from "@mantine/core";
import DatePicker from "components/Inputs/DatePicker";
import InlineLoader from "components/Loading/Inline";
import { useAlert, useSettings, useUser, } from "hooks";
import {
  Address, Kit, Matter, Organization, Shipment,
} from "interfaces/main";
import { Validation, } from "interfaces/responses";
import { axios, } from "libs";
import { useEffect, useState, } from "react";
import { resolveTheme, } from "themes/main";
import { parseJsonFields, } from "utils/fetching";
import { deepObjectCompare, } from "utils/methods";
import { shipmentConfirmationTypes, } from "vars/constants";
import { countries, } from "vars/countries";
import states from "vars/stateTitleCase";

function ShippingDetails({
  markComplete,
  invalidate,
  matter,
  kit,
  updateKit,
}: {
  // eslint-disable-next-line no-unused-vars
  markComplete: (data: undefined) => void;
  invalidate: () => void;
  matter: Matter;
  kit: Kit;
  // eslint-disable-next-line no-unused-vars
  updateKit: (k: Kit) => void;
}) {
  const { setAlert, } = useAlert();
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [validationRules, setValidationRules] = useState<Validation>();
  const [requiredFields, setRequiredFields] = useState<string[]>([]);
  const { getUserOrganization, } = useUser();
  const [carriers, setCarriers] = useState<
    {
      name: string;
      code: string;
    }[]
  >([]);

  const [FormData, setFormData] = useState<Partial<Kit>>(kit);

  const kitInitialized = () => {
    if (!FormData) {
      return false;
    }

    const outboundShipment = getShipment("outbound");
    const inboundShipment = getShipment("inbound");

    if (!outboundShipment || !inboundShipment) {
      return false;
    }

    return true;
  };

  const setFormDataIfInitialized = (newFormData: Partial<Kit>) => {
    if (kitInitialized()) {
      setFormData(newFormData);
      return;
    }
    console.error("Tried to set form data before kit was initialized");
  };

  const onError = (response: any): void => {
    // eslint-disable-next-line no-console
    console.error("On error", response);
    setAlert({
      type: "danger",
      heading: "Error",
      message: response.Error,
    });
  };
  const [organization, setOrganization] = useState<Organization>();

  const getOrganization = () => {
    axios
      .get(`/organizations/${getUserOrganization()?.id}`)
      .then((res) => {
        setOrganization(res.data.data);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error("error", err);
      });
  };

  useEffect(() => {
    axios
      .get(
        `/organizations/${matter.organization_id}/matters/${matter.id}/kits/create`
      )
      .then((res) => {
        const { validation, } = res.data.data as { validation: Validation };
        setRequiredFields(
          Object.keys(validation.rules).filter((rule) => {
            if (!validation.rules[rule].includes("required")) {
              return false;
            }
            return true;
          })
        );
        setValidationRules(validation);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error("error", err);
      })
      .finally(() => {
        setLoading(false);
      });

    axios
      .get("/shipping/carriers")
      .then((res) => {
        setCarriers(res.data.data || []);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error("error", err);
      });

    getOrganization();
  }, [kit, matter]);

  const addressIsComplete = (address: Address | null | undefined) => {
    if (address === undefined) {
      return false;
    }
    if (address === null) {
      return false;
    }
    return (
      !!address.street1 &&
      !!address.city &&
      !!address.state &&
      !!address.postalCode &&
      !!address.country &&
      !!address.name
    );
  };

  const unsavedChanges = !deepObjectCompare(kit, FormData);

  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);

  const isRequired = (field: string): boolean => requiredFields.includes(field);

  const formattedStates = states ?
    states.map((state) => ({
      label: state.name,
      value: state.abbreviation,
    })) :
    [];

  const formattedCountries = countries ?
    countries.map((country) => ({
      label: country.name,
      value: country.code,
    })) :
    [];

  const formatDate = (date: Date) => {
    const ISO = date.toISOString();
    return ISO.replace("T", " ").replace("Z", "").split(".")[0];
  };

  const updateShipment = (
    bound: "outbound" | "inbound",
    update: Partial<Shipment>
  ) => {
    const newShipments = FormData?.shipments?.map((shipment) => {
      if (shipment.type === bound) {
        return { ...shipment, ...update, };
      }
      return shipment;
    });
    setFormDataIfInitialized({ ...FormData, shipments: newShipments, });
  };

  const getShipment = (bound: "outbound" | "inbound") => {
    if (FormData?.shipments) {
      return FormData.shipments.find((shipment) => shipment.type === bound);
    }
    return undefined;
  };

  const updateAddress = (
    bound: "outbound" | "inbound",
    type: "from" | "to",
    update: Partial<Address>
  ) => {
    const newShipments = FormData?.shipments?.map((shipment) => {
      if (shipment.type === bound) {
        const newAddress = { ...shipment[`${type}_address`], ...update, };
        return { ...shipment, [`${type}_address`]: newAddress, };
      }
      return shipment;
    });
    setFormDataIfInitialized({ ...FormData, shipments: newShipments, });
  };

  const updateBatchAddresses = (
    addresses: { bound: "outbound" | "inbound"; type: "from" | "to" }[],
    update: Partial<Address>
  ) => {
    const newShipments = [...(FormData?.shipments || [])];
    addresses.forEach((address) => {
      const shipment = newShipments.find((s) => s.type === address.bound);
      if (shipment) {
        const newAddress = {
          ...shipment[`${address.type}_address`],
          ...update,
        };
        (shipment[`${address.type}_address`] as Partial<Address>) = newAddress;
      }
    });
    setFormDataIfInitialized({ ...FormData, shipments: newShipments, });
  };

  const getAddress = (bound: "outbound" | "inbound", type: "from" | "to") => {
    if (FormData?.shipments) {
      const shipment = FormData.shipments.find((s) => s.type === bound);
      if (shipment) {
        return shipment[`${type}_address`];
      }
    }
    return undefined;
  };

  const [outboundCarrierServices, setOutboundCarrierServices] = useState<
    {
      name: string;
      code: string;
    }[]
  >([]);
  const [inboundCarrierServices, setInboundCarrierServices] = useState<
    {
      name: string;
      code: string;
    }[]
  >([]);

  const formattedCarriers = carriers ?
    carriers.map((carrier) => ({
      label: carrier.name,
      value: carrier.code,
    })) :
    [];

  const formattedOutboundCarrierServices = outboundCarrierServices ?
    outboundCarrierServices.map((service) => ({
      label: service.name,
      value: service.code,
    })) :
    [];

  //   const formattedInboundCarrierServices = inboundCarrierServices ?
  //     outboundCarrierServices.map((service) => ({
  //       label: service.name,
  //       value: service.code,
  //     })) :
  //     [];

  const addressIsEmpty = (address: Address | null | undefined) => {
    if (address === undefined) {
      return true;
    }
    if (address === null) {
      return true;
    }
    return (
      !address.street1 &&
      !address.street2 &&
      !address.street3 &&
      !address.city &&
      !address.state &&
      !address.postalCode &&
      !address.country
    );
  };

  const getOrganizationDimensions = () => (organization ?
    {
      length: organization?.length,
      width: organization?.width,
      height: organization?.height,
    } :
    undefined);

  const getShipmentDimensions = (bound: "outbound" | "inbound") => {
    const shipment = getShipment(bound);
    if (shipment) {
      return {
        length: shipment.length,
        width: shipment.width,
        height: shipment.height,
        weight: shipment.weight,
      };
    }
    return undefined;
  };

  const shipmentNeedsDimensions = (bound: "outbound" | "inbound") => {
    const shipment = getShipment(bound);
    if (!shipment) return false;
    const { width, height, length, } = shipment;
    return !width && !height && !length;
  };

  const outboundNeedsDimensions = shipmentNeedsDimensions("outbound");

  const inboundNeedsDimensions = shipmentNeedsDimensions("inbound");

  useEffect(() => {}, [
    getShipmentDimensions("inbound"),
    getShipmentDimensions("outbound")
  ]);

  const formattedWeightUnits = [
    {
      label: "Pounds",
      value: "pounds",
    },
    {
      label: "Ounces",
      value: "ounces",
    },
    {
      label: "Grams",
      value: "grams",
    }
  ];

  const formattedDimensionsUnits = [
    {
      label: "Inches",
      value: "inches",
    },
    {
      label: "Centimeters",
      value: "centimeters",
    }
  ];

  const [outboundShipmentType, setOutboundShipmentType] = useState<
    "new" | "existing"
  >("new");

  const initialOutboundShipmentType = getShipment("outbound")?.tracking_number;

  useEffect(() => {
    if (initialOutboundShipmentType) {
      setOutboundShipmentType("existing");
    }
  }, [initialOutboundShipmentType]);

  useEffect(() => {
    if (getShipment("outbound")?.carrier_code) {
      axios
        .get(
          `/shipping/carriers/${getShipment("outbound")?.carrier_code}/services`
        )
        .then((res) => {
          setOutboundCarrierServices(res.data.data);
        });
    }
    if (getShipment("inbound")?.carrier_code) {
      axios
        .get(
          `/shipping/carriers/${getShipment("inbound")?.carrier_code}/services`
        )
        .then((res) => {
          setInboundCarrierServices(res.data.data);
        });
    }
  }, [
    getShipment("outbound")?.carrier_code,
    getShipment("inbound")?.carrier_code
  ]);

  useEffect(() => {
    if (!kitInitialized()) return;
    if (!getShipment("outbound")?.carrier_code && carriers) {
      updateShipment("outbound", {
        carrier_code: "ups_walleted",
      });
    }
    if (!getShipment("inbound")?.carrier_code && carriers) {
      updateShipment("inbound", {
        carrier_code: "ups_walleted",
      });
    }
  }, [
    carriers,
    getShipment("outbound")?.carrier_code,
    getShipment("inbound")?.carrier_code
  ]);

  useEffect(() => {
    if (!kitInitialized()) return;
    if (
      getShipment("outbound")?.carrier_code === "ups_walleted" &&
      !getShipment("outbound")?.service_code
    ) {
      updateShipment("outbound", {
        service_code: "ups_2nd_day_air",
      });
    } else if (
      getShipment("outbound")?.carrier_code !== "ups_walleted" &&
      !getShipment("outbound")?.service_code
    ) {
      updateShipment("outbound", {
        service_code: "",
      });
    }
  }, [outboundCarrierServices]);

  useEffect(() => {
    if (!kitInitialized()) return;
    if (
      getShipment("inbound")?.carrier_code === "ups_walleted" &&
      !getShipment("inbound")?.service_code
    ) {
      updateShipment("inbound", {
        service_code: "ups_2nd_day_air",
      });
    } else if (getShipment("inbound")?.carrier_code !== "ups_walleted") {
      updateShipment("inbound", {
        service_code: "",
      });
    }
  }, [inboundCarrierServices]);

  const autoFillFields = () => {
    if (!kitInitialized()) {
      return;
    }

    // * Address Handling
    if (
      addressIsEmpty(getAddress("outbound", "from")) &&
      addressIsEmpty(getAddress("inbound", "to")) &&
      organization?.address
    ) {
      updateBatchAddresses(
        [
          { bound: "outbound", type: "from", },
          { bound: "inbound", type: "to", }
        ],
        { ...organization.address, }
      );
    } else if (
      addressIsEmpty(getAddress("inbound", "to")) &&
      organization?.address
    ) {
      updateAddress("inbound", "to", { ...organization.address, });
    } else if (
      addressIsEmpty(getAddress("outbound", "from")) &&
      organization?.address
    ) {
      updateAddress("outbound", "from", { ...organization.address, });
    }

    // * Shipment Stuff
    if (inboundNeedsDimensions) {
      const orgDimensions = getOrganizationDimensions();
      if (!orgDimensions) return;
      updateShipment("inbound", {
        width: orgDimensions.width,
        height: orgDimensions.height,
        length: orgDimensions.length,
      });
    }
    if (outboundNeedsDimensions) {
      const orgDimensions = getOrganizationDimensions();
      if (!orgDimensions) return;
      updateShipment("outbound", {
        width: orgDimensions.width,
        height: orgDimensions.height,
        length: orgDimensions.length,
      });
    }

    // * Other
    if (!getShipment("inbound")?.dimension_units) {
      updateShipment("inbound", {
        dimension_units: "inches",
      });
    }
    if (!getShipment("outbound")?.dimension_units) {
      updateShipment("outbound", {
        dimension_units: "inches",
      });
    }
    if (!getShipment("inbound")?.weight_units) {
      updateShipment("inbound", {
        weight_units: "pounds",
      });
    }
    if (!getShipment("outbound")?.weight_units) {
      updateShipment("outbound", {
        weight_units: "pounds",
      });
    }
    if (!getAddress("outbound", "to")?.country) {
      updateAddress("outbound", "to", {
        country: "US",
      });
    }
    if (!getAddress("inbound", "to")?.country) {
      updateAddress("inbound", "to", {
        country: "US",
      });
    }
  };

  useEffect(() => {
    if (!kitInitialized()) return;
    autoFillFields();
  }, [FormData, organization]);

  const checkIsComplete = () => addressIsComplete(getAddress("outbound", "from")) &&
    addressIsComplete(getAddress("outbound", "to")) &&
    addressIsComplete(getAddress("inbound", "from")) &&
    addressIsComplete(getAddress("inbound", "to"));

  useEffect(() => {
    if (checkIsComplete() && !unsavedChanges) {
      markComplete(undefined);
    } else {
      invalidate();
    }
  }, [FormData, kit, checkIsComplete()]);

  const onSubmit = (): void => {
    setLoading(true);

    // validate
    if (!FormData) {
      return;
    }
    const errors = requiredFields.filter((field) => {
      if (FormData[field as keyof Partial<Kit>] === undefined) {
        return true;
      }
      return false;
    });

    if (errors.length) {
      setAlert({
        type: "danger",
        heading: "Error",
        message: "Please fill out all required fields",
      });
      return;
    }

    axios
      .put(
        `/organizations/${matter.organization_id}/matters/${matter.id}/kits/${kit.id}`,
        FormData
      )
      .then((res) => {
        const newKit = res.data.data as Kit;
        setAlert({
          type: "success",
          heading: "Success",
          message: "Kit edited successfully",
        });
        const parsed = parseJsonFields(newKit);
        updateKit(parsed);
        if (checkIsComplete()) {
          markComplete(undefined);
        }
      })
      .catch(onError)
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (getAddress("outbound", "to")) {
      updateAddress("inbound", "from", getAddress("outbound", "to") as Address);
    }
  }, [getAddress("outbound", "to")]);

  useEffect(() => {
    if (getAddress("outbound", "from")) {
      updateAddress("inbound", "to", getAddress("outbound", "from") as Address);
    }
  }, [getAddress("outbound", "from")]);

  return (
    <div>
      <Grid>
        {loading && (
          <Grid.Col>
            <InlineLoader />
          </Grid.Col>
        )}
        <Grid.Col span={12}>
          <Title order={3} color={currentTheme.colors.text} weight={300}>
            Outbound Address
          </Title>
        </Grid.Col>
        <Grid.Col span={12}>
          <SegmentedControl
            data={[
              {
                label: "New",
                value: "new",
              },
              {
                label: "Existing",
                value: "existing",
              }
            ]}
            onChange={(e) => {
              setOutboundShipmentType(e as "new" | "existing");
            }}
            value={outboundShipmentType}
          />
        </Grid.Col>
        {outboundShipmentType === "existing" && (
          <Grid.Col sm={12} md={6} lg={4}>
            <TextInput
              label="Tracking Number"
              name="tracking_number"
              required={isRequired("tracking_number")}
              onChange={(e) => {
                updateShipment("outbound", {
                  tracking_number: e.target.value,
                });
              }}
              placeholder="Enter a tracking number"
              value={getShipment("outbound")?.tracking_number || ""}
            />
          </Grid.Col>
        )}
        {outboundShipmentType === "new" && (
          <>
            <Grid.Col sm={12} md={4}>
              <TextInput
                label="Name"
                name="name"
                required={isRequired("name")}
                onChange={(e) => {
                  updateAddress("outbound", "to", {
                    name: e.target.value,
                  });
                }}
                placeholder="Enter a name"
                value={getAddress("outbound", "to")?.name || ""}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={4}>
              <TextInput
                label="Company"
                name="company"
                required={isRequired("company")}
                onChange={(e) => {
                  updateAddress("outbound", "to", {
                    company: e.target.value,
                  });
                }}
                placeholder="Enter a company name"
                value={getAddress("outbound", "to")?.company || ""}
                disabled={!!getAddress("outbound", "to")?.residential}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={4}>
              <TextInput
                label="Phone"
                name="phone"
                required={isRequired("phone")}
                onChange={(e) => {
                  updateAddress("outbound", "to", {
                    phone: e.target.value,
                  });
                }}
                placeholder="Enter a phone number"
                value={getAddress("outbound", "to")?.phone || ""}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={4}>
              <TextInput
                label="Street1"
                name="street1"
                required={isRequired("street1")}
                onChange={(e) => {
                  updateAddress("outbound", "to", {
                    street1: e.target.value,
                  });
                }}
                placeholder="Enter a street address"
                value={getAddress("outbound", "to")?.street1 || ""}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={4}>
              <TextInput
                label="Street2"
                name="street2"
                onChange={(e) => {
                  updateAddress("outbound", "to", {
                    street2: e.target.value,
                  });
                }}
                placeholder="Enter a street address"
                value={getAddress("outbound", "to")?.street2 || ""}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={4}>
              <TextInput
                label="Street3"
                name="street3"
                onChange={(e) => {
                  updateAddress("outbound", "to", {
                    street3: e.target.value,
                  });
                }}
                placeholder="Enter a street address"
                value={getAddress("outbound", "to")?.street3 || ""}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={4}>
              <TextInput
                label="City"
                name="city"
                required={isRequired("city")}
                onChange={(e) => {
                  updateAddress("outbound", "to", {
                    city: e.target.value,
                  });
                }}
                placeholder="Enter a city"
                value={getAddress("outbound", "to")?.city || ""}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={4}>
              <Select
                label="State"
                name="state"
                required={isRequired("state")}
                data={formattedStates}
                onChange={(value) => {
                  if (value) {
                    updateAddress("outbound", "to", {
                      state: value as string,
                    });
                  }
                }}
                searchable
                placeholder="Select a state"
                value={getAddress("outbound", "to")?.state || ""}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={4}>
              <TextInput
                label="Zip"
                name="zip"
                required={isRequired("zip")}
                onChange={(e) => {
                  updateAddress("outbound", "to", {
                    postalCode: e.target.value,
                  });
                }}
                placeholder="Enter a zip code"
                value={getAddress("outbound", "to")?.postalCode || ""}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={6}>
              <Select
                label="Country"
                name="country"
                required={isRequired("country")}
                data={formattedCountries}
                onChange={(value) => {
                  if (value) {
                    updateAddress("outbound", "to", {
                      country: value as string,
                    });
                  }
                }}
                searchable
                placeholder="Select a country"
                value={getAddress("outbound", "to")?.country || "US"}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={6}>
              <Checkbox
                label="Residential"
                name="residential"
                onChange={(e) => {
                  updateAddress("outbound", "to", {
                    residential: e.target.checked ? 1 : 0,
                  });
                }}
                checked={!!getAddress("outbound", "to")?.residential || false}
                disabled={!!getAddress("outbound", "to")?.company}
                styles={{
                  root: {
                    marginTop: "40px",
                  },
                }}
              />
            </Grid.Col>
            <Grid.Col span={12} />
            <Grid.Col span={12}>
              <Title order={3} color={currentTheme.colors.text} weight={300}>
                Shipping Information
              </Title>
            </Grid.Col>
            <Grid.Col sm={12} md={4}>
              <Select
                label="Carrier"
                name="carrier"
                required={isRequired("carrier")}
                data={formattedCarriers}
                onChange={(e) => {
                  if (!e) return;
                  updateShipment("outbound", {
                    carrier_code: e,
                  });
                }}
                placeholder="Select a carrier"
                value={getShipment("outbound")?.carrier_code || ""}
                searchable
              />
            </Grid.Col>
            <Grid.Col sm={12} md={4}>
              <Select
                label="Service"
                name="service"
                required={isRequired("service")}
                data={formattedOutboundCarrierServices}
                onChange={(value) => {
                  if (value) {
                    updateShipment("outbound", {
                      service_code: value,
                    });
                  }
                }}
                disabled={!getShipment("outbound")?.carrier_code}
                placeholder={
                  getShipment("outbound")?.carrier_code ?
                    `${
                      formattedOutboundCarrierServices.length > 0 ?
                        "Select a services" :
                        "No services available"
                    }` :
                    "Select a carrier first"
                }
                value={getShipment("outbound")?.service_code || ""}
                searchable
              />
            </Grid.Col>
            <Grid.Col sm={12} md={4}>
              <DatePicker
                label="Ship Date"
                name="ship_date"
                required={isRequired("ship_date")}
                onChange={(value) => {
                  if (value) {
                    updateShipment("outbound", {
                      ship_date: formatDate(value),
                    });
                  }
                }}
                placeholder="Select a ship date"
                value={
                  getShipment("outbound")?.ship_date ?
                    new Date(getShipment("outbound")?.ship_date || "") :
                    new Date()
                }
              />
            </Grid.Col>
            <Grid.Col sm={12} md={3}>
              <NumberInput
                label="Weight"
                name="weight"
                required={isRequired("weight")}
                onChange={(e) => {
                  updateShipment("outbound", {
                    weight: Number(e || 0),
                  });
                }}
                placeholder="Enter a weight"
                value={Number(getShipment("outbound")?.weight) || 0}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={3}>
              <NumberInput
                label="Length"
                name="length"
                required={isRequired("length")}
                onChange={(e) => {
                  updateShipment("outbound", {
                    length: Number(e || 0),
                  });
                }}
                placeholder="Enter a length"
                value={Number(getShipment("outbound")?.length) || 0}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={3}>
              <NumberInput
                label="Width"
                name="width"
                required={isRequired("width")}
                onChange={(e) => {
                  updateShipment("outbound", {
                    width: Number(e || 0),
                  });
                }}
                placeholder="Enter a width"
                value={Number(getShipment("outbound")?.width) || 0}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={3}>
              <NumberInput
                label="Height"
                name="height"
                required={isRequired("height")}
                onChange={(e) => {
                  updateShipment("outbound", {
                    height: Number(e || 0),
                  });
                }}
                placeholder="Enter a height"
                value={Number(getShipment("outbound")?.height) || 0}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={6}>
              <Select
                label="Weight Units"
                name="weight_units"
                required={isRequired("weight_units")}
                data={formattedWeightUnits}
                onChange={(value) => {
                  if (value) {
                    updateShipment("outbound", {
                      weight_units: value as Shipment["weight_units"],
                    });
                  }
                }}
                placeholder="Select a weight unit"
                value={getShipment("outbound")?.weight_units || ""}
                searchable
              />
            </Grid.Col>
            <Grid.Col sm={12} md={6}>
              <Select
                label="Dimension Units"
                name="dimension_units"
                required={isRequired("dimension_units")}
                data={formattedDimensionsUnits}
                onChange={(value) => {
                  if (value) {
                    updateShipment("outbound", {
                      dimension_units: value as Shipment["dimension_units"],
                    });
                  }
                }}
                placeholder="Select a dimension unit"
                value={getShipment("outbound")?.dimension_units || ""}
                searchable
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Select
                name="confirmation"
                label="Confirmation"
                data={shipmentConfirmationTypes(
                  getShipment("outbound")?.carrier_code === "fedex"
                )}
                onChange={(value) => {
                  if (value) {
                    updateShipment("outbound", {
                      confirmation: value as Shipment["confirmation"],
                    });
                  }
                }}
                value={getShipment("outbound")?.confirmation || "none"}
              />
            </Grid.Col>
          </>
        )}
      </Grid>
      {unsavedChanges && (
        <>
          <Grid.Col span={12} />
          <Grid.Col span={12}>
            <Group position="right">
              <Button variant="default" onClick={onSubmit}>
                Submit
              </Button>
              {loading && <InlineLoader />}
            </Group>
          </Grid.Col>
        </>
      )}
    </div>
  );
}

export default ShippingDetails;
