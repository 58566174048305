import React from "react";
/** @jsxImportSource @emotion/react */
import { css, keyframes, } from "@emotion/react";
import { Loader, } from "@mantine/core";
import { useSettings, } from "hooks";
import { resolveTheme, } from "themes/main";

interface LoadingProps {
  text?: string;
}

Loading.defaultProps = {
  text: "Loading...",
};

function Loading({ text, }: LoadingProps) {
  const { theme: { current, }, } = useSettings();
  const currentTheme = resolveTheme(current);

  const loaderIn = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `;

  const styles = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    color: ${currentTheme.colors.textLight};
    z-index: 50;
    background-color: ${currentTheme.colors.background};
    animation: ${loaderIn} 0.2s ease-in-out;
  `;
  return (
    <div css={styles}>
      <Loader
        color={currentTheme.colors.textLight}
        size="lg"
      />
      {text}
    </div>
  );
}

export default Loading;
