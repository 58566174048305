import {
  FieldTypes,
  KitStatusOption,
  Shipment,
  ThemeTypes,
} from "interfaces/main";

export { states as stateOptions, } from "vars/stateTitleCase";

// * Recent Modules
export const VisitTimeout = 5000;
export const VisitAmount = 10;

// * Details
export const fieldTypes: FieldTypes[] = [
  "text",
  "number",
  "date",
  "checkbox",
  "datetime",
  "password",
  "email"
];

export const kitStatusTypes: {
  [key: number]: KitStatusOption;
} = {
  1: {
    value: 1,
    label: "Created",
  },
  2: {
    value: 2,
    label: "Ready for Shipment",
  },
  3: {
    value: 3,
    label: "In Transit (Outbound)",
  },
  4: {
    value: 4,
    label: "Delivered",
  },
  5: {
    value: 5,
    label: "Recalled",
  },
  6: {
    value: 6,
    label: "In Transit (Inbound)",
  },
  7: {
    value: 7,
    label: "Returned",
  },
  8: {
    value: 8,
    label: "Completed",
  },
};

export const taskStatuses = {
  0: {
    name: "Open",
  },
  1: {
    name: "In Progress",
  },
  2: {
    name: "Completed",
  },
  3: {
    name: "Cancelled",
  },
};

export const colorSchemeOptions: ThemeTypes[] = ["light", "dark"];

export const shipmentConfirmationTypes = (
  isFedex: boolean
): { label: string; value: Shipment["confirmation"] }[] => [
  { label: "None", value: "none", },
  { label: "Delivery", value: "delivery", },
  { label: "Signature", value: "signature", },
  {
    label: "Adult Signature",
    value: "adult_signature",
  },
  ...(isFedex ?
    [
        {
          label: "Direct Signature (FedEx)",
          value: "direct_signature",
        } as const
    ] :
    [])
];
