import { Fields, } from "interfaces/main";
import React from "react";
import JSONSection from "components/Inputs/JsonFields";

interface CredentialProps {
  credentials: Fields;
  // eslint-disable-next-line no-unused-vars
  submitCredentials: (credentials: Fields) => void;
  disabled?: boolean;
  readonly?: boolean;
  display_only?: boolean;
}

CredentialsSection.defaultProps = {
  disabled: false,
  readonly: false,
  display_only: false,
};

function CredentialsSection({
  credentials,
  submitCredentials,
  disabled,
  readonly,
  display_only,
}: CredentialProps) {
  return (
    <JSONSection
      title="Credentials"
      fields={credentials}
      submitFields={submitCredentials}
      disabled={disabled}
      readonly={readonly}
      display_only={display_only}
    />
  );
}

export default CredentialsSection;
