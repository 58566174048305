import React, { MouseEventHandler, } from "react";
/** @jsxImportSource @emotion/react */
import { css, } from "@emotion/react";
import { useSettings, } from "hooks";
import { resolveTheme, } from "themes/main";

interface TabularProps<Tabs> {
  tabs: {
    text: Tabs;
    // eslint-disable-next-line no-unused-vars
    onClick: MouseEventHandler<HTMLButtonElement>;
    id: string;
  }[];
  currentTab: Tabs;
}

function Tabular<Tabs extends unknown>({
  tabs,
  currentTab,
}: TabularProps<Tabs>) {
  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);
  const { colors, } = currentTheme;

  return (
    <div
      css={css`
        .tab {
          background-color: transparent;
          border: none;
          padding: 0;
          padding-bottom: 14px;
          font-weight: 300;
          border-bottom: 1.5px solid transparent;
          margin-right: 24px;
          padding-inline: 5px;
          color: ${colors.textLight};
          transition: all 0.2s ease-in-out;

          &:hover {
            border-bottom: 1.5px solid ${colors.textLight};
            color: ${colors.textLight};
          }
        }

        .active {
          border-bottom: 1.5px solid ${colors.text};
          color: ${colors.text};
          transition: border-bottom 0.2s ease-in-out;
          transition: color 0.2s ease-in-out;

          &:hover {
            border-bottom: 1.5px solid ${colors.text};
            color: ${colors.text};
          }
        }
      `}
    >
      {tabs.map((t) => (
        <button
          type="button"
          key={`tab-${t.id}`}
          onClick={t.onClick}
          className={`tab ${currentTab === t.id ? "active" : ""}`}
        >
          {t.text}
        </button>
      ))}
    </div>
  );
}

export default Tabular;
