import React, { useEffect, useState, } from "react";
import { Loading, } from "components";
import { useNavigate, useParams, } from "react-router";
import { axios, } from "libs";
import { useUser, useSearch, useSettings, } from "hooks";
import { Collection, } from "interfaces/main";
import { getModuleCompleteness, getModuleFields, } from "utils/methods";
import IntegratedListView from "components/Views/IntegratedListView";
import { getFormattedTimeString, } from "utils/formatting";
import { resolveTheme, } from "themes/main";

function CollectionList() {
  const [collections, setCollections] = useState<Collection[]>([]);
  const [loading, setLoading] = useState(false);
  const { organization_id, matter_id, custodian_id, } = useParams();

  const navigate = useNavigate();
  const { hasRole, } = useUser();
  // eslint-disable-next-line no-unused-vars
  const { getTable, } = useSearch();
  const { sort, } = getTable("collections");

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/collections`
      )
      .then((res) => {
        setCollections(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));

    return () => {
      setCollections([]);
    };
  }, [organization_id, matter_id, custodian_id]);

  const allFields: (keyof Collection)[] | undefined =
    collections?.[0] &&
    (Object.keys(collections[0]) as unknown as (keyof Collection)[]);

  const allowedFields = new Map([
    ["id", 0],
    ["name", 0],
    ["description", 0],
    ["scheduled_at", 0],
    ["completed_at", 0],
    ["closable", 0],
    ["scheduled_at", 0],
    ["current_task", 0],
    ["created_at", 0],
    ["updated_at", 0]
  ]);

  const fields = getModuleFields(allowedFields, allFields);

  const getSubtitle = (col: Collection) => {
    if (!sort.field) {
      return col.completed_at ?
        `Completed at ${getFormattedTimeString(col.completed_at)}` :
        col.current_task ||
            `Last update at ${getFormattedTimeString(col.updated_at)}`;
    }
    return `${sort.field}: ${col[sort.field as keyof Collection]}`;
  };

  const listItems = collections?.map((collection) => ({
    id: collection.id,
    title: collection.name,
    subtitle: getSubtitle(collection),
    completeness: getModuleCompleteness(collection) || 0,
    endpoint: `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/collections/${collection.id}`,
    full_record: collection,
  }));

  const {
    theme: { current: theme, },
  } = useSettings();
  const currentTheme = resolveTheme(theme);

  if (loading) {
    return <Loading />;
  }

  return (
    <IntegratedListView
      full_record_list={collections || []}
      list_items={listItems}
      title="Collections"
      buttons={[
        {
          text: "",
          icon: "add",
          handleClick: () => {
            navigate(
              `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/collections/create`
            );
          },
          color: currentTheme.colors.primary,
          variant: "default",
          invisible: !hasRole("user"),
        }
      ]}
      loading={loading}
      search
      collapsable
      fields={fields}
      table_name="collections"
    />
  );
}

export default CollectionList;
