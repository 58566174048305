import React, { useEffect, useState, } from "react";
import { Create, } from "components/Views";
import { Loading, } from "components";
import { axios, } from "libs";
import { useParams, useNavigate, } from "react-router-dom";
import { Validation, } from "interfaces/responses";
import { Grid, TextInput, } from "@mantine/core";
import { useAlert, useSettings, } from "hooks";
import { Task, } from "interfaces/main";
import { resolveTheme, } from "themes/main";
import { TaskForm, } from "./index";

function CreateTask() {
  const { organization_id, template_id, } = useParams();
  const [formState, setFormState] = useState<TaskForm>({
    description: "",
  });
  const [loading, setLoading] = useState(false);
  const [validationRules, setValidationRules] = useState<Validation>();
  const [requiredFields, setRequiredFields] = useState<string[]>([]);
  const [validated, setValidated] = useState(false);
  // const { hasRole } = useUser();
  const { setAlert, } = useAlert();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/organizations/${organization_id}/templates/${template_id}/tasks/create`)
      .then((res) => {
        const validation = res.data.data.validation as Validation;
        setValidationRules(validation);
        setRequiredFields(
          Object.entries(validation.rules)
            .filter(([, value]) => value.includes("required"))
            .map(([key]) => key)
        );
      })
      .catch((err) => {
        console.error(err);
        setAlert({
          type: "danger",
          message: "Error loading tasks validation rules",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const submitTask = () => {
    setLoading(true);
    axios
      .post(`/organizations/${organization_id}/templates/${template_id}/tasks`, formState)
      .then((res) => {
        setAlert({
          type: "success",
          message: "Task created successfully",
        });
        const newTask = res.data.data as Task;
        setLoading(false);
        navigate(`/organizations/${organization_id}/templates/${template_id}/tasks/${newTask.id}`);
      })
      .catch((err) => {
        console.error(err);
        setAlert({
          type: "danger",
          message: "Error creating task",
        });
        setLoading(false);
      });
  };

  const { theme: { current, }, } = useSettings();
  const currentTheme = resolveTheme(current);
  const { colors, } = currentTheme;

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <Create
        title="Create Template"
        buttons={[
          {
            text: "Submit",
            handleClick: () => {
              submitTask();
            },
            variant: "filled",
            color: colors.primary,
            disabled: !validated,
          }
        ]}
        formState={formState}
        requiredFields={requiredFields}
        setValidated={setValidated}
      >
        <Grid>
          <Grid.Col sm={12} md={12}>
            <TextInput
              label="Description"
              name="description"
              onChange={(e) => {
                setFormState({ ...formState, description: e.currentTarget.value, });
              }}
              required={validationRules?.rules?.description?.includes("required")}
            />
          </Grid.Col>
        </Grid>
      </Create>
    </div>
  );
}

export default CreateTask;
