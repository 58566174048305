/* @jsxImportSource @emotion/react */
import { css, } from "@emotion/react";
import {
  ColorSwatch, Flex, Group, Progress, Text,
} from "@mantine/core";
import { useSettings, } from "hooks";
import { Task, } from "interfaces/main";
import React, { useEffect, useState, } from "react";
import { resolveTheme, } from "themes/main";

interface ProgressSectionProps {
    tasks: Task[];
}

function ProgressSection({ tasks, }: ProgressSectionProps) {
  const {
    theme: { current: theme, },
  } = useSettings();
  const currentTheme = resolveTheme(theme);

  const getTaskStatusBreakdown = (
    tsks: Task[]
  ): {
    percentage: number;
    type: number;
  }[] => {
    // there are 3 possible statuses for a task: "incomplete", "complete", "in progress"
    // we want to show a breakdown of the tasks by status, as a percentage
    const totalTasks = tsks.length || 0;

    const types: {
      [key: number]: number;
    } = {};

    tsks.forEach((task) => {
      if (task.status === null) {
        types[0] = types[0] ? types[0] + 1 : 1;
        return;
      }
      types[task.status] = types[task.status] ? types[task.status] + 1 : 1;
    });

    const breakdown: {
      percentage: number;
      type: number;
    }[] = [];
    Object.entries(types).forEach(([key, value]) => {
      breakdown.push({
        percentage: (value / totalTasks) * 100,
        type: parseInt(key, 10),
      });
    });

    const sortedBreakdown = breakdown.sort((a, b) => {
      if (a.type < b.type) {
        return 1;
      }
      if (a.type > b.type) {
        return -1;
      }
      return 0;
    });

    const filteredBreakdown = sortedBreakdown.filter(
      () => true
    );

    return filteredBreakdown;
  };

  const taskTypeToColor: {
    [key: number]: string;
  } = {
    0: currentTheme.colors.backgroundContrast,
    1: `${currentTheme.colors.primary}90`,
    2: currentTheme.colors.primary,
    3: currentTheme.colors.background,
    4: `${currentTheme.colors.background}90`,
  };

  const getFormattedSections = (
    sections: {
      percentage: number;
      type: number;
    }[]
  ) => sections.map((sect) => ({
    color: taskTypeToColor[sect.type],
    value: sect.percentage,
  }));

  const [sections, setSections] = useState<
    {
      value: number;
      color: string;
    }[]
  >([]);

  useEffect(() => {
    if (tasks.length) {
      setSections(
        getFormattedSections(getTaskStatusBreakdown(tasks || []))
      );
    }
  }, [tasks]);

  const getCompleteness = () => {
    // use completed_tasks, total_tasks to calculate completeness
    const completedTasks = tasks.filter((task) => task.status === 2);
    const totalTasks = tasks.length;
    return Math.round((completedTasks.length / totalTasks) * 100);
  };

  return (
    <div css={css`
      padding: 24px 24px 36px 24px;
    `}
    >
      <Flex justify="space-between">
        <Text size="lg" weight="400" color={currentTheme.colors.text}>
          {getCompleteness()}
          %
        </Text>
        <Group position="left" spacing="lg">
          <Group position="center" spacing="xs">
            <ColorSwatch size={18} color={taskTypeToColor[4]} />
            <Text size="xs" color={currentTheme.colors.text}>Optional</Text>
          </Group>
          <Group position="center" spacing="xs">
            <ColorSwatch size={18} color={taskTypeToColor[3]} />
            <Text size="xs" color={currentTheme.colors.text}>Canceled</Text>
          </Group>
          <Group position="center" spacing="xs">
            <ColorSwatch size={18} color={taskTypeToColor[2]} />
            <Text size="xs" color={currentTheme.colors.text}>Completed</Text>
          </Group>
          <Group position="center" spacing="xs">
            <ColorSwatch size={18} color={taskTypeToColor[1]} />
            <Text size="xs" color={currentTheme.colors.text}>In Progress</Text>
          </Group>
          <Group position="center" spacing="xs">
            <ColorSwatch size={18} color={taskTypeToColor[0]} />
            <Text size="xs" color={currentTheme.colors.text}>Open</Text>
          </Group>
        </Group>
      </Flex>
      <br />
      {
        tasks.length ? (
          <Progress
            title="Collection Progress"
            sections={sections}
            style={{
              boxShadow: "0px .2px 5px rgba(0, 0, 0, 0.1)",
            }}
          />
        ) : (
          <Text
            size="sm"
            weight="300"
            color={currentTheme.colors.textLight}
            style={{
              textAlign: "center",
            }}
          >
            No tasks found
          </Text>
        )
      }
    </div>
  );
}

export default ProgressSection;
