import React, { useEffect, useState, } from "react";
import axios from "libs/axiosInstance";
import { EmailFragment as EmailFragmentType, } from "interfaces/main";
import { getModuleFields, } from "utils/methods";
import { useNavigate, useParams, } from "react-router-dom";
import { useSearch, useSettings, } from "hooks";
import { Loading, } from "components";
import { resolveTheme, } from "themes/main";
import IntegratedListView from "components/Views/IntegratedListView";

function EmailFragment() {
  const { organization_id, email_template_id, } = useParams();
  const [emailFragments, setEmailFragments] = useState<EmailFragmentType[]>([]);
  const { getTable, } = useSearch();
  const { sort, } = getTable("email_templates");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `/organizations/${organization_id}/email-templates/${email_template_id}/fragments`
      )
      .then((res) => {
        setEmailFragments(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    organization_id
  ]);

  const allFields: (keyof EmailFragmentType)[] | undefined =
    emailFragments?.[0] &&
    (Object.keys(emailFragments[0]) as unknown as (keyof EmailFragmentType)[]);

  const allowedFields = new Map([
    ["id", 0],
    ["name", 0],
    ["global", 0],
    ["created_at", 0],
    ["updated_at", 0]
  ]);

  const fields = getModuleFields(allowedFields, allFields);

  const getSubtitle = (t: EmailFragmentType) => {
    if (!sort.field) {
      return `${t.id}`;
    }
    return `${sort.field}: ${t[sort.field as keyof EmailFragmentType]}`;
  };

  const listItems = emailFragments.map((temp) => ({
    id: temp.id,
    title: temp.name,
    subtitle: getSubtitle(temp),
    endpoint: `/organizations/${organization_id}/email-templates/${email_template_id}/fragments/${temp.id}`,
    full_record: temp,
  }));

  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);
  const { colors, } = currentTheme;

  if (loading) {
    return <Loading />;
  }

  return (
    <IntegratedListView
      title="Fragments"
      with_separator
      buttons={[
        {
          text: "",
          handleClick: () => {
            navigate(`/organizations/${organization_id}/email-templates/${email_template_id}/fragments/create`);
          },
          icon: "add",
          variant: "default",
          color: colors.primary,
        }
      ]}
      search
      table_name="email_fragments"
      fields={fields}
      full_record_list={emailFragments}
      list_items={listItems}
      collapsable
    />
  );
}

export default EmailFragment;
