import React, { MouseEventHandler, useEffect, } from "react";

/** @jsxImportSource @emotion/react */
import { css, } from "@emotion/react";
import {
  Button, Title,
} from "@mantine/core";
import Loading from "components/Loading";
import { useSettings, } from "hooks";
import { resolveTheme, } from "themes/main";

interface CreateViewProps {
    children: React.ReactNode;
    title: string;
    buttons: {
        text: string;
        handleClick: MouseEventHandler<HTMLButtonElement>;
        color: string;
        variant?: "outline" | "white" | "light" | "default" | "filled" | "subtle" | "gradient"
        disabled?: boolean;
        invisible?: boolean;
    }[];
    loading?: boolean;
    formState: {
        [key: string]: string | number | null | undefined;
    } | any;
    requiredFields: string[];
    // eslint-disable-next-line no-unused-vars
    setValidated?: (validated: boolean) => void;
}

CreateView.defaultProps = {
  setValidated: () => {},
  loading: false,
};

function CreateView({
  children, title, buttons, loading, formState, requiredFields, setValidated,
}: CreateViewProps) {
  if (loading) {
    return <Loading />;
  }

  const getInvalidFields = () => {
    const invalidFields:string[] = [];
    requiredFields.forEach((field) => {
      if (!formState[field]) {
        invalidFields.push(field);
      }
    });
    return invalidFields;
  };

  const [invalidFields, setInvalidFields] = React.useState(getInvalidFields());

  useEffect(() => {
    setInvalidFields(getInvalidFields());
    if (setValidated) { setValidated(invalidFields.length === 0); }
  }, [formState, requiredFields]);

  useEffect(() => {
    if (setValidated) {
      setValidated(invalidFields.length === 0);
    }
  }, [invalidFields]);

  const { theme: { current: theme, }, } = useSettings();
  const currentTheme = resolveTheme(theme);

  return (
    <div
      css={css`
      padding: 36px 24px;

      @media (min-width: 768px) {
        padding: 36px 48px;
      }

      @media (min-width: 1024px) {
        padding: 36px 64px;
      }
    `}
    >
      <div>
        <Title
          order={1}
          weight={300}
          style={{
            fontFamily: "\"Roboto\", sans-serif",
            fontSize: "32px",
            fontWeight: 300,
          }}
          color={currentTheme.colors.text}
        >
          {title}
        </Title>
      </div>
      <hr />
      <div>
        {children}
      </div>
      <div css={css`
        margin-top: 24px;
      `}
      >
        {
            invalidFields.length > 0 && (
              <p
                css={css`
                  color: ${currentTheme.colors.textLight};
                  font-weight: 300;
                `}
              >
                Please fill out the following fields:
                {" "}
                <span css={css`
                    font-weight: 400;
                    color: ${currentTheme.colors.danger};
                `}
                >
                  {invalidFields.join(", ")}
                </span>
              </p>
            )
          }
      </div>
      <hr />
      <div css={css`
        display: flex;
        justify-content: flex-end;
      `}
      >
        {buttons.map((button, idx) => (
          !button.invisible && (
          <Button
            // eslint-disable-next-line react/no-array-index-key
            key={idx + button.text}
            onClick={button.handleClick}
            color={button.color}
            variant={button.variant}
            disabled={button.disabled}
            style={{ marginLeft: "14px", }}
          >
            {button.text}
          </Button>
          )))}
      </div>
    </div>
  );
}

export default CreateView;
