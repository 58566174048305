import React, { useEffect, useState, } from "react";
import { useParams, useNavigate, } from "react-router-dom";
import EditView from "components/Views/EditView";
import { axios, } from "libs";
import { Validation, } from "interfaces/responses";
import { Button, Grid, TextInput, } from "@mantine/core";
import { useAlert, useSettings, } from "hooks";
import { getFormattedMatterName, } from "utils/formatting";
import { Matter, } from "interfaces/main";
import { Default, } from "components/Views";
import JSONForm from "components/Inputs/JsonForm";
import { parseJsonFields, } from "utils/fetching";
import { resolveTheme, } from "themes/main";

function Edit() {
  const [matter, setMatter] = useState<Matter>();
  const [loading, setLoading] = useState(true);
  const [validated, setValidated] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [validationRules, setValidationRules] = useState<Validation>();
  const [requiredFields, setRequiredFields] = useState<string[]>([]);
  const { organization_id, matter_id, } = useParams();
  const { setAlert, } = useAlert();
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`/organizations/${organization_id}/matters/${matter_id}`)
      .then((response) => {
        const parsed = parseJsonFields(response.data.data);
        setMatter(parsed);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });

    axios
      .get(`/organizations/${organization_id}/matters/create`)
      .then((response) => {
        const { validation, } = response.data.data;
        setRequiredFields(
          Object.keys(validation.rules).filter((rule) => {
            if (!validation.rules[rule].includes("required")) {
              return false;
            }
            return true;
          })
        );
        setValidationRules(validation);
      });
  }, []);

  const handleSubmit = () => {
    setLoading(true);
    axios
      .put(`/organizations/${organization_id}/matters/${matter_id}`, matter)
      .then((response) => {
        setValidated(false);
        setMatter({
          ...matter,
          ...response.data.data,
        });
        setAlert({
          type: "success",
          message: "Matter updated successfully",
        });
        setLoading(false);
        navigate(`/organizations/${organization_id}/matters/${matter_id}`);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        setAlert({
          type: "danger",
          message: "Error updating matter",
        });
        setLoading(false);
      });
  };

  const setDetails = (details: Matter["details"]) => {
    setMatter({
      ...(matter as Matter),
      details,
    });
  };

  const setCredentials = (credentials: Matter["credentials"]) => {
    setMatter({
      ...(matter as Matter),
      credentials,
    });
  };

  const { theme: { current, }, } = useSettings();
  const currentTheme = resolveTheme(current);
  const { colors, } = currentTheme;

  return (
    <>
      <EditView
        title={
          matter ? `Edit ${getFormattedMatterName(matter)}` : "Edit Matter"
        }
        buttons={
          [
            // {
            //   text: "Submit",
            //   handleClick: handleSubmit,
            //   color: "digitalPurple",
            //   variant: "filled",
            //   disabled: !validated
            // }
          ]
        }
        loading={loading}
        formState={matter}
        requiredFields={requiredFields}
        setValidated={setValidated}
      >
        <Grid>
          <Grid.Col sm={12} md={6}>
            <TextInput
              label="Name"
              value={matter?.name}
              onChange={(e) => {
                setMatter({
                  ...(matter as Matter),
                  name: e.target.value,
                });
              }}
              required={validationRules?.rules?.name?.includes("required")}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6}>
            <TextInput
              label="Matter Code"
              value={matter?.code}
              onChange={(e) => {
                setMatter({
                  ...(matter as Matter),
                  code: e.target.value,
                });
              }}
              required={validationRules?.rules?.code?.includes("required")}
            />
          </Grid.Col>
        </Grid>
      </EditView>
      <Default>
        <JSONForm
          title="Details"
          setFields={setDetails}
          fields={matter?.details || {}}
        />
        <br />
        <br />
        <hr />
        <br />
        <br />
        <JSONForm
          title="Credentials"
          setFields={setCredentials}
          fields={matter?.credentials || {}}
        />

        <hr />
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
          }}
        >
          <Button
            color={colors.primary}
            onClick={handleSubmit}
            disabled={!validated}
          >
            Submit
          </Button>
        </div>
      </Default>
    </>
  );
}

export default Edit;
