import React from "react";
import { Loader, Text, } from "@mantine/core";
import { useSettings, } from "hooks";
import { resolveTheme, } from "themes/main";
/** @jsxImportSource @emotion/react */
import { css, } from "@emotion/react";

// eslint-disable-next-line react/require-default-props
function InlineLoader({ text = "", }: { text?: string }) {
  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);

  return (
    <span
      css={css`
            display: flex;
            align-items: center;
            gap: 14px;
        `}
    >
      <Loader size="sm" color={currentTheme.colors.textLight} />
      <Text color={currentTheme.colors.textLight}>
        {text}
      </Text>
    </span>
  );
}

export default InlineLoader;
