import { Fields, } from "interfaces/main";
import React from "react";
import JSONSection from "components/Inputs/JsonFields";

interface DetailsProps {
  details: Fields;
  // eslint-disable-next-line no-unused-vars
  submitDetails: (details: Fields) => void;
  disabled?: boolean;
  readonly?: boolean;
  display_only?: boolean;
}

DetailsSection.defaultProps = {
  disabled: false,
  readonly: false,
  display_only: false,
};

function DetailsSection({
  details,
  submitDetails,
  disabled,
  readonly,
  display_only,
}: DetailsProps) {
  return (
    <JSONSection
      title="Details"
      fields={details}
      submitFields={submitDetails}
      disabled={disabled}
      readonly={readonly}
      display_only={display_only}
    />
  );
}

export default DetailsSection;
