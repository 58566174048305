import React, { useEffect, useState, } from "react";
import { useLocation, useParams, } from "react-router-dom";
import { Anchor, Breadcrumbs as MantineBreadcrumbs, } from "@mantine/core";
/** @jsxImportSource @emotion/react */
import { css, } from "@emotion/react";
import { axios, } from "libs";
import {
  getFormattedCustodianName,
  getFormattedDeviceName,
  getFormattedMatterName,
} from "utils/formatting";
import {
  Collection,
  Custodian,
  Device,
  DeviceType,
  EmailFragment,
  EmailTemplate,
  Kit,
  Matter,
  Organization,
  Task,
  Template,
} from "interfaces/main";
import { User, } from "interfaces/user";
import { useSettings, useUser, } from "hooks";
import { resolveTheme, } from "themes/main";

type AvailableParams =
  | "organization_id"
  | "matter_id"
  | "device_id"
  | "device_type_id"
  | "user_id"
  | "template_id"
  | "task_id"
  | "kit_id"
  | "custodian_id"
  | "collection_id"
  | "email_template_id"
  | "email_fragment_id";

const getFormattedModuleName = (
  module:
    | Organization
    | Matter
    | Template
    | Device
    | DeviceType
    | Custodian
    | Collection
    | Task
    | User
    | Kit
    | EmailTemplate
    | EmailFragment,
  type: AvailableParams
) => {
  switch (type) {
    case "organization_id":
      return (module as Organization).name;
    case "matter_id":
      return getFormattedMatterName(module as Matter);
    case "device_id":
      return getFormattedDeviceName(module as Device);
    case "device_type_id":
      return (module as DeviceType).name;
    case "user_id":
      return (module as User).name;
    case "template_id":
      return (module as Template).name;
    case "task_id":
      return (module as Task).description;
    case "kit_id":
      return (module as Kit).name;
    case "custodian_id":
      return getFormattedCustodianName(module as Custodian);
    case "collection_id":
      return (module as Collection).name;
    case "email_template_id":
      return (module as EmailTemplate).name;
    case "email_fragment_id":
      return (module as EmailFragment).name;
    default:
      return "";
  }
};

const getFormattedPathPoint = (pathpoint: string) => {
  // looks something like "organizations" or "device-types"
  const formatted = pathpoint
    .split("-")
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(" ");
  return formatted;
};

interface BreadcrumbModule {
  name: string;
  parent: AvailableParams | AvailableParams[] | undefined;
  // eslint-disable-next-line no-unused-vars
  endpoint: (params: { [key: string]: string | undefined }) => string;
}

interface ResolvedBreadcrumbModule {
  name: string;
  endpoint: string;
  type: string;
  moduleName: string;
}

interface BreadcrumbModules {
  [key: string]: BreadcrumbModule;
}

function Breadcrumbs() {
  const params = useParams();

  const moduleBreakdown: BreadcrumbModules = {
    organization_id: {
      name: "Organization",
      parent: undefined,
      endpoint: () => `/organizations/${params.organization_id}`,
    },
    matter_id: {
      name: "Matter",
      parent: "organization_id",
      endpoint: () => `/organizations/${params.organization_id}/matters/${params.matter_id}`,
    },
    device_id: {
      name: "Device",
      parent: "organization_id",
      endpoint: () => `/organizations/${params.organization_id}/devices/${params.device_id}`,
    },
    device_type_id: {
      name: "Device Type",
      parent: "organization_id",
      endpoint: () => `/organizations/${params.organization_id}/device-types/${params.device_type_id}`,
    },
    user_id: {
      name: "User",
      parent: "organization_id",
      endpoint: () => `/organizations/${params.organization_id}/users/${params.user_id}`,
    },
    template_id: {
      name: "Template",
      parent: "organization_id",
      endpoint: () => `/organizations/${params.organization_id}/templates/${params.template_id}`,
    },
    custodian_id: {
      name: "Custodian",
      parent: "matter_id",
      endpoint: () => `/organizations/${params.organization_id}/matters/${params.matter_id}/custodians/${params.custodian_id}`,
    },
    kit_id: {
      name: "Kit",
      parent: "matter_id",
      endpoint: () => `/organizations/${params.organization_id}/matters/${params.matter_id}/kits/${params.kit_id}`,
    },
    collection_id: {
      name: "Collection",
      parent: "matter_id",
      endpoint: () => `/organizations/${params.organization_id}/matters/${params.matter_id}/custodians/${params.custodian_id}/collections/${params.collection_id}`,
    },
    task_id: {
      name: "Task",
      parent: [
        "matter_id",
        "custodian_id",
        "kit_id",
        "collection_id",
        "template_id"
      ],
      endpoint: ({
        matter_id,
        collection_id,
        kit_id,
        custodian_id,
        template_id,
      }: {
        matter_id?: string;
        custodian_id?: string;
        kit_id?: string;
        collection_id?: string;
        template_id?: string;
      }) => {
        if (collection_id) {
          return `/organizations/${params.organization_id}/matters/${matter_id}/custodians/${custodian_id}/collections/${collection_id}/tasks/${params.task_id}`;
        }
        if (kit_id) {
          return `/organizations/${params.organization_id}/matters/${matter_id}/kits/${kit_id}/tasks/${params.task_id}`;
        }
        if (custodian_id) {
          return `/organizations/${params.organization_id}/matters/${matter_id}/custodians/${custodian_id}/tasks/${params.task_id}`;
        }
        if (template_id) {
          return `/organizations/${params.organization_id}/templates/${template_id}/tasks/${params.task_id}`;
        }
        return `/organizations/${params.organization_id}/matters/${matter_id}/tasks/${params.task_id}`;
      },
    },
    email_template_id: {
      name: "Email",
      parent: "organization_id",
      endpoint: () => `/organizations/${params.organization_id}/email-templates/${params.email_template_id}`,
    },
    email_fragment_id: {
      name: "Fragment",
      parent: "email_template_id",
      endpoint: () => `/organizations/${params.organization_id}/email-templates/${params.email_template_id}/fragments/${params.email_fragment_id}`,
    },
  };

  const ignoreParams = ["step"];

  const getDrilldown = () => {
    const drilldown: ResolvedBreadcrumbModule[] = [];
    Object.keys(params).forEach((key) => {
      if (ignoreParams.includes(key)) {
        return;
      }
      const resolvedBreadcrumbModule: ResolvedBreadcrumbModule = {
        name: moduleBreakdown[key].name,
        endpoint: moduleBreakdown[key].endpoint(params),
        type: key as AvailableParams,
        moduleName: moduleBreakdown[key].name,
      };
      drilldown.push(resolvedBreadcrumbModule);
    });
    return drilldown;
  };

  const resolveDrilldownModules = async () => {
    const drilldown = getDrilldown();
    const promises = drilldown.map(async (dr) => {
      const mResponse = await axios
        .get(dr.endpoint)
        .then((res) => res.data.data)
        .catch(() => ({
          name: "N/A",
        }));
      return {
        ...dr,
        name: getFormattedModuleName(mResponse, dr.type as AvailableParams),
      };
    });
    setDrilldown(await Promise.all(promises));
  };

  useEffect(() => {
    resolveDrilldownModules();
  }, [params]);

  const [drilldown, setDrilldown] = useState<ResolvedBreadcrumbModule[]>([]);

  const { pathname, } = useLocation();
  // eslint-disable-next-line no-unused-vars
  const { hasRole, } = useUser();

  const rules: {
    [key: string]: {
      show: boolean;
    };
  } = {
    organizations: {
      show: hasRole("superuser"),
    },
    organization_id: {
      show: hasRole("superuser"),
    },
  };

  const [fullPath, setFullPath] = useState<ResolvedBreadcrumbModule[]>([]);

  const showFullPath = false;

  const getFullPath = () => {
    if (!showFullPath) {
      return drilldown;
    }
    const drdn = [...drilldown].reverse();
    const fullpath = pathname
      .split("/")
      .filter((p) => p)
      .map((p) => {
        if (Number(p) && drdn.length > 0) {
          return drdn.pop() as ResolvedBreadcrumbModule;
        }

        return {
          name: getFormattedPathPoint(p),
          endpoint: "",
          type: p,
          moduleName: getFormattedPathPoint(p),
        };
      })
      .filter((p) => {
        if (rules[p.type] && !rules[p.type].show) {
          return false;
        }
        return true;
      });

    for (let i = 0; i < fullpath.length; i += 1) {
      const point = fullpath[i];
      if (!point.endpoint) {
        const pathnameUntilPoint = pathname.slice(
          0,
          pathname.indexOf(point.type) + point.type.length + 1
        );
        fullpath[i].endpoint = pathnameUntilPoint;
      }
    }

    return fullpath;
  };

  useEffect(() => {
    setFullPath(getFullPath());
  }, [drilldown]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (!fullPath.length) {
    return null;
  }

  return (
    <div>
      <ResolveComponent isMobile={isMobile} fullPath={fullPath} />
    </div>
  );
}

export default Breadcrumbs;

function ResolveComponent({
  isMobile,
  fullPath,
}: {
  isMobile: boolean;
  fullPath: ResolvedBreadcrumbModule[];
}) {
  if (isMobile) {
    return <MobileBreadcrumbs fullPath={fullPath} />;
  }
  return <DesktopBreadcrumbs fullPath={fullPath} />;
}

function DesktopBreadcrumbs({
  fullPath,
}: {
  fullPath: ResolvedBreadcrumbModule[];
}) {
  const {
    theme: { current, },
  } = useSettings();
  const { colors, } = resolveTheme(current);

  return (
    <div
      css={css`
        background-color: ${colors.background};
        padding: 24px 24px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 14px;

        @media (min-width: 768px) {
          padding: 24px 48px;
        }

        @media (min-width: 1024px) {
          padding: 24px 64px;
        }
      `}
    >
      <MantineBreadcrumbs>
        {fullPath.map((breadcrumb) => (
          <div key={breadcrumb.name + breadcrumb.endpoint}>
            <Anchor
              href={breadcrumb.endpoint}
              key={breadcrumb.name}
              style={{
                position: "relative",
              }}
            >
              {breadcrumb.name}
              {" "}
              <div
                style={{
                  position: "absolute",
                  top: "17px",
                  right: "0",
                  left: "0",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <span
                  style={{
                    fontSize: "9px",
                    color: colors.textLight,
                    opacity: 0.4,
                    fontWeight: 300,
                  }}
                >
                  {breadcrumb.moduleName}
                </span>
              </div>
            </Anchor>
          </div>
        ))}
      </MantineBreadcrumbs>
    </div>
  );
}

function MobileBreadcrumbs({
  fullPath,
}: {
  fullPath: ResolvedBreadcrumbModule[];
}) {
  const {
    theme: { current, },
  } = useSettings();
  const { colors, } = resolveTheme(current);

  const [opened, setOpened] = useState(false);

  const lastElement = fullPath[fullPath.length - 1];

  return (
    <div
      css={css`
        padding: 18px 24px;
        display: flex;
        gap: 14px;
      `}
    >
      <div>
        <button
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            flex-wrap: wrap;
            height: 36px;
            width: 36px;
            background-color: transparent;
            cursor: pointer;
            border-radius: 50%;
            transition: all 0.2s ease-in-out;

            &:hover {
              transform: scale(0.95);
              box-shadow: inset 0.2px 0.2px 8px rgba(0, 0, 0, 0.15);
            }

            &:active {
              transform: scale(0.94);
            }
          `}
          onClick={() => setOpened(!opened)}
          tabIndex={0}
          type="button"
        >
          <i
            className="material-symbols-outlined"
            style={{
              color: colors.text,
            }}
          >
            {opened ? "expand_more" : "chevron_right"}
          </i>
        </button>
      </div>
      {opened ? (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 14px;
          `}
        >
          {fullPath.map((breadcrumb) => (
            <div>
              <Anchor
                href={breadcrumb.endpoint}
                key={breadcrumb.name}
                style={{
                  position: "relative",
                }}
              >
                {breadcrumb.name}
                {" "}
                <div
                  style={{
                    position: "absolute",
                    top: "17px",
                    right: "0",
                    left: "0",
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <span
                    style={{
                      fontSize: "9px",
                      color: colors.textLight,
                      opacity: 0.4,
                      fontWeight: 300,
                    }}
                  >
                    {breadcrumb.moduleName}
                  </span>
                </div>
              </Anchor>
            </div>
          ))}
        </div>
      ) : (
        <div>
          <Anchor
            href={lastElement.endpoint}
            key={lastElement.name}
            style={{
              position: "relative",
            }}
          >
            {lastElement.name}
            {" "}
            <div
              style={{
                position: "absolute",
                top: "17px",
                right: "0",
                left: "0",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <span
                style={{
                  fontSize: "9px",
                  color: colors.textLight,
                  opacity: 0.4,
                  fontWeight: 300,
                }}
              >
                {lastElement.moduleName}
              </span>
            </div>
          </Anchor>
        </div>
      )}
    </div>
  );
}
