import React from "react";
/** @jsxImportSource @emotion/react */
import { css, } from "@emotion/react";
import { useSettings, } from "hooks";
import { resolveTheme, } from "themes/main";

DatePicker.defaultProps = {
  disabled: false,
};

function DatePicker({
  value,
  placeholder,
  required,
  label,
  onChange,
  disabled,
  name,
}: {
  value: string | Date;
  placeholder: string;
  required: boolean;
  label: string;
  // eslint-disable-next-line no-shadow, no-unused-vars
  onChange: (value: Date) => void;
  disabled?: boolean;
  name: string;
}) {
  const randomID =
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15);

  const getParsedValue = () => {
    if (value instanceof Date) {
      return value.toISOString().split("T")[0];
    }
    return value;
  };

  const { theme: { current, }, } = useSettings();
  const { colors, } = resolveTheme(current);

  return (
    <div css={css`
        display: flex;
        flex-direction: column;
        color: ${colors.text};
        box-sizing: border-box;
        
        label {
            font-size: 0.875rem;
            font-weight: 500;
            color: ${colors.textLight};
            margin-bottom: 0.25rem;
        }

        input {
            border: 1px solid ${colors.backgroundContrast};
            background-color: ${disabled ? colors.inputBackground : colors.inputBackground};
            color: ${disabled ? colors.textLight : colors.text};
            height: 2.25rem;
            box-sizing: border-box;
            border-radius: 0.25rem;
            padding: 0.5rem;
            font-size: 0.875rem;
            font-weight: var(--bs-body-font-weight);
            ${disabled ? "cursor: not-allowed;" : ""}
            ${disabled ? "opacity: 0.5;" : ""}
        }
    `}
    >
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor={randomID}>{label}</label>
      <input
        type="date"
        name={name}
        id={randomID}
        placeholder={placeholder}
        value={getParsedValue()}
        onChange={(e) => {
          const date = new Date(e.target.value);
          onChange(date);
        }}
        required={required}
        disabled={disabled}
      />
    </div>
  );
}

export default DatePicker;
