import React from "react";
import { Lightning, } from "phosphor-react";
/** @jsxImportSource @emotion/react */
import { css, } from "@emotion/react";
import { useSettings, } from "hooks";
import { resolveTheme, } from "themes/main";

interface QuickActionProps {
  name: string;
  onClick: () => void;
  icon?: JSX.Element;
}

QuickAction.defaultProps = {
  icon: <Lightning size={24} />,
};

export function QuickAction({ name, onClick, icon, }: QuickActionProps) {
  const {
    theme: { current, },
  } = useSettings();
  const { colors, } = resolveTheme(current);

  return (
    <button
      onClick={onClick}
      type="button"
      css={css`
        display: flex;
        justify-content: space-between;
        padding: 14px 24px;
        border: none;
        background-color: ${colors.background};
        color: ${colors.text};
        border-radius: 10px;
        transition: all 0.2s ease-in-out;

        &:hover {
          transform: scale(1.02);
        }

        &:active {
          transform: scale(0.98);
        }

        .icon {
          margin-right: 12px;
          color: ${colors.primary};
        }

        .text {
          margin: 0;
          padding: 0;
          font-size: 16px;
        }
      `}
    >
      <div className="icon">{icon}</div>
      <p className="text">{name}</p>
    </button>
  );
}

interface QuickActionsProps {
  actions: QuickActionProps[];
}

function QuickActions({ actions, }: QuickActionsProps) {
  return (
    <div>
      {actions.map((action) => (
        <QuickAction key={action.name} {...action} />
      ))}
    </div>
  );
}

export default QuickActions;
