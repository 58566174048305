import React, {
  createContext,
  useState,
  ReactNode,
  useMemo,
  useEffect,
  useRef,
} from "react";
import { AxiosError, AxiosResponse, } from "axios";
import { unstable_batchedUpdates, } from "react-dom";
import { axios, getCookie, } from "libs";
import { User, } from "interfaces/user";

interface ContextUser extends Omit<User, "id"> {
  id: number | null;
}

export type UserRoles =
  | "hyperuser"
  | "superuser"
  | "poweruser"
  | "user"
  | "restricted"
  | "view-only";

export interface UserContextProps {
  user: ContextUser;
  isLoggedIn: boolean;
  // eslint-disable-next-line no-unused-vars
  setUser: (config: User, rememberMe?: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  setIsLoggedIn: (bool: boolean) => void;
  removeUser: () => void;
  // eslint-disable-next-line no-unused-vars
  hasRole: (role: UserRoles) => boolean;
  isLoading: boolean;
  // eslint-disable-next-line no-unused-vars
  setIsLoading: (bool: boolean) => void;
  getUserOrganization: () => User["organizations"][0] | null;
}

const initialUser = {
  id: null,
  name: "",
  email: "",
  role: {
    created_at: "",
    id: null,
    name: "",
    updated_at: "",
  },
  organizations: [],
  organization_id: null,
  role_id: null,
  two_factor_recovery_codes: [],
  two_factor_secret: "",
  created_at: "",
  updated_at: "",
};

const UserContext = createContext<UserContextProps>({
  user: initialUser,
  isLoggedIn: false,
  setUser: () => {},
  setIsLoggedIn: () => {},
  removeUser: () => {},
  hasRole: () => false,
  isLoading: false,
  setIsLoading: () => {},
  getUserOrganization: () => null,
});

export function UserProvider(props: { children: ReactNode }): JSX.Element {
  const isMounted = useRef(false);
  const { children, } = props;
  const [userContext, setUserContext] = useState<ContextUser>(initialUser);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const onSuccess = (response: AxiosResponse): void => {
    unstable_batchedUpdates(() => {
      if (response.config.url === "/me") {
        setUserContext(response.data.data);
        setIsLoggedIn(true);
      }
    });
  };
  const onError = (error: AxiosError): void => {
    // eslint-disable-next-line no-console
    console.info("Auth from remember token", error);
  };

  useEffect(() => {
    const XSRF_TOKEN = getCookie("XSRF-TOKEN");
    if ((XSRF_TOKEN && isLoggedIn) || !isMounted.current) {
      setIsLoading(true);
      axios
        .get("/me")
        .then(onSuccess)
        .catch(onError)
        .finally(() => {
          setIsLoading(false);
        });
    }
    isMounted.current = true;
  }, [isLoggedIn]);

  const value: UserContextProps = useMemo<UserContextProps>(
    () => ({
      user: userContext,
      isLoggedIn,
      isLoading,
      setUser: (user: User, rememberMe?: boolean): void => {
        const newUser: User = {
          id: user.id || 0,
          name: user.name || "",
          email: user.email || "",
          organization_id: user.organization_id || user.organizations[0].id,
          role_id: user.role_id || 0,
          two_factor_recovery_codes: user.two_factor_recovery_codes || [],
          two_factor_secret: user.two_factor_secret || "",
          created_at: user.created_at || "",
          updated_at: user.updated_at || "",
          role: {
            created_at: user.role.created_at || "",
            id: user.role.id || 0,
            name: user.role.name || "",
            updated_at: user.role.updated_at || "",
          },
          organizations: user.organizations || [],
        };

        setUserContext(newUser);

        if (rememberMe) {
          //  handle remember me
        }
      },
      setIsLoggedIn: (bool: boolean): void => {
        setIsLoggedIn(bool);
      },
      removeUser: (): void => {
        unstable_batchedUpdates(() => {
          setUserContext(initialUser);
          setIsLoggedIn(false);
          axios.post("/auth/logout");
        });
      },
      hasRole: (role: string): boolean => {
        if (!userContext.role || !userContext.role.name) return false;
        const role_priority = [
          "hyperuser",
          "superuser",
          "poweruser",
          "user",
          "restricted",
          "view-only"
        ];

        const rolePriority = role_priority.indexOf(role);
        const validRoles = role_priority.slice(0, rolePriority + 1);
        return validRoles.includes(userContext.role.name);
      },
      setIsLoading: (bool: boolean): void => {
        setIsLoading(bool);
      },
      getUserOrganization: (): User["organizations"][0] | null => {
        if (!userContext.organizations || !userContext.organizations.length) {
          return null;
        }
        return userContext.organizations[0];
      },
    }),
    [userContext, isLoggedIn, isLoading]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export const UserConsumer = UserContext.Consumer;

export default UserContext;
