import { EmailFragment as EmailFragmentDefinition, } from "interfaces/main";
import React, { useEffect, useState, } from "react";
import { useParams, useNavigate, } from "react-router-dom";
import { Details as DetailedView, } from "components/Views";
import { axios, } from "libs";
import { useAlert, useSettings, } from "hooks";
import { Loading, } from "components";
import { Grid, } from "@mantine/core";
import TextDisplay from "components/Display/TextDisplay";
import { parseJsonFields, } from "utils/fetching";
import { resolveTheme, } from "themes/main";

import HTMLDisplay from "components/Display/HTMLDisplay";

function EmailFragment() {
  const { organization_id, email_template_id, email_fragment_id, } = useParams();
  const [fragment, setFragment] = useState<EmailFragmentDefinition>();
  const [loading, setLoading] = useState(true);
  const { setAlert, setConfirmation, } = useAlert();
  const navigate = useNavigate();

  const getFragment = () => axios
    .get(
      `/organizations/${organization_id}/email-templates/${email_template_id}/fragments/${email_fragment_id}`
    )
    .then((res) => {
      const parsed = parseJsonFields(res.data.data);
      setFragment(parsed);
    })
    .catch((err) => {
      console.error(err);
      setAlert({ message: err.message, type: "danger", });
    })
    .finally(() => {
      setLoading(false);
    });

  useEffect(() => {
    setLoading(true);
    getFragment();
  }, [
    organization_id
  ]);

  const handleDelete = () => {
    setConfirmation("Are you sure you want to delete this fragment?", () => {
      setLoading(true);
      axios
        .delete(
          `/organizations/${organization_id}/emails-templates/${email_template_id}/fragments/${email_fragment_id}`
        )
        .then(() => {
          setAlert({
            message: "Fragment deleted successfully",
            type: "success",
          });
          navigate(
            `/organizations/${organization_id}/email-templates/${email_template_id}`
          );
        })
        .catch((err) => {
          console.error(err);
          setAlert({
            message: "There was a problem deleting the fragment",
            type: "danger",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);
  const { colors, } = currentTheme;

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <DetailedView
        title={fragment?.name || "Fragment"}
        buttons={[
          // {
          //   text: "Tasks",
          //   handleClick: () => {
          //     navigate("tasks");
          //   },
          //   variant: "subtle",
          //   color: "digitalPurple"
          // },
          {
            text: "Edit",
            handleClick: () => {
              navigate("edit");
            },
            variant: "default",
            color: colors.primary,
          },
          {
            text: "Delete",
            handleClick: handleDelete,
            color: colors.danger,
            variant: "filled",
          }
        ]}
      >
        <Grid>
          <Grid.Col sm={12} md={6}>
            <TextDisplay label="Name" data={fragment?.name} />
          </Grid.Col>
          <Grid.Col sm={12} md={6}>
            <TextDisplay label="content" data={fragment?.content} />
          </Grid.Col>
          <Grid.Col span={12}>
            <HTMLDisplay
              label="Preview"
              data={fragment?.content || ""}
              secure
            />
          </Grid.Col>
        </Grid>
      </DetailedView>
    </div>
  );
}

export default EmailFragment;
