import React, { useEffect, useState, } from "react";
import IntegratedListView from "components/Views/IntegratedListView";
import { useAlert, useSearch, useSettings, } from "hooks";
import { DeviceType, } from "interfaces/main";
import axios from "libs/axiosInstance";
import { useNavigate, useParams, } from "react-router-dom";
import { getFormattedTimeString, } from "utils/formatting";
import { resolveTheme, } from "themes/main";
import { getModuleFields, } from "utils/methods";

function List() {
  const [deviceTypes, setDeviceTypes] = useState<DeviceType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { organization_id, } = useParams();
  const { getTable, } = useSearch();
  const { sort, } = getTable("device_types");

  // const { user } = useUser();
  const { setAlert, } = useAlert();
  //   const { hasRole } = useUser();

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/organizations/${organization_id}/device-types`)
      .then((res) => {
        setDeviceTypes(res.data.data);
      })
      .catch((err: any) => {
        // eslint-disable-next-line no-console
        console.error(err);
        setAlert({
          type: "danger",
          message: "Error fetching device types",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    organization_id
  ]);

  const allFields: (keyof DeviceType)[] | undefined =
    deviceTypes?.[0] &&
    (Object.keys(deviceTypes[0]) as unknown as (keyof DeviceType)[]);

  const allowedFields = new Map([
    ["id", 0],
    ["name", 0],
    ["global", 0],
    ["created_at", 0],
    ["updated_at", 0]
  ]);

  const fields = getModuleFields(allowedFields, allFields);

  const getSubtitle = (dt: DeviceType) => {
    if (!sort.field) {
      return dt.updated_at ?
        `Last update at ${getFormattedTimeString(dt.updated_at)}` :
        `Created at ${getFormattedTimeString(dt.created_at)}`;
    }
    return `${sort.field}: ${dt[sort.field as keyof DeviceType]}`;
  };
  const listItems = deviceTypes.map((deviceType) => ({
    id: deviceType.id,
    title: deviceType.name,
    subtitle: getSubtitle(deviceType),
    endpoint: `/organizations/${organization_id}/device-types/${deviceType.id}`,
    full_record: deviceType,
  }));

  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);
  const { colors, } = currentTheme;

  return (
    <div>
      <IntegratedListView
        title="Device Types"
        loading={loading}
        search
        buttons={[
          {
            text: "",
            icon: "add",
            handleClick: () => {
              navigate(`/organizations/${organization_id}/device-types/create`);
            },
            color: colors.primary,
            variant: "default",
            // invisible: !hasRole("user")
          }
        ]}
        table_name="device_types"
        fields={fields}
        full_record_list={deviceTypes}
        list_items={listItems}
        with_separator={false}
        with_title={false}
      />
    </div>
  );
}

export default List;
