/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* @jsxImportSource @emotion/react */
import { css, keyframes, } from "@emotion/react";
import { Grid, Highlight, Text, } from "@mantine/core";
import Search from "components/Search/Search";
import SortDirection from "components/Search/SortDirection";
import { useSearch, useSettings, } from "hooks";
import { MouseEvent, useEffect, useState, } from "react";
import ActionButtons from "components/ActionButtons/ActionButtons";
import { resolveTheme, } from "themes/main";
import { useNavigate, } from "react-router-dom";
import { ArrowDown, ArrowUp, } from "phosphor-react";
import { itemSearchMatch, } from "utils/search";

interface ListItem {
  id: number;
  full_record: {
    [key: string]: any;
  };
  endpoint: string;
}

interface HeadedTableProps {
  list_items: ListItem[];
  fields: {
    name: string;
    priority: number;
  }[];
  search: boolean;
  table_name: string;
  actions: ("view" | "edit" | "delete" | "copy")[];
}

function HeadedTable({
  list_items,
  fields,
  search,
  table_name,
  // eslint-disable-next-line no-unused-vars
  actions,
}: HeadedTableProps) {
  const { getTable, setTableSort, clearTableSort, } = useSearch();
  const table = getTable(table_name);
  const { sort, query, } = table;

  const updateSort = (field: string) => {
    setTableSort(table_name, {
      ...sort,
      field,
    });
  };

  const clearSort = () => {
    clearTableSort(table_name);
  };

  const toggleSort = (field: string) => {
    // first, the sort should be updated to the field, and be on ascending
    // second, the sort direction should be toggled to descending
    // third, the sort should be cleared
    if (sort.field === field) {
      if (sort.direction === "asc") {
        setTableSort(table_name, {
          ...sort,
          direction: "desc",
        });
      } else {
        clearSort();
      }
    } else {
      updateSort(field);
    }
  };

  const filteredListItems = list_items.filter((item) => {
    if (query === "") {
      return true;
    }
    const match = itemSearchMatch(
      {
        title: String(item.id),
        subtitle: item.endpoint,
        fields: {
          ...item.full_record,
        },
      },
      table
    );
    return match.matches;
  });

  const sortedListItems = filteredListItems.sort((a, b) => {
    const aField = a.full_record[sort.field];
    const bField = b.full_record[sort.field];
    if (aField < bField) {
      return sort.direction === "asc" ? -1 : 1;
    }
    if (aField > bField) {
      return sort.direction === "asc" ? 1 : -1;
    }
    return 0;
  });

  const rowIn = keyframes`
    from {  
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
    `;

  const cellIn = keyframes`
    from {
        opacity: 0;
        transform: translateX(10px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
    `;

  const [contextMenu, setContextMenu] = useState<{
    x: number;
    y: number;
    visible: boolean;
    full_item: ListItem | null;
    copyText: string;
  }>({
    x: 0,
    y: 0,
    visible: false,
    full_item: null,
    copyText: "",
  });

  useEffect(() => {
    const handleWindowClick = () => {
      setContextMenu({
        x: 0,
        y: 0,
        visible: false,
        full_item: null,
        copyText: "",
      });
    };

    window.addEventListener("click", handleWindowClick);

    return () => {
      window.removeEventListener("click", handleWindowClick);
    };
  }, []);

  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);

  const isTablet = window.innerWidth < 1280;

  const prioritizedFields = fields.filter((field) => {
    if (isTablet) {
      return field.priority < 1;
    }
    return field.priority < 2;
  });

  return (
    <div
      tabIndex={0}
      role="button"
      onClick={() => {
        setContextMenu({
          x: 0,
          y: 0,
          visible: false,
          full_item: null,
          copyText: "",
        });
      }}
    >
      {search && (
        <Grid
          style={{
            margin: "14px 0",
            padding: "0",
          }}
          gutter={8}
        >
          <Grid.Col sm={12} md={6}>
            <Search table_name={table_name} global={false} />
          </Grid.Col>
          <Grid.Col sm={6} md={6}>
            <SortDirection table_name={table_name} />
          </Grid.Col>
        </Grid>
      )}
      {list_items.length > 0 ? (
        <div
          css={css`
            box-shadow: 0.2px 0.2px 10px 0 rgba(0, 0, 0, 0.1);
            transition: 0.3s ease-in-out all;
            padding: 24px;
            background-color: ${currentTheme.colors.background};
            border-radius: 10px;

            &:hover {
              box-shadow: 0.2px 0.2px 15px 0 rgba(0, 0, 0, 0.1);
            }

            table {
              width: 100%;
              box-sizing: border-box;
              font-size: 14px;
            }

            th {
              text-align: center;
              font-weight: 500;
              box-sizing: border-box;
              padding: 8px 4px;
              cursor: pointer;
              transition: all 0.2s ease-in-out;
              border-bottom: 1px solid ${currentTheme.colors.backgroundContrast};
              color: ${currentTheme.colors.text};

              &:hover {
                background-color: ${currentTheme.colors.backgroundAlt};
              }
            }

            tr {
              padding: 14px 0;
              border-bottom: 1px solid ${currentTheme.colors.backgroundContrast};
              animation: ${rowIn} 0.4s ease-in-out;
              transition: all 0.2s ease-in-out;
              position: relative;

              &:last-child {
                border-bottom: none;
              }
            }

            td {
              text-align: center;
              box-sizing: border-box;
              padding: 14px 0px;
              font-size: 12px;
              cursor: pointer;
              animation: ${cellIn} 0.6s ease-in-out;
              text-overflow: ellipsis;

              &:hover {
                background-color: ${currentTheme.colors.backgroundAlt};
              }
            }
          `}
        >
          <table>
            <thead>
              <tr>
                {prioritizedFields.map((field) => (
                  <th
                    key={field.name}
                    title={`Sort by ${field}`}
                    style={{
                      color:
                        sort.field === field.name ?
                          currentTheme.colors.primary :
                          "",
                    }}
                    onClick={() => {
                      toggleSort(field.name);
                    }}
                  >
                    <span>{field.name}</span>
                    {sort.field === field.name && (
                      <span
                        style={{
                          marginLeft: "8px",
                        }}
                      >
                        {sort.direction === "asc" ? (
                          <ArrowDown size={14} />
                        ) : (
                          <ArrowUp size={14} />
                        )}
                      </span>
                    )}
                  </th>
                ))}
                {/* <th>Actions</th> */}
              </tr>
            </thead>
            <tbody>
              {sortedListItems.map((item) => (
                <tr key={item.id}>
                  {prioritizedFields.map((field) => (
                    <TableCell
                      key={field.name}
                      item={item}
                      field={field.name}
                      table_name={table_name}
                      setContextMenu={setContextMenu}
                    />
                  ))}
                  {/* <td
                  css={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  `}
                >
                  <div
                    css={css`
                      width: fit-content;
                    `}
                  >
                    <ActionButtons
                      view={actions.includes("view")}
                      edit={actions.includes("edit")}
                      delete={actions.includes("delete")}
                      endpoint={item.endpoint}
                    />
                  </div>
                </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <Text
          size="md"
          weight="300"
          color={currentTheme.colors.textLight}
          align="center"
        >
          There are no
          {" "}
          {table_name}
          .
        </Text>
      )}
      <ContextMenu
        x={contextMenu.x}
        y={contextMenu.y}
        visible={contextMenu.visible}
        item={contextMenu.full_item}
        actions={actions}
        copyText={contextMenu.copyText}
      />
    </div>
  );
}

export default HeadedTable;

function TableCell({
  item,
  field,
  table_name,
  setContextMenu,
}: {
  item: ListItem;
  field: string;
  table_name: string;
  setContextMenu: ({
    // eslint-disable-next-line no-unused-vars
    x,
    // eslint-disable-next-line no-unused-vars
    y,
    // eslint-disable-next-line no-unused-vars
    visible,
    // eslint-disable-next-line no-unused-vars
    full_item,
    // eslint-disable-next-line no-unused-vars
    copyText,
  }: {
    x: number;
    y: number;
    visible: boolean;
    full_item: ListItem | null;
    copyText: string;
  }) => void;
}) {
  const { getTable, } = useSearch();
  const table = getTable(table_name);
  const { query, queryBreakdown, } = table;

  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);

  const handleContextMenu = (e: MouseEvent) => {
    e.preventDefault();
    setContextMenu({
      x: e.clientX,
      y: e.clientY,
      visible: true,
      full_item: item,
      copyText: item.full_record[field],
    });
  };

  const containsQuery = (f: string) => {
    if (!query) {
      return false;
    }
    const stringField = String(f);
    if (
      queryBreakdown.must_include.some((q) => stringField.includes(q)) ||
      queryBreakdown.may_include.some((q) => !stringField.includes(q))
    ) {
      return true;
    }
    return false;
  };

  const trimField = (f: string) => {
    const stringField = String(f);
    if (stringField.length > 20) {
      return `${stringField.slice(0, 20)}...`;
    }
    return stringField;
  };

  const navigate = useNavigate();

  return (
    <td
      key={field}
      title={item.full_record[field]}
      style={{
        backgroundColor: containsQuery(item.full_record[field]) ?
          currentTheme.colors.highlight :
          "",
      }}
      data-value={item.full_record[field]}
      onContextMenu={(e) => {
        e.preventDefault();
        handleContextMenu(e);
      }}
      onBlur={() => {
        setContextMenu({
          x: 0,
          y: 0,
          visible: false,
          full_item: null,
          copyText: "",
        });
      }}
    >
      <div>
        <Highlight
          highlight={[
            ...queryBreakdown.must_include,
            ...queryBreakdown.may_include
          ]}
          highlightStyles={() => ({
            fontWeight: 500,
            WebkitBackgroundClip: "text",
            color: currentTheme.colors.primary,
          })}
          onClick={(e) => {
            e.stopPropagation();
            navigate(item.endpoint);
          }}
          style={{
            color: currentTheme.colors.text,
          }}
        >
          {trimField(item.full_record[field])}
        </Highlight>
      </div>
    </td>
  );
}

function ContextMenu({
  x,
  y,
  visible,
  item,
  actions,
  copyText,
}: {
  x: number;
  y: number;
  visible: boolean;
  item: ListItem | null;
  actions: ("view" | "edit" | "delete" | "copy")[];
  copyText: string;
}) {
  const menuIn = keyframes`
    0% {
      opacity: 0;
      transform: scale(0.9);
      height: 0;
    }
    100% {
      opacity: 1;
      transform: scale(1);
      height: initial;
    }
  `;

  const {
    theme: { current: theme, },
  } = useSettings();
  const currentTheme = resolveTheme(theme);

  if (!visible) {
    return null;
  }

  return (
    <div
      style={{
        position: "fixed",
        top: y,
        left: x,
        visibility: visible ? "visible" : "hidden",
      }}
      css={css`
        background-color: ${currentTheme.colors.background};
        border-radius: 5px;
        padding: 8px;
        box-shadow: 0.2px 0.2px 10px rgba(0, 0, 0, 0.1);
        animation: ${menuIn} 0.3s ease-in-out;
        border: 1px solid ${currentTheme.colors.backgroundContrast};
      `}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <ActionButtons
        view={actions.includes("view")}
        edit={actions.includes("edit")}
        delete={actions.includes("delete")}
        endpoint={item?.endpoint || ""}
        copy={actions.includes("copy")}
        copyText={copyText || ""}
      />
    </div>
  );
}
