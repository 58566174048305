import React, { useEffect, useState, } from "react";
import { axios, } from "libs";
import { useParams, useNavigate, } from "react-router-dom";
import DetailsView from "components/Views/DetailsView";
import { useAlert, useSettings, useUser, } from "hooks";
import {
  DeviceType as DeviceTypeDefinition,
} from "interfaces/main";
/** @jsxImportSource @emotion/react */
// import { css } from "@emotion/react";
import { parseJsonFields, } from "utils/fetching";
import { Grid, } from "@mantine/core";
import TextDisplay from "components/Display/TextDisplay";
import JSONSection from "components/Inputs/JsonFields";
import { resolveTheme, } from "themes/main";

function DeviceType() {
  const [deviceType, setDeviceType] = useState<DeviceTypeDefinition | null>(null);
  const { organization_id, device_type_id, } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const alertContext = useAlert();
  const { hasRole, } = useUser();

  const getDevice = async () => {
    setLoading(true);
    axios
      .get(`/organizations/${organization_id}/device-types/${device_type_id}`)
      .then((res) => {
        const parsed = parseJsonFields(res.data.data);
        setDeviceType(parsed);
      })
      .catch((err) => {
        alertContext.setAlert({
          type: "danger",
          message: "Error fetching device",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getDevice();
  }, [
    organization_id,
    device_type_id
  ]);

  const handleDelete = () => {
    alertContext.setConfirmation(
      "Are you sure you want to delete this device?",
      () => {
        setLoading(true);
        axios
          .delete(`/organizations/${organization_id}/device-types/${device_type_id}`)
          .then(() => {
            alertContext.setAlert({
              type: "success",
              message: "Device deleted successfully",
            });
            navigate(`/organizations/${organization_id}/devices`);
          })
          .catch((err) => {
            alertContext.setAlert({
              type: "danger",
              message: "Error deleting device",
            });
            // eslint-disable-next-line no-console
            console.error(err);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    );
  };

  const submitFields = (fields: DeviceTypeDefinition["peripherals"]) => {
    setLoading(true);
    axios
      .put(`/organizations/${organization_id}/device-types/${device_type_id}`, {
        peripherals: fields,
      })
      .then(() => {
        alertContext.setAlert({
          type: "success",
          message: "Device updated successfully",
        });
        getDevice();
      })
      .catch((err) => {
        alertContext.setAlert({
          type: "danger",
          message: "Error updating device",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const { theme: { current, }, } = useSettings();
  const currentTheme = resolveTheme(current);
  const { colors, } = currentTheme;

  return (
    <div>
      <DetailsView
        title={deviceType?.name || "Device Type"}
        loading={loading}
        buttons={[
          // {
          //   text: "Custodians",
          //   handleClick: () => {
          //     navigate("custodians");
          //   },
          //   color: "digitalPurple",
          //   variant: "subtle"
          // },
          {
            text: "Edit",
            handleClick: () => {
              navigate("edit");
            },
            color: colors.primary,
            variant: "default",
            invisible: !hasRole("restricted"),
          },
          {
            text: "Delete",
            handleClick: handleDelete,
            color: colors.danger,
            variant: "filled",
            invisible: !hasRole("poweruser"),
          }
        ]}
      >
        <Grid>
          <Grid.Col sm={12} md={6}>
            <TextDisplay
              label="Name"
              data={deviceType?.name || "None provided"}
            />
          </Grid.Col>
        </Grid>
      </DetailsView>
      <JSONSection
        title="Peripherals"
        fields={deviceType?.peripherals || {}}
        submitFields={submitFields}
      />
    </div>
  );
}

export default DeviceType;
