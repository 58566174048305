import type { Theme, } from "./main.d";

export const lightTheme: Theme = {
  colors: {
    background: "#fff",
    backgroundAlt: "#f5f6f8",
    backgroundContrast: "#eaeaea",
    text: "#000",
    textLight: "#6e6e6e",
    link: "#80409B",
    primary: "#80409B",
    danger: "red",
    dangerContrast: "#fff",
    highlight: "#fff9db",
    success: "#4caf50",
    info: "#1c7ed6",
    inputBackground: "#fff",
    shadow: "rgba(0, 0, 0, 0.1)",
  },
  font: {
    family: "Inter, sans-serif",
  },
};

export const darkTheme: Theme = {
  colors: {
    background: "#1e1e1e",
    backgroundAlt: "#2e2e2e",
    backgroundContrast: "#3e3e3e",
    text: "#fff",
    textLight: "#C1C2C5",
    link: "#9264A5",
    primary: "#9264A5",
    danger: "red",
    dangerContrast: "#fff",
    highlight: "#3e3e3e",
    success: "#4caf50",
    info: "#1c7ed6",
    inputBackground: "#25262b",
    shadow: "rgba(0, 0, 0, 0.1)",
  },
  font: {
    family: "Inter, sans-serif",
  },
};

export const resolveTheme = (theme: string): Theme => {
  switch (theme) {
    case "light":
      return lightTheme;
    case "dark":
      return darkTheme;
    default:
      return lightTheme;
  }
};
