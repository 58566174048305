import {
  Grid,
  PasswordInput,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { Default, } from "components/Views";
import React, { ChangeEvent, } from "react";
/** @jsxImportSource @emotion/react */
import { css, } from "@emotion/react";
import { copyToClipboard, } from "utils/methods";
import { useAlert, useSettings, } from "hooks";
import { Field, Fields, } from "interfaces/main";
import { resolveTheme, } from "themes/main";

interface JSONProps {
  title: string;
  fields: Fields;
  // eslint-disable-next-line no-unused-vars
  submitFields: (fields: Fields) => void;
  disabled?: boolean;
  readonly?: boolean;
  display_only?: boolean;
}

JSONSection.defaultProps = {
  disabled: false,
  readonly: false,
  display_only: false,
};

function JSONSection({
  title, fields, submitFields, disabled, readonly, display_only,
}: JSONProps) {
  const updateField = (field: Field) => {
    if (disabled) return;
    submitFields({
      ...fields,
      [field.name]: field,
    });
  };

  const sortedFields = Object.values(fields).sort((a, b) => {
    const aSortOrder = a.sort_order || 1;
    const bSortOrder = b.sort_order || 1;
    return aSortOrder - bSortOrder;
  });

  const { theme: { current, }, } = useSettings();
  const currentTheme = resolveTheme(current);

  return (
    <Default title={title} collapsable>
      <Grid>
        {Object.keys(fields).length ? (
          sortedFields.map((field) => (
            <Grid.Col sm={12} md={4} key={field.label}>
              <JSONInput
                field={field}
                updateField={updateField}
                disabled={disabled}
                readonly={readonly}
                display_only={display_only}
              />
            </Grid.Col>
          ))
        ) : (
          <Grid.Col span={12}>
            <Text size="md" weight="300" color={currentTheme.colors.textLight} align="center">
              No details.
            </Text>
          </Grid.Col>
        )}
      </Grid>
    </Default>
  );
}

export default JSONSection;

JSONInput.defaultProps = {
  disabled: false,
  readonly: false,
  display_only: false,
};

export function JSONInput({
  field,
  updateField,
  disabled = false,
  readonly = false,
  display_only = false,
}: {
  field: Field;
  // eslint-disable-next-line no-unused-vars
  updateField: (fd: Field) => void;
  disabled?: boolean;
  readonly?: boolean;
  display_only?: boolean;
}) {
  const [editing, setEditing] = React.useState(false);
  const [newValue, setNewValue] = React.useState(field.value);
  const [copied, setCopied] = React.useState(false);

  const { setAlert, } = useAlert();

  const submitChange = () => {
    setEditing(false);
    updateField({
      ...field,
      value: newValue,
    });
  };

  const { theme: { current, }, } = useSettings();
  const currentTheme = resolveTheme(current);

  const style = {
    cursor: editing ? "text" : "pointer",
    color: currentTheme.colors.text,
  };

  const formattedLabel = `${field.label}${editing ? " - editing" : ""}`;

  const formattedPlaceholder = `${field.label}${editing ? " - editing" : ""}`;

  let Component: React.ForwardRefExoticComponent<
    any & React.RefAttributes<any>
  > = TextInput;
  switch (field.type) {
    case "text":
      Component = TextInput;
      break;
    case "textarea":
      Component = Textarea;
      break;
    case "password":
      Component = PasswordInput;
      break;
    default:
      Component = TextInput;
      break;
  }

  if (display_only) {
    return (
      <div>
        <Text size="sm" weight="bold" color={currentTheme.colors.textLight}>
          {field.label}
        </Text>
        <Text size="sm" color={currentTheme.colors.text}>
          {field.value || "N/A"}
        </Text>
      </div>
    );
  }

  return (
    <div
      css={css`
        position: relative;

        .icons {
          position: absolute;
          top: -2px;
          right: 0px;
        }

        .icon {
          // transform: translateY(-50%);
          padding: 4px;
          // background-color: #fff;
          border-radius: 50%;
          cursor: pointer;
          font-size: 14px;
          margin-left: 4px;

          &:hover {
            background-color: ${currentTheme.colors.backgroundContrast};
          }
        }

        .check {
          color: ${currentTheme.colors.success};
        }

        .delete {
          color: ${currentTheme.colors.danger};
        }

        .edit {
          color: ${currentTheme.colors.info};
        }

        .copy {
          color: ${currentTheme.colors.textLight}90;
          transition: all 0.2s ease;

          &:hover {
            color: ${currentTheme.colors.textLight};
          }
        }

        .copied {
          color: ${currentTheme.colors.success};
        }
      `}
    >
      <Component
        label={formattedLabel}
        value={newValue || ""}
        onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          setNewValue(e.currentTarget.value);
        }}
        style={style}
        readOnly={(!editing && !disabled) || readonly}
        placeholder={formattedPlaceholder}
        type={field.type}
        variant={editing ? "default" : "filled"}
        disabled={disabled}
        styles={{
          input: {
            backgroundColor: currentTheme.colors.background,
            border: `1px solid ${currentTheme.colors.backgroundContrast}`,
            boxShadow: `.2px .2px 10px 0 ${currentTheme.colors.shadow}`,
          },
        }}
      />
      {editing ? (
        <div className="icons">
          <i
            className="material-icons delete icon"
            onClick={(e) => {
              e.stopPropagation();
              setEditing(false);
              setNewValue(field.value);
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
              if (e.key === "Enter") {
                setEditing(false);
                setNewValue(field.value);
              }
            }}
            role="button"
            tabIndex={0}
            title="Cancel"
          >
            cancel
          </i>
          <i
            className="material-icons check icon"
            onClick={(e) => {
              e.stopPropagation();
              submitChange();
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
              if (e.key === "Enter") {
                submitChange();
              }
            }}
            role="button"
            tabIndex={0}
            title="Save"
          >
            check
          </i>
        </div>
      ) : (
        <div className="icons">
          <i
            className="material-icons copy icon"
            onClick={(e) => {
              e.stopPropagation();
              copyToClipboard(newValue);
              setCopied(true);
              setTimeout(() => {
                setCopied(false);
              }, 2000);
              setAlert({
                message: "Copied to clipboard",
                type: "success",
              });
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
              if (e.key === "Enter") {
                copyToClipboard(newValue);
                setCopied(true);
                setTimeout(() => {
                  setCopied(false);
                }, 2000);
                setAlert({
                  message: "Copied to clipboard",
                  type: "success",
                });
              }
            }}
            role="button"
            tabIndex={0}
            title={`Copy "${field.label}"`}
          >
            {copied ? <span className="copied">check</span> : "content_copy"}
          </i>
          {!disabled && !readonly && (
            <i
              className="material-icons edit icon"
              onClick={(e) => {
                e.stopPropagation();
                setEditing(true);
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
                if (e.key === "Enter") {
                  setEditing(true);
                }
              }}
              role="button"
              tabIndex={0}
              title="Edit"
            >
              edit
            </i>
          )}
        </div>
      )}
    </div>
  );
}
