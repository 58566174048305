import React, {
  createContext, useState, ReactNode, useMemo,
} from "react";
import { showNotification, } from "@mantine/notifications";
import { useSettings, } from "hooks";
import { resolveTheme, } from "themes/main";

export type AlertType = "success" | "danger" | "info";
export interface Alert {
  show: boolean;
  type: AlertType;
  heading: string;
  message: string;
}

export interface IncomingAlert {
  type: AlertType;
  message: string;
  heading?: string;
  timeout?: number;
}
export interface Confirmation {
  show: boolean;
  message: string;
  onConfirm: () => void;
}
export interface AlertContextProps {
  alert: Alert;
  confirmation: Confirmation;
  // eslint-disable-next-line no-unused-vars
  setAlert: (config: IncomingAlert) => void;
  removeAlert: () => void;
  // eslint-disable-next-line no-unused-vars
  setConfirmation: (message: string, onConfirm: () => void) => void;
  removeConfirmation: () => void;
}

const initialAlert: Alert = {
  show: false,
  type: "info",
  heading: "",
  message: "",
};

const initialConfirm: Confirmation = {
  show: false,
  message: "",
  onConfirm: () => {},
};

const AlertContext = createContext<AlertContextProps>({
  alert: initialAlert,
  confirmation: initialConfirm,
  setAlert: () => {},
  removeAlert: () => {},
  setConfirmation: () => {},
  removeConfirmation: () => {},
});

export function AlertProvider(props: { children: ReactNode }): JSX.Element {
  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);

  const typeToColor = {
    success: currentTheme.colors.success,
    danger: currentTheme.colors.danger,
    info: currentTheme.colors.info,
  };

  const typeToDefaultHeading = {
    success: "Success",
    danger: "Error",
    info: "Info",
  };

  const { children, } = props;
  const [alertConfig, setAlertConfig] = useState(initialAlert);
  const [confirmationConfig, setConfirmationConfig] = useState(initialConfirm);
  const value: AlertContextProps = useMemo<AlertContextProps>(
    () => ({
      alert: alertConfig,
      confirmation: confirmationConfig,
      setAlert: (config: IncomingAlert): void => {
        const newConfig: Alert = {
          show: true,
          type: config.type || alertConfig.type,
          heading:
            config.heading || typeToDefaultHeading[config.type],
          message: config.message || alertConfig.message,
        };
        setAlertConfig(newConfig);
        showNotification({
          title: config.heading || alertConfig.heading,
          message: config.message || alertConfig.message,
          color: typeToColor[config.type || alertConfig.type],
          autoClose:
            config.timeout || typeToTimeout[config.type || alertConfig.type],
        });
      },
      removeAlert: (): void => setAlertConfig(initialAlert),
      setConfirmation: (message: string, onConfirm: () => void): void => {
        setConfirmationConfig({
          show: true,
          message,
          onConfirm,
        });
      },
      removeConfirmation: (): void => setConfirmationConfig(initialConfirm),
    }),
    [alertConfig, confirmationConfig]
  );

  return (
    <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
  );
}

const typeToTimeout = {
  success: 3000,
  danger: 5000,
  info: 3000,
};

export const AlertConsumer = AlertContext.Consumer;

export default AlertContext;
