import React, { useEffect, useState, } from "react";
import { Loading, } from "components";
import { AxiosError, AxiosResponse, } from "axios";
import { useNavigate, } from "react-router";
import { axios, } from "libs";
import {
  useAlert, useUser, useSearch, useSettings,
} from "hooks";
import {
  getModuleCompleteness, getModuleFields,
} from "utils/methods";
import { Organization, } from "interfaces/main";
import IntegratedListView from "components/Views/IntegratedListView";
import { getFormattedTimeString, } from "utils/formatting";
import { resolveTheme, } from "themes/main";

function OrganizationList() {
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [loading, setLoading] = useState(true);
  const { setAlert, } = useAlert();
  const { hasRole, } = useUser();
  const { getTable, } = useSearch();
  const { sort, } = getTable("organizations");

  const onSuccess = (response: AxiosResponse) => {
    setOrganizations(response.data.data);
  };
  const onError = (err: AxiosError) => {
    // eslint-disable-next-line no-console
    console.error(err);
    setAlert({
      type: "danger",
      heading: "Error",
      message: "Error fetching organizations",
    });
  };

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    axios
      .get("/organizations")
      .then(onSuccess)
      .catch(onError)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const allFields: (keyof Organization)[] =
    organizations?.[0] &&
    (Object.keys(organizations[0]) as unknown as (keyof Organization)[]);

  const allowedFields = new Map([
    ["id", 0],
    ["name", 0],
    ["code_prefix", 0],
    ["created_at", 0],
    ["updated_at", 0]
  ]);

  const fields = getModuleFields(allowedFields, allFields);

  const getSubtitle = (org: Organization) => {
    if (!sort.field) {
      return org.updated_at ?
        `Last update at ${getFormattedTimeString(org.updated_at)}` :
        `Created at ${getFormattedTimeString(org.created_at)}`;
    }
    return `${sort.field}: ${org[sort.field as keyof Organization]}`;
  };

  const listItems = organizations.map((org) => ({
    id: org.id,
    title: org.name,
    subtitle: getSubtitle(org),
    completeness: getModuleCompleteness(org) || 0,
    endpoint: `/organizations/${org.id}`,
    full_record: org,
  }));

  const { theme: { current, }, } = useSettings();
  const currentTheme = resolveTheme(current);
  const { colors, } = currentTheme;

  if (loading) {
    return <Loading />;
  }

  return (
    <IntegratedListView
      title="Organizations"
      buttons={[
        {
          text: "",
          icon: "add",
          handleClick: () => navigate("/organizations/create"),
          color: colors.primary,
          variant: "default",
          invisible: !hasRole("superuser"),
        }
      ]}
      loading={loading}
      search
      table_name="organizations"
      fields={fields}
      full_record_list={organizations}
      list_items={listItems}
    />
  );
}

export default OrganizationList;
