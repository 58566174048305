import { useAlert, useSettings, } from "hooks";
import React, { useEffect, useState, } from "react";
import { resolveTheme, } from "themes/main";
import { copyToClipboard, } from "utils/methods";
import { getSanitizedHTML, } from "utils/security";
/** @jsxImportSource @emotion/react */
import { css, } from "@emotion/react";

function HTMLDisplay({
  secure,
  data,
  label,
}: {
  secure: boolean;
  data: string;
  label: string;
}) {
  const randomID = Math.random().toString().replace(".", "");
  const [copied, setCopied] = useState(false);
  // eslint-disable-next-line no-undef
  const [switchBackTimeout, setSwitchBackTimeout] = useState<NodeJS.Timeout>();

  const { setAlert, } = useAlert();

  const copyText = (e: any) => {
    e.stopPropagation();
    copyToClipboard(data);
    setCopied(true);
    setAlert({
      type: "success",
      message: "Copied to clipboard",
    });
    setSwitchBackTimeout(
      setTimeout(() => {
        setCopied(false);
      }, 2000)
    );
  };

  useEffect(
    () => () => {
      if (switchBackTimeout) {
        clearTimeout(switchBackTimeout);
      }
    },
    [switchBackTimeout]
  );

  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);

  return (
    <div
      css={css`
        label {
          font-family: "Roboto", sans-serif;
          font-size: 16px;
          margin-bottom: 24px;
          color: ${currentTheme.colors.text};
        }

        span {
          display: block;
          font-family: "Roboto", sans-serif;
          padding: 24px 24px;
          box-shadow: 0.2px 0.2px 8px rgba(0, 0, 0, 0.1);
          border-radius: 2px;
          background-color: ${currentTheme.colors.background};
          font-size: 14px;
          border-radius: 6px;
          color: ${currentTheme.colors.text};
        }
      `}
    >
      <label htmlFor={label + randomID}>{label}</label>
      <div
        id={label + randomID}
        style={{
          position: "relative",
        }}
      >
        <span
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: secure ? getSanitizedHTML(data) : data,
          }}
        />
        <button
          type="button"
          onClick={copyText}
          css={css`
            border: none;
            background-color: transparent;
            padding: 0;
            position: absolute;
            right: 24px;
            top: calc(50% - 7px);
            transform: translateY(-45%);
            cursor: pointer;
            outline: none;
            transition: all 0.2s ease-in-out;

            &:hover {
              opacity: 0.7;
            }
          `}
        >
          {!copied ? (
            <i
              className="material-icons"
              css={css`
                font-size: 14px;
                color: ${currentTheme.colors.textLight};
              `}
              title="Click to copy html to clipboard"
            >
              content_copy
            </i>
          ) : (
            <i
              className="material-icons"
              css={css`
                font-size: 14px;
                color: ${currentTheme.colors.success};
              `}
              title="Copied to clipboard"
            >
              check
            </i>
          )}
        </button>
      </div>
    </div>
  );
}

export default HTMLDisplay;
