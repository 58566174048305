import {
  DeviceType,
  Task,
  Template as TemplateDefinition,
} from "interfaces/main";
import React, { useEffect, useState, } from "react";
import { useParams, useNavigate, } from "react-router-dom";
import { Default, Details as DetailedView, List, } from "components/Views";
import { axios, } from "libs";
import { useAlert, useSettings, } from "hooks";
import { Loading, } from "components";
import {
  Button, Dialog, Flex, Grid, Select, Text,
} from "@mantine/core";
import Tasks from "components/Tasks/Tasks";
import TextDisplay from "components/Display/TextDisplay";
// import DetailsSection from "components/Details/Details";
import JSONForm from "components/Inputs/JsonForm";
import { parseJsonFields, } from "utils/fetching";
import { resolveTheme, } from "themes/main";
import AssignmentCard from "components/InfoCard/AssignmentCard";

function Template() {
  const { organization_id, template_id, } = useParams();
  const [template, setTemplate] = useState<TemplateDefinition>();
  const [templateTasks, setTemplateTasks] = useState<Task[]>([]);
  const [loading, setLoading] = useState(true);
  const { setAlert, setConfirmation, } = useAlert();
  const navigate = useNavigate();

  const getTemplate = () => axios
    .get(`/organizations/${organization_id}/templates/${template_id}`)
    .then((res) => {
      const parsed = parseJsonFields(res.data.data);
      setTemplate(parsed);
    })
    .catch((err) => {
      console.error(err);
      setAlert({ message: err.message, type: "danger", });
    })
    .finally(() => {
      setLoading(false);
    });

  useEffect(() => {
    setLoading(true);
    getTemplate();
    getDeviceTypes();

    axios
      .get(`/organizations/${organization_id}/templates/${template_id}/tasks`)
      .then((res) => {
        setTemplateTasks(res.data.data);
      })
      .catch((err) => {
        console.error(err);
        setAlert({ message: err.message, type: "danger", });
      });
  }, []);

  const handleDelete = () => {
    setConfirmation("Are you sure you want to delete this template?", () => {
      setLoading(true);
      axios
        .delete(`/organizations/${organization_id}/templates/${template_id}`)
        .then(() => {
          setAlert({
            message: "Template deleted successfully",
            type: "success",
          });
          navigate(`/organizations/${organization_id}/templates`);
        })
        .catch((err) => {
          console.error(err);
          setAlert({
            message: "There was a problem deleting the template",
            type: "danger",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const linkedTasks = templateTasks.map((task) => ({
    ...task,
    link: `/organizations/${organization_id}/templates/${template_id}/tasks/${task.id}`,
    endpoint: `/organizations/${organization_id}/templates/${template_id}/tasks/${task.id}`,
  }));

  const setTasks = (tasks: Task[]) => {
    setTemplateTasks(tasks);
  };

  const sortTasks = (a: Task, b: Task) => {
    if (a.sort_order < b.sort_order) {
      return -1;
    }
    if (a.sort_order > b.sort_order) {
      return 1;
    }
    return 0;
  };

  const getTemplateTasks = () => {
    axios
      .get(`/organizations/${organization_id}/templates/${template_id}`, {
        cache: false,
      })
      .then((res) => {
        setTemplateTasks(res.data.data);
      })
      .catch((err) => {
        console.error(err);
        setAlert({ message: err.message, type: "danger", });
      });
  };

  const submitDetails = (details: TemplateDefinition["details"]) => {
    axios
      .put(`/organizations/${organization_id}/templates/${template_id}`, {
        details,
      })
      .then((res) => {
        setAlert({
          type: "success",
          message: "Template details updated successfully",
        });
        const parsed = parseJsonFields(res.data.data);
        setTemplate(parsed);
      })
      .catch((err) => {
        setAlert({
          type: "danger",
          message: "Error updating template details",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  const submitCredentials = (
    credentials: TemplateDefinition["credentials"]
  ) => {
    axios
      .put(`/organizations/${organization_id}/templates/${template_id}`, {
        credentials,
      })
      .then((res) => {
        setAlert({
          type: "success",
          message: "Template credentials updated successfully",
        });
        const parsed = parseJsonFields(res.data.data);
        setTemplate(parsed);
      })
      .catch((err) => {
        setAlert({
          type: "danger",
          message: "Error updating template credentials",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);
  const { colors, } = currentTheme;

  const [deviceTypes, setDeviceTypes] = useState<DeviceType[]>([]);
  const [managingDeviceTypes, setManagingDeviceTypes] = useState(false);
  const [newDeviceType, setNewDeviceType] = useState<number>();

  const addableDeviceTypes = () => {
    const existingDeviceTypes = template?.device_types || [];
    const deviceTypeIds = existingDeviceTypes.map(
      (deviceType) => deviceType.id
    );
    return deviceTypes.filter(
      (deviceType) => !deviceTypeIds.includes(deviceType.id)
    );
  };

  const assignDeviceTypesToTemplate = () => {
    axios
      .post(
        `/organizations/${organization_id}/templates/${template_id}/assign`,
        {
          attach: [newDeviceType],
        }
      )
      .then(() => {
        setAlert({
          type: "success",
          message: "Device types assigned to template successfully",
        });
        getTemplate();
      })
      .catch((err) => {
        setAlert({
          type: "danger",
          message: "Error assigning device types to template",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  const unassignDeviceTypesFromTemplate = (device_type: number) => {
    axios
      .post(
        `/organizations/${organization_id}/templates/${template_id}/assign`,
        {
          detach: [device_type],
        }
      )
      .then(() => {
        setAlert({
          type: "success",
          message: "Device types unassigned from template successfully",
        });
        getTemplate();
      })
      .catch((err) => {
        setAlert({
          type: "danger",
          message: "Error unassigning device types from template",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  const getDeviceTypes = () => {
    axios
      .get(`/organizations/${organization_id}/device-types`)
      .then((res) => {
        setDeviceTypes(res.data.data);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        setAlert({
          type: "danger",
          message: "Error fetching device types",
        });
      });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <DetailedView
        title={template?.name || "Template"}
        buttons={[
          // {
          //   text: "Tasks",
          //   handleClick: () => {
          //     navigate("tasks");
          //   },
          //   variant: "subtle",
          //   color: "digitalPurple"
          // },
          {
            text: "Edit",
            handleClick: () => {
              navigate("edit");
            },
            variant: "default",
            color: colors.primary,
          },
          {
            text: "Delete",
            handleClick: handleDelete,
            color: colors.danger,
            variant: "filled",
          }
        ]}
      >
        <Grid>
          <Grid.Col sm={12} md={6}>
            <TextDisplay label="Name" data={template?.name} />
          </Grid.Col>
          <Grid.Col sm={12} md={6}>
            <TextDisplay label="Type" data={template?.module} />
          </Grid.Col>
        </Grid>
      </DetailedView>
      {template?.module === "collections" && (
        <List
          title="Device Types"
          buttons={[
            {
              text: "Assign Device Type",
              handleClick: () => {
                setManagingDeviceTypes(true);
              },
              color: currentTheme.colors.primary,
              variant: "filled",
            }
          ]}
          table_name="device_types"
          collapsable
        >
          <Dialog
            opened={managingDeviceTypes}
            withCloseButton
            onClose={() => setManagingDeviceTypes(false)}
            style={{ width: "100%", }}
            // css={css`
            //   .mantine-Dialog-root{
            //     outline: 1px solid red;
            //   }
            // `}
            size="xs"
          >
            <Grid>
              <Grid.Col sm={12} md={12}>
                <Select
                  placeholder="Search for a device type"
                  data={addableDeviceTypes().map((dvc) => ({
                    label: dvc.name,
                    value: dvc.id.toString(),
                  }))}
                  onChange={(value) => {
                    if (!value) return;
                    setNewDeviceType(Number(value));
                  }}
                  label="Device Type"
                  dropdownPosition="top"
                />
              </Grid.Col>
              <Grid.Col sm={12}>
                <Flex justify="flex-end">
                  <Button
                    onClick={assignDeviceTypesToTemplate}
                    variant="filled"
                    color={currentTheme.colors.primary}
                  >
                    Assign
                  </Button>
                </Flex>
              </Grid.Col>
            </Grid>
          </Dialog>
          <Grid>
            {template?.device_types?.length ? (
              template?.device_types?.map((device_type) => (
                <Grid.Col span={12} key={device_type.id}>
                  <AssignmentCard
                    record={{
                      title: device_type.name,
                      subtitle: device_type.updated_at,
                      ...device_type,
                      link: `/organizations/${organization_id}/device-types/${device_type.id}`,
                    }}
                    removeRecord={unassignDeviceTypesFromTemplate}
                    editable
                  />
                </Grid.Col>
              ))
            ) : (
              <Grid.Col span={12}>
                <Text
                  size="md"
                  weight="300"
                  color={currentTheme.colors.textLight}
                  align="center"
                >
                  This template has no assigned device types.
                </Text>
              </Grid.Col>
            )}
          </Grid>
        </List>
      )}
      <Tasks
        tasks={linkedTasks?.sort(sortTasks) || []}
        endpoint={`/organizations/${organization_id}/templates/${template_id}/tasks`}
        setTasks={setTasks}
        editable={false}
        refreshTasks={getTemplateTasks}
        showProgress={false}
      />
      <Default>
        <JSONForm
          title="Details"
          fields={template?.details || {}}
          setFields={submitDetails}
        />
      </Default>

      <Default>
        <JSONForm
          title="Credentials"
          fields={template?.credentials || {}}
          setFields={submitCredentials}
        />
      </Default>
    </div>
  );
}

export default Template;
