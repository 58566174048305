import Dexie, { Table, } from "dexie";

export interface Collection {
  id?: number;
  name: string;
  matter_id: number;
  organization_id: number;
  custodian_id: number;
  mysql_id: number;
  updated_offline_at?: Date | null;
  added_offline_at?: Date | null;
  last_synced_at?: Date;
}

export interface CollectionUpdate {
  id?: number;
  mysql_id: number;
  organization_id: number;
  matter_id: number;
  custodian_id: number;
  data: string;
}
export class CustomizedDexie extends Dexie {
  collections!: Table<Collection>;

  collection_updates!: Table<CollectionUpdate>;

  constructor() {
    super("coc");
    this.version(5).stores({
      collections: "++id, name, matter_id, custodian_id, mysql_id",
      collection_updates: "++id, mysql_id, data",
    });
  }
}

export const db = new CustomizedDexie();
