import React from "react";
/** @jsxImportSource @emotion/react */
import { css, keyframes, } from "@emotion/react";
import { useSettings, } from "hooks";
import { resolveTheme, } from "themes/main";

interface DataCardProps {
    children: React.ReactNode;
}

function DataCard({ children, }: DataCardProps) {
  const { theme: { current, }, } = useSettings();
  const { colors, } = resolveTheme(current);

  const cardIn = keyframes`
    from {
      opacity: 0;
      transform: translateX(-5%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  `;

  return (
    <div css={css`
        background-color: ${colors.background};
        padding: 24px 36px;
        color: ${colors.text};
        border-radius: 10px;
        box-shadow: 0.2px 0.2px 10px 0 rgba(0, 0, 0, 0.1);
        transition: 0.3s ease-in-out all;
        animation: ${cardIn} 0.7s ease-in-out;
    `}
    >
      {children}
    </div>
  );
}

export default DataCard;
