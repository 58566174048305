import { useEffect, useState, } from "react";
import { useAlert, useSettings, } from "hooks";
import CreateView from "components/Views/CreateView";
import { Validation, } from "interfaces/responses";
import { axios, } from "libs";
import { Grid, Select, TextInput, } from "@mantine/core";
import { useParams, useNavigate, } from "react-router-dom";
import { Custodian, Template, } from "interfaces/main";
import { resolveTheme, } from "themes/main";

function CreateCustodian() {
  const alertContext = useAlert();
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [validationRules, setValidationRules] = useState<Validation>();
  const [requiredFields, setRequiredFields] = useState<string[]>([]);
  const [validated, setValidated] = useState(false);
  const { organization_id, matter_id, } = useParams();
  const navigate = useNavigate();
  const { setAlert, } = useAlert();
  const [templates, setTemplates] = useState<Template[]>([]);
  const [template_id, setTemplateId] = useState<number | null>(null);

  const [FormData, setFormData] = useState<Partial<Custodian>>({
    first_name: "",
    last_name: "",
    email: "",
    matter_id: undefined,
    phone_number: "",
  });

  const onError = (response: any): void => {
    // eslint-disable-next-line no-console
    console.error("On error", response);
    setAlert({
      type: "danger",
      heading: "Error",
      message: response.Error,
    });
  };

  useEffect(() => {
    axios
      .get(
        `/organizations/${organization_id}/matters/${matter_id}/custodians/create`
      )
      .then((res) => {
        const { validation, } = res.data.data as { validation: Validation };
        setRequiredFields(
          Object.keys(validation.rules).filter((rule) => {
            if (!validation.rules[rule].includes("required")) {
              return false;
            }
            return true;
          })
        );
        setValidationRules(validation);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error("error", err);
      }).finally(() => {
        setLoading(false);
      });

    setLoading(true);
    axios
      .get(`/organizations/${organization_id}/templates/type/custodians`)
      .then((res) => {
        setTemplates(res.data.data);
      })
      .catch(onError)
      .finally(() => setLoading(false));
  }, []);

  const onSubmit = (): void => {
    setLoading(true);
    axios
      .post(
        `/organizations/${organization_id}/matters/${matter_id}/custodians`,
        FormData
      )
      .then((res) => {
        const newCustodian = res.data.data as Custodian;
        alertContext.setAlert({
          type: "success",
          heading: "Success",
          message: "Custodian created successfully",
        });
        if (template_id) {
          assignTemplate(newCustodian.id);
        } else {
          navigate(`/organizations/${organization_id}/matters/${matter_id}/custodians/${newCustodian.id}`);
        }
      })
      .catch(onError)
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const { name, value, } = e.target;
    setFormData({ ...FormData, [name]: value, });
  };

  const formattedTemplates = templates.map((temp) => ({
    label: temp.name,
    value: temp.id.toString(),
  }));

  const assignTemplate = (custodian_id: number) => {
    setLoading(true);
    axios
      .post(
        `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/template`,
        {
          template_id,
        }
      )
      .then(() => {
        alertContext.setAlert({
          type: "success",
          heading: "Success",
          message: "Template assigned successfully",
        });
        navigate(
          `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}`
        );
      })
      .catch(onError);
  };

  const { theme: { current: theme, }, } = useSettings();
  const currentTheme = resolveTheme(theme);

  return (
    <CreateView
      title="Create Custodian"
      loading={loading}
      buttons={[
        {
          text: "Submit",
          handleClick: onSubmit,
          color: currentTheme.colors.primary,
          disabled: !validated,
        }
      ]}
      requiredFields={requiredFields}
      formState={FormData}
      setValidated={setValidated}
    >
      <Grid>
        <Grid.Col span={6}>
          <TextInput
            label="First Name"
            name="first_name"
            onChange={handleChange}
            required={requiredFields.includes("first_name")}
            placeholder="First name..."
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput
            label="Last Name"
            name="last_name"
            onChange={handleChange}
            required={requiredFields.includes("last_name")}
            placeholder="Last name..."
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput
            label="Email"
            name="email"
            onChange={handleChange}
            required={requiredFields.includes("email")}
            placeholder="Email..."
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput
            label="Phone Number"
            name="phone_number"
            onChange={handleChange}
            required={requiredFields.includes("phone_number")}
            placeholder="Phone number..."
          />
        </Grid.Col>
        <Grid.Col sm={12} md={6}>
          <Select
            label="Template"
            name="template_id"
            required={validationRules?.rules?.template_id?.includes("required")}
            data={formattedTemplates}
            onChange={(value) => {
              if (value) {
                setTemplateId(parseInt(value, 10));
              }
            }}
            searchable
            placeholder="Select a template..."
          />
        </Grid.Col>
      </Grid>
    </CreateView>
  );
}

export default CreateCustodian;
