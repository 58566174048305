import React, { useEffect, useState, } from "react";
import { Create, } from "components/Views";
import { Loading, } from "components";
import { axios, } from "libs";
import { useParams, useNavigate, } from "react-router-dom";
import { Validation, } from "interfaces/responses";
import {
  Checkbox, Grid, Select, TextInput,
} from "@mantine/core";
import { useUser, useAlert, useSettings, } from "hooks";
import { EmailTemplate, Template, } from "interfaces/main";
import { resolveTheme, } from "themes/main";

function CreateTemplate() {
  const { organization_id, } = useParams();
  const [formState, setFormState] = useState<Partial<Template>>({
    name: "",
    global: false,
    module: undefined,
  });
  const [loading, setLoading] = useState(false);
  const [validationRules, setValidationRules] = useState<Validation>();
  const [requiredFields, setRequiredFields] = useState<string[]>([]);
  const [validated, setValidated] = useState(false);
  const { hasRole, } = useUser();
  const { setAlert, } = useAlert();
  const navigate = useNavigate();
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/organizations/${organization_id}/templates/create`)
      .then((res) => {
        const validation = res.data.data.validation as Validation;
        setValidationRules(validation);
        setRequiredFields(
          Object.entries(validation.rules)
            .filter(([, value]) => value.includes("required"))
            .map(([key]) => key)
        );
      })
      .catch((err) => {
        console.error(err);
        setAlert({
          type: "danger",
          message: "Error loading template validation rules",
        });
      })
      .finally(() => {
        setLoading(false);
      });

    axios
      .get(`/organizations/${organization_id}/email-templates`)
      .then((res) => {
        setEmailTemplates(res.data.data);
      })
      .catch((e) => {
        console.error(e);
        setAlert({
          type: "danger",
          message: "Error loading email templates",
        });
      });
  }, []);

  useEffect(() => {
    if (formState.module) {
      axios
        .get(
          `/organizations/${organization_id}/templates/type/${formState.module}`
        )
        .then((res) => {
          setTemplates(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          setAlert({
            type: "danger",
            message: "Error loading templates",
          });
        });
    }
  }, [formState.module]);

  const submitTemplate = () => {
    setLoading(true);
    axios
      .post(`/organizations/${organization_id}/templates`, formState)
      .then((res) => {
        setAlert({
          type: "success",
          message: "Template created successfully",
        });
        const newTemplate = res.data.data as Template;
        setLoading(false);
        if (template_id) {
          assignTemplate(newTemplate.id);
          return;
        }
        navigate(
          `/organizations/${organization_id}/templates/${newTemplate.id}`
        );
      })
      .catch((err) => {
        console.error(err);
        setAlert({
          type: "danger",
          message: "Error creating template",
        });
        setLoading(false);
      });
  };

  const [templates, setTemplates] = useState<Template[]>([]);
  const [template_id, setTemplateId] = useState<number | null>(null);

  const assignTemplate = (t_id: number) => {
    setLoading(true);
    axios
      .post(`/organizations/${organization_id}/templates/${t_id}/template`, {
        template_id,
      })
      .then(() => {
        setAlert({
          type: "success",
          heading: "Success",
          message: "Template assigned successfully",
        });
        navigate(`/organizations/${organization_id}/templates/${t_id}`);
      })
      .catch((err) => {
        console.error(err);
        setAlert({
          type: "danger",
          heading: "Error",
          message: "Error assigning template",
        });
      });
  };

  // eslint-disable-next-line arrow-body-style
  const formattedTemplates = templates.map((temp) => {
    return {
      label: temp.name,
      value: temp.id.toString(),
    };
  });

  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);
  const { colors, } = currentTheme;

  const modulesToHaveEmailTemplateOn = ["kits", "collections"];

  useEffect(() => {
    if (!modulesToHaveEmailTemplateOn.includes) {
      setFormState({ ...formState, email_template_id: undefined, });
    }
  }, [formState.module]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <Create
        title="Create Template"
        buttons={[
          {
            text: "Submit",
            handleClick: () => {
              submitTemplate();
            },
            variant: "filled",
            color: colors.primary,
            disabled: !validated,
          }
        ]}
        formState={formState}
        requiredFields={requiredFields}
        setValidated={setValidated}
      >
        <Grid>
          <Grid.Col sm={12} md={6}>
            <TextInput
              label="Name"
              name="name"
              onChange={(e) => {
                setFormState({ ...formState, name: e.currentTarget.value, });
              }}
              required={validationRules?.rules?.name?.includes("required")}
              placeholder="Template name..."
              value={formState.name || ""}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6}>
            <Select
              label="Type"
              name="module"
              placeholder="Select a module..."
              required={validationRules?.rules?.module?.includes("required")}
              data={[
                {
                  label: "Matter",
                  value: "matters",
                },
                {
                  label: "Custodian",
                  value: "custodians",
                },
                {
                  label: "Collection",
                  value: "collections",
                },
                {
                  label: "Kit",
                  value: "kits",
                }
              ]}
              onChange={(e) => {
                setFormState({
                  ...formState,
                  module: e as Template["module"],
                });
              }}
              searchable
              value={formState.module || ""}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6}>
            <Select
              label="From Template"
              name="template_id"
              required={validationRules?.rules?.template_id?.includes(
                "required"
              )}
              data={formattedTemplates}
              onChange={(value) => {
                if (value) {
                  setTemplateId(parseInt(value, 10));
                }
              }}
              searchable
              disabled={!formState.module}
              placeholder={
                formState.module ?
                  `Select a ${formState.module} template...` :
                  "Select a type first..."
              }
              value={template_id?.toString() || ""}
            />
          </Grid.Col>
          {formState.module &&
            modulesToHaveEmailTemplateOn.includes(formState.module) && (
              <Grid.Col sm={12} md={6}>
                <Select
                  label="Email Template"
                  name="email_template_id"
                  data={emailTemplates.map((t) => ({
                    label: t.name,
                    value: t.id.toString(),
                  }))}
                  onChange={(value) => {
                    if (value) {
                      setFormState({
                        ...formState,
                        email_template_id: Number(value),
                      });
                    }
                  }}
                  placeholder="Select an email template"
                  value={formState.email_template_id?.toString() || ""}
                />
              </Grid.Col>
          )}
          {hasRole("hyperuser") && (
            <Grid.Col sm={12} md={12}>
              <Checkbox
                label="Available Globally"
                name="global"
                onChange={(e) => {
                  setFormState({
                    ...formState,
                    global: e.currentTarget.checked,
                  });
                }}
                required={validationRules?.rules?.global?.includes("required")}
                checked={!!formState.global}
              />
            </Grid.Col>
          )}
        </Grid>
      </Create>
    </div>
  );
}

export default CreateTemplate;
