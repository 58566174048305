import React, { useEffect, useState, } from "react";
import { useNavigate, useParams, } from "react-router-dom";
import { axios, } from "libs";
import { useAlert, useSearch, useSettings, } from "hooks";
import { Template, } from "interfaces/main";
import { Loading, } from "components";
import IntegratedListView from "components/Views/IntegratedListView";
import { resolveTheme, } from "themes/main";
import { getModuleFields, } from "utils/methods";

function List() {
  const navigate = useNavigate();
  const { organization_id, } = useParams();
  const [templates, setTemplates] = useState<Template[]>([]);
  const [loading, setLoading] = useState(true);
  const { setAlert, } = useAlert();
  const { getTable, } = useSearch();
  const { sort, } = getTable("templates");

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/organizations/${organization_id}/templates`)
      .then((res) => {
        setTemplates(res.data.data);
      })
      .catch((err) => {
        console.error(err);
        setAlert({ message: err.message, type: "danger", });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [organization_id]);

  const allFields: (keyof Template)[] | undefined =
    templates?.[0] &&
    (Object.keys(templates[0]) as unknown as (keyof Template)[]);

  const allowedFields = new Map([
    ["id", 0],
    ["name", 0],
    ["organization_at", 0],
    ["global", 0],
    ["module", 0],
    ["created_at", 0],
    ["updated_at", 0]
  ]);

  const fields = getModuleFields(allowedFields, allFields);

  const getSubtitle = (t: Template) => {
    if (!sort.field) {
      return t.module;
    }
    return `${sort.field}: ${t[sort.field as keyof Template]}`;
  };

  const listItems = templates.map((temp) => ({
    id: temp.id,
    title: temp.name,
    subtitle: getSubtitle(temp),
    endpoint: `/organizations/${organization_id}/templates/${temp.id}`,
    full_record: temp,
  }));

  const { theme: { current, }, } = useSettings();
  const currentTheme = resolveTheme(current);
  const { colors, } = currentTheme;

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <IntegratedListView
        title="Templates"
        with_separator={false}
        buttons={[
          {
            text: "",
            handleClick: () => {
              navigate("create");
            },
            icon: "add",
            variant: "default",
            color: colors.primary,
          }
        ]}
        search
        table_name="templates"
        fields={fields}
        full_record_list={templates}
        list_items={listItems}
      />
    </div>
  );
}

export default List;
