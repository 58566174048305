import React, { useEffect, useState, } from "react";
import { useParams, useNavigate, } from "react-router-dom";
import EditView from "components/Views/EditView";
import { axios, } from "libs";
import { Validation, } from "interfaces/responses";
import { Button, Grid, TextInput, } from "@mantine/core";
import { useAlert, useSettings, } from "hooks";
import { Custodian, } from "interfaces/main";
import { Loading, } from "components";
import { Default, } from "components/Views";
import JSONForm from "components/Inputs/JsonForm";
import { parseJsonFields, } from "utils/fetching";
import { resolveTheme, } from "themes/main";

function Edit() {
  const [custodian, setCustodian] = useState<Partial<Custodian>>();
  const [loading, setLoading] = useState(true);
  const [validated, setValidated] = useState(false);
  const [validationRules, setValidationRules] = useState<Validation>();
  const [requiredFields, setRequiredFields] = useState<string[]>([]);
  const { organization_id, matter_id, custodian_id, } = useParams();
  const { setAlert, } = useAlert();
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(
        `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}`
      )
      .then((response) => {
        const parsed = parseJsonFields(response.data.data);
        setCustodian(parsed);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });

    axios
      .get(
        `/organizations/${organization_id}/matters/${matter_id}/custodians/create`
      )
      .then((response) => {
        const { validation, } = response.data.data;
        setRequiredFields(
          Object.keys(validation.rules).filter((rule) => {
            if (!validation.rules[rule].includes("required")) {
              return false;
            }
            return true;
          })
        );
        setValidationRules(validation);
      });
  }, [
    organization_id,
    matter_id,
    custodian_id
  ]);

  const handleSubmit = () => {
    setLoading(true);
    axios
      .put(
        `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}`,
        custodian
      )
      .then((response) => {
        setValidated(false);
        setCustodian(response.data.data);
        setAlert({
          type: "success",
          message: "Custodian updated successfully",
        });
        navigate(
          `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}`
        );
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        setAlert({
          type: "danger",
          message: "Error updating custodian",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const setDetails = (details: Custodian["details"]) => {
    setCustodian({
      ...custodian,
      details,
    });
  };

  const setCredentials = (credentials: Custodian["credentials"]) => {
    setCustodian({
      ...custodian,
      credentials,
    });
  };

  const { theme: { current, }, } = useSettings();
  const currentTheme = resolveTheme(current);
  const { colors, } = currentTheme;

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <EditView
        title="Custodian"
        buttons={
          [
            // {
            //   text: "Submit",
            //   handleClick: handleSubmit,
            //   color: "digitalPurple",
            //   variant: "filled",
            //   disabled: !validated
            // }
          ]
        }
        loading={loading}
        formState={custodian}
        requiredFields={requiredFields}
        setValidated={setValidated}
      >
        <Grid>
          <Grid.Col sm={12} md={6}>
            <TextInput
              label="First Name"
              value={custodian?.first_name || ""}
              onChange={(e) => {
                setCustodian({
                  ...custodian,
                  first_name: e.target.value,
                });
              }}
              required={validationRules?.rules?.name?.includes("required")}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6}>
            <TextInput
              label="Last Name"
              value={custodian?.last_name || ""}
              onChange={(e) => {
                setCustodian({
                  ...custodian,
                  last_name: e.target.value,
                });
              }}
              required={validationRules?.rules?.code?.includes("required")}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6}>
            <TextInput
              label="Email"
              value={custodian?.email || ""}
              onChange={(e) => {
                setCustodian({
                  ...custodian,
                  email: e.target.value,
                });
              }}
              required={validationRules?.rules?.email?.includes("required")}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6}>
            <TextInput
              label="Phone Number"
              value={custodian?.phone_number || ""}
              onChange={(e) => {
                setCustodian({
                  ...custodian,
                  phone_number: e.target.value,
                });
              }}
              required={validationRules?.rules?.phone_number?.includes(
                "required"
              )}
            />
          </Grid.Col>
        </Grid>
      </EditView>
      <Default>
        <JSONForm
          title="Details"
          setFields={setDetails}
          fields={custodian?.details || {}}
        />
        <br />
        <br />
        <hr />
        <br />
        <br />
        <JSONForm
          title="Credentials"
          setFields={setCredentials}
          fields={custodian?.credentials || {}}
        />

        <hr />
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
          }}
        >
          <Button
            color={colors.primary}
            onClick={handleSubmit}
            disabled={!validated}
          >
            Submit
          </Button>
        </div>
      </Default>
    </>
  );
}

export default Edit;
