import React, { useEffect, useState, } from "react";
import { Loading, } from "components";
import { useUser, } from "hooks";
import { axios, } from "libs";
import { Dashboard, } from "interfaces/responses";
import TabsView from "components/Views/TabsView";
import { useSearchParams, } from "react-router-dom";
import { MoonStars, Sun, SunHorizon, } from "phosphor-react";
/** @jsxImportSource @emotion/react */
import { css, } from "@emotion/react";
import AllMatterList from "pages/Matters/AllMatters";
import { MatterList, } from "pages";
import ActiveKits from "./ActiveKits";
import Overview from "./Overview";

export type Tabs = "overview" | "active_kits" | "active_matters";

function Home() {
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const queryTab = (searchParams.get("tab") || "overview") as Tabs;
  const [tab, setTab] = useState<Tabs>(queryTab);
  const [dashboard, setDashboard] = useState<Dashboard>();

  useEffect(() => {
    setSearchParams({
      tab,
    });
  }, [tab]);

  useEffect(() => {
    setLoading(true);
    axios
      .get("/dashboard")
      .then((res) => {
        const data = res.data.data as Dashboard;
        setDashboard(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, []);

  const { getUserOrganization, user, } = useUser();

  // eslint-disable-next-line no-unused-vars
  const getCurrentTimeOfDay = (): "morning" | "day" | "evening" | "night" => {
    const hour = new Date().getHours();
    if (hour >= 5 && hour < 12) return "morning";
    if (hour >= 12 && hour < 17) return "day";
    if (hour >= 17 && hour < 20) return "evening";
    return "night";
  };

  const timeOfDayToIcon = {
    morning: <SunHorizon />,
    day: <Sun />,
    evening: <SunHorizon />,
    night: <MoonStars />,
  };

  const timeOfDayToMessageSelection = {
    morning: ["Good morning and welcome!"],
    day: ["Hello and welcome!"],
    evening: ["Good evening and welcome!"],
    night: ["Welcome! You're up late!"],
  };

  const topMessage = () => {
    const timeOfDay = getCurrentTimeOfDay();
    const messageSelection = timeOfDayToMessageSelection[timeOfDay];
    const randomIndex = Math.floor(Math.random() * messageSelection.length);
    const message = messageSelection[randomIndex];
    const icon = timeOfDayToIcon[timeOfDay];
    return (
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 8px;

          .icon {
            font-size: 15px;
          }
        `}
      >
        <span>{message}</span>
        <div className="icon">{icon}</div>
      </div>
    );
  };

  if (loading) return <Loading />;

  if (!dashboard) return <Loading />;

  return (
    <TabsView
      toptext={topMessage()}
      title={`${user.name}'s Dashboard`}
      subtitle={getUserOrganization()?.name || ""}
      tabs={[
        {
          text: "Overview",
          id: "overview",
        },
        {
          text: "Active Kits",
          id: "active_kits",
        },
        {
          text: "Active Matters",
          id: "active_matters",
        }
      ]}
      setUpperTab={setTab}
      defaultTab={tab}
      currentTab={tab}
    >
      <DisplayCurrentTab
        currentTab={tab}
        dashboard={dashboard}
        setTab={setTab}
      />
    </TabsView>
  );
}

export default Home;

interface DisplayCurrentTabProps {
  currentTab: Tabs;
  dashboard: Dashboard;
  // eslint-disable-next-line no-unused-vars
  setTab: (tab: Tabs) => void;
}

export function DisplayCurrentTab({
  currentTab,
  dashboard,
  setTab,
}: DisplayCurrentTabProps) {
  const { hasRole, } = useUser();

  if (!dashboard) return null;

  switch (currentTab) {
    case "overview":
      return (
        <Overview dashboard={dashboard} setTab={(tab: Tabs) => setTab(tab)} />
      );
    case "active_kits":
      return <ActiveKits active_kits={dashboard.active_kits} />;
    case "active_matters":
      if (hasRole("superuser")) {
        return <AllMatterList withTitle={false} withPaddingY={false} />;
      }
      return <MatterList with_title={false} with_padding_y={false} />;
    default:
      return null;
  }
}
