import React, { useEffect, useState, } from "react";
/** @jsxImportSource @emotion/react */
import { css, } from "@emotion/react";
import { copyToClipboard, } from "utils/methods";
import { useAlert, useSettings, } from "hooks";
import { Link, } from "react-router-dom";
import { resolveTheme, } from "themes/main";

interface TextDisplayProps {
  label: string;
  data: any;
  endpoint?: string;
  style?: React.CSSProperties;
  pStyle?: React.CSSProperties;
  text_only?: boolean;
}

TextDisplay.defaultProps = {
  endpoint: undefined,
  style: {},
  pStyle: {},
  text_only: false,
};

function TextDisplay({
  label,
  data,
  endpoint,
  style,
  pStyle,
  text_only,
}: TextDisplayProps) {
  const randomID = Math.random().toString().replace(".", "");
  const [copied, setCopied] = useState(false);
  // eslint-disable-next-line no-undef
  const [switchBackTimeout, setSwitchBackTimeout] = useState<NodeJS.Timeout>();

  const { setAlert, } = useAlert();

  const copyText = (e: any) => {
    e.stopPropagation();
    copyToClipboard(data);
    setCopied(true);
    setAlert({
      type: "success",
      message: "Copied to clipboard",
    });
    setSwitchBackTimeout(
      setTimeout(() => {
        setCopied(false);
      }, 2000)
    );
  };

  useEffect(
    () => () => {
      if (switchBackTimeout) {
        clearTimeout(switchBackTimeout);
      }
    },
    [switchBackTimeout]
  );

  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);

  if (text_only) {
    return (
      <div
        css={css`
          label {
            font-family: "Roboto", sans-serif;
            font-size: 16px;
            margin-bottom: 8px;
            color: ${currentTheme.colors.text};
          }

          p {
            font-family: "Roboto", sans-serif;
            border-radius: 2px;
            font-size: 14px;
            color: ${currentTheme.colors.text};
          }
        `}
        style={style}
      >
        <label htmlFor={label + randomID}>{label}</label>
        <p
          id={label + randomID}
          style={{
            position: "relative",
            ...pStyle,
          }}
          title={data}
        >
          {data}
        </p>
      </div>
    );
  }

  return (
    <div
      css={css`
        label {
          font-family: "Roboto", sans-serif;
          font-size: 16px;
          margin-bottom: 8px;
          color: ${currentTheme.colors.text};
        }

        p {
          font-family: "Roboto", sans-serif;
          padding: 8px 14px;
          box-shadow: 0.2px 0.2px 8px rgba(0, 0, 0, 0.1);
          border-radius: 2px;
          background-color: ${currentTheme.colors.background};
          font-size: 14px;
          border-radius: 6px;
          color: ${currentTheme.colors.text};
        }
      `}
      style={style}
    >
      <label htmlFor={label + randomID}>{label}</label>
      <p
        id={label + randomID}
        style={{
          position: "relative",
          ...pStyle,
        }}
        title={data}
      >
        {data}
        {endpoint && (
          <Link
            css={css`
              border: none;
              background-color: transparent;
              padding: 0;
              position: absolute;
              right: 36px;
              top: 50%;
              transform: translateY(-45%);
              cursor: pointer;
              outline: none;
              transition: all 0.2s ease-in-out;

              &:hover {
                opacity: 0.7;
              }
            `}
            to={endpoint}
          >
            <i
              className="material-icons"
              css={css`
                font-size: 14px;
                color: ${currentTheme.colors.textLight};
              `}
              title="View"
            >
              arrow_forward
            </i>
          </Link>
        )}
        <button
          type="button"
          onClick={copyText}
          css={css`
            border: none;
            background-color: transparent;
            padding: 0;
            position: absolute;
            right: 14px;
            top: 50%;
            transform: translateY(-45%);
            cursor: pointer;
            outline: none;
            transition: all 0.2s ease-in-out;

            &:hover {
              opacity: 0.7;
            }
          `}
        >
          {!copied ? (
            <i
              className="material-icons"
              css={css`
                font-size: 14px;
                color: ${currentTheme.colors.textLight};
              `}
              title="Click to copy text to clipboard"
            >
              content_copy
            </i>
          ) : (
            <i
              className="material-icons"
              css={css`
                font-size: 14px;
                color: ${currentTheme.colors.success};
              `}
              title="Copied to clipboard"
            >
              check
            </i>
          )}
        </button>
      </p>
    </div>
  );
}

export default TextDisplay;
