import React, { useEffect, } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/fonts.css";
import "./css/main.scss";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { MantineProvider, createEmotionCache, } from "@mantine/core";
import { NotificationsProvider, } from "@mantine/notifications";
import {
  LandingPage,
  CreateUser,
  UserList,
  CreateMatter,
  CustodianList,
  CreateCustodian,
  CollectionList,
  Organization,
  CreateCollection,
  OrganizationList,
  CreateOrganization,
  EditOrganization,
  User,
  EditMatter,
  EditCustodian,
  EditCollection,
  EditUser,
  PageNotFound,
  Home,
  OfflineCollections,
  OfflineViewCollection,
  OfflineEditCollection,
  Settings,
  Template,
  CreateTemplate,
  EditTemplate,
  TaskList,
  Task,
  CreateTask,
  EditTask,
  CollectionTask,
  Login,
  MatterTask,
  CustodianTask,
  CreateDevice,
  Device,
  EditDevice,
  CreateDeviceType,
  DeviceType,
  EditDeviceType,
  KitList,
  CreateKit,
  Kit,
  EditKit,
  KitPickList,
  Devices,
  CreateEmailTemplate,
  EmailTemplate,
  EditEmailTemplate,
  TemplateList,
  CreateEmailFragment,
  EmailFragment,
  EditEmailFragment,
  EmailTemplateList,
  Wizards,
  AllWizards,
  Matters,
  DisplayOnlyCollection,
} from "pages";
import { AlertProvider, } from "contexts/AlertContext";
import Matter from "pages/Matters/Matter";
import Custodian from "pages/Custodians/Custodian";
import Collection from "pages/Collections/Collection";
import { useOfflineServices, useUser, useSettings, } from "hooks";
import { syncDB, } from "db/cacheDB";

function App() {
  const customCache = createEmotionCache({
    key: "mantine",
  });
  // eslint-disable-next-line no-unused-vars
  const { hasRole, } = useUser();
  // eslint-disable-next-line no-unused-vars
  const { isOnline, } = useOfflineServices();

  useEffect(() => {
    syncDB();
  }, []);

  const {
    theme: { current: theme, },
  } = useSettings();

  return (
    <AlertProvider>
      <MantineProvider
        emotionCache={customCache}
        theme={{
          colors: {
            digitalPurple: [
              "#C5BBC9",
              "#B5A6BB",
              "#A790B0",
              "#9B7BA9",
              "#9264A5",
              "#8950A0",
              "#80409B",
              "#7F3F98"
            ],
          },
          primaryColor: "digitalPurple",
          fontFamily: "Inter, sans-serif",
          colorScheme: theme,
        }}
      >
        <NotificationsProvider position="top-right">
          <Router>
            <Routes>
              <Route path="*" element={<PageNotFound />} />
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<LandingPage />}>
                <Route index element={<Navigate to="/home" />} />
                <Route path="home" element={<Home />} />
                <Route path="settings" element={<Settings />} />
                <Route path="wizards">
                  <Route index element={<Wizards />} />
                  {Object.keys(AllWizards).map((wizard) => {
                    const Element = AllWizards[wizard as keyof typeof AllWizards];
                    return (
                      <Route
                        key={wizard}
                        path={wizard}
                        element={<Element />}
                      />
                    );
                  })}
                </Route>
                {isOnline ? (
                  <Route path="organizations">
                    {hasRole("superuser") && (
                      <Route path="create" element={<CreateOrganization />} />
                    )}
                    <Route path=":organization_id">
                      <Route index element={<Organization />} />
                      {hasRole("superuser") && (
                        <Route path="edit" element={<EditOrganization />} />
                      )}
                      {hasRole("poweruser") && (
                        <Route path="users">
                          <Route path="create" element={<CreateUser />} />
                          <Route path=":user_id">
                            <Route index element={<User />} />
                            <Route path="edit" element={<EditUser />} />
                          </Route>
                          <Route index element={<UserList />} />
                        </Route>
                      )}
                      <Route path="email-templates">
                        <Route index element={<EmailTemplateList />} />
                        <Route path="create" element={<CreateEmailTemplate />} />
                        <Route path=":email_template_id">
                          <Route index element={<EmailTemplate />} />
                          <Route path="edit" element={<EditEmailTemplate />} />
                          <Route path="fragments">
                            <Route path="create" element={<CreateEmailFragment />} />
                            <Route path=":email_fragment_id">
                              <Route index element={<EmailFragment />} />
                              <Route path="edit" element={<EditEmailFragment />} />
                            </Route>
                          </Route>
                        </Route>
                      </Route>
                      <Route path="templates">
                        <Route index element={<TemplateList />} />
                        <Route path="create" element={<CreateTemplate />} />
                        <Route path=":template_id">
                          <Route index element={<Template />} />
                          <Route path="edit" element={<EditTemplate />} />
                          <Route path="tasks">
                            <Route index element={<TaskList />} />
                            <Route path="create" element={<CreateTask />} />
                            <Route path=":task_id">
                              <Route index element={<Task />} />
                              <Route path="edit" element={<EditTask />} />
                            </Route>
                          </Route>
                        </Route>
                      </Route>
                      <Route path="devices">
                        <Route index element={<Devices />} />
                        <Route path="create" element={<CreateDevice />} />
                        <Route path=":device_id">
                          <Route index element={<Device />} />
                          <Route path="edit" element={<EditDevice />} />
                        </Route>
                      </Route>
                      <Route path="device-types">
                        <Route path="create" element={<CreateDeviceType />} />
                        <Route path=":device_type_id">
                          <Route index element={<DeviceType />} />
                          <Route path="edit" element={<EditDeviceType />} />
                        </Route>
                      </Route>
                      <Route path="matters">
                        <Route index element={<Matters />} />
                        {hasRole("user") && (
                          <Route path="create" element={<CreateMatter />} />
                        )}
                        <Route path=":matter_id">
                          <Route index element={<Matter />} />
                          {hasRole("restricted") && (
                            <Route path="edit" element={<EditMatter />} />
                          )}
                          <Route path="kits">
                            <Route index element={<KitList />} />
                            <Route path="create" element={<CreateKit />} />
                            <Route path=":kit_id">
                              <Route index element={<Kit />} />
                              <Route path="edit" element={<EditKit />} />
                              <Route path="edit/step/:step" element={<EditKit />} />
                              <Route
                                path="picklist"
                                element={<KitPickList />}
                              />
                            </Route>
                          </Route>
                          <Route path="tasks">
                            <Route path=":task_id">
                              <Route index element={<MatterTask />} />
                            </Route>
                          </Route>
                          <Route path="custodians">
                            <Route index element={<CustodianList />} />
                            {hasRole("user") && (
                              <Route
                                path="create"
                                element={<CreateCustodian />}
                              />
                            )}
                            <Route path=":custodian_id">
                              <Route index element={<Custodian />} />
                              {hasRole("restricted") && (
                                <Route
                                  path="edit"
                                  element={<EditCustodian />}
                                />
                              )}
                              <Route path="tasks">
                                <Route path=":task_id">
                                  <Route index element={<CustodianTask />} />
                                </Route>
                              </Route>
                              <Route path="collections">
                                <Route index element={<CollectionList />} />
                                {hasRole("user") && (
                                  <Route
                                    path="create"
                                    element={<CreateCollection />}
                                  />
                                )}
                                <Route path=":collection_id">
                                  <Route index element={<Collection />} />
                                  {hasRole("restricted") && (
                                    <Route
                                      path="edit"
                                      element={<EditCollection />}
                                    />
                                  )}
                                  <Route path="display-only" element={<DisplayOnlyCollection />} />
                                  <Route path="tasks">
                                    <Route path=":task_id">
                                      <Route
                                        index
                                        element={<CollectionTask />}
                                      />
                                    </Route>
                                  </Route>
                                </Route>
                              </Route>
                            </Route>
                          </Route>
                        </Route>
                      </Route>
                    </Route>
                    <Route index element={<OrganizationList />} />
                  </Route>
                ) : (
                  <Route path="collections">
                    <Route index element={<OfflineCollections />} />
                    <Route path=":collection_id">
                      <Route index element={<OfflineViewCollection />} />
                      <Route path="edit" element={<OfflineEditCollection />} />
                    </Route>
                  </Route>
                )}
                {/* PRIVACY ROUTES */}
                {/* <Route path="privacy">
                  <Route path="policy" element={<h1>Privacy Policy Page</h1>} />
                  <Route
                    path="terms-of-use"
                    element={<h1>Privacy Terms of Use Page</h1>}
                  />
                  <Route
                    path="copyright"
                    element={<h1>Privacy Copyright Page</h1>}
                  />
                </Route> */}

                {/* PERMISSION ROUTES */}
                {/* <Route path="access">
                  <Route path="roles" element={<h1>Privacy Policy Page</h1>} />
                  <Route
                    path="permissions"
                    element={<h1>Privacy Policy Page</h1>}
                  />
                </Route> */}
              </Route>
            </Routes>
          </Router>
        </NotificationsProvider>
      </MantineProvider>
    </AlertProvider>
  );
}

export default App;
