import React, { useEffect, useState, } from "react";
import { axios, } from "libs";
import { useParams, useNavigate, } from "react-router-dom";
import DetailsView from "components/Views/DetailsView";
import { useAlert, useSettings, useUser, } from "hooks";
import {
  Device as DeviceDefinition,
} from "interfaces/main";
/** @jsxImportSource @emotion/react */
// import { css } from "@emotion/react";
import { getFormattedDeviceName, } from "utils/formatting";
import { parseJsonFields, } from "utils/fetching";
import JSONSection from "components/Inputs/JsonFields";
import { Grid, } from "@mantine/core";
import TextDisplay from "components/Display/TextDisplay";
import { resolveTheme, } from "themes/main";

function Device() {
  const [device, setDevice] = useState<DeviceDefinition | null>(null);
  const { organization_id, device_id, } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const alertContext = useAlert();
  const { hasRole, } = useUser();

  const getDevice = async () => {
    setLoading(true);
    axios
      .get(`/organizations/${organization_id}/devices/${device_id}`)
      .then((res) => {
        const parsed = parseJsonFields(res.data.data);
        setDevice(parsed);
      })
      .catch((err) => {
        alertContext.setAlert({
          type: "danger",
          message: "Error fetching device",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getDevice();
  }, [
    organization_id,
    device_id
  ]);

  const handleDelete = () => {
    alertContext.setConfirmation(
      "Are you sure you want to delete this device?",
      () => {
        setLoading(true);
        axios
          .delete(`/organizations/${organization_id}/devices/${device_id}`)
          .then(() => {
            alertContext.setAlert({
              type: "success",
              message: "Device deleted successfully",
            });
            navigate(`/organizations/${organization_id}/devices`);
          })
          .catch((err) => {
            alertContext.setAlert({
              type: "danger",
              message: "Error deleting device",
            });
            // eslint-disable-next-line no-console
            console.error(err);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    );
  };

  const submitPeripherals = (peripherals: DeviceDefinition["peripherals"]) => {
    axios
      .put(
        `/organizations/${organization_id}/devices/${device_id}`,
        {
          peripherals,
        }
      )
      .then((res) => {
        alertContext.setAlert({
          type: "success",
          message: "Matter details updated successfully",
        });
        const parsed = parseJsonFields(res.data.data);
        setDevice({
          ...device,
          ...parsed,
        });
      })
      .catch((err) => {
        alertContext.setAlert({
          type: "danger",
          message: "Error updating collection details",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  const submitSpecifications = (specifications: DeviceDefinition["specifications"]) => {
    axios
      .put(
        `/organizations/${organization_id}/devices/${device_id}`,
        {
          specifications,
        }
      )
      .then((res) => {
        alertContext.setAlert({
          type: "success",
          message: "Matter details updated successfully",
        });
        const parsed = parseJsonFields(res.data.data);
        setDevice({
          ...device,
          ...parsed,
        });
      })
      .catch((err) => {
        alertContext.setAlert({
          type: "danger",
          message: "Error updating collection details",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  const { theme: { current, }, } = useSettings();
  const currentTheme = resolveTheme(current);
  const { colors, } = currentTheme;

  const calculateInitialStorage = (storage: number) => {
    if (!storage) {
      return {
        value: 0,
        unit: "MB",
      };
    }

    if (storage < 1000) {
      return {
        value: storage,
        unit: "MB",
      };
    }

    if (storage < 1000000) {
      return {
        value: storage / 1000,
        unit: "GB",
      };
    }

    return {
      value: storage / 1000000,
      unit: "TB",
    };
  };

  return (
    <div>
      <DetailsView
        title={getFormattedDeviceName(device) || "Device"}
        loading={loading}
        buttons={[
          // {
          //   text: "Custodians",
          //   handleClick: () => {
          //     navigate("custodians");
          //   },
          //   color: "digitalPurple",
          //   variant: "subtle"
          // },
          {
            text: "Edit",
            handleClick: () => {
              navigate("edit");
            },
            color: colors.primary,
            variant: "default",
            invisible: !hasRole("restricted"),
          },
          {
            text: "Delete",
            handleClick: handleDelete,
            color: colors.danger,
            variant: "filled",
            invisible: !hasRole("poweruser"),
          }
        ]}
      >
        <Grid>
          <Grid.Col sm={12} md={6}>
            <TextDisplay
              label="Make"
              data={device?.make || "None provided"}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6}>
            <TextDisplay
              label="Model"
              data={device?.model || "None provided"}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6}>
            <TextDisplay
              label="Serial Number"
              data={device?.serial || "None provided"}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6}>
            <TextDisplay
              label="Storage"
              data={`${calculateInitialStorage(device?.storage || 0).value} ${calculateInitialStorage(device?.storage || 0).unit}`}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6}>
            <TextDisplay
              label="Type"
              data={device?.device_type?.name || "None provided"}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6}>
            <TextDisplay
              label="Weight"
              data={device?.weight || "None provided"}
            />
          </Grid.Col>
          {device?.kit_id && device?.kit && (
            <Grid.Col sm={12} md={6}>
              <TextDisplay
                label="Kit"
                data={`${device?.kit?.name} (${device.kit.id})` || "None provided"}
                endpoint={`/organizations/${organization_id}/matters/${device.kit.matter_id}/kits/${device?.kit_id}`}
              />
            </Grid.Col>
          )}
        </Grid>
      </DetailsView>
      <JSONSection
        title="Specifications"
        fields={device?.specifications || {}}
        submitFields={submitSpecifications}
      />
      <JSONSection
        title="Peripherals"
        fields={device?.peripherals || {}}
        submitFields={submitPeripherals}
      />
    </div>
  );
}

export default Device;
