import React from "react";
/** @jsxImportSource @emotion/react */
import { css, } from "@emotion/react";
import { useSearch, useSettings, } from "hooks";
import { resolveTheme, } from "themes/main";

function SortDirection({
  table_name,
}: {
  table_name: string;
}) {
  const options: ["asc", "desc"] = ["asc", "desc"];

  const { getTable, setTableSort, } = useSearch();
  const { sort, } = getTable(table_name);
  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);

  return (
    <div
      css={css`
        width: 100%;
        margin: 14px 0;
      `}
    >
      <select
        name="sort_order"
        css={css`
          width: 100%;
          height: 42px;
          padding: 8px 16px;
          border: 1px solid ${currentTheme.colors.backgroundContrast};
          font-size: 16px;
          box-sizing: border-box;
          outline: none;
          transition: all 0.2s ease-in-out;
          border-radius: 8px;
          background-color: ${currentTheme.colors.background};
          color: ${currentTheme.colors.text};

          &:focus {
            border-color: ${currentTheme.colors.primary};
            border-radius: 0;
          }
        `}
        onChange={(e) => setTableSort(table_name, {
          direction: e.target.value as "asc" | "desc",
          field: sort.field || "",
        })}
        value={sort.direction || "asc"}
      >
        <option value="">Sort Direction...</option>
        {options?.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
}

export default SortDirection;
