import { useOfflineServices, } from "hooks";

interface DetectorProps {
    // eslint-disable-next-line no-unused-vars
    render: (isOnline: boolean) => JSX.Element | null;
}

function Detector({ render, } : DetectorProps) {
  const { isOnline, } = useOfflineServices();

  return render(isOnline);
}

export default Detector;
