/* eslint-disable no-unreachable */
import { useLayoutEffect, useState, } from "react";
import { axios, getCookie, } from "libs";
import { AxiosResponse, } from "axios";
import { useSettings, } from "hooks";

export interface useOfflineServicesTypes {
  isOnline: boolean;
  isOnlineText: string;
}

function useOfflineServices(): useOfflineServicesTypes {
  const [isOnline, setIsOnline] = useState<boolean>(false);
  const {
    network: {
      offline_mode: {
        current: offlineMode,
      },
    },
  } = useSettings();

  useLayoutEffect(() => {
    window.addEventListener("offline", setOnlineStatus);
    window.addEventListener("online", setOnlineStatus);
    setOnlineStatus();
    return () => {
      window.removeEventListener("offline", setOnlineStatus);
      window.removeEventListener("online", setOnlineStatus);
    };
  }, []);

  const setOnlineStatus = (): void => {
    if (navigator.onLine) {
      isReachable().then(function (online: boolean) {
        if (online) {
          // handle online status
          setIsOnline(true);
        } else {
          setIsOnline(false);
        }
      });
    } else {
      // handle offline status
      setIsOnline(false);
    }
  };

  if (offlineMode) {
    return {
      isOnline: false,
      isOnlineText: "Offline",
    };
  }

  return {
    isOnline,
    isOnlineText: isOnline ? "Online" : "Offline",
  };
}

export default useOfflineServices;

async function isReachable() : Promise<boolean> {
  // return false; // ! Only to easily mimic offline status during development
  const XSRF_TOKEN = getCookie("XSRF-TOKEN");

  if (XSRF_TOKEN) {
  // const response: AxiosResponse = await axios.get("/ping", {
    //   headers: {
    //     "X-XSRF-TOKEN": XSRF_TOKEN
    //   }
    // });
    return true;
  }
  const response:AxiosResponse = await axios.get("/sanctum/csrf-cookie");
  return response.status === 200;
}
