import React, { useEffect, useState, } from "react";
import { Loading, } from "components";
import { useNavigate, useParams, } from "react-router";
import { axios, } from "libs";
import { useUser, useSearch, useSettings, } from "hooks";
import { Kit, } from "interfaces/main";
import { getModuleExclusiveCompleteness, getModuleFields, } from "utils/methods";
import IntegratedListView from "components/Views/IntegratedListView";
import { kitStatusTypes, } from "vars/constants";
import { resolveTheme, } from "themes/main";

function KitList() {
  const [kits, setKits] = useState<Kit[]>([]);
  const [loading, setLoading] = useState(false);

  const onError = (err: any) => {
    // eslint-disable-next-line no-console
    console.error(err);
  };
  const { organization_id, matter_id, } = useParams();
  const { hasRole, } = useUser();
  const { getTable, } = useSearch();
  const { sort, } = getTable("kits");

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/organizations/${organization_id}/matters/${matter_id}/kits`)
      .then((res) => {
        setKits(res.data.data);
      })
      .catch(onError)
      .finally(() => setLoading(false));
  }, []);

  const allFields: (keyof Kit)[] =
    kits?.[0] && (Object.keys(kits[0]) as unknown as (keyof Kit)[]);

  const allowedFields = new Map([
    ["id", 0],
    ["matter_id", 0],
    ["status", 0],
    ["status_label", 0],
    ["created_at", 0],
    ["updated_at", 0],
    ["name", 0]
  ]);

  const fields = getModuleFields(allowedFields, allFields);

  const getSubtitle = (k: Kit) => {
    if (!sort.field) {
      return kitStatusTypes[k.status].label;
    }
    return `${sort.field}: ${k[sort.field as keyof Kit]}`;
  };

  const listItems = kits.map((kit) => ({
    id: kit.id,
    title: kit.name,
    subtitle: getSubtitle(kit),
    endpoint: `/organizations/${organization_id}/matters/${matter_id}/kits/${kit.id}`,
    full_record: kit,
    completeness: getModuleExclusiveCompleteness(kit) || 0,
  }));

  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);
  const { colors, } = currentTheme;

  if (loading) {
    return <Loading />;
  }

  return (
    <IntegratedListView
      title="Kits"
      loading={loading}
      buttons={[
        {
          text: "",
          icon: "add",
          handleClick: () => navigate(
            `/organizations/${organization_id}/matters/${matter_id}/kits/create`
          ),
          color: colors.primary,
          variant: "default",
          invisible: !hasRole("user"),
        }
      ]}
      search
      collapsable
      fields={fields}
      table_name="kits"
      full_record_list={kits}
      list_items={listItems}
    />
  );
}

export default KitList;
