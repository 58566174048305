import { useEffect, useState, } from "react";
import { useAlert, useSettings, useUser, } from "hooks";
import CreateView from "components/Views/CreateView";
import { Validation, } from "interfaces/responses";
import { axios, } from "libs";
import {
  Grid, Select, Text, TextInput,
} from "@mantine/core";
import { useParams, useNavigate, } from "react-router-dom";
import { parseJsonFields, } from "utils/fetching";
import { EmailTemplate, Kit, Template, } from "interfaces/main";
import { resolveTheme, } from "themes/main";
import { validateEmail, } from "utils/methods";

function CreateKit() {
  const alertContext = useAlert();
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [validationRules, setValidationRules] = useState<Validation>();
  const [requiredFields, setRequiredFields] = useState<string[]>([]);
  const [validated, setValidated] = useState(false);
  const { organization_id, matter_id, } = useParams();
  const { setAlert, } = useAlert();
  const [templates, setTemplates] = useState<Template[]>([]);
  const [template_id, setTemplateId] = useState<number | null>(null);
  const { user, } = useUser();
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([]);

  const [FormData, setFormData] = useState<Partial<Kit>>({
    name: "",
    notification_emails: [user.email],
    email_template_id: undefined,
  });

  const onError = (response: any): void => {
    // eslint-disable-next-line no-console
    console.error("On error", response);
    setAlert({
      type: "danger",
      heading: "Error",
      message: response.Error,
    });
  };

  useEffect(() => {
    axios
      .get(`/organizations/${organization_id}/matters/${matter_id}/kits/create`)
      .then((res) => {
        const { validation, } = res.data.data as { validation: Validation };
        setRequiredFields(
          Object.keys(validation.rules).filter((rule) => {
            if (!validation.rules[rule].includes("required")) {
              return false;
            }
            return true;
          })
        );
        setValidationRules(validation);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error("error", err);
      })
      .finally(() => {
        setLoading(false);
      });

    axios
      .get(`/organizations/${organization_id}/templates/type/kits`)
      .then((res) => {
        setTemplates(res.data.data);
      })
      .catch(onError);

    axios
      .get(`/organizations/${organization_id}/email-templates`)
      .then((res) => {
        setEmailTemplates(res.data.data);
      })
      .catch(onError);
  }, []);

  const navigate = useNavigate();

  const initialize = (): void => {
    setLoading(true);
    axios
      .post(
        `/organizations/${organization_id}/matters/${matter_id}/kits`,
        FormData
      )
      .then((res) => {
        const newKit = parseJsonFields(res.data.data) as Kit;
        alertContext.setAlert({
          type: "success",
          heading: "Success",
          message: "Kit created successfully",
        });
        if (template_id) {
          assignTemplate(newKit.id);
        } else {
          navigate(
            `/organizations/${organization_id}/matters/${matter_id}/kits/${newKit.id}/edit/step/2`
          );
        }
      })
      .catch(onError)
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const { name, value, } = e.target;
    setFormData({ ...FormData, [name]: value, });
  };

  const formattedTemplates = templates.map((temp) => ({
    label: temp.name,
    value: temp.id.toString(),
  }));

  const assignTemplate = (kit_id: number) => {
    setLoading(true);
    axios
      .post(
        `/organizations/${organization_id}/matters/${matter_id}/kits/${kit_id}/template`,
        {
          template_id,
        }
      )
      .then(() => {
        alertContext.setAlert({
          type: "success",
          heading: "Success",
          message: "Template assigned successfully",
        });
        navigate(
          `/organizations/${organization_id}/matters/${matter_id}/kits/${kit_id}/edit/step/2`
        );
      })
      .catch(onError);
  };

  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);
  const { colors, } = currentTheme;

  const isRequired = (field: string): boolean => requiredFields.includes(field);

  return (
    <CreateView
      title="Step 1: Kit Initialization"
      loading={loading}
      buttons={[
        {
          text: "Submit",
          handleClick: initialize,
          color: colors.primary,
          disabled: !validated,
        }
      ]}
      requiredFields={requiredFields}
      formState={FormData}
      setValidated={setValidated}
    >
      <Grid>
        <Grid.Col sm={12} md={6}>
          <TextInput
            label="Name"
            name="name"
            required={isRequired("name")}
            onChange={handleChange}
            placeholder="Enter a name for the kit"
            value={FormData.name || ""}
          />
        </Grid.Col>
        <Grid.Col sm={12} md={6}>
          <Select
            label="Template"
            name="template_id"
            data={formattedTemplates}
            onChange={(value) => {
              if (value) {
                setTemplateId(Number(value));
              }
            }}
            searchable
            placeholder="Select a template"
            value={String(template_id) || ""}
          />
        </Grid.Col>
        <Grid.Col sm={12} md={6}>
          <TextInput
            label="Notification Emails"
            name="notification_emails"
            required={isRequired("notification_emails")}
            onChange={(e) => {
              setFormData({
                ...FormData,
                notification_emails: e.target.value ?
                  e.target.value.split(",").map((email) => email.trim()) :
                  [],
              });
            }}
            placeholder="Enter a comma separated list of emails"
            value={FormData.notification_emails?.join(", ") || ""}
          />
          {FormData.notification_emails?.map((email) => {
            if (!validateEmail(email) && email.length > 0) {
              return (
                <Text
                  color={currentTheme.colors.danger}
                  size="xs"
                  weight={300}
                  key={email}
                >
                  &quot;
                  {email}
                  &quot; is not a valid email address
                </Text>
              );
            }
            return null;
          })}
        </Grid.Col>
        <Grid.Col sm={12} md={6}>
          <Select
            label="Email Template"
            name="email_template_id"
            data={emailTemplates.map((t) => ({
              label: t.name,
              value: t.id.toString(),
            }))}
            onChange={(value) => {
              if (value) {
                setFormData({
                  ...FormData,
                  email_template_id: Number(value),
                });
              }
            }}
            placeholder="Select an email template"
            searchable
          />
        </Grid.Col>
      </Grid>
    </CreateView>
  );
}

export default CreateKit;
