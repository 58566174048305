import React, { useEffect, } from "react";
import {
  Grid, Text, Timeline, Title,
} from "@mantine/core";
import { useSettings, } from "hooks";
import { resolveTheme, } from "themes/main";
import { css, } from "@emotion/react";
import { Check, } from "phosphor-react";
/** @jsxImportSource @emotion/react */

export interface Step {
  name: string;
  // eslint-disable-next-line no-unused-vars
  element: (args: {
    // eslint-disable-next-line no-unused-vars
    markComplete: <T>(data: T | undefined) => void;
    invalidate: () => void;
  }) => JSX.Element | JSX.Element[];
  id: string;
}

interface WizardProps {
  name: string;
  steps: Step[];
  currentStep: number;
  // eslint-disable-next-line no-unused-vars
  submitData: <T>(id: string, data: T) => void;
  nextStep: () => void;
  // eslint-disable-next-line no-unused-vars
  setStep: (step: number) => void;
  // eslint-disable-next-line no-unused-vars
  onStepCompletenessChange: (stepCompleteness: boolean[]) => void;
}

function Wizard({
  name,
  steps,
  currentStep: cStep,
  submitData,
  nextStep,
  setStep,
  onStepCompletenessChange,
}: WizardProps) {
  const {
    theme: { current: currentTheme, },
  } = useSettings();
  const { colors, } = resolveTheme(currentTheme);
  const [stepCompleteness, setStepCompleteness] = React.useState<boolean[]>(
    () => steps.map(() => false)
  );

  useEffect(() => {
    onStepCompletenessChange(stepCompleteness);
  }, [stepCompleteness]);

  const isLast = cStep === steps.length - 1;

  // eslint-disable-next-line no-unused-vars
  const markStepComplete = (step: number) => {
    setStepCompleteness((prev) => {
      const newCompleteness = [...prev];
      newCompleteness[step] = true;
      return newCompleteness;
    });
    if (!isLast) {
      nextStep();
    }
  };

  const stepsToShow = steps.slice(0, cStep + 1);

  useEffect(() => {
    // find the first incomplete step
    const firstIncomplete = stepCompleteness.findIndex((step) => !step);
    if (firstIncomplete !== -1) {
      setStep(firstIncomplete);
    }
  }, [stepCompleteness]);

  return (
    <div
      css={css`
        color: ${colors.text};
      `}
    >
      <Grid gutter={36}>
        <Grid.Col span={12}>
          <Title order={1} weight={400}>
            {name}
          </Title>
        </Grid.Col>
        <Grid.Col span={12}>
          <hr />
        </Grid.Col>
        <Grid.Col sm={12} md={9}>
          <Grid>
            {stepsToShow.map((step, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Grid.Col span={12} key={index}>
                <Title order={2} weight={400}>
                  Step
                  {" "}
                  {index + 1}
                  :
                  {" "}
                  {step.name}
                </Title>
                <div
                  css={css`
                    padding-block: 24px;
                  `}
                >
                  {step.element({
                    markComplete: (data: any) => {
                      markStepComplete(index);
                      submitData(step.id, data);
                    },
                    invalidate: () => {
                      setStepCompleteness((prev) => {
                        const newCompleteness = [...prev];
                        newCompleteness[index] = false;
                        for (let i = index + 1; i < newCompleteness.length; i += 1) {
                          newCompleteness[i] = false;
                        }
                        return newCompleteness;
                      });
                    },
                  })}
                </div>
              </Grid.Col>
            ))}
          </Grid>
        </Grid.Col>
        <Grid.Col
          sm={0}
          md={3}
          css={css`
          @media (max-width: 768px) {
            display: none;
          }
        `}
        >
          <div css={css`
            position: sticky;
            top: 36px;
          `}
          >
            <Timeline
              active={cStep}
              bulletSize={24}
              lineWidth={2}
              color="gray"
            >
              {steps.map((step, index) => (
                <Timeline.Item
                  key={step.name}
                  bullet={stepCompleteness[index] ? <Check /> : <div />}
                  title={(
                    <Text
                      color="dimmed"
                      size="md"
                    >
                      {step.name}
                    </Text>
                )}
                />
              ))}
            </Timeline>
          </div>
        </Grid.Col>
      </Grid>
    </div>
  );
}

export default Wizard;
