import React, { useEffect, useState, } from "react";
import { axios, } from "libs";
import { useParams, } from "react-router-dom";
import DetailsView from "components/Views/DetailsView";
import { Loading, } from "components";
import { useAlert, useSettings, } from "hooks";
import DisplayNotes from "components/Notes/DisplayNotes";
import {
  Task,
  Collection as CollectionDefinition,
} from "interfaces/main";
import DisplayTasks from "components/Tasks/DisplayTasks";
import { parseJsonFields, } from "utils/fetching";
import DetailsSection from "components/Details/Details";
import CredentialsSection from "components/Credentials/Credentials";
import { isEmptyObject, } from "utils/methods";
import TextDisplay from "components/Display/TextDisplay";
import { Grid, } from "@mantine/core";

function CollectionDisplayOnly() {
  const [collection, setCollection] = useState<CollectionDefinition | null>(
    null
  );
  const [collectionTasks, setCollectionTasks] = useState<Task[]>([]);
  const [loading, setLoading] = useState(false);
  const {
    organization_id, matter_id, custodian_id, collection_id,
  } =
    useParams();
  const alertContext = useAlert();

  const getCollection = async () => axios
    .get(
      `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/collections/${collection_id}`
    )
    .then((res) => {
      const parsed = parseJsonFields(res.data.data);
      setCollection(parsed);
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      alertContext.setAlert({
        type: "danger",
        message: "Error fetching collection",
      });
    });

  const getTasks = () => {
    axios
      .get(
        `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/collections/${collection_id}/tasks`
      )
      .then((res) => {
        setCollectionTasks(res.data.data);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        alertContext.setAlert({
          type: "danger",
          message: "Error fetching tasks",
        });
      });
  };

  useEffect(() => {
    setLoading(true);
    getCollection().finally(() => setLoading(false));
    getTasks();
  }, [
    organization_id,
    matter_id,
    custodian_id
  ]);

  const tasksWithLinks = collectionTasks.map((task) => ({
    ...task,
    link: `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/collections/${collection_id}/tasks/${task.id}`,
    endpoint: `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/collections/${collection_id}/tasks/${task.id}`,
  }));

  const {
    view: { ui: { show_navigation, }, },
  } = useSettings();

  useEffect(() => {
    show_navigation.set(false);

    return () => {
      show_navigation.set(true);
    };
  }, [show_navigation.set]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <DetailsView
        title={collection?.name || "Collection"}
        loading={loading}
        buttons={[]}
      >
        <Grid>
          <Grid.Col sm={12} md={6}>
            <TextDisplay
              label="Scheduled At"
              data={collection?.scheduled_at || "N/A"}
              style={{
                width: "50vw",
              }}
              text_only
            />
          </Grid.Col>
        </Grid>
      </DetailsView>
      {!isEmptyObject(collection?.details) && (
        <DetailsSection
          details={collection?.details || {}}
          submitDetails={() => { }}
          readonly
          display_only
        />
      )}
      {!isEmptyObject(collection?.credentials) && (
        <CredentialsSection
          credentials={collection?.credentials || {}}
          submitCredentials={() => { }}
          readonly
          display_only
        />
      )}
      <DisplayNotes
        notes={collection?.notes || []}
        disabled={!!collection?.completed_at}
      />
      <DisplayTasks
        tasks={tasksWithLinks || []}
        showProgress
      />
    </div>
  );
}

export default CollectionDisplayOnly;
