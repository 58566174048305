import React, {
  ChangeEvent, FormEvent, useEffect, useState,
} from "react";
import { AxiosResponse, } from "axios";
import { axios, } from "libs";
import { useAlert, useSettings, } from "hooks";
import { AlertContextProps, } from "contexts/AlertContext";
import CreateView from "components/Views/CreateView";
import {
  Checkbox,
  Grid,
  NumberInput,
  Select,
  TextInput,
  Title,
} from "@mantine/core";
import { Validation, } from "interfaces/responses";
import { useNavigate, } from "react-router-dom";
import { Organization, } from "interfaces/main";
import { resolveTheme, } from "themes/main";
import { stateOptions, } from "vars/constants";
import { countries, } from "vars/countries";

/** @jsxImportSource @emotion/react */

function CreateOrganization(): JSX.Element {
  const alertState: AlertContextProps = useAlert();

  const [formData, setFormData] = useState<Partial<Organization>>({
    name: "",
    code_prefix: "",
    length: 0,
    width: 0,
    height: 0,
  });
  const [validated, setValidated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [validationRules, setValidationRules] = useState<Validation>();
  const [requiredFields, setRequiredFields] = useState<string[]>([]);
  const navigate = useNavigate();

  const onChange = (event: ChangeEvent): void => {
    const target = event.target as HTMLInputElement;
    setFormData({ ...formData, [target.name]: target.value, });
  };
  // eslint-disable-next-line no-unused-vars
  const onSuccess = (response: AxiosResponse): void => {
    alertState.setAlert({
      type: "success",
      message: "Organization created successfully",
    });

    setValidated(false);
    setFormData({
      name: "",
      code_prefix: "",
      address: {
        street1: "",
        street2: "",
        street3: "",
        postalCode: "",
        city: "",
        state: "",
        country: "",
        company: "",
        phone: "",
      },
    });
  };
  const onError = (err: AxiosResponse): void => {
    // eslint-disable-next-line no-console
    console.error(err);
    alertState.setAlert({
      type: "danger",
      message: "Error creating organization",
    });
  };

  const onSubmit = (e: FormEvent): void => {
    e.preventDefault();
    setLoading(true);

    axios
      .post("/organizations", formData)
      .then((res) => {
        alertState.setAlert({
          type: "success",
          message: "Organization created successfully",
        });
        const newOrganization = res.data.data as Organization;
        navigate(`/organizations/${newOrganization.id}`);
      })
      .catch(onError);
  };

  useEffect(() => {
    axios
      .get("/organizations/create")
      .then((res) => {
        const { validation, } = res.data.data as { validation: Validation };
        setRequiredFields(
          Object.keys(validation.rules).filter((rule) => {
            if (!validation.rules[rule].includes("required")) {
              return false;
            }
            return true;
          })
        );
        setValidationRules(validation);
      })
      .catch(onError);
    setLoading(false);
  }, []);

  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);
  const { colors, } = currentTheme;

  return (
    <CreateView
      title="Create Organization"
      requiredFields={requiredFields}
      loading={loading}
      buttons={[
        {
          text: "Submit",
          handleClick: onSubmit,
          disabled: !validated,
          color: colors.primary,
        }
      ]}
      setValidated={setValidated}
      formState={formData}
    >
      <Grid>
        <Grid.Col md={6} sm={12}>
          <TextInput
            label="Name"
            name="name"
            placeholder="Name"
            onChange={onChange}
            required={validationRules?.rules?.name?.includes("required")}
          />
        </Grid.Col>
        <Grid.Col md={6} sm={12}>
          <TextInput
            label="Client Code"
            name="code_prefix"
            placeholder="Client Code"
            onChange={onChange}
            required={validationRules?.rules?.code_prefix?.includes("required")}
          />
        </Grid.Col>
        <Grid.Col span={12} />
        <Grid.Col sm={12} span={6}>
          <Title order={3} weight={300} color={currentTheme.colors.text}>
            Default Kit Dimensions
          </Title>
        </Grid.Col>
        <Grid.Col sm={12} md={6}>
          <NumberInput
            label="Length"
            name="length"
            placeholder="Length"
            onChange={(e) => {
              setFormData({ ...formData, length: e || 1, });
            }}
            required={validationRules?.rules?.length?.includes("required")}
            value={formData.length}
          />
        </Grid.Col>
        <Grid.Col sm={12} md={6}>
          <NumberInput
            label="Width"
            name="width"
            placeholder="Width"
            onChange={(e) => {
              setFormData({ ...formData, width: e || 1, });
            }}
            required={validationRules?.rules?.width?.includes("required")}
            value={formData.width}
          />
        </Grid.Col>
        <Grid.Col sm={12} md={6}>
          <NumberInput
            label="Height"
            name="height"
            placeholder="Height"
            onChange={(e) => {
              setFormData({ ...formData, height: e || 1, });
            }}
            required={validationRules?.rules?.height?.includes("required")}
            value={formData.height}
          />
        </Grid.Col>
        <Grid.Col span={12} />
        <Grid.Col sm={12} span={6}>
          <Title order={3} weight={300} color={currentTheme.colors.text}>
            Organization Address
          </Title>
        </Grid.Col>
        <Grid.Col md={6} sm={12}>
          <TextInput
            label="Street 1"
            name="street1"
            placeholder="Street 1"
            onChange={(e) => {
              setFormData({
                ...formData,
                address: {
                  ...formData.address,
                  street1: e.target.value,
                },
              });
            }}
            required={validationRules?.rules?.street1?.includes("required")}
            value={formData.address?.street1 || ""}
          />
        </Grid.Col>
        <Grid.Col md={6} sm={12}>
          <TextInput
            label="Street 2"
            name="street2"
            placeholder="Street 2"
            onChange={(e) => {
              setFormData({
                ...formData,
                address: {
                  ...formData.address,
                  street2: e.target.value,
                },
              });
            }}
            required={validationRules?.rules?.street2?.includes("required")}
            value={formData?.address?.street2 || ""}
          />
        </Grid.Col>
        <Grid.Col md={6} sm={12}>
          <TextInput
            label="Street 3"
            name="street3"
            placeholder="Street 3"
            onChange={(e) => {
              setFormData({
                ...formData,
                address: {
                  ...formData.address,
                  street3: e.target.value,
                },
              });
            }}
            required={validationRules?.rules?.street3?.includes("required")}
            value={formData.address?.street3 || ""}
          />
        </Grid.Col>
        <Grid.Col md={6} sm={12}>
          <TextInput
            label="City"
            name="city"
            placeholder="City"
            onChange={(e) => {
              setFormData({
                ...formData,
                address: {
                  ...formData.address,
                  city: e.target.value,
                },
              });
            }}
            required={validationRules?.rules?.city?.includes("required")}
            value={formData.address?.city || ""}
          />
        </Grid.Col>
        <Grid.Col md={6} sm={12}>
          <Select
            label="State"
            name="state"
            placeholder="State"
            data={stateOptions.map((state) => ({
              label: state.name,
              value: state.abbreviation,
            }))}
            onChange={(value): void => {
              if (!value) {
                return;
              }
              setFormData({
                ...formData,
                address: {
                  ...formData.address,
                  state: value,
                },
              });
            }}
            required={validationRules?.rules?.state?.includes("required")}
            value={formData.address?.state || ""}
            searchable
          />
        </Grid.Col>
        <Grid.Col md={6} sm={12}>
          <TextInput
            label="Postal Code/Zip Code"
            name="postalCode"
            placeholder="Postal Code/Zip Code"
            onChange={(e) => {
              setFormData({
                ...formData,
                address: {
                  ...formData.address,
                  postalCode: e.target.value,
                },
              });
            }}
            required={validationRules?.rules?.postalCode?.includes("required")}
            value={formData.address?.postalCode || ""}
          />
        </Grid.Col>
        <Grid.Col md={6} sm={12}>
          <Select
            label="Country"
            name="country"
            placeholder="Country"
            onChange={(e) => {
              if (!e) {
                return;
              }
              setFormData({
                ...formData,
                address: {
                  ...formData.address,
                  country: e,
                },
              });
            }}
            required={validationRules?.rules?.country?.includes("required")}
            value={formData.address?.country || ""}
            data={countries.map((country) => ({
              label: country.name,
              value: country.code,
            }))}
            searchable
          />
        </Grid.Col>
        <Grid.Col md={6} sm={12}>
          <TextInput
            label="Phone"
            name="phone"
            placeholder="Phone"
            onChange={(e) => {
              setFormData({
                ...formData,
                address: {
                  ...formData.address,
                  phone: e.target.value,
                },
              });
            }}
            required={validationRules?.rules?.phone?.includes("required")}
            value={formData.address?.phone || ""}
          />
        </Grid.Col>
        <Grid.Col md={6} sm={12}>
          <TextInput
            label="Company"
            name="company"
            placeholder="Company"
            onChange={(e) => {
              setFormData({
                ...formData,
                address: {
                  ...formData.address,
                  company: e.target.value,
                },
              });
            }}
            required={validationRules?.rules?.company?.includes("required")}
            value={formData.address?.company || ""}
          />
        </Grid.Col>
        <Grid.Col md={6} sm={12}>
          <TextInput
            label="Name"
            name="name"
            placeholder="Name"
            onChange={(e) => {
              setFormData({
                ...formData,
                address: {
                  ...formData.address,
                  name: e.target.value,
                },
              });
            }}
            required={validationRules?.rules?.name?.includes("required")}
            value={formData.address?.name || ""}
          />
        </Grid.Col>
        <Grid.Col sm={12} md={6}>
          <Checkbox
            label="Residential"
            name="residential"
            onChange={(e) => {
              setFormData({
                ...formData,
                address: {
                  ...formData.address,
                  residential: e.target.checked ? 1 : 0,
                },
              });
            }}
            checked={!!formData.address?.residential || false}
            styles={{
              root: {
                marginTop: "40px",
              },
            }}
          />
        </Grid.Col>
      </Grid>
    </CreateView>
  );
}

export default CreateOrganization;
