import React, { useEffect, } from "react";
import { useOfflineServices, } from "hooks";

interface OnlineProps {
    children: React.ReactNode;
    // eslint-disable-next-line no-unused-vars
    onChange?: (isOnline: boolean) => void;
    className?: string;
    style?: React.CSSProperties;
}

Online.defaultProps = {
  onChange: () => {},
  className: "",
  style: {},
};

function Online({
  children, onChange, className, style,
}: OnlineProps) {
  const { isOnline, } = useOfflineServices();

  useEffect(() => {
    if (onChange) {
      onChange(isOnline);
    }
  }, [isOnline]);

  return isOnline ? <div className={className} style={style}>{children}</div> : null;
}

export default Online;
