import React, { useState, } from "react";
/** @jsxImportSource @emotion/react */
import { css, } from "@emotion/react";
import { useMantineTheme, } from "@mantine/core";
import { Link, useNavigate, } from "react-router-dom";
import { useAlert, useSettings, } from "hooks";
import { axios, } from "libs";
import { resolveTheme, } from "themes/main";
import { copyToClipboard, } from "utils/methods";

interface ActionButtonProps {
  endpoint: string;
  view?: boolean;
  edit?: boolean;
  delete?: boolean;
  copy?: boolean;
  disabled?: boolean;
  copyText?: string;
}

ActionButtons.defaultProps = {
  edit: false,
  delete: false,
  view: false,
  copy: false,
  disabled: false,
  copyText: "",
};

function ActionButtons({
  endpoint,
  edit,
  delete: deleteButton,
  view,
  copy,
  disabled,
  copyText,
}: ActionButtonProps) {
  const theme = useMantineTheme();
  const navigate = useNavigate();

  const { setAlert, setConfirmation, } = useAlert();

  const {
    theme: { current: themeObj, },
  } = useSettings();
  const currentTheme = resolveTheme(themeObj);

  const handleDelete = () => {
    setConfirmation("Are you sure you want to delete this item?", () => {
      // setLoading(true);
      axios
        .delete(endpoint)
        .then(() => {
          setAlert({
            message: "Item deleted successfully",
            type: "success",
          });
          navigate(0);
        })
        .catch((err) => {
          console.error(err);
          setAlert({
            message: "There was a problem deleting the item",
            type: "danger",
          });
        });
    });
  };

  const [copied, setCopied] = useState(false);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        width: 100%;

        @media (min-width: 768px) {
          flex-direction: row;
        }

        .button {
          border: 1px solid ${currentTheme.colors.backgroundAlt};
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 6px;
          border-radius: 6px;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
          margin: 0 5px;
          transition: 0.2s ease-in-out all;
          opacity: ${disabled ? 0.5 : 0.8};
          background-color: ${currentTheme.colors.background};
          margin-block: 4px;

          @media (min-width: 768px) {
            margin-block: 0;
          }

          &:hover {
            box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
            opacity: ${disabled ? 0.5 : 1};
          }

          &:active {
            transform: translateY(1px);
          }
        }

        .view {
          color: ${currentTheme.colors.primary};
          text-decoration: none;
        }

        .delete {
          background-color: ${currentTheme.colors.danger};
          color: ${currentTheme.colors.dangerContrast};
        }

        .edit {
          color: ${currentTheme.colors.info};
          text-decoration: none;
        }

        .copy {
          color: ${currentTheme.colors.success};
        }

        .uncopied {
          color: ${currentTheme.colors.textLight};
        }

        .icon {
          font-size: 14px;
          font-weight: 600;
        }
      `}
    >
      {deleteButton && (
        <button
          type="button"
          className={`button delete ${theme}`}
          title="Delete"
          onClick={(e) => {
            e.stopPropagation();
            handleDelete();
          }}
          disabled={disabled}
        >
          <i className={`icon material-symbols-outlined ${theme}`}>delete</i>
        </button>
      )}
      {edit && (
        <Link
          className={`button edit ${theme}`}
          title="Edit"
          to={`${endpoint}/edit`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <i className={`icon material-symbols-outlined ${theme}`}>edit</i>
        </Link>
      )}
      {view && (
        <Link
          className="button view"
          title="View"
          to={endpoint}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <i className="material-symbols-outlined icon">arrow_forward</i>
        </Link>
      )}
      {copy && (
        <button
          type="button"
          className="button"
          title="Copy"
          onClick={(e) => {
            e.stopPropagation();
            if (copyText) {
              copyToClipboard(copyText);
              setCopied(true);
              setTimeout(() => {
                setCopied(false);
              }, 2000);
            }
            setAlert({
              message: "Copied to clipboard",
              type: "success",
            });
          }}
          disabled={disabled}
        >
          {
            copied ? (
              <i className="material-symbols-outlined icon copy">check</i>
            ) : (
              <i className="material-symbols-outlined icon uncopied">content_copy</i>
            )
          }
        </button>
      )}
    </div>
  );
}

export default ActionButtons;
