import React, { useEffect, useState, } from "react";
import { axios, } from "libs";
import { useParams, useNavigate, } from "react-router-dom";
import DetailsView from "components/Views/DetailsView";
import DefaultView from "components/Views/DefaultView";
import { useAlert, useSettings, useUser, } from "hooks";
import {
  Button,
  Dialog,
  Flex,
  Grid,
  Select,
  Text,
} from "@mantine/core";
import { Note, Matter as MatterDefinition, Task, } from "interfaces/main";
import { User, } from "interfaces/user";
/** @jsxImportSource @emotion/react */
// import { css } from "@emotion/react";
import Notes from "components/Notes/Notes";
import Tasks from "components/Tasks/Tasks";
import Custodians from "pages/Custodians/List";
import Kits from "pages/Kits/List";
import { getFormattedMatterName, } from "utils/formatting";
import { addRecentModuleFromMatter, } from "utils/recent";
import { VisitTimeout, } from "vars/constants";
import DetailsSection from "components/Details/Details";
import CredentialsSection from "components/Credentials/Credentials";
import { parseJsonFields, } from "utils/fetching";
import { isEmptyObject, } from "utils/methods";
import { resolveTheme, } from "themes/main";
import AssignmentCard from "components/InfoCard/AssignmentCard";

function Matter() {
  const [matter, setMatter] = useState<MatterDefinition | null>(null);
  const { organization_id, matter_id, } = useParams();

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const alertContext = useAlert();
  const { hasRole, } = useUser();
  const [users, setUsers] = useState<User[]>([]);
  const [newUser, setNewUser] = useState<number>();
  const [addingUser, setAddingUser] = useState(false);

  const getMatter = async () => {
    setLoading(true);
    axios
      .get(`/organizations/${organization_id}/matters/${matter_id}`)
      .then((res) => {
        const parsed = parseJsonFields(res.data.data);
        setMatter(parsed);
      })
      .catch((err) => {
        alertContext.setAlert({
          type: "danger",
          message: "Error fetching matter",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getUsers = async () => {
    axios
      .get(`/organizations/${organization_id}/users`)
      .then((res) => {
        setUsers(res.data.data);
      })
      .catch((err) => {
        alertContext.setAlert({
          type: "danger",
          message: "Error fetching users",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  useEffect(() => {
    getMatter();
    getUsers();
  }, [organization_id, matter_id]);

  useEffect(() => {
    const addRecent = setTimeout(() => {
      if (matter) {
        addRecentModuleFromMatter(matter);
      }
    }, VisitTimeout);

    return () => {
      clearTimeout(addRecent);
    };
  }, [matter]);

  const handleDelete = () => {
    alertContext.setConfirmation(
      "Are you sure you want to delete this matter?",
      () => {
        setLoading(true);
        axios
          .delete(`/organizations/${organization_id}/matters/${matter_id}`)
          .then(() => {
            alertContext.setAlert({
              type: "success",
              message: "Matter deleted successfully",
            });
            navigate(`/organizations/${organization_id}/matters`);
          })
          .catch((err) => {
            alertContext.setAlert({
              type: "danger",
              message: "Error deleting matter",
            });
            // eslint-disable-next-line no-console
            console.error(err);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    );
  };

  const handleArchive = () => {
    alertContext.setConfirmation(
      "Are you sure you want to archive this matter? Archiving will complete all kits in the matter.",
      () => {
        setLoading(true);
        axios
          .post(
            `/organizations/${organization_id}/matters/${matter_id}/archive`
          )
          .then(() => {
            alertContext.setAlert({
              type: "success",
              message: "Matter archived successfully",
            });
            getMatter();
          })
          .catch((err) => {
            alertContext.setAlert({
              type: "danger",
              message: "Error archiving matter",
            });
            // eslint-disable-next-line no-console
            console.error(err);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    );
  };

  const handleUnarchive = () => {
    alertContext.setConfirmation(
      "Are you sure you want to unarchive this matter?",
      () => {
        setLoading(true);
        axios
          .post(
            `/organizations/${organization_id}/matters/${matter_id}/unarchive`
          )
          .then(() => {
            alertContext.setAlert({
              type: "success",
              message: "Matter unarchived successfully",
            });
            getMatter();
          })
          .catch((err) => {
            alertContext.setAlert({
              type: "danger",
              message: "Error unarchiving matter",
            });
            // eslint-disable-next-line no-console
            console.error(err);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    );
  };

  const submitNotes = async (notes: Note[]) => {
    setLoading(true);
    try {
      try {
        const res = await axios.put(
          `/organizations/${organization_id}/matters/${matter_id}`,
          {
            ...matter,
            notes,
          }
        );
        const parsed = parseJsonFields(res.data.data);
        setMatter({
          ...matter,
          ...(parsed as MatterDefinition),
        });
        alertContext.setAlert({
          type: "success",
          message: "Notes updated successfully",
        });
        return parsed?.notes;
      } catch (err) {
        alertContext.setAlert({
          type: "danger",
          message: "Error updating notes",
        });
        // eslint-disable-next-line no-console
        console.error(err);
        return undefined;
      }
    } finally {
      setLoading(false);
    }
  };

  const assignUser = () => {
    setLoading(true);
    axios
      .post(`/organizations/${organization_id}/matters/${matter_id}/assign`, {
        attach: [newUser],
      })
      .then(() => {
        setNewUser(undefined);
        alertContext.setAlert({
          type: "success",
          message: "User assigned successfully",
        });
        getMatter();
        setAddingUser(false);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        alertContext.setAlert({
          type: "danger",
          message: "Error assigning user",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const unassignUser = (user_id: number) => {
    alertContext.setConfirmation(
      "Are you sure you want to unassign this user?",
      () => {
        setLoading(true);
        axios
          .post(
            `/organizations/${organization_id}/matters/${matter_id}/assign`,
            {
              detach: [user_id],
            }
          )
          .then(() => {
            alertContext.setAlert({
              type: "success",
              message: "User unassigned successfully",
            });
            getMatter();
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.error(err);
            alertContext.setAlert({
              type: "danger",
              message: "Error unassigning user",
            });
          })
          .finally(() => {
            setLoading(false);
          });
      }
    );
  };

  const getMatterTasks = () => {
    axios
      .get(`/organizations/${organization_id}/matters/${matter_id}`, {
        cache: false,
      })
      .then((res) => {
        const parsed = parseJsonFields(res.data.data);
        setMatter({
          ...(matter as MatterDefinition),
          ...parsed,
        });
      })
      .catch((err) => {
        alertContext.setAlert({
          type: "danger",
          message: "Error fetching tasks",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  const addableUsers = users.filter((usr) => {
    if (matter?.users) {
      return !matter.users.some((us) => us.id === usr.id);
    }
    return true;
  });

  const tasksWithLinks = matter?.tasks.map((tsk) => ({
    ...tsk,
    link: `/organizations/${organization_id}/matters/${matter_id}/tasks/${tsk.id}`,
    endpoint: `/organizations/${organization_id}/matters/${matter_id}/tasks/${tsk.id}`,
  }));

  const submitDetails = (details: MatterDefinition["details"]) => {
    axios
      .put(`/organizations/${organization_id}/matters/${matter_id}`, {
        details,
      })
      .then((res) => {
        alertContext.setAlert({
          type: "success",
          message: "Matter details updated successfully",
        });
        const parsed = parseJsonFields(res.data.data);
        setMatter({
          ...matter,
          ...parsed,
        });
      })
      .catch((err) => {
        alertContext.setAlert({
          type: "danger",
          message: "Error updating collection details",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  const submitCredentials = (credentials: MatterDefinition["credentials"]) => {
    axios
      .put(`/organizations/${organization_id}/matters/${matter_id}`, {
        credentials,
      })
      .then((res) => {
        alertContext.setAlert({
          type: "success",
          message: "Matter details updated successfully",
        });
        const parsed = parseJsonFields(res.data.data);
        setMatter(parsed);
      })
      .catch((err) => {
        alertContext.setAlert({
          type: "danger",
          message: "Error updating collection details",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);
  const { colors, } = currentTheme;

  const disabled = matter?.archived_at;

  return (
    <div>
      <DetailsView
        title={matter ? getFormattedMatterName(matter) : "Matter"}
        loading={loading}
        buttons={[
          // {
          //   text: "Custodians",
          //   handleClick: () => {
          //     navigate("custodians");
          //   },
          //   color: "digitalPurple",
          //   variant: "subtle"
          // },
          {
            text: "Edit",
            handleClick: () => {
              navigate("edit");
            },
            color: colors.primary,
            variant: "default",
            invisible: !hasRole("restricted"),
          },
          {
            text: "Archive",
            handleClick: handleArchive,
            color: colors.danger,
            variant: "default",
            invisible: !hasRole("poweruser") || !!matter?.archived_at,
          },
          {
            text: "Unarchive",
            handleClick: handleUnarchive,
            color: colors.primary,
            variant: "default",
            invisible: !hasRole("poweruser") || !matter?.archived_at,
          },
          {
            text: "Delete",
            handleClick: handleDelete,
            color: colors.danger,
            variant: "filled",
            invisible: !hasRole("poweruser"),
          }
        ]}
      >
        <div />
      </DetailsView>
      {!isEmptyObject(matter?.details) && (
        <DetailsSection
          details={matter?.details || {}}
          submitDetails={submitDetails}
        />
      )}
      {!isEmptyObject(matter?.credentials) && (
        <CredentialsSection
          credentials={matter?.credentials || {}}
          submitCredentials={submitCredentials}
        />
      )}
      <Custodians />
      <Kits />
      <DefaultView
        title="Users"
        collapsable
        buttons={[
          {
            text: "Assign User",
            handleClick: () => {
              setAddingUser(true);
            },
            color: colors.primary,
            variant: "filled",
            disabled: !!disabled,
          }
        ]}
      >
        <Dialog
          opened={addingUser}
          withCloseButton
          onClose={() => setAddingUser(false)}
          style={{ width: "100%", }}
          // css={css`
          //   .mantine-Dialog-root{
          //     outline: 1px solid red;
          //   }
          // `}
          size="xs"
        >
          <Grid>
            <Grid.Col sm={12} md={12}>
              <Select
                placeholder="Search for a user"
                data={addableUsers.map((usr) => ({
                  value: usr.id.toString(),
                  label: usr.name,
                }))}
                onChange={(value) => {
                  if (value) setNewUser(Number(value));
                }}
                label="User"
                dropdownPosition="top"
                searchable
              />
            </Grid.Col>
            <Grid.Col sm={12}>
              <Flex justify="flex-end">
                <Button
                  onClick={assignUser}
                  variant="filled"
                  color={colors.primary}
                >
                  Assign
                </Button>
              </Flex>
            </Grid.Col>
          </Grid>
        </Dialog>
        <Grid>
          {matter?.users.length ? (
            matter?.users?.map((usr) => (
              <Grid.Col key={usr.id + usr.email} sm={12}>
                <AssignmentCard
                  key={usr.id + usr.email}
                  record={{
                    ...usr,
                    title: usr.name,
                    subtitle: usr.email,
                    link: `/organizations/${organization_id}/users/${usr.id}`,
                  }}
                  removeRecord={unassignUser}
                  editable={hasRole("poweruser")}
                />
              </Grid.Col>
            ))
          ) : (
            <Grid.Col span={12}>
              <Text
                size="md"
                weight="300"
                color={colors.textLight}
                align="center"
              >
                This matter has no assigned users.
              </Text>
            </Grid.Col>
          )}
        </Grid>
      </DefaultView>
      <Notes
        notes={matter?.notes || []}
        submitNotes={submitNotes}
        photo_endpoint={`/organizations/${organization_id}/photos`}
        refreshNotes={getMatter}
        disabled={!!disabled}
      />
      <Tasks
        tasks={tasksWithLinks || []}
        endpoint={`/organizations/${organization_id}/matters/${matter_id}/tasks`}
        setTasks={(tasks: Task[]) => {
          setMatter({
            ...(matter as MatterDefinition),
            tasks,
          });
        }}
        editable
        refreshTasks={getMatterTasks}
        showProgress
        disabled={!!disabled}
      />
    </div>
  );
}

export default Matter;
