import React, { useEffect, useState, } from "react";
import { useNavigate, useParams, } from "react-router-dom";
import EditView from "components/Views/EditView";
import { axios, } from "libs";
import { Validation, } from "interfaces/responses";
import {
  Button,
  Checkbox,
  Dialog,
  Flex,
  Grid,
  Select,
  Text,
  TextInput,
} from "@mantine/core";
import { useAlert, useSettings, } from "hooks";
import {
  Collection,
  DeviceType,
  EmailTemplate,
  Template,
} from "interfaces/main";
import JSONForm from "components/Inputs/JsonForm";
import { Default, List, } from "components/Views";
import { parseJsonFields, } from "utils/fetching";
import {
  checkOfflineCollectionExists,
  deleteOfflineCollectionIfExists,
  getAndAddOrUpdateOfflineCollection,
} from "db/modules/collections";
import { resolveTheme, } from "themes/main";
import AssignmentCard from "components/InfoCard/AssignmentCard";

function Edit() {
  const [collection, setCollection] = useState<Partial<Collection>>({
    id: undefined,
    notes: undefined,
    name: undefined,
    custodian_id: undefined,
    tasks: undefined,
    custodian: undefined,
    details: {},
    email_template_id: undefined,
  });
  const [loading, setLoading] = useState(true);
  const [validated, setValidated] = useState(false);
  const [validationRules, setValidationRules] = useState<Validation>();
  const [requiredFields, setRequiredFields] = useState<string[]>([]);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [template_id, setTemplateId] = useState<number | null>(null);
  const {
    organization_id, matter_id, custodian_id, collection_id,
  } =
    useParams();
  const { setAlert, } = useAlert();
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([]);
  const [deviceTypes, setDeviceTypes] = useState<DeviceType[]>([]);
  const [managingDeviceTypes, setManagingDeviceTypes] = useState(false);
  const [newDeviceType, setNewDeviceType] = useState<number>();

  const setDetails = (details: Collection["details"]) => {
    setCollection({
      ...collection,
      details,
    });
  };

  const setCredentials = (credentials: Collection["credentials"]) => {
    setCollection({
      ...collection,
      credentials,
    });
  };

  const getCollection = () => {
    setLoading(true);
    axios
      .get(
        `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/collections/${collection_id}`
      )
      .then((response) => {
        const parsed = parseJsonFields(response.data.data);
        setCollection(parsed);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getDeviceTypes = () => {
    axios
      .get(`/organizations/${organization_id}/device-types`)
      .then((res) => {
        setDeviceTypes(res.data.data);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        setAlert({
          type: "danger",
          message: "Error fetching device types",
        });
      });
  };

  useEffect(() => {
    getCollection();
    getDeviceTypes();

    axios
      .get(
        `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/collections/create`
      )
      .then((response) => {
        const { validation, } = response.data.data;
        setRequiredFields(
          Object.keys(validation.rules).filter((rule) => {
            if (!validation.rules[rule].includes("required")) {
              return false;
            }
            return true;
          })
        );
        setValidationRules(validation);
      });

    axios
      .get(`/organizations/${organization_id}/templates/type/collections`)
      .then((res) => {
        setTemplates(res.data.data);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      })
      .finally(() => setLoading(false));

    axios
      .get(`/organizations/${organization_id}/email-templates`)
      .then((res) => {
        setEmailTemplates(res.data.data);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
        setAlert({
          type: "danger",
          message: "Error fetching email templates",
        });
      });
  }, []);

  const handleSubmit = () => {
    setLoading(true);
    axios
      .put(
        `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/collections/${collection_id}`,
        collection
      )
      .then((response) => {
        setValidated(false);
        const parsed = parseJsonFields(response.data.data);
        setCollection(parsed);
        setAlert({
          type: "success",
          message: "Collection updated successfully",
        });
        if (template_id) {
          assignTemplate();
        } else {
          navigate(
            `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/collections/${collection_id}`
          );
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        setAlert({
          type: "danger",
          message: "Error updating matter",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [availableOffline, setAvailableOffline] = useState<boolean>();
  const paramsDefined =
    organization_id && matter_id && custodian_id && collection_id;

  useEffect(() => {
    if (availableOffline === undefined) {
      return;
    }
    if (availableOffline && paramsDefined) {
      getAndAddOrUpdateOfflineCollection(
        organization_id,
        matter_id,
        custodian_id,
        collection_id
      );
    }
    if (!availableOffline && paramsDefined) {
      deleteOfflineCollectionIfExists(collection_id);
    }
  }, [availableOffline]);

  useEffect(() => {
    if (collection_id) {
      checkOfflineCollectionExists(collection_id).then((res) => {
        setAvailableOffline(res);
      });
    }
  }, []);

  const assignTemplate = () => {
    setLoading(true);
    axios
      .post(
        `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/collections/${collection_id}/template`,
        {
          template_id,
        }
      )
      .then(() => {
        setAlert({
          type: "success",
          heading: "Success",
          message: "Template assigned successfully",
        });
        navigate(
          `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/collections/${collection_id}`
        );
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        setAlert({
          type: "danger",
          heading: "Error",
          message: "Error assigning template",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  // eslint-disable-next-line arrow-body-style
  const formattedTemplates = templates.map((temp) => {
    return {
      label: temp.name,
      value: temp.id.toString(),
    };
  });

  const assignDeviceTypesToCollection = () => {
    axios
      .post(
        `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/collections/${collection_id}/assign`,
        {
          attach: [newDeviceType],
        }
      )
      .then(() => {
        setAlert({
          type: "success",
          message: "Device types assigned to collection successfully",
        });
        getCollection();
      })
      .catch((err) => {
        setAlert({
          type: "danger",
          message: "Error assigning device types to collection",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  const unassignDeviceTypesFromCollection = (device_type: number) => {
    axios
      .post(
        `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/collections/${collection_id}/assign`,
        {
          detach: [device_type],
        }
      )
      .then(() => {
        setAlert({
          type: "success",
          message: "Device types unassigned from collection successfully",
        });
        getCollection();
      })
      .catch((err) => {
        setAlert({
          type: "danger",
          message: "Error unassigning device types from collection",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  const addableDeviceTypes = () => {
    const existingDeviceTypes = collection?.device_types || [];
    const deviceTypeIds = existingDeviceTypes.map(
      (deviceType) => deviceType.id
    );
    return deviceTypes.filter(
      (deviceType) => !deviceTypeIds.includes(deviceType.id)
    );
  };

  const {
    theme: { current: theme, },
  } = useSettings();
  const currentTheme = resolveTheme(theme);

  const sortedTemplates = formattedTemplates.sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });

  return (
    <>
      <EditView
        title={`Edit Collection: ${collection?.name}`}
        buttons={
          [
            // {
            //   text: "Submit",
            //   handleClick: handleSubmit,
            //   color: "digitalPurple",
            //   variant: "filled",
            //   disabled: !validated
            // }
          ]
        }
        loading={loading}
        formState={collection}
        requiredFields={requiredFields}
        setValidated={setValidated}
      >
        <Grid>
          <Grid.Col sm={12} md={6}>
            <TextInput
              label="Name"
              value={collection.name || ""}
              onChange={(e) => {
                setCollection({
                  ...collection,
                  name: e.target.value,
                });
              }}
              required={validationRules?.rules?.name?.includes("required")}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6}>
            <TextInput
              label="Scheduled At"
              value={collection.scheduled_at || ""}
              type="datetime-local"
              onChange={(e) => {
                setCollection({
                  ...collection,
                  scheduled_at: e.target.value,
                });
              }}
              required={validationRules?.rules?.scheduled_at?.includes(
                "required"
              )}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6}>
            <Select
              label="Template"
              name="template_id"
              required={validationRules?.rules?.template_id?.includes(
                "required"
              )}
              searchable
              data={sortedTemplates}
              onChange={(value) => {
                if (value) {
                  setTemplateId(parseInt(value, 10));
                }
              }}
            />
          </Grid.Col>
          {/* <Grid.Col sm={12} md={6}>
            <Autocomplete
              label="Email Template"
              name="email_template_id"
              data={emailTemplates.map((t) => ({
                label: t.name,
                value: t.id.toString(),
              }))}
              onChange={(value) => {
                if (value) {
                  setCollection({
                    ...collection,
                    email_template_id: Number(value),
                  });
                }
              }}
              placeholder="Select an email template"
            />
          </Grid.Col> */}
        </Grid>
      </EditView>

      <Default>
        <div>
          <Checkbox
            label="Collection Available Offline"
            checked={availableOffline || false}
            onChange={() => {
              setAvailableOffline(!availableOffline);
            }}
          />
          <Text
            size="xs"
            color={currentTheme.colors.textLight}
            style={{ marginTop: "14px", }}
          >
            * This affects this device only. You will need it checked on all
            devices you want to use this collection on.
          </Text>
        </div>
      </Default>

      <Default>
        <JSONForm
          title="Details"
          setFields={setDetails}
          fields={collection.details || {}}
        />
        <br />
        <br />
        <hr />
        <br />
        <br />
        <JSONForm
          title="Credentials"
          setFields={setCredentials}
          fields={collection?.credentials || {}}
        />
        <br />
        <br />
      </Default>
      <List
        title="Device Types"
        buttons={[
          {
            text: "Assign Device Type",
            handleClick: () => {
              setManagingDeviceTypes(true);
            },
            color: currentTheme.colors.primary,
            variant: "filled",
            disabled: !!collection?.completed_at,
          }
        ]}
        table_name="device_types"
        collapsable
      >
        <Dialog
          opened={managingDeviceTypes}
          withCloseButton
          onClose={() => setManagingDeviceTypes(false)}
          style={{ width: "100%", }}
          // css={css`
          //   .mantine-Dialog-root{
          //     outline: 1px solid red;
          //   }
          // `}
          size="xs"
        >
          <Grid>
            <Grid.Col sm={12} md={12}>
              <Select
                placeholder="Search for a device type"
                data={addableDeviceTypes().map((dvc) => ({
                  value: dvc.id.toString(),
                  label: dvc.name,
                }))}
                onChange={(value) => {
                  if (!value) {
                    return;
                  }
                  setNewDeviceType(parseInt(value, 10));
                }}
                label="Device Type"
                dropdownPosition="top"
              />
            </Grid.Col>
            <Grid.Col sm={12}>
              <Flex justify="flex-end">
                <Button
                  onClick={assignDeviceTypesToCollection}
                  variant="filled"
                  color={currentTheme.colors.primary}
                >
                  Assign
                </Button>
              </Flex>
            </Grid.Col>
          </Grid>
        </Dialog>
        <Grid>
          {collection?.device_types?.length ? (
            collection?.device_types?.map((device_type) => (
              <Grid.Col span={12} key={device_type.id}>
                <AssignmentCard
                  record={{
                    title: device_type.name,
                    subtitle: device_type.updated_at,
                    ...device_type,
                    link: `/organizations/${organization_id}/device-types/${device_type.id}`,
                  }}
                  removeRecord={unassignDeviceTypesFromCollection}
                  editable
                  disabled={!!collection?.completed_at}
                />
              </Grid.Col>
            ))
          ) : (
            <Grid.Col span={12}>
              <Text
                size="md"
                weight="300"
                color={currentTheme.colors.textLight}
                align="center"
              >
                This collection has no assigned device types.
                {collection?.completed_at && (
                  <span> Open collection to add more.</span>
                )}
              </Text>
            </Grid.Col>
          )}
        </Grid>
      </List>
      <Default>
        <hr />
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
          }}
        >
          <Button
            color={currentTheme.colors.primary}
            onClick={handleSubmit}
            disabled={!validated}
          >
            Submit
          </Button>
        </div>
      </Default>
    </>
  );
}

export default Edit;
