import type { SearchTable, } from "contexts/SearchContext";

export const parseQueryBreakdown = (
  query: string
): SearchTable["queryBreakdown"] => {
  let workingQuery = query;
  const queryBreakdown: SearchTable["queryBreakdown"] = {
    omit: [],
    similar: [],
    may_include: [],
    must_include: [],
    fulltext: workingQuery,
  };

  // !<query> means omit, so append to omit list, or !"<query> <continued>"
  const omitMatches = query.match(/!(\(.*\b\))/g);
  if (omitMatches) {
    const omits = omitMatches
      .map((match) => match.replace("!", "").replace("(", "").replace(")", "").trim())
      .filter((omit) => !!omit);
    omits.forEach((omit) => {
      queryBreakdown.omit.push(omit);
    });
    workingQuery = workingQuery.replace(/!(\(.*\b\))/g, "").trim();
  }

  // ~<query> means similar, so append to similar list
  const similarMatches = workingQuery.match(/~(\(.*\b\))/g);
  if (similarMatches) {
    const similars = similarMatches.map((match) => match.replace("~", "").replace("(", "").replace(")", "").trim());
    similars.forEach((similar) => {
      queryBreakdown.similar.push(similar);
    });
    workingQuery = workingQuery.replace(/~(\(.*\b\))/g, "").trim();
  }

  // ?<query> means may include, so append to may_include list
  const mayIncludeMatches = workingQuery.match(/\?(\(.*\b\))/g);
  if (mayIncludeMatches) {
    const mayIncludes = mayIncludeMatches.map((match) => match.replace("?", "").replace("(", "").replace(")", "").trim());
    mayIncludes.forEach((mayInclude) => {
      queryBreakdown.may_include.push(mayInclude);
    });
    workingQuery = workingQuery.replace(/\?(\(.*\b\))/g, "").trim();
  }

  const groupMatches = detectGroups(workingQuery);
  if (groupMatches) {
    groupMatches.and.forEach((group) => {
      queryBreakdown.must_include.push(...group.filter((g) => !!g));
    });
    groupMatches.or.forEach((group) => {
      queryBreakdown.may_include.push(...group);
    });
    workingQuery = removeGroups(workingQuery);
  }

  // <query>, <query>, <query> means must include, so append to must_include list
  const mustIncludeArray = workingQuery.split(",").map((q) => q.trim());
  if (mustIncludeArray.length) {
    queryBreakdown.must_include.push(...mustIncludeArray.filter((q) => !!q));
  }

  queryBreakdown.fulltext = workingQuery;

  return queryBreakdown;
};

export const detectGroups = (query: string) => {
  // (<query> and <query> and <query>) is an example of a group
  const groups = query.match(/\((.*?)\)/g);

  const toReturn: {
    or: string[][];
    and: string[][];
  } = {
    or: [],
    and: [],
  };

  if (groups) {
    groups.forEach((group) => {
      const type = group.includes(" or ") ? "or" : "and";
      const groupQuery = group.replace("(", "").replace(")", "").trim();
      // eslint-disable-next-line default-case
      switch (type) {
        case "or":
          groupQuery.split(" or ").forEach((q) => {
            toReturn[type].push([q]);
          });
          break;
        case "and":
          groupQuery.split(" and ").forEach((q) => {
            toReturn[type].push([q]);
          });
          break;
      }
    });
  }

  return toReturn;
};

export const removeGroups = (query: string) => {
  const groups = query.match(/\((.*?)\)/g);
  let newQuery = query;
  if (groups) {
    groups.forEach((group) => {
      newQuery = newQuery.replace(group, "").trim();
    });
  }
  return newQuery;
};

interface SearchItem {
  title: string;
  subtitle: string;
  fields: {
    [key: string]: any;
  };
}

export const itemSearchMatch = (item: SearchItem, table: SearchTable) => {
  const {
    queryBreakdown,
    sort: { field: selected_field, },
  } = table;

  const match: {
    matches: boolean;
    score: number;
    must_include: {
      // * All of these must be present in the item
      satisfied: boolean;
      amount_satisfied: number;
      total_to_satisfy: number;
    };
    may_include: {
      // * At least one of these things must be present in the item
      satisfied: boolean;
      amount_satisfied: number;
      total_to_satisfy: number;
    };
    omissions: {
      // * None of these things can be present in the item
      satisfied: boolean;
      amount_satisfied: number;
      total_to_satisfy: number;
    };
    similar: {
      // * At least one of these things must be present in the item
      satisfied: boolean;
      amount_satisfied: number;
      total_to_satisfy: number;
      satisfied_by: string[];
    };
  } = {
    matches: false,
    score: 0,
    must_include: {
      satisfied: false,
      amount_satisfied: 0,
      total_to_satisfy: queryBreakdown.must_include.length,
    },
    may_include: {
      satisfied: false,
      amount_satisfied: 0,
      total_to_satisfy: queryBreakdown.may_include.length,
    },
    omissions: {
      satisfied: false,
      amount_satisfied: 0,
      total_to_satisfy: queryBreakdown.omit.length,
    },
    similar: {
      satisfied: false,
      amount_satisfied: 0,
      total_to_satisfy: queryBreakdown.similar.length,
      satisfied_by: [],
    },
  };

  // * First check omissions
  queryBreakdown.omit.forEach((omit) => {
    if (!itemContainsValue(item, omit)) {
      match.omissions.amount_satisfied += 1;
      return;
    }
    match.score -= 100;
  });
  if (match.omissions.amount_satisfied === match.omissions.total_to_satisfy) {
    match.omissions.satisfied = true;
  }

  // * Then check must_include
  queryBreakdown.must_include.forEach((mustInclude) => {
    if (
      itemContainsValueAccountingForSelectedField(
        item,
        mustInclude,
        selected_field
      )
    ) {
      match.must_include.amount_satisfied += 1;
      match.score += 100;
      return;
    }
    match.score -= 100;
  });
  if (
    match.must_include.amount_satisfied === match.must_include.total_to_satisfy
  ) {
    match.must_include.satisfied = true;
  }

  // * Check may_include
  queryBreakdown.may_include.forEach((mayInclude) => {
    if (itemContainsValue(item, mayInclude)) {
      match.may_include.amount_satisfied += 1;
      match.score += 50;
    }
  });
  if (match.may_include.amount_satisfied > 0) {
    match.may_include.satisfied = true;
  } else if (match.may_include.total_to_satisfy === 0) {
    match.may_include.satisfied = true;
  }

  // * Check similar
  queryBreakdown.similar.forEach((similar) => {
    const similarity = getItemSimilarity(item, similar, selected_field);
    if (similarity > 75) {
      match.similar.amount_satisfied += 1;
      match.similar.satisfied_by.push(similar);
      match.score += 50;
    }
  });
  if (match.similar.amount_satisfied > 0) {
    match.similar.satisfied = true;
  }

  if (match.score > 0) {
    match.matches = true;
  }

  return match;
};

const itemContainsValue = (item: SearchItem, value: string) => {
  const values = Object.values(item.fields);
  const itemString = JSON.stringify(values).toLowerCase();
  return (
    itemString.includes(value) ||
    item.title.toLowerCase().includes(value) ||
    item.subtitle.toLowerCase().includes(value)
  );
};

const itemContainsValueAccountingForSelectedField = (
  item: SearchItem,
  value: string,
  selected_field: string | undefined
) => {
  if (selected_field) {
    const fieldValue = String(item.fields[selected_field]);
    return fieldValue.toLowerCase().includes(value.toLowerCase());
  }
  return itemContainsValue(item, value.toLowerCase());
};

function levenshteinDistance(str1: string = "", str2: string = ""): number {
  const track: number[][] = Array(str2.length + 1)
    .fill(null)
    .map(() => Array(str1.length + 1).fill(null));
  for (let i = 0; i <= str1.length; i += 1) {
    track[0][i] = i;
  }
  for (let j = 0; j <= str2.length; j += 1) {
    track[j][0] = j;
  }
  for (let j = 1; j <= str2.length; j += 1) {
    for (let i = 1; i <= str1.length; i += 1) {
      const indicator = str1[i - 1] === str2[j - 1] ? 0 : 1;
      track[j][i] = Math.min(
        track[j][i - 1] + 1, // deletion
        track[j - 1][i] + 1, // insertion
        track[j - 1][i - 1] + indicator // substitution
      );
    }
  }
  return track[str2.length][str1.length];
}

const getStringSimilarityPercentage = (string1: string, string2: string) => {
  const distance = levenshteinDistance(string1, string2);
  const maxDistance = Math.max(string1.length, string2.length);
  return Math.floor((1 - distance / maxDistance) * 100);
};

export const getItemSimilarity = (
  item: SearchItem,
  query: string,
  selected_field: string
) => {
  if (selected_field) {
    const itemField =
      item.fields[selected_field as keyof (typeof item)["fields"]];
    const similarity = getStringSimilarityPercentage(
      String(itemField).toLocaleLowerCase(),
      query.toLocaleLowerCase()
    );
    return similarity;
  }
  const titleSimilarity = getStringSimilarityPercentage(item.title, query);
  const subtitleSimilarity = getStringSimilarityPercentage(
    item.subtitle,
    query
  );
  const totalSimilarity = titleSimilarity + subtitleSimilarity;
  return totalSimilarity;
};
