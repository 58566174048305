import React from "react";
/** @jsxImportSource @emotion/react */
import { css, keyframes, } from "@emotion/react";
import { Loader, } from "@mantine/core";
import { useSettings, } from "hooks";
import { resolveTheme, } from "themes/main";

interface LoadingProps {
  text?: string;
}

ContainedLoading.defaultProps = {
  text: "Loading...",
};

function ContainedLoading({ text, }: LoadingProps) {
  const { theme: { current, }, } = useSettings();
  const currentTheme = resolveTheme(current);

  const loaderIn = keyframes`
    0% {
      opacity: 0;
      transform: translateY(10vh);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  `;

  const styles = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    min-height: 20vh;
    color: ${currentTheme.colors.textLight};
    z-index: 50;
    animation: ${loaderIn} 0.5s ease-in-out;
  `;
  return (
    <div css={styles}>
      <Loader
        color={currentTheme.colors.textLight}
        size="lg"
      />
      {text}
    </div>
  );
}

export default ContainedLoading;
