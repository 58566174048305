import {
  EmailTemplate as EmailTemplateDefinition,
} from "interfaces/main";
import React, { useEffect, useState, } from "react";
import { useParams, useNavigate, } from "react-router-dom";
import { Details as DetailedView, } from "components/Views";
import { axios, } from "libs";
import { useAlert, useSettings, } from "hooks";
import { Loading, } from "components";
import {
  Grid,
} from "@mantine/core";
import TextDisplay from "components/Display/TextDisplay";
import { parseJsonFields, } from "utils/fetching";
import { resolveTheme, } from "themes/main";
import { EmailFragmentList, } from "pages";

function EmailTemplate() {
  const { organization_id, email_template_id, } = useParams();
  const [template, setTemplate] = useState<EmailTemplateDefinition>();
  const [loading, setLoading] = useState(true);
  const { setAlert, setConfirmation, } = useAlert();
  const navigate = useNavigate();

  const getTemplate = () => axios
    .get(`/organizations/${organization_id}/email-templates/${email_template_id}`)
    .then((res) => {
      const parsed = parseJsonFields(res.data.data);
      setTemplate(parsed);
    })
    .catch((err) => {
      console.error(err);
      setAlert({ message: err.message, type: "danger", });
    })
    .finally(() => {
      setLoading(false);
    });

  useEffect(() => {
    setLoading(true);
    getTemplate();
  }, [
    organization_id
  ]);

  const handleDelete = () => {
    setConfirmation("Are you sure you want to delete this template?", () => {
      setLoading(true);
      axios
        .delete(`/organizations/${organization_id}/emails-templates/${email_template_id}`)
        .then(() => {
          setAlert({
            message: "Template deleted successfully",
            type: "success",
          });
          navigate(`/organizations/${organization_id}/email-templates`);
        })
        .catch((err) => {
          console.error(err);
          setAlert({
            message: "There was a problem deleting the template",
            type: "danger",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);
  const { colors, } = currentTheme;

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <DetailedView
        title={template?.name || "Template"}
        buttons={[
          // {
          //   text: "Tasks",
          //   handleClick: () => {
          //     navigate("tasks");
          //   },
          //   variant: "subtle",
          //   color: "digitalPurple"
          // },
          {
            text: "Edit",
            handleClick: () => {
              navigate("edit");
            },
            variant: "default",
            color: colors.primary,
          },
          {
            text: "Delete",
            handleClick: handleDelete,
            color: colors.danger,
            variant: "filled",
          }
        ]}
      >
        <Grid>
          <Grid.Col sm={12} md={6}>
            <TextDisplay label="Name" data={template?.name} />
          </Grid.Col>
          <Grid.Col sm={12} md={6}>
            <TextDisplay label="Global" data={template?.global} />
          </Grid.Col>
        </Grid>
      </DetailedView>
      <EmailFragmentList />
    </div>
  );
}

export default EmailTemplate;
