import React, { MouseEventHandler, } from "react";
/** @jsxImportSource @emotion/react */
import { css, } from "@emotion/react";
import {
  Button, Title, Grid,
} from "@mantine/core";
import Loading from "components/Loading";
import Search from "components/Search/Search";
import SortOn from "components/Search/SortOn";
import SortOrder from "components/Search/SortDirection";
import { useSettings, } from "hooks";
import { resolveTheme, } from "themes/main";

export interface ListViewProps {
  children: React.ReactNode;
  buttons?: {
    text: string;
    handleClick: MouseEventHandler<HTMLButtonElement>;
    color: string;
    variant?:
      | "outline"
      | "white"
      | "light"
      | "default"
      | "filled"
      | "subtle"
      | "gradient";
    disabled?: boolean;
    invisible?: boolean;
    icon?: string;
  }[];
  title: string;
  loading?: boolean;
  search?: boolean;
  collapsable?: boolean;
  fields?: {
    name: string;
    priority: number;
  }[];
  table_name: string;
  only_search?: boolean;
  with_separator?: boolean;
  collapsed_by_default?: boolean;
}

ListView.defaultProps = {
  loading: false,
  buttons: [],
  search: false,
  collapsable: false,
  fields: [],
  only_search: false,
  with_separator: true,
  collapsed_by_default: false,
};

function ListView({
  children,
  buttons,
  title,
  loading,
  search,
  collapsable,
  fields,
  table_name,
  only_search,
  with_separator,
  collapsed_by_default,
}: ListViewProps) {
  const [collapsed, setCollapsed] = React.useState(collapsed_by_default);

  const collapse = () => {
    setCollapsed(!collapsed);
  };

  const {
    theme: { current: theme, },
  } = useSettings();
  const currentTheme = resolveTheme(theme);

  if (loading) {
    return <Loading />;
  }

  return (
    <div
      css={css`
        padding: 36px 24px;
        border-top: 1px solid ${with_separator ? currentTheme.colors.backgroundContrast : "transparent"};

        @media (min-width: 768px) {
          padding: 36px 48px;
        }

        @media (min-width: 1024px) {
          padding: 36px 64px;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;

          @media (min-width: 768px) {
            justify-content: space-between;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            gap: 0 14px;
          `}
        >
          {collapsable && (
            <button
              css={css`
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                border: 1px solid ${currentTheme.colors.backgroundContrast};
                height: 36px;
                width: 36px;
                background-color: ${currentTheme.colors.background};
                cursor: pointer;
                border-radius: 50%;
                box-shadow: inset 0.2px 0.2px 8px rgba(0, 0, 0, 0.1);
                transition: all 0.2s ease-in-out;

                &:hover {
                  transform: scale(0.95);
                  box-shadow: inset 0.2px 0.2px 8px rgba(0, 0, 0, 0.15);
                }

                &:active {
                  transform: scale(0.94);
                }
              `}
              onClick={collapse}
              tabIndex={0}
              type="button"
              style={{
                backgroundColor: currentTheme.colors.background,
              }}
            >
              <i
                className="material-symbols-outlined"
                style={{
                  color: currentTheme.colors.text,
                }}
              >
                {collapsed ? "chevron_right" : "expand_more"}
              </i>
            </button>
          )}

          <Title
            order={1}
            weight={300}
            style={{
              fontFamily: "\"Roboto\", sans-serif",
              fontSize: "32px",
              fontWeight: 300,
              color: currentTheme.colors.text,
            }}
          >
            {title}
          </Title>
        </div>
        {!!buttons?.length && (
          <div
            css={css`
              display: flex;
              justify-content: space-around;
              width: 100%;
              margin-top: 24px;

              & > * {
                margin-bottom: 8px;
              }

              @media (min-width: 768px) {
                justify-content: flex-end;
                width: initial;
                margin-top: 0;
              }
            `}
          >
            {buttons?.map(
              (button, idx) => !button.invisible && (
              <Button
                    // eslint-disable-next-line react/no-array-index-key
                key={idx + button.text}
                onClick={button.handleClick}
                color={button.color}
                variant={button.variant}
                css={css`
                      @media (min-width: 768px) {
                        margin-left: 16px;
                      }
                    `}
                disabled={button.disabled}
              >
                {button.icon && (
                <i className="material-symbols-outlined">{button.icon}</i>
                )}
                {button.text || ""}
              </Button>
              )
            )}
          </div>
        )}
      </div>
      {!collapsed && (
        <div>
          <br />
          {search && (
            <Grid
              style={{
                margin: "14px 0",
                padding: "0",
              }}
              gutter={8}
            >
              <Grid.Col sm={12} md={only_search ? 12 : 6}>
                <Search
                  debounce
                  timeout={300}
                  table_name={table_name}
                  global={false}
                />
              </Grid.Col>
              {!only_search && (
                <>
                  <Grid.Col sm={6} md={4}>
                    <SortOn fields={fields || []} table_name={table_name} />
                  </Grid.Col>
                  <Grid.Col sm={6} md={2}>
                    <SortOrder table_name={table_name} />
                  </Grid.Col>
                </>
              )}
              <hr />
            </Grid>
          )}
          <div>{children}</div>
        </div>
      )}
    </div>
  );
}

export default ListView;
