import React, { useEffect, useState, } from "react";
/** @jsxImportSource @emotion/react */
import { css, } from "@emotion/react";
import { useSettings, } from "hooks";
import { resolveTheme, } from "themes/main";
import {
  Grid, Text, Title,
} from "@mantine/core";
import Tabular from "components/Navigation/Tabular";
import QuickActions from "components/ActionButtons/QuickActions";

interface TabsViewProps<Tabs extends string> {
  children: React.ReactNode;
  tabs: {
    text: string;
    id: Tabs;
    visible?: boolean;
  }[];
  defaultTab: Tabs;
  title: string;
  subtitle: string;
  // eslint-disable-next-line no-unused-vars
  setUpperTab: (tab: Tabs) => void;
  toptext?: string | React.ReactNode;
  quickActions?: {
    name: string;
    onClick: () => void;
  }[];
  currentTab?: Tabs;
}

TabsView.defaultProps = {
  toptext: "",
  quickActions: [],
  currentTab: undefined,
};

function TabsView<Tabs extends string>({
  children,
  tabs,
  defaultTab,
  toptext,
  title,
  subtitle,
  quickActions,
  setUpperTab,
  currentTab,
}: TabsViewProps<Tabs>) {
  const {
    theme: { current, },
  } = useSettings();
  const { colors, } = resolveTheme(current);

  const [tab, setTab] = useState<Tabs>(defaultTab);

  useEffect(() => {
    if (currentTab) {
      setTab(currentTab);
    }
  }, [currentTab]);

  useEffect(() => {
    setUpperTab(tab);
  }, [tab]);

  const filteredTabs = tabs.filter((t) => t.visible !== false);

  return (
    <div
      css={css`
        .tophalf {
          background-color: ${colors.background};
          padding-block-start: 24px;
          padding-inline: 24px;

          @media (min-width: 768px) {
            padding-block-start: 24px;
            padding-inline: 56px;
          }

          .toptext {
            ${toptext ? "" : "display: none;"}
            margin-bottom: 18px;
          }

          .title {
            margin-bottom: 8px;
          }

          .subtitle {
            margin-bottom: 36px;
          }

          .midsection {
            margin-bottom: 36px;
          }
        }
      `}
    >
      <Grid>
        <Grid.Col span={12}>
          <div className="tophalf">
            <Text
              className="toptext"
              weight={300}
              color={colors.textLight}
              size="xs"
            >
              {toptext}
            </Text>
            <Title weight={300} color={colors.text} className="title">
              {title}
            </Title>
            <Text color={colors.textLight} weight={300} className="user-org">
              {subtitle}
            </Text>
            <div className="midsection">
              <QuickActions actions={quickActions || []} />
            </div>
            <Tabular
              tabs={filteredTabs.map((t) => ({
                ...t,
                onClick: () => setTab(t.id),
              }))}
              currentTab={tab}
            />
          </div>
        </Grid.Col>
        <Grid.Col span={12}>
          <div className="bottomhalf">{children}</div>
        </Grid.Col>
      </Grid>
    </div>
  );
}

export default TabsView;
