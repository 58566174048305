import React, { useCallback, useEffect, useState, } from "react";
import { MatterList, } from "pages";
import { useSearchParams, } from "react-router-dom";
import TabsView from "components/Views/TabsView";
import { useUser, } from "hooks";
import { axios, } from "libs";
import AllMatterList from "./AllMatters";
import ArchivedMatterList from "./Archived";
import AllArchivedMatters from "./AllArchived";

type Tabs = "matters" | "archived" | "all-matters" | "all-archived-matters";

function index() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { hasRole, } = useUser();
  const defaultTab = hasRole("superuser") ? "all-matters" : "matters";
  const queryTab = (searchParams.get("tab") || defaultTab) as Tabs;
  const [tab, setTab] = useState<Tabs>(queryTab);

  useEffect(() => {
    setSearchParams({
      tab,
    });
  }, [tab]);

  const [numMatters, setNumMatters] = useState<number | undefined>();
  const [numArchivedMatters, setNumArchivedMatters] = useState<
    number | undefined
  >();

  useEffect(() => {
    axios
      .get("/matters")
      .then((res) => {
        setNumMatters(res.data.data.length);
      })
      .catch((err) => {
        console.error(err);
      });

    axios
      .get("/matters/archives")
      .then((res) => {
        setNumArchivedMatters(res.data.data.length);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const getSubtitle = useCallback(() => {
    if (numMatters === undefined || numArchivedMatters === undefined) {
      return "Manage your matters";
    }

    let subtitle = "";

    subtitle += `${numMatters} active matter${numMatters === 1 ? "" : "s"}`;
    subtitle += `, ${numArchivedMatters} archived matter${
      numArchivedMatters === 1 ? "" : "s"
    }`;

    return subtitle;
  }, [numMatters, numArchivedMatters]);

  return (
    <TabsView
      title="Matters"
      subtitle={getSubtitle()}
      tabs={[
        {
          text: "Active [All]",
          id: "all-matters",
          visible: hasRole("superuser"),
        },
        {
          text: "Archived [All]",
          id: "all-archived-matters",
          visible: hasRole("superuser"),
        },
        {
          text: hasRole("superuser") ? "Active [Org]" : "Matters",
          id: "matters",
        },
        {
          text: hasRole("superuser") ? "Archived [Org]" : "Archived",
          id: "archived",
        }
      ]}
      setUpperTab={setTab}
      defaultTab={tab}
      currentTab={tab}
    >
      <DisplayCurrentTab currentTab={tab} />
    </TabsView>
  );
}

export default index;

interface DisplayCurrentTabProps {
  currentTab: Tabs;
}

export function DisplayCurrentTab({ currentTab, }: DisplayCurrentTabProps) {
  switch (currentTab) {
    case "all-matters":
      return <AllMatterList withTitle={false} withPaddingY={false} />;
    case "all-archived-matters":
      return <AllArchivedMatters withTitle={false} withPaddingY={false} />;
    case "matters":
      return (
        <MatterList
          collapseable={false}
          with_separator={false}
          with_title={false}
          with_padding_y={false}
        />
      );
    case "archived":
      return <ArchivedMatterList with_title={false} with_padding_y={false} />;
    default:
      return null;
  }
}
