import { ListViewModes, ThemeTypes, } from "interfaces/main";
import {
  createContext, ReactNode, useEffect, useMemo, useState,
} from "react";

interface Settings {
  theme: {
    current: ThemeTypes;
    // eslint-disable-next-line no-unused-vars
    set: (theme: ThemeTypes) => void;
    // eslint-disable-next-line no-unused-vars
    toggle: () => void;
  };
  network: {
    offline_mode: {
      current: boolean;
      // eslint-disable-next-line no-unused-vars
      set: (value: boolean) => void;
    };
  };
  view: {
    list_views: {
      default_view_mode: {
        current: ListViewModes;
        // eslint-disable-next-line no-unused-vars
        set: (value: ListViewModes) => void;
        // eslint-disable-next-line no-unused-vars
        toggle: () => void;
      };
    };
    ui: {
      show_navigation: {
        current: boolean;
        // eslint-disable-next-line no-unused-vars
        set: (value: boolean) => void;
      }
    }
  };
  search: {
    preserve_search: {
      current: boolean;
      // eslint-disable-next-line no-unused-vars
      set: (value: boolean) => void;
    };
  }
}

const intitialSettings: Settings = {
  theme: {
    current: "light",
    set: () => { },
    toggle: () => { },
  },
  network: {
    offline_mode: {
      current: sessionStorage.getItem("offlineMode") === "true",
      // eslint-disable-next-line no-unused-vars
      set: (value: boolean) => { },
    },
  },
  view: {
    list_views: {
      default_view_mode: {
        current: "list",
        // eslint-disable-next-line no-unused-vars
        set: (value: ListViewModes) => { },
        // eslint-disable-next-line no-unused-vars
        toggle: () => { },
      },
    },
    ui: {
      show_navigation: {
        current: true,
        // eslint-disable-next-line no-unused-vars
        set: (value: boolean) => { },
      },
    },
  },
  search: {
    preserve_search: {
      current: false,
      // eslint-disable-next-line no-unused-vars
      set: (value: boolean) => { },
    },
  },
};

const SettingsContext = createContext<Settings>(intitialSettings);

function SettingsProvider(props: { children: ReactNode }): JSX.Element {
  const [offlineMode, setOfflineMode] = useState<boolean>(false);
  const [theme, setTheme] = useState<ThemeTypes>(
    (localStorage.getItem("theme") as ThemeTypes) || "light"
  );
  const [defaultTableView, setDefaultTableView] = useState<ListViewModes>(
    (localStorage.getItem("defaultTableView") as ListViewModes) || "list"
  );
  const [preserveSearch, setPreserveSearch] = useState<boolean>(
    localStorage.getItem("preserveSearch") === "true" || false
  );
  const [showNavigation, setShowNavigation] = useState<boolean>(true);

  useEffect(() => {
    sessionStorage.setItem("offlineMode", offlineMode.toString());
  }, [offlineMode]);

  useEffect(() => {
    localStorage.setItem("defaultTableView", defaultTableView);
  }, [defaultTableView]);

  // same thing for theme
  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);

  useEffect(() => {
    localStorage.setItem("preserveSearch", preserveSearch.toString());
  }, [preserveSearch]);

  const toggleTheme = () => {
    setTheme((prevTheme) => {
      if (prevTheme === "light") {
        return "dark";
      }

      return "light";
    });
  };

  const toggleTableViewMode = () => {
    setDefaultTableView((prevMode) => {
      if (prevMode === "list") {
        return "table";
      }

      return "list";
    });
  };

  const value: Settings = useMemo<Settings>(
    () => ({
      theme: {
        current: theme,
        set: setTheme,
        toggle: toggleTheme,
      },
      network: {
        offline_mode: {
          current: offlineMode,
          set: setOfflineMode,
        },
      },
      view: {
        list_views: {
          default_view_mode: {
            current: defaultTableView,
            set: setDefaultTableView,
            toggle: toggleTableViewMode,
          },
        },
        ui: {
          show_navigation: {
            current: showNavigation,
            set: setShowNavigation,
          },
        },
      },
      search: {
        preserve_search: {
          current: preserveSearch,
          set: setPreserveSearch,
        },
      },
    }),
    [offlineMode, theme, defaultTableView, preserveSearch, showNavigation]
  );

  return (
    <SettingsContext.Provider value={value}>
      {props.children}
    </SettingsContext.Provider>
  );
}

export { SettingsContext, SettingsProvider, };
