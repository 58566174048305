import React, { useEffect, useState, } from "react";
import { Outlet, } from "react-router-dom";
import { useUser, useSettings, } from "hooks";
import { UserContextProps, } from "contexts/UserContext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Loading, } from "components";
/** @jsxImportSource @emotion/react */
import { css, } from "@emotion/react";
import ConfirmModal from "components/Modals/Confirm";
import { resolveTheme, } from "themes/main";
import Spotlight from "components/Spotlight/Spotlight";
import Breadcrumbs from "components/Navigation/Breadcrumbs";
import GlobalSearch from "components/Search/GlobalSearch";
import Body from "./Body";
import Sidebar from "./Sidebar";
import Login from "./Login";

function Main(): JSX.Element {
  const [mainLoading, setMainLoading] = useState(true);
  const userContext: UserContextProps = useUser();

  useEffect(() => {
    setMainLoading(userContext.isLoading);
  }, [userContext.isLoading]);

  const {
    theme: { current: theme, },
    view: { ui: { show_navigation, }, },
  } = useSettings();

  if (!userContext.isLoggedIn && !mainLoading) {
    return (
      <Row>
        <Col>
          <Login />
        </Col>
      </Row>
    );
  }

  if (mainLoading) {
    return <Loading />;
  }

  return (
    <Spotlight>
      <GlobalSearch>
        <main
          css={css`
            background-color: ${resolveTheme(theme).colors.backgroundAlt};
            min-height: 100vh;
            padding-block-end: 56px;

            @media (min-width: 768px) {
              ${show_navigation.current && "padding-inline-start: 200px"}
            }

            @media (min-width: 1024px) {
              ${show_navigation.current && "padding-inline-start: 250px"}
            }
          `}
        >
          {show_navigation.current && (
            <>
              <Sidebar />
              <Breadcrumbs />
            </>
          )}
          <ConfirmModal />
          <Body>
            <Outlet />
          </Body>
          {/* <Footer /> */}
        </main>
      </GlobalSearch>
    </Spotlight>
  );
}

export default Main;
