import React from "react";
import { Default, } from "components/Views";
import {
  Checkbox,
  Grid,
  Group,
  SegmentedControl,
  Text,
  Title,
} from "@mantine/core";
import { useSettings, } from "hooks";
import { ListViewModes, ThemeTypes, } from "interfaces/main";
import { resolveTheme, } from "themes/main";

function Settings() {
  const {
    network: {
      offline_mode: { current: offlineMode, set: setOfflineMode, },
    },
    view: {
      list_views: {
        default_view_mode: {
          current: defaultTableView,
          set: setDefaultTableView,
        },
      },
    },
    theme: { current: theme, set: setTheme, },
    search: {
      preserve_search: {
        current: preserveSearch,
        set: setPreserveSearch,
      },
    },
  } = useSettings();

  const isMobile = window.innerWidth <= 768;

  return (
    <div>
      <Default title="Settings" with_separator={false}>
        <Grid>
          <Grid.Col>
            <Title
              order={3}
              weight={400}
              color={resolveTheme(theme).colors.text}
            >
              Network
            </Title>
          </Grid.Col>
          <Grid.Col sm={12}>
            <Group>
              <Title
                order={4}
                weight={300}
                color={resolveTheme(theme).colors.text}
              >
                Offline Mode
              </Title>
              <Text size="xs" color={resolveTheme(theme).colors.textLight}>
                * The app will present itself as if your phone was offline
              </Text>
            </Group>
          </Grid.Col>
          <Grid.Col sm={12}>
            <Checkbox
              label="Enable offline mode"
              checked={offlineMode}
              onChange={(event) => setOfflineMode(event.currentTarget.checked)}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          {!isMobile && (
            <>
              <hr style={{ width: "100%", }} />
              <br />
              <Grid.Col>
                <Title
                  order={3}
                  weight={400}
                  color={resolveTheme(theme).colors.text}
                >
                  View
                </Title>
              </Grid.Col>
              <Grid.Col sm={12}>
                <Group>
                  <Title
                    order={4}
                    weight={300}
                    color={resolveTheme(theme).colors.text}
                  >
                    Default View Mode
                  </Title>
                  <Text size="xs" color={resolveTheme(theme).colors.textLight}>
                    * The default view mode for list views
                  </Text>
                </Group>
              </Grid.Col>
              <Grid.Col sm={12}>
                <SegmentedControl
                  data={[
                    { label: "Table", value: "table", },
                    { label: "List", value: "list", }
                  ]}
                  value={defaultTableView}
                  onChange={(value) => setDefaultTableView(value as ListViewModes)}
                />
              </Grid.Col>
            </>
          )}
          <Grid.Col sm={12} style={{ marginTop: "24px", }}>
            <Group>
              <Title
                order={4}
                weight={300}
                color={resolveTheme(theme).colors.text}
              >
                Theme
              </Title>
              <Text size="xs" color={resolveTheme(theme).colors.textLight}>
                * The theme by which the app presents itself
              </Text>
            </Group>
          </Grid.Col>
          <Grid.Col sm={12}>
            <SegmentedControl
              data={[
                { label: "Light", value: "light", },
                { label: "Dark", value: "dark", }
              ]}
              onChange={(v) => {
                setTheme(v as ThemeTypes);
              }}
              value={theme}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <hr style={{ width: "100%", }} />
          <br />
          <Grid.Col>
            <Title
              order={3}
              weight={400}
              color={resolveTheme(theme).colors.text}
            >
              Search
            </Title>
          </Grid.Col>
          <Grid.Col sm={12}>
            <Group>
              <Title
                order={4}
                weight={300}
                color={resolveTheme(theme).colors.text}
              >
                Preserve Search
              </Title>
              <Text size="xs" color={resolveTheme(theme).colors.textLight}>
                * A
                table&lsquo;
                search query will be preserved through page refreshes
              </Text>
            </Group>
          </Grid.Col>
          <Grid.Col sm={12}>
            <Checkbox
              label="Preserve search query"
              checked={preserveSearch}
              onChange={(event) => setPreserveSearch(event.currentTarget.checked)}
            />
          </Grid.Col>
        </Grid>
      </Default>
    </div>
  );
}

export default Settings;
