import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";

import { UserProvider, } from "contexts/UserContext";
import { SettingsProvider, } from "contexts/SettingsContext";
import { SearchProvider, } from "contexts/SearchContext";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <UserProvider>
    <SettingsProvider>
      <SearchProvider>
        <App />
      </SearchProvider>
    </SettingsProvider>
  </UserProvider>,
  document.getElementById("root")
);

// * Service Worker Registration
serviceWorkerRegistration.register();

// * Report Web Vitals
reportWebVitals();
