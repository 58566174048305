/** @jsxImportSource @emotion/react */
import { css, keyframes, } from "@emotion/react";
import { useNavigate, } from "react-router-dom";
import { useAlert, useOfflineServices, useSettings, } from "hooks";
import {
  Text, RingProgress, useMantineTheme, Center,
} from "@mantine/core";
import { useState, } from "react";
import { copyToClipboard, } from "utils/methods";
import { resolveTheme, } from "themes/main";
import ActionButtons from "../ActionButtons/ActionButtons";

type InfoCardProps = {
  title: string;
  endpoint: string;
  subtitle?: string;
  completeness?: number | null;
  exclude?: ("view" | "delete" | "edit")[];
};

InfoCard.defaultProps = {
  subtitle: "",
  completeness: null,
  exclude: [],
};
// eslint-disable-next-line no-unused-vars
function InfoCard({
  title,
  subtitle,
  endpoint,
  completeness,
  exclude,
}: InfoCardProps) {
  const navigate = useNavigate();
  const { colors, } = useMantineTheme();

  const { isOnline, } = useOfflineServices();

  const [copied, setCopied] = useState(false);
  const { setAlert, } = useAlert();

  const { theme: { current: theme, }, } = useSettings();
  const currentTheme = resolveTheme(theme);

  const cardIn = keyframes`
    from {
      opacity: 0;
      transform: translateX(-5%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  `;

  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 8px 14px;
        box-shadow: 0.2px 0.2px 10px 0 rgba(0, 0, 0, 0.1);
        transition: 0.3s ease-in-out all;
        cursor: pointer;
        border-radius: 10px;
        animation: ${cardIn} 0.7s ease-in-out;
        box-sizing: border-box;

        &:hover {
          box-shadow: 0.2px 0.2px 15px 0 rgba(0, 0, 0, 0.1);
          transform: scale(0.99);
        }

        &:active {
          transform: scale(0.98);
        }

        @media (min-width: 768px) {
          padding: 14px 24px;
        }

        .info {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          .text {
            margin-left: 14px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          }
        }

        .title {
          // font-size: 18px;
          margin: 0;
        }

        .subtitle {
          // font-size: 14px;
          margin: 0;
        }

        .button {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      `}
      style={{
        backgroundColor: currentTheme.colors.background,
      }}
      onClick={() => {
        navigate(endpoint);
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          navigate(endpoint);
        }
      }}
      role="button"
      tabIndex={0}
    >
      <div className="info">
        {completeness !== null && completeness !== undefined && (
          <div className="ring">
            <RingProgress
              thickness={3}
              size={40}
              roundCaps
              sections={[
                {
                  value: completeness,
                  color: colors.digitalPurple[6],
                }
              ]}
              label={(
                <Center>
                  <Text size={10} color={currentTheme.colors.text}>
                    {completeness}
                    %
                  </Text>
                </Center>
              )}
            />
          </div>
        )}
        <div className="text">
          <Text
            size="md"
            color={currentTheme.colors.text}
          >
            {title}
          </Text>
          <div
            css={css`
              display: flex;
              align-items: center;
              margin-top: 4px;
            `}
          >
            <Text
              size="xs"
              color={currentTheme.colors.textLight}
              onClick={(e) => {
                e.stopPropagation();
                copyToClipboard(subtitle || "");
                setCopied(true);
                setAlert({
                  type: "success",
                  message: "Copied to clipboard",
                });
                setTimeout(() => {
                  setCopied(false);
                }, 2000);
              }}
              css={css`
                cursor: pointer;

                &:hover {
                  text-decoration: underline;
                }
              `}
              title={
                copied ?
                  "Copied to clipboard" :
                  "Click to copy to clipboard"
              }
            >
              {subtitle}
            </Text>
            {!copied ? (
              <i
                className="material-icons"
                css={css`
                  margin-left: 8px;
                  font-size: 14px;
                  color: ${currentTheme.colors.textLight};
                `}
                title="Click text to copy to clipboard"
                onClick={(e: any) => {
                  e.stopPropagation();
                  copyToClipboard(subtitle || "");
                  setCopied(true);
                  setAlert({
                    type: "success",
                    message: "Copied to clipboard",
                  });
                  setTimeout(() => {
                    setCopied(false);
                  }, 2000);
                }}
                onKeyDown={({ key, }) => {
                  if (key === "Enter") {
                    copyToClipboard(subtitle || "");
                    setCopied(true);
                    setAlert({
                      type: "success",
                      message: "Copied to clipboard",
                    });
                    setTimeout(() => {
                      setCopied(false);
                    }, 2000);
                  }
                }}
                role="button"
                tabIndex={0}
              >
                content_copy
              </i>
            ) : (
              <i
                className="material-icons"
                css={css`
                  margin-left: 8px;
                  font-size: 14px;
                  color: ${currentTheme.colors.success};
                `}
                title="Copied to clipboard"
              >
                check
              </i>
            )}
          </div>
        </div>
      </div>
      <div className="buttons">
        <ActionButtons
          endpoint={endpoint}
          view={!exclude?.includes("view")}
          delete={isOnline && !exclude?.includes("delete")}
          edit={!exclude?.includes("edit")}
        />
      </div>
    </div>
  );
}

export default InfoCard;
