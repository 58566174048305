/* eslint-disable no-unused-vars */
import {
  Collection, Device, DeviceType, Kit,
} from "interfaces/main";
import React, { useState, } from "react";
/** @jsxImportSource @emotion/react */
import { css, } from "@emotion/react";
import {
  getFormattedCustodianName,
  getMoreDetailedFormattedDeviceName,
} from "utils/formatting";
import { Center, Select, Text, } from "@mantine/core";
import { axios, } from "libs";
import { Link, useParams, } from "react-router-dom";
import { useAlert, useSettings, } from "hooks";
import { resolveTheme, } from "themes/main";
import { ArrowRight, } from "phosphor-react";

interface KitManagerProps {
  kit: Kit | null;
  devices: Device[];
  // eslint-disable-next-line no-unused-vars
  assignDevicesToKit: (deviceIds: number[]) => Promise<any>;
  // eslint-disable-next-line no-unused-vars
  unassignDevicesFromKit: (deviceIds: number[]) => Promise<any>;
  // eslint-disable-next-line no-unused-vars
  refreshKit: () => Promise<any>;
  disabled: boolean;
  organization_id: number;
}

function KitManager({
  kit,
  devices,
  assignDevicesToKit,
  unassignDevicesFromKit,
  refreshKit,
  disabled,
  organization_id,
}: KitManagerProps) {
  if (!kit) {
    return null;
  }
  const { setAlert, } = useAlert();

  const assignCollectionsToDevice = (
    device_id: number,
    collection_id: number
  ) => axios
    .post(`/organizations/${organization_id}/devices/${device_id}/assign`, {
      attach: [collection_id],
    })
    .then(() => {
      setAlert({
        message: "Device assigned to collection",
        type: "success",
      });
    })
    .catch(() => {
      setAlert({
        message: "There was an error assigning the device to the collection",
        type: "danger",
      });
    });

  const unassignCollectionsFromDevice = (
    device_id: number,
    collection_id: number
  ) => axios
    .post(`/organizations/${organization_id}/devices/${device_id}/assign`, {
      detach: [collection_id],
    })
    .then(() => {
      setAlert({
        message: "Device unassigned from collection",
        type: "success",
      });
    })
    .catch(() => {
      setAlert({
        message:
            "There was an error unassigning the device from the collection",
        type: "danger",
      });
    });

  // eslint-disable-next-line arrow-body-style
  const searchDevicePresentOnKitCollections = (dId: number) => {
    // eslint-disable-next-line max-len
    return kit.custodians.some((c) => c.collections.some((col) => col.devices.some((d) => d.id === dId)));
  };

  const moreThanOneInstanceOfDeviceOnKit = (dId: number) => {
    let count = 0;
    kit.custodians.forEach((c) => {
      c.collections.forEach((col) => {
        col.devices.forEach((d) => {
          if (d.id === dId) {
            count += 1;
          }
        });
      });
    });
    return count > 1;
  };

  const handleCollectionDeviceChange = async (
    collection_id: number,
    existing_device_id: number | undefined,
    new_device_id: number
  ) => {
    if (existing_device_id) {
      await unassignCollectionsFromDevice(existing_device_id, collection_id);
      if (!moreThanOneInstanceOfDeviceOnKit(existing_device_id)) {
        await unassignDevicesFromKit([existing_device_id]);
      }
    }
    await assignCollectionsToDevice(new_device_id, collection_id);
    await assignDevicesToKit([new_device_id]);
    await refreshKit();
  };

  const handleCollectionDeviceNullify = async (
    collection_id: number,
    existing_device_id: number | undefined
  ) => {
    if (existing_device_id) {
      await unassignCollectionsFromDevice(existing_device_id, collection_id);
      if (!moreThanOneInstanceOfDeviceOnKit(existing_device_id)) {
        await unassignDevicesFromKit([existing_device_id]);
      }
    }
    await refreshKit();
  };

  const {
    theme: { current: themeObj, },
  } = useSettings();
  const currentTheme = resolveTheme(themeObj);

  const filteredCustodians = disabled ?
    kit.custodians.filter((c) => {
      const hasDeviceTypes = c.collections.some(
        (col) => col.device_types.length > 0
      );
      return hasDeviceTypes;
    }) :
    kit.custodians;

  const showCollection = (collection: Collection) => {
    if (disabled) {
      if (collection.devices.length > 0) {
        return true;
      }
      return false;
    }
    return true;
  };

  const findCurrentDropdownValue = (
    collection: Collection,
    deviceType: DeviceType
  ) => {
    const deviceCurrentlyAssigned = collection.devices?.find((d) => {
      const isInKit = kit.devices.find((kd) => kd.id === d.id);
      const matchesDeviceType = d.device_type_id === deviceType.id;
      return isInKit && matchesDeviceType;
    });
    if (deviceCurrentlyAssigned) {
      return deviceCurrentlyAssigned;
    }
    return null;
  };

  return (
    <div>
      {filteredCustodians.length > 0 ? (
        filteredCustodians.map((custodian) => (
          <div
            key={custodian.id}
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 24px;
              border: 1px solid ${currentTheme.colors.backgroundContrast};
              width: 100%;
              box-sizing: border-box;
              background-color: ${currentTheme.colors.background};
              color: ${currentTheme.colors.text};
              box-shadow: 0.2px 0.2px 10px 0 rgba(0, 0, 0, 0.1);
              transition: 0.3s ease-in-out all;
              border-radius: 10px;
              margin-bottom: 24px;

              &:hover {
                box-shadow: 0.2px 0.2px 15px 0 rgba(0, 0, 0, 0.1);
              }

              @media (min-width: 768px) {
                align-items: flex-start;
              }
            `}
          >
            <p
              css={css`
                font-size: 24px;
                font-weight: 400;
                margin-bottom: 0;
              `}
            >
              {getFormattedCustodianName(custodian)}
            </p>
            <hr style={{ width: "100%", }} />
            <ul
              css={css`
                list-style: none;
                padding: 0;
                width: 100%;
              `}
            >
              {custodian.collections.length > 0 ? (
                custodian.collections.map(
                  (collection) => showCollection(collection) && (
                  <li
                    key={collection.id + collection.name}
                    css={css`
                          border: 1px solid
                            ${currentTheme.colors.backgroundContrast};
                          display: flex;
                          flex-direction: column;
                          justify-content: center;
                          align-items: center;
                          margin-bottom: 24px;
                          padding: 14px;

                          @media (min-width: 768px) {
                            flex-direction: row;
                            justify-content: space-between;
                          }
                        `}
                  >
                    <div
                      css={css`
                            width: 100%;
                          `}
                    >
                      <p
                        css={css`
                              font-size: 18px;
                              text-align: center;
                              font-weight: 300;

                              @media (min-width: 768px) {
                                text-align: left;
                              }
                            `}
                      >
                        Suggestions for
                        {" "}
                        <Link
                          to={`/organizations/${organization_id}/matters/${kit.matter_id}/custodians/${collection.custodian_id}/collections/${collection.id}`}
                          css={css`
                                color: ${currentTheme.colors.text};
                                text-decoration: none;
                                color: ${currentTheme.colors.primary};

                                &:hover {
                                  color: ${currentTheme.colors.primary};
                                }
                              `}
                        >
                          <strong
                            css={css`
                                  font-weight: 400;
                                  color: ${currentTheme.colors.text};
                                  text-decoration: none;
                                  margin-right: 5px;
                                  transition: 0.3s ease-in-out all;

                                  &:hover {
                                    text-decoration: underline;
                                    margin-right: 8px;
                                  }
                                `}
                          >
                            {collection.name}
                          </strong>
                          <ArrowRight size={16} weight="bold" />
                        </Link>
                      </p>
                      {collection.device_types.length > 0 ? (
                        <div>
                          <ul
                            css={css`
                                  list-style: none;
                                  padding: 0;
                                `}
                          >
                            {collection.device_types.map((dt) => (
                              <li
                                css={css`
                                      display: flex;
                                      flex-direction: column;
                                      justify-content: center;
                                      align-items: flex-start;
                                      padding-left: 14px;
                                      border-left: 1px solid #eaeaea;
                                      margin-bottom: 14px;

                                      @media (min-width: 768px) {
                                        align-items: center;
                                        flex-direction: row;
                                        justify-content: space-between;
                                      }
                                    `}
                                key={dt.id + dt.name}
                              >
                                <p
                                  css={css`
                                        font-size: 16px;
                                        font-weight: 300;
                                        padding: 0;
                                        margin: 0;
                                        margin-bottom: 4px;

                                        @media (min-width: 768px) {
                                          margin-bottom: 0;
                                          margin-right: 14px;
                                        }
                                      `}
                                >
                                  {dt.name}
                                </p>
                                <Select
                                  disabled={disabled}
                                  searchable
                                  data={[
                                    {
                                      label: "Select a device",
                                      value: "",
                                    },
                                    ...devices
                                      .filter(
                                        (d) => d.device_type_id === dt.id
                                      )
                                      .map((d) => ({
                                        label:
                                              getMoreDetailedFormattedDeviceName(
                                                d
                                              ),
                                        value: String(d.id),
                                      })),
                                    ...kit.devices
                                      .filter(
                                        (d) => d.device_type_id === dt.id
                                      )
                                      .map((d) => ({
                                        label:
                                              getMoreDetailedFormattedDeviceName(
                                                d
                                              ),
                                        value: String(d.id),
                                        // disabled: true,
                                      }))
                                  ].sort((a, b) => {
                                    if (a.label < b.label) {
                                      return -1;
                                    }
                                    if (a.label > b.label) {
                                      return 1;
                                    }
                                    return 0;
                                  })}
                                  value={
                                        findCurrentDropdownValue(
                                          collection,
                                          dt
                                        )?.id?.toString() || ""
                                      }
                                  placeholder="Select a device"
                                  onChange={async (value) => {
                                    if (!value) {
                                      // unnassign the current device
                                      const existingDevice =
                                            findCurrentDropdownValue(
                                              collection,
                                              dt
                                            );

                                      if (!existingDevice) {
                                        return;
                                      }
                                      await handleCollectionDeviceNullify(
                                        collection.id,
                                        existingDevice.id
                                      );

                                      return;
                                    }

                                    const device = [
                                      ...devices,
                                      ...kit.devices
                                    ].find((d) => d.id === Number(value));

                                    if (!device) {
                                      return;
                                    }

                                    const existingDevice =
                                          findCurrentDropdownValue(
                                            collection,
                                            dt
                                          );

                                    handleCollectionDeviceChange(
                                      collection.id,
                                      existingDevice?.id,
                                      device.id
                                    );
                                  }}
                                  rightSection={(
                                    <DeviceLink
                                      device={findCurrentDropdownValue(
                                        collection,
                                        dt
                                      )}
                                      organization_id={organization_id}
                                    />
                                      )}
                                />
                              </li>
                            ))}
                          </ul>
                        </div>
                      ) : (
                        <Center>
                          <Text
                            weight={300}
                            size="sm"
                            color={currentTheme.colors.textLight}
                          >
                            No suggested devices.
                          </Text>
                        </Center>
                      )}
                    </div>
                  </li>
                  )
                )
              ) : (
                <li>
                  <Text
                    size="md"
                    color={currentTheme.colors.textLight}
                    align="center"
                    weight="300"
                  >
                    No collections on this custodian,
                    {" "}
                    <Link
                      to={`/organizations/${organization_id}/matters/${kit.matter_id}/custodians/${custodian.id}/collections/create`}
                      style={{
                        color: currentTheme.colors.textLight,
                      }}
                    >
                      add one
                    </Link>
                    .
                  </Text>
                </li>
              )}
            </ul>
          </div>
        ))
      ) : (
        <Text
          size="md"
          color={currentTheme.colors.textLight}
          align="center"
          weight="300"
        >
          No custodians assigned to this kit.
        </Text>
      )}
    </div>
  );
}

export default KitManager;

function DeviceLink({
  device,
  organization_id,
}: {
  device: Device | null;
  organization_id: number;
}) {
  const {
    theme: { current: themeObj, },
  } = useSettings();
  const currentTheme = resolveTheme(themeObj);
  if (!device) {
    return null;
  }
  return (
    <Link to={`/organizations/${organization_id}/devices/${device.id}`}>
      <i
        className="material-icons"
        css={css`
          font-size: 14px;
          color: ${currentTheme.colors.textLight};
        `}
        title="View"
      >
        arrow_forward
      </i>
    </Link>
  );
}
