import { useSettings, } from "hooks";
import {
  createContext, ReactNode, useEffect, useMemo, useState,
} from "react";
import { parseQueryBreakdown, } from "utils/search";

export interface SearchTable {
  query: string;
  sort: {
    field: string;
    direction: "asc" | "desc";
  };
  queryBreakdown: {
    omit: string[];
    similar: string[];
    may_include: string[];
    must_include: string[];
    fulltext: string;
  };
}

export type SearchTablePassed = Omit<SearchTable, "queryBreakdown">;

export interface Search {
  global_query: string;
  // eslint-disable-next-line no-unused-vars
  setGlobalQuery: (query: string) => void;
  tables: {
    [table_name: string]: SearchTable;
  };
  // eslint-disable-next-line no-unused-vars
  setTable: (table_name: string, table: SearchTablePassed) => void;
  setTableQuery: (
    // eslint-disable-next-line no-unused-vars
    table_name: string,
    // eslint-disable-next-line no-unused-vars
    query: SearchTablePassed["query"]
  ) => void;
  // eslint-disable-next-line no-unused-vars
  setTableSort: (table_name: string, sort: SearchTablePassed["sort"]) => void;
  // eslint-disable-next-line no-unused-vars
  clearTableSort: (table_name: string) => void;
  // eslint-disable-next-line no-unused-vars
  getTable: (table_name: string) => SearchTable;
}

const defaultTable: SearchTable = {
  query: "",
  sort: {
    field: "",
    direction: "asc",
  },
  queryBreakdown: {
    omit: [],
    similar: [],
    may_include: [],
    must_include: [],
    fulltext: "",
  },
};

type Tables = {
  [table_name: string]: SearchTable;
};

const validateJsonSearch = (json: string): boolean => {
  try {
    const parsed = JSON.parse(json);
    return typeof parsed === "object" && parsed !== null;
  } catch (err) {
    return false;
  }
};

const getValidatedJsonSearchOrEmpty = (json: string): Tables | false => {
  if (localStorage.getItem("preserveSearch") !== "true") {
    return false;
  }
  if (validateJsonSearch(json)) {
    return JSON.parse(json);
  }
  return false;
};

// TODO: Should check the local storage matches the schema
const localSave: Tables = getValidatedJsonSearchOrEmpty(
  localStorage.getItem("search") as string
) ?
  JSON.parse(localStorage.getItem("search") as string) :
  {};

const initialTables: Tables = {
  organizations: defaultTable,
  users: defaultTable,
  templates: defaultTable,
  device_types: defaultTable,
  devices: defaultTable,
  matters: defaultTable,
  all_matters: defaultTable,
  custodians: defaultTable,
  kits: defaultTable,
  collections: defaultTable,
  tasks: defaultTable,
  recent: defaultTable,
  assigned_organizations: defaultTable,
  active_kits: defaultTable,
  email_templates: defaultTable,
  email_fragments: defaultTable,
  matters_archived: defaultTable,
  all_matters_archived: defaultTable,
  ...localSave,
};

const initialSearch: Search = {
  global_query: "",
  setGlobalQuery: () => {},
  tables: initialTables,
  setTable: () => {},
  setTableQuery: () => {},
  setTableSort: () => {},
  clearTableSort: () => {},
  getTable: () => ({ ...defaultTable, }),
};

const SearchContext = createContext(initialSearch);

function SearchProvider(props: { children: ReactNode }): JSX.Element {
  const { search: { preserve_search: { current: preserveSearch, }, }, } = useSettings();
  const { children, } = props;
  const [global_query, setGlobalQuery] = useState("");
  const [tables, setTables] = useState<{
    [table_name: string]: SearchTable;
  }>(initialTables);

  const setTable = (table_name: string, table: SearchTablePassed) => {
    const breakdown = parseQueryBreakdown(table.query);
    setTables({
      ...tables,
      [table_name]: { ...table, queryBreakdown: breakdown, },
    });

    return table;
  };

  const setTableQuery = (table_name: string, query: string) => {
    setTable(table_name, {
      ...tables[table_name],
      query,
    });
  };

  const setTableSort = (table_name: string, sort: SearchTable["sort"]) => {
    setTable(table_name, {
      ...tables[table_name],
      sort: {
        ...sort,
      },
    });
  };

  const clearTableSort = (table_name: string) => {
    setTable(table_name, {
      ...tables[table_name],
      sort: {
        field: "",
        direction: "asc",
      },
    });
  };

  const getTable = (table_name: keyof Tables) => {
    // if the table doesn't exist, create it
    if (!tables[table_name]) {
      return defaultTable;
    }

    return tables[table_name];
  };

  useEffect(() => {
    if (!preserveSearch) {
      return;
    }
    localStorage.setItem("search", JSON.stringify(tables));
  }, [tables]);

  const value: Search = useMemo(
    () => ({
      global_query,
      setGlobalQuery,
      tables: {},
      setTable,
      setTableQuery,
      setTableSort,
      getTable,
      clearTableSort,
    }),
    [
      global_query,
      tables,
      setGlobalQuery,
      setTable,
      setTableQuery,
      setTableSort,
      getTable,
      clearTableSort
    ]
  );

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
}

export { SearchContext, SearchProvider, };
