import React, { useEffect, useState, } from "react";
import { Details, } from "components/Views";
import { Device, } from "interfaces/main";
import {
  Checkbox, Flex, Grid, ScrollArea, Text,
} from "@mantine/core";
/** @jsxImportSource @emotion/react */
import { css, } from "@emotion/react";
import { useSettings, } from "hooks";
import { resolveTheme, } from "themes/main";
import { useParams, } from "react-router-dom";
import { Loading, } from "components";
import { axios, } from "libs";
import { parseIterableJsonFields, } from "utils/fetching";

function Picklist() {
  const { organization_id, matter_id, kit_id, } = useParams();
  const [loading, setLoading] = useState<boolean>(true);

  // eslint-disable-next-line no-unused-vars
  const [devices, setDevices] = useState<Partial<Device>[]>([]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `/organizations/${organization_id}/matters/${matter_id}/kits/${kit_id}/devices`
      )
      .then((res) => {
        const parsed = parseIterableJsonFields(res.data.data);
        setDevices([...parsed]);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, [
    organization_id
  ]);

  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);
  const { colors, } = currentTheme;

  const getListFormattedDeviceName = (d: Partial<Device>) => {
    const assetTag = d.asset_tag ? ` (${d.asset_tag})` : "";
    const make = d.make ? `${d.make} ` : "";
    const model = d.model ? `${d.model}` : "";
    const serial = d.serial ? ` (${d.serial})` : "";

    return `${make}${model}${assetTag}${serial}`;
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div
      css={css`
        padding-inline: 24px;
      `}
    >
      <Details
        title="Picklist"
      >
        <Grid>
          <Grid.Col span={12}>
            <Text color={colors.text} size="md">
              List generated based on devices assigned to the kit
            </Text>
          </Grid.Col>
          <Grid.Col span={12} />
          <ScrollArea
            style={{
              width: "100%",
            }}
          >
            {devices.length > 0 ? (
              devices.map((device) => (
                <Grid.Col
                  key={getListFormattedDeviceName(device)}
                  style={{
                    marginBottom: 14,
                  }}
                >
                  <Flex
                    align="start"
                    justify="space-between"
                    direction="column"
                  >
                    <Checkbox
                      size="md"
                      label={getListFormattedDeviceName(device)}
                      color={colors.text}
                    />
                    <ul
                      css={css`
                        list-style: none;
                        padding: 0;
                        margin: 0;
                        margin-left: 36px;
                        margin-top: 14px;
                        border-left: 2px solid ${colors.textLight};
                      `}
                    >
                      {device.peripherals &&
                        Object.keys(device.peripherals).length > 0 &&
                        Object.keys(device.peripherals).map((peripheral) => (
                          <li
                            key={peripheral}
                            css={css`
                              font-size: 14px;
                              padding-left: 14px;
                              transition: all 0.2s ease-in-out;
                              color: ${colors.textLight};

                              &:hover {
                                color: ${colors.text};
                                padding-left: 16px;
                              }
                            `}
                          >
                            {device.peripherals?.[peripheral].label}
                            :
                            {" "}
                            {device.peripherals?.[peripheral].value}
                          </li>
                        ))}
                    </ul>
                  </Flex>
                </Grid.Col>
              ))
            ) : (
              <Text size="sm" color={colors.textLight} align="center">
                No devices to pick
              </Text>
            )}
          </ScrollArea>
        </Grid>
      </Details>
    </div>
  );
}

export default Picklist;
