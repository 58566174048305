import React from "react";
import { Task, } from "interfaces/main";
import { Default, } from "components/Views";
import DisplayOnlyTaskCard from "components/InfoCard/DisplayOnlyTaskCard";
import {
  Text,
} from "@mantine/core";
/** @jsxImportSource @emotion/react */
import { css, } from "@emotion/react";
import { useSettings, } from "hooks";
import ProgressSection from "components/Progress/Progress";
import { resolveTheme, } from "themes/main";

interface SpecialTask extends Task {
  link: string;
  endpoint: string;
}

interface TaskProps {
  tasks: SpecialTask[];
  showProgress: boolean;
}

Tasks.defaultProps = {
};

function Tasks({
  tasks,
  showProgress,
}: TaskProps) {
  const sortedTasks = tasks.sort((a, b) => a.sort_order - b.sort_order);

  const {
    theme: { current: theme, },
  } = useSettings();
  const currentTheme = resolveTheme(theme);

  return (
    <div
      role="button"
      tabIndex={0}
    >
      <Default
        title="Tasks"
        buttons={[]}
        collapsable
      >
        {showProgress && tasks.length > 0 && (
          <ProgressSection tasks={sortedTasks} />
        )}
        {tasks.length > 0 ? (
          sortedTasks.map((task) => (
            <div
              key={task.id + task.description}
              css={css`
                display: flex;
                justify-content: space-between;
                margin: 14px 0;
                align-items: center;
              `}
            >
              <p
                css={css`
                  width: 24px;
                  margin: 0;
                  color: ${currentTheme.colors.textLight};
                `}
              >
                {task.sort_order}
              </p>
              <div
                css={css`
                  border-left: 2px solid ${currentTheme.colors.background};
                  padding-left: 14px;
                  width: 100%;
                `}
              >
                <DisplayOnlyTaskCard
                  task={task}
                />
              </div>
            </div>
          ))
        ) : (
          <Text
            size="md"
            weight="300"
            color={currentTheme.colors.textLight}
            align="center"
          >
            No tasks.
          </Text>
        )}
      </Default>
    </div>
  );
}

export default Tasks;
