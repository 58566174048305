import React, { useState, useEffect, } from "react";
import { useAlert, useSettings, } from "hooks";
import { useNavigate, useParams, } from "react-router-dom";
import { AxiosResponse, } from "axios";
import { TextInput, Grid, Select, } from "@mantine/core";
import CreateView from "components/Views/CreateView";
import { axios, } from "libs";
import { Validation, } from "interfaces/responses";
import { Organization, Template, } from "interfaces/main";
import { resolveTheme, } from "themes/main";

function CreateMatter() {
  const alertContext = useAlert();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { organization_id, } = useParams();
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [organizationIdToUse, setOrganizationIdToUse] = useState<
    string | undefined
  >(organization_id !== "any" ? organization_id : undefined);

  const [FormData, setFormData] = useState({
    name: "",
    code: "",
  });
  const [validated, setValidated] = useState(false);
  const [validationRules, setValidationRules] = useState<Validation>();
  const [requiredFields, setRequiredFields] = useState<string[]>([]);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [template_id, setTemplateId] = useState<number | null>(null);

  // const { user } = useUser();

  const { name, code, } = FormData;

  useEffect(() => {
    setLoading(true);
    axios
      .get("/organizations")
      .then((res) => {
        setOrganizations(res.data.data);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        alertContext.setAlert({
          type: "danger",
          message: "Error fetching organizations",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onError = (err: AxiosResponse) => {
    // eslint-disable-next-line no-console
    console.error(err);
    alertContext.setAlert({
      type: "danger",
      heading: "Error",
      message: "Error creating matter",
    });
    setLoading(false);
  };

  const onSubmit = () => {
    setLoading(true);
    axios
      .post(`/organizations/${organizationIdToUse}/matters`, {
        name,
        code,
      })
      .then((res) => {
        const newMatter = res.data.data;
        alertContext.setAlert({
          type: "success",
          heading: "Success",
          message: "Matter created successfully",
        });
        if (template_id) {
          assignTemplate(newMatter.id);
        } else {
          navigate(
            `/organizations/${organizationIdToUse}/matters/${newMatter.id}`
          );
        }
      })
      .catch(onError);
  };

  const assignTemplate = (matter_id: number) => {
    setLoading(true);
    axios
      .post(`/organizations/${organization_id}/matters/${matter_id}/template`, {
        template_id,
      })
      .then(() => {
        alertContext.setAlert({
          type: "success",
          heading: "Success",
          message: "Template assigned successfully",
        });
        navigate(`/organizations/${organization_id}/matters/${matter_id}`);
      })
      .catch(onError);
  };

  useEffect(() => {
    if (!organizationIdToUse) {
      return;
    }
    setLoading(true);
    axios
      .get(`/organizations/${organizationIdToUse}/templates/type/matters`)
      .then((res) => {
        setTemplates(res.data.data);
      })
      .catch(onError)
      .finally(() => setLoading(false));

    setLoading(true);
    axios
      .get(`/organizations/${organizationIdToUse}/matters/create`)
      .then((res) => {
        const { validation, } = res.data.data as { validation: Validation };
        setRequiredFields(
          Object.keys(validation.rules).filter((rule) => {
            if (!validation.rules[rule].includes("required")) {
              return false;
            }
            return true;
          })
        );
        setValidationRules(validation);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [organizationIdToUse]);

  const formattedTemplates = templates.map((temp) => ({
    label: temp.name,
    value: temp.id.toString(),
  }));

  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);
  const { colors, } = currentTheme;

  return (
    <CreateView
      title="Create Matter"
      loading={loading}
      buttons={[
        {
          text: "Submit",
          handleClick: onSubmit,
          color: colors.primary,
          disabled: !validated,
        }
      ]}
      formState={FormData}
      requiredFields={requiredFields}
      setValidated={setValidated}
    >
      <Grid gutter="sm">
        {organization_id === "any" && (
          <Grid.Col sm={12} md={6}>
            <Select
              label="Organization"
              name="organization_id"
              required={validationRules?.rules?.template_id?.includes(
                "required"
              )}
              placeholder="Select Organization"
              data={[...organizations.map((o) => ({
                label: o.name,
                value: o.id.toString(),
              }))]}
              onChange={(value) => {
                if (value) {
                  setOrganizationIdToUse(value);
                }
              }}
              value={organizationIdToUse?.toString()}
              searchable
            />
          </Grid.Col>
        )}
        <Grid.Col sm={12} md={6}>
          <TextInput
            label="Name"
            name="name"
            onChange={(e) => {
              setFormData({ ...FormData, name: e.currentTarget.value, });
            }}
            required={validationRules?.rules?.name?.includes("required")}
            disabled={!organizationIdToUse}
            placeholder="Enter Matter Name"
          />
        </Grid.Col>
        <Grid.Col sm={12} md={6}>
          <TextInput
            label="Code"
            name="code"
            onChange={(e) => {
              setFormData({ ...FormData, code: e.currentTarget.value, });
            }}
            required={validationRules?.rules?.code?.includes("required")}
            disabled={!organizationIdToUse}
            placeholder="Enter Matter Code"
          />
        </Grid.Col>
        <Grid.Col sm={12} md={6}>
          <Select
            label="Template"
            name="template_id"
            required={validationRules?.rules?.template_id?.includes("required")}
            data={formattedTemplates}
            disabled={!organizationIdToUse}
            placeholder="Select Template"
            onChange={(value) => {
              if (value) {
                setTemplateId(parseInt(value, 10));
              }
            }}
            searchable
          />
        </Grid.Col>
      </Grid>
    </CreateView>
  );
}

export default CreateMatter;
