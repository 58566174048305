import React, { useEffect, useState, } from "react";
import { useUser, useAlert, useSettings, } from "hooks";
import { axios, } from "libs";
import { Grid, TextInput, } from "@mantine/core";
import EditView from "components/Views/EditView";
import { Loading, } from "components";
import { User, } from "interfaces/user";
import CreateView from "components/Views/CreateView";

/** @jsxImportSource @emotion/react */
import { css, } from "@emotion/react";
import { resolveTheme, } from "themes/main";

function Profile() {
  const userContext = useUser();
  const [loading, setLoading] = useState(false);
  const [userForm, setUserForm] = useState<Partial<User>>({
    name: "",
    email: "",
  });
  const [validated, setValidated] = useState(false);
  const [requiredFields, setRequiredFields] = useState<string[]>([]);
  const { setAlert, } = useAlert();

  const [newPassword, setNewPassword] = useState({
    password: "",
    password_confirmation: "",
  });
  const [passwordValidated, setPasswordValidated] = useState(false);

  useEffect(() => {
    axios
      .get("/me")
      .then((res) => {
        setUserForm(res.data.data);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        setAlert({
          type: "danger",
          message: "Error fetching user data",
        });
      });

    setRequiredFields(["name", "email"]);
    setLoading(false);
  }, []);

  const checkPasswordValidated = () => {
    if (
      newPassword.password === newPassword.password_confirmation &&
      newPassword.password.length > 0
    ) {
      setPasswordValidated(true);
    } else {
      setPasswordValidated(false);
    }
  };

  useEffect(() => {
    checkPasswordValidated();
  }, [newPassword]);

  const submitProfile = () => {
    setLoading(true);
    axios.put("/me", userForm).then((res) => {
      const newUser = res.data.data;
      userContext.setUser({
        ...userContext.user,
        ...newUser,
      });
      setUserForm({
        ...userForm,
        ...newUser,
      });
      setAlert({
        type: "success",
        message: "Profile updated",
      });
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      setAlert({
        type: "danger",
        message: "Error updating profile",
      });
    }).finally(() => setLoading(false));
  };

  const submitPassword = () => {
    setLoading(true);
    axios.put("/me", newPassword).then(() => {
      setAlert({
        type: "success",
        message: "Password updated",
      });
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      setAlert({
        type: "danger",
        message: "Error updating password",
      });
    }).finally(() => setLoading(false));
  };

  const { theme: { current, }, } = useSettings();
  const currentTheme = resolveTheme(current);
  const { colors, } = currentTheme;

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <EditView
        title="Profile"
        formState={userForm}
        setValidated={setValidated}
        requiredFields={requiredFields}
        buttons={[
          {
            text: "Save",
            handleClick: submitProfile,
            disabled: !validated,
            color: colors.primary,
          }
        ]}
        loading={loading}
      >
        <Grid>
          <Grid.Col sm={12} md={6}>
            <TextInput
              label="Name"
              value={userForm?.name || ""}
              onChange={(e) => setUserForm({ ...userForm, name: e.currentTarget.value, })}
              required={requiredFields.includes("name")}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6}>
            <TextInput
              label="Email"
              value={userForm?.email || ""}
              onChange={(e) => setUserForm({ ...userForm, email: e.currentTarget.value, })}
              required={requiredFields.includes("email")}
            />
          </Grid.Col>
        </Grid>
      </EditView>
      <CreateView
        title="Update Password"
        buttons={[
          {
            text: "Submit",
            handleClick: submitPassword,
            color: colors.primary,
            disabled: !passwordValidated,
          }
        ]}
        loading={loading}
        requiredFields={["password", "password_confirmation"]}
        formState={newPassword}
      >
        <Grid>
          <Grid.Col sm={12} md={6}>
            <TextInput
              label="New Password"
              type="password"
              autoComplete="new-password"
              value={newPassword.password}
              onChange={(e) => setNewPassword({
                ...newPassword,
                password: e.currentTarget.value,
              })}
              required
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6}>
            <TextInput
              label="Confirm Password"
              type="password"
              autoComplete="new-password"
              value={newPassword.password_confirmation}
              onChange={(e) => {
                setNewPassword({
                  ...newPassword,
                  password_confirmation: e.currentTarget.value,
                });
              }}
              required
            />
            {
              !passwordValidated && newPassword.password.length > 0 && (
                <span
                  css={css`
                    color: ${colors.danger};
                    font-size: 14px;
                  `}
                >
                  * passwords do not match
                </span>
              )
            }
          </Grid.Col>
        </Grid>
      </CreateView>
    </div>
  );
}

export default Profile;
