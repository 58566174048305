import React, { MouseEventHandler, useEffect, useState, } from "react";
import SimpleTable from "components/Tables/SimpleTable";
/** @jsxImportSource @emotion/react */
import { css, keyframes, } from "@emotion/react";
import {
  Box, Button, Grid, Title,
} from "@mantine/core";
import Search from "components/Search/Search";
import SortOn from "components/Search/SortOn";
import SortDirection from "components/Search/SortDirection";
import {
  Collection,
  Custodian,
  Device,
  DeviceType,
  EmailFragment,
  EmailTemplate,
  Kit,
  ListViewModes,
  Matter,
  Organization,
  RecentModule,
  Template,
} from "interfaces/main";
import { useSearch, useSettings, } from "hooks";
import { getModuleFieldApplicationLogic, } from "utils/methods";
import { User, } from "interfaces/user";
import { Rows, Table, } from "phosphor-react";
import HeadedTable from "components/Tables/HeadedTable";
import { resolveTheme, } from "themes/main";
import ContainedLoading from "components/Loading/Contained";
import { itemSearchMatch, } from "utils/search";

type SupportedRecords =
  | Organization
  | Matter
  | Custodian
  | Collection
  | Kit
  | DeviceType
  | User
  | Template
  | Device
  | RecentModule
  | EmailTemplate
  | EmailFragment;

interface ListItem {
  id: number;
  title: string;
  subtitle: string;
  endpoint: string;
  completeness?: number | null;
  full_record: SupportedRecords;
}

interface IntegratedListViewProps {
  buttons?: {
    text: string;
    handleClick: MouseEventHandler<HTMLButtonElement>;
    color: string;
    variant?:
      | "outline"
      | "white"
      | "light"
      | "default"
      | "filled"
      | "subtle"
      | "gradient";
    disabled?: boolean;
    invisible?: boolean;
    icon?: string;
  }[];
  title: string;
  loading?: boolean;
  search?: boolean;
  collapsable?: boolean;
  fields?: {
    name: string;
    priority: number;
  }[];
  table_name: string;
  list_items: ListItem[];
  full_record_list: SupportedRecords[];
  with_separator?: boolean;
  with_title?: boolean;
  with_padding_y?: boolean;
}

IntegratedListView.defaultProps = {
  loading: false,
  buttons: [],
  search: false,
  collapsable: false,
  fields: [],
  with_separator: true,
  with_title: true,
  with_padding_y: true,
};

function IntegratedListView({
  list_items,
  table_name,
  buttons,
  title,
  loading,
  search,
  collapsable,
  fields,
  // eslint-disable-next-line no-unused-vars
  full_record_list,
  with_separator,
  with_title,
  with_padding_y,
}: IntegratedListViewProps) {
  const isMobile = () => window.innerWidth <= 768;
  const [collapsed, setCollapsed] = useState(false);
  const {
    view: {
      list_views: {
        default_view_mode: { current: defaultViewMode, },
      },
    },
  } = useSettings();
  const [viewMode, setViewMode] = useState<ListViewModes>(defaultViewMode);

  useEffect(() => {
    const ctrlL = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key === "L") {
        setViewMode((prev) => {
          if (prev === "table") return "list";
          return "table";
        });
      }
    };

    window.addEventListener("keydown", ctrlL);

    return () => {
      window.removeEventListener("keydown", ctrlL);
    };
  }, []);

  const collapse = () => {
    setCollapsed(!collapsed);
  };

  const { getTable, } = useSearch();
  const table = getTable(table_name);
  const { query, sort, } = table;

  const recordTypeHasCompleteness = list_items.some(
    (item) => item.completeness !== undefined
  );

  const getFullRecordWithOnlyFields = (item: ListItem) => {
    // we want an object with only the fields listed in the fields array
    const fullRecord: { [key: string]: any } = {};
    fields?.forEach((field) => {
      fullRecord[field.name] =
        item.full_record[field.name as keyof typeof item.full_record];
    });
    return fullRecord;
  };

  const filteredListItems = list_items.filter((item) => {
    if (!query) return true;
    const match = itemSearchMatch(
      {
        title: item.title,
        subtitle: item.subtitle,
        fields: {
          ...getFullRecordWithOnlyFields(item),
          completeness: recordTypeHasCompleteness ? item.completeness : null,
        },
      },
      table
    );
    const passes = match.matches;
    return passes;
    // if (recordTypeHasCompleteness && sort.field === "completeness") {
    //   return String(item.completeness)
    //     .toLowerCase()
    //     .includes(query.toLowerCase());
    // }
    // if (String(item.full_record[sort.field as keyof typeof item.full_record])) {
    //   const passes =
    //     String(item.full_record[sort.field as keyof typeof item.full_record])
    //       .toLowerCase()
    //       .includes(query.toLowerCase()) ||
    //     item.title.toLowerCase().includes(query.toLowerCase()) ||
    //     item.subtitle.toLowerCase().includes(query.toLowerCase());

    //   return passes;
    // }
    // return false;
  });

  const sortedListItems = filteredListItems.sort((a, b) => {
    if (sort?.field) {
      const field = sort.field as keyof SupportedRecords | "completeness";

      const aAtField =
        field === "completeness" ? a.completeness : a.full_record[field];
      const bAtField =
        field === "completeness" ? b.completeness : b.full_record[field];

      return getModuleFieldApplicationLogic(aAtField, bAtField);
    }
    // if has a completeness field, sort by completeness
    if (recordTypeHasCompleteness) {
      const completenessA = a.completeness || 0;
      const completenessB = b.completeness || 0;
      if (completenessA !== null && completenessB !== null) {
        if (completenessA > completenessB) {
          return 1;
        }
        if (completenessA < completenessB) {
          return -1;
        }
      }
    }

    if (a.title.toLowerCase() < b.title.toLowerCase()) {
      return -1;
    }
    if (a.title.toLowerCase() > b.title.toLowerCase()) {
      return 1;
    }
    return 0;
  });

  const listItemsInDirection =
    sort.direction === "asc" ? sortedListItems : sortedListItems.reverse();

  const getSubtext = (item: ListItem) => {
    if (
      recordTypeHasCompleteness &&
      sort.field &&
      sort.field === "completeness"
    ) {
      return `completeness: ${item.completeness}%`;
    }

    return item.subtitle;
  };

  const formattedListItems = listItemsInDirection.map((item) => ({
    ...item,
    subtitle: getSubtext(item),
  }));

  const fullFields = recordTypeHasCompleteness ?
    [
      ...(fields || []),
      {
        name: "completeness",
        priority: 0,
      }
    ] :
    fields || [];

  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);

  if (loading) {
    return (
      <div
        style={{
          position: "relative",
        }}
      >
        <ContainedLoading />
      </div>
    );
  }

  return (
    <div
      css={css`
        ${with_padding_y ? "padding: 36px 24px;" : "padding: 0 24px;"}
        border-top: 1px solid ${currentTheme.colors.backgroundContrast};
        ${!with_separator && "border-top: none;"}
        position: relative;
        display: flex;
        flex-direction: ${with_title || isMobile() ? "column" : "row-reverse"};
        gap: ${with_title || isMobile() ? "0" : "24px"};

        @media (min-width: 768px) {
          ${with_padding_y ? "padding: 36px 48px;" : "padding: 0 48px;"}
        }

        @media (min-width: 1024px) {
          ${with_padding_y ? "padding: 36px 64px;" : "padding: 0 64px;"}
        }
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;

          @media (min-width: 768px) {
            justify-content: space-between;
          }
        `}
      >
        {
          with_title && (
            <div
              css={css`
              display: flex;
              align-items: center;
              justify-content: flex-start;
              flex-wrap: wrap;
              gap: 0 14px;
            `}
            >
              {collapsable && (
              <button
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-wrap: wrap;
                  border: 1px solid ${currentTheme.colors.backgroundContrast};
                  height: 36px;
                  width: 36px;
                  background-color: ${currentTheme.colors.background};
                  cursor: pointer;
                  border-radius: 50%;
                  box-shadow: inset 0.2px 0.2px 8px rgba(0, 0, 0, 0.1);
                  transition: all 0.2s ease-in-out;

                  &:hover {
                    transform: scale(0.95);
                    box-shadow: inset 0.2px 0.2px 8px rgba(0, 0, 0, 0.15);
                  }

                  &:active {
                    transform: scale(0.94);
                  }
                `}
                onClick={collapse}
                tabIndex={0}
                type="button"
              >
                <i
                  className="material-symbols-outlined"
                  style={{
                    color: currentTheme.colors.text,
                  }}
                >
                  {collapsed ? "chevron_right" : "expand_more"}
                </i>
              </button>
              )}
              <Title
                order={1}
                weight={300}
                style={{
                  fontFamily: "\"Roboto\", sans-serif",
                  fontSize: "32px",
                  fontWeight: 300,
                }}
                color={currentTheme.colors.text}
              >
                {title}
              </Title>
            </div>
          )
        }
        <div
          css={css`
              display: flex;
              justify-content: space-around;
              width: 100%;
              margin-top: 24px;

              & > * {
                margin-bottom: 8px;
              }

              @media (min-width: 768px) {
                flex-direction: ${with_title ? "row" : "column-reverse"}};
                justify-content: ${with_title ? "flex-end" : "flex-end"};
                width: initial;
                margin-top: 32px;
              }
            `}
        >
          <Box
            sx={() => ({
              display: isMobile() ? "none" : "initial",
              textAlign: "right",
            })}
          >
            {viewMode === "list" ? (
              <Button
                color={currentTheme.colors.text}
                variant="default"
                onClick={() => {
                  setViewMode("table");
                }}
                title="Set view mode to table"
              >
                <Table size={18} />
              </Button>
            ) : (
              <Button
                color={currentTheme.colors.text}
                variant="default"
                onClick={() => {
                  setViewMode("list");
                }}
                title="Set view mode to list"
              >
                <Rows size={18} />
              </Button>
            )}
          </Box>
          {!!buttons?.length &&
              buttons.length > 0 &&
              buttons?.map(
                (button, idx) => !button.invisible && (
                // eslint-disable-next-line react/no-array-index-key
                  <Box key={idx + button.text} sx={() => ({ textAlign: "right", })}>
                    <Button
                      onClick={button.handleClick}
                      color={button.color}
                      variant={button.variant}
                      css={css`
                        @media (min-width: 768px) {
                          margin-left: 16px;
                        }

                        i {
                          font-size: 18px;
                        }
                      `}
                      disabled={button.disabled}
                    >
                      {button.icon && (
                      <i className="material-symbols-outlined">
                        {button.icon}
                      </i>
                      )}
                      {button.text || ""}
                    </Button>
                  </Box>

                )
              )}
        </div>
      </div>
      {!collapsed && (
        <div css={css`
          width: 100%;
        `}
        >
          {viewMode === "list" ? (
            <ListViewMode
              search={!!search}
              table_name={table_name}
              fields={fullFields}
              list_items={formattedListItems}
              withTitle={with_title || false}
            />
          ) : (
            <TableViewMode
              search={!!search}
              table_name={table_name}
              fields={fullFields}
              list_items={list_items}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default IntegratedListView;

function ListViewMode({
  search,
  table_name,
  fields,
  list_items,
  // eslint-disable-next-line no-unused-vars
  withTitle,
}: {
  search: boolean;
  table_name: string;
  fields: {
    name: string;
    priority: number;
  }[];
  list_items: ListItem[];
  withTitle: boolean;
}) {
  const viewIn = keyframes`
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  `;

  return (
    <div
      css={css`
        animation: ${viewIn} 0.3s ease-in-out;
      `}
    >
      {search && (
        <Grid
          style={{
            margin: "14px 0",
            padding: "0",
          }}
          gutter={8}
        >
          <Grid.Col sm={12} md={6}>
            <Search
              debounce
              timeout={300}
              table_name={table_name}
              global={false}
            />
          </Grid.Col>
          <Grid.Col sm={6} md={4}>
            <SortOn fields={fields || []} table_name={table_name} />
          </Grid.Col>
          <Grid.Col sm={6} md={2}>
            <SortDirection table_name={table_name} />
          </Grid.Col>
          <hr />
        </Grid>
      )}
      <div>
        <SimpleTable list_items={list_items} table_name={table_name} />
      </div>
    </div>
  );
}

function TableViewMode({
  search,
  table_name,
  fields,
  list_items,
}: {
  search: boolean;
  table_name: string;
  fields: {
    name: string;
    priority: number;
  }[];
  list_items: ListItem[];
}) {
  const formattedListItems = list_items.map((item) => ({
    id: item.id,
    full_record:
      "completeness" in item ?
        {
          ...item.full_record,
          completeness: item.completeness || 0,
        } :
        { ...item.full_record, },
    endpoint: item.endpoint,
  }));

  const viewIn = keyframes`
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  `;

  return (
    <div
      css={css`
        animation: ${viewIn} 0.3s ease-in-out;
      `}
    >
      <HeadedTable
        fields={fields}
        search={search}
        table_name={table_name}
        list_items={formattedListItems}
        actions={
          ["edit", "view", "delete", "copy"] as (
            | "view"
            | "edit"
            | "delete"
            | "copy"
          )[]
        }
      />
    </div>
  );
}
