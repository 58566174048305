import React, { useEffect, useState, } from "react";
import { useSearch, useSettings, } from "hooks";
/** @jsxImportSource @emotion/react */
import { css, } from "@emotion/react";
import { X, } from "phosphor-react";
import { resolveTheme, } from "themes/main";
import ToolTipCard from "components/InfoCard/ToolTipCard";

interface SearchProps {
  debounce?: boolean;
  timeout?: number;
  table_name?: string;
  global?: boolean;
}

Search.defaultProps = {
  debounce: false,
  timeout: 500,
  table_name: "",
  global: true,
};

function Search({
  debounce, timeout, table_name, global,
}: SearchProps) {
  const { setTableQuery, setGlobalQuery, getTable, } = useSearch();
  const table = table_name ? getTable(table_name) : null;
  const query = table?.query || null;
  const [input, setInput] = useState(query || "");

  const setQuery = () => {
    switch (global) {
      case true:
        setGlobalQuery(input);
        break;
      case false:
        if (!table_name) return;
        setTableQuery(table_name, input);
        break;
      default:
        break;
    }
  };

  const {
    theme: { current: theme, },
  } = useSettings();
  const currentTheme = resolveTheme(theme);

  useEffect(() => {
    if (debounce) {
      const debouncedInput = setTimeout(() => {
        setQuery();
      }, timeout);

      return () => clearTimeout(debouncedInput);
    }

    setQuery();

    return () => {};
  }, [input]);

  useEffect(
    () => () => {
      if (input !== "") {
        setQuery();
      }
    },
    []
  );

  const [toolTipActive, setToolTipActive] = useState(false);

  return (
    <div
      css={css`
        width: 100%;
        margin: 14px 0;
        position: relative;
      `}
    >
      <input
        type="text"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder="Type your query..."
        css={css`
          width: 100%;
          height: 42px;
          padding: 8px 16px;
          border: 1px solid ${currentTheme.colors.backgroundContrast};
          font-size: 16px;
          box-sizing: border-box;
          outline: none;
          transition: all 0.2s ease-in-out;
          border-radius: 8px;
          background-color: ${currentTheme.colors.background};
          color: ${currentTheme.colors.text};

          &:focus {
            border-radius: 0;
            border-color: ${currentTheme.colors.primary};
          }
        `}
      />
      <button
        type="button"
        onClick={() => setInput("")}
        css={css`
          position: absolute;
          right: 14px;
          top: 9px;
          background-color: transparent;
          border: none;
          outline: none;
          cursor: pointer;
          transition: all 0.2s ease-in-out;
          height: 24px;
          width: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: ${currentTheme.colors.textLight}80;

          &:hover {
            color: ${currentTheme.colors.textLight};
          }
        `}
        title="Clear search"
      >
        <X size={18} />
      </button>
      <div
        css={css`
          position: absolute;
          z-index: 5;
          right: 42px;
          top: 9px;
          display: none;
        `}
      >
        <ToolTipCard
          title="How to search"
          open={toolTipActive}
          onClose={() => setToolTipActive(false)}
          onOpen={() => setToolTipActive(true)}
        >
          <p>Search is hard</p>
        </ToolTipCard>
      </div>
    </div>
  );
}

export default Search;
