/* eslint-disable max-len */
import {
  Task as TaskDefinition,
  StatusOptions,
  Note,
} from "interfaces/main";
import React, { useEffect, useState, } from "react";
import { useParams, useNavigate, } from "react-router-dom";
import { Details, } from "components/Views";
import { axios, } from "libs";
import { useAlert, useSettings, } from "hooks";
import { Loading, } from "components";
import {
  Grid, TextInput, Button, SegmentedControl,
} from "@mantine/core";
/** @jsxImportSource @emotion/react */
// import { css, } from "@emotion/react";
import Notes from "components/Notes/Notes";
import { resolveTheme, } from "themes/main";
import TextDisplay from "components/Display/TextDisplay";

  interface TaskForm {
    id: number | undefined;
    description: string;
    assigned_to: number | undefined;
    status: number | undefined;
    sort_order: number | undefined;
    completed_at: string;
  }

function Task() {
  const {
    organization_id, matter_id, custodian_id, task_id,
  } =
      useParams();
  const [task, setTask] = useState<TaskDefinition>();
  const [updatedTask, setUpdatedTask] = useState<TaskForm>({
    id: undefined,
    description: "",
    assigned_to: undefined,
    status: undefined,
    sort_order: undefined,
    completed_at: "",
  });
  const [loading, setLoading] = useState(true);
  const { setAlert, setConfirmation, } = useAlert();
  const navigate = useNavigate();
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    if (task) {
      setUpdatedTask(task);
    }
  }, [task]);

  const getTask = () => axios
    .get(
      `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/tasks/${task_id}`
    )
    .then((res) => {
      setTask(res.data.data);
    })
    .catch((err) => {
      console.error(err);
      setAlert({
        message: "There was a problem fetching the task",
        type: "danger",
      });
    });

  useEffect(() => {
    setLoading(true);
    getTask()
      .finally(() => {
        setLoading(false);
      });
  }, [
    organization_id,
    matter_id,
    custodian_id
  ]);

  const handleDelete = () => {
    setConfirmation("Are you sure you want to delete this task?", () => {
      setLoading(true);
      axios
        .delete(
          `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/tasks/${task_id}`
        )
        .then(() => {
          setAlert({
            message: "Task deleted successfully",
            type: "success",
          });
          navigate(
            `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}`
          );
        })
        .catch((err) => {
          console.error(err);
          setAlert({
            message: "There was a problem deleting the task",
            type: "danger",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const setTaskStatus = (status: number) => {
    axios
      .put(
        `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/tasks/${task_id}`,
        { status, }
      )
      .then((res) => {
        setTask({
          ...task,
          ...res.data.data,
        });
        setAlert({
          message: "Task updated successfully",
          type: "success",
        });
      })
      .catch((err) => {
        console.error(err);
        setAlert({
          message: "There was a problem completing the task",
          type: "danger",
        });
      });
  };

  const submitEditedTask = () => {
    setLoading(true);
    axios
      .put(
        `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/tasks/${task_id}`,
        updatedTask
      )
      .then((res) => {
        setTask({
          ...task,
          ...res.data.data,
        });
        setEditing(false);
        setAlert({
          message: "Task updated successfully",
          type: "success",
        });
      })
      .catch((err) => {
        console.error(err);
        setAlert({
          message: "There was a problem updating the task",
          type: "danger",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const submitNotes = async (notes: Note[]) => {
    setLoading(true);
    try {
      try {
        const res = await axios
          .put(
            `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/tasks/${task_id}`,
            {
              ...task,
              notes,
            }
          );
        setTask({ ...(res.data.data as TaskDefinition), });
        setAlert({
          type: "success",
          message: "Notes updated successfully",
        });
        return (res.data.data as TaskDefinition).notes;
      } catch (err) {
        setAlert({
          type: "danger",
          message: "Error updating notes",
        });
        // eslint-disable-next-line no-console
        console.error(err);
        return undefined;
      }
    } finally {
      setLoading(false);
    }
  };

  const statusOptions: StatusOptions[] = [
    {
      label: "Open",
      value: 0,
    },
    {
      label: "In Progress",
      value: 1,
    },
    {
      label: "Completed",
      value: 2,
    }
  ];

  const statusOptionsMap = statusOptions.map((option) => ({
    label: option.label,
    value: option.value.toString(),
  }));

  const { theme: { current: theme, }, } = useSettings();
  const currentTheme = resolveTheme(theme);

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      {!editing ? (
        <Details
          title={task?.description.toString() || "Task"}
          buttons={[
            {
              text: "Edit",
              handleClick: () => {
                setEditing(true);
              },
              color: currentTheme.colors.primary,
              variant: "default",
            },
            {
              text: "Delete",
              handleClick: handleDelete,
              color: currentTheme.colors.danger,
              variant: "filled",
            }
          ]}
        >
          <Grid>
            <Grid.Col sm={12} md={6}>
              <TextDisplay
                label="Description"
                data={task?.description.toString() || "No description"}
              />
            </Grid.Col>
            <Grid.Col>
              <SegmentedControl
                data={statusOptionsMap}
                value={task?.status?.toString() || "0"}
                onChange={(val) => {
                  setTaskStatus(parseInt(val, 10));
                }}
              />
            </Grid.Col>
          </Grid>
        </Details>
      ) : (
        <Details
          title={task?.description.toString() || "Task"}
          buttons={[
            {
              text: "View",
              handleClick: () => {
                setEditing(false);
              },
              color: currentTheme.colors.primary,
              variant: "default",
            },
            {
              text: "Delete",
              handleClick: handleDelete,
              color: currentTheme.colors.danger,
              variant: "filled",
            }
          ]}
        >
          <Grid>
            <Grid.Col sm={12} md={6}>
              <TextInput
                label="Description"
                value={updatedTask?.description}
                onChange={(e) => {
                  setUpdatedTask({
                    ...updatedTask,
                    description: e.currentTarget.value,
                  });
                }}
              />
            </Grid.Col>
            <Grid.Col sm={12}>
              <Button color={currentTheme.colors.primary} onClick={submitEditedTask}>
                Update
              </Button>
            </Grid.Col>
          </Grid>
        </Details>
      )}
      <Notes
        notes={task?.notes || []}
        submitNotes={submitNotes}
        photo_endpoint={`/organizations/${organization_id}/photos`}
        refreshNotes={getTask}
      />
    </div>
  );
}

export default Task;
