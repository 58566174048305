/* eslint-disable no-unreachable */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import { db, Collection as DBCollection, } from "db/db";
import { Collection, } from "interfaces/main";
import axios from "libs/axiosInstance";

export const addOfflineCollection = async (collection: {
  name: string;
  organization_id: number;
  matter_id: number;
  custodian_id: number;
  mysql_id: number;
}) => {
  try {
    if (!collection.mysql_id) {
      throw new Error(
        "Collection mysql_id is required on adding offline collection"
      );
    }

    const exists = await db.collections
      .where("mysql_id")
      .equals(Number(collection.mysql_id))
      .first();

    if (exists) {
      throw new Error("Collection already exists, update it instead");
    }

    const response = await db.collections.add({
      ...collection,
      added_offline_at: new Date(),
    });
    return response;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("error", err);
    return null;
  }
};

export const getAndAddOfflineCollection = async (
  org_id: string,
  matter_id: string,
  custodian_id: string,
  collection_id: string
): Promise<boolean> => {
  try {
    const collectionExists = await db.collections
      .where("mysql_id")
      .equals(Number(collection_id))
      .count();

    if (collectionExists) {
      throw new Error("Collection already exists");
    }

    const collection: Collection = await axios
      .get(
        `/organizations/${org_id}/matters/${matter_id}/custodians/${custodian_id}/collections/${collection_id}`
      )
      .then((res) => res.data.data as Collection);

    const mysql_id = Number(collection.id);

    const newCollection: {
      name: string;
      id: number;
      organization_id: number;
      matter_id: number;
      custodian_id: number;
      mysql_id: number;
    } = {
      mysql_id,
      organization_id: Number(collection.custodian.matter.organization_id),
      matter_id: Number(collection.custodian.matter_id),
      ...collection,
    };

    const added = await addOfflineCollection(newCollection);

    if (!added) {
      throw new Error("Collection could not be added");
    }

    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const updateOfflineCollection = async (collection: DBCollection) => {
  try {
    if (!collection.mysql_id) {
      throw new Error(
        "Collection mysql_id is required on updating offline collection"
      );
    }

    const exists = await db.collections
      .where("mysql_id")
      .equals(Number(collection.mysql_id))
      .first();

    if (!exists) {
      throw new Error("Collection doesn't exist, add it instead");
    }

    const response = await db.collections.update(exists, {
      ...collection,
    });
    return response;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("error", err);
    return null;
  }
};

export const getAndUpdateOfflineCollection = async (
  org_id: string,
  matter_id: string,
  custodian_id: string,
  collection_id: string
): Promise<boolean> => {
  try {
    const collectionExists = await db.collections
      .where("mysql_id")
      .equals(Number(collection_id))
      .count();

    if (!collectionExists) {
      throw new Error("Collection doesn't exist");
    }

    const collection: Collection = await axios
      .get(
        `/organizations/${org_id}/matters/${matter_id}/custodians/${custodian_id}/collections/${collection_id}`
      )
      .then((res) => res.data.data as Collection);

    const mysql_id = Number(collection.id);

    const newCollection: {
      name: string;
      id: number;
      organization_id: number;
      matter_id: number;
      custodian_id: number;
      mysql_id: number;
    } = {
      mysql_id,
      organization_id: Number(collection.custodian.matter.organization_id),
      matter_id: Number(collection.custodian.matter_id),
      ...collection,
    };

    const updated = await updateOfflineCollection(newCollection);

    if (!updated) {
      throw new Error("Collection could not be updated");
    }

    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const getAndAddOrUpdateOfflineCollection = async (
  org_id: string,
  matter_id: string,
  custodian_id: string,
  collection_id: string
): Promise<boolean> => {
  try {
    const collectionExists = await checkOfflineCollectionExists(collection_id);

    if (collectionExists) {
      return await getAndUpdateOfflineCollection(
        org_id,
        matter_id,
        custodian_id,
        collection_id
      );
    }

    return await getAndAddOfflineCollection(
      org_id,
      matter_id,
      custodian_id,
      collection_id
    );
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const deleteOfflineCollection = async (
  collection_id: number | string
) => {
  try {
    if (!collection_id) {
      throw new Error(
        "Collection mysql_id is required on deleting offline collection"
      );
    }

    const exists = await db.collections
      .where("mysql_id")
      .equals(Number(collection_id))
      .first();

    if (!exists) {
      throw new Error("Collection doesn't exist");
    }

    if (!exists.id) {
      throw new Error("Collection id not found on deleting offline collection");
    }

    const response = await db.collections.delete(exists.id);
    return response;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("error", err);
    return null;
  }
};

export const checkOfflineCollectionExists = async (
  collection_id: number | string
) => {
  try {
    if (!collection_id) {
      throw new Error(
        "Collection mysql_id is required on checking offline collection"
      );
    }

    const exists = await db.collections
      .where("mysql_id")
      .equals(Number(collection_id))
      .count();

    return !!exists;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("error", err);
    return false;
  }
};

export const deleteOfflineCollectionIfExists = async (
  collection_id: number | string
) => {
  try {
    if (!collection_id) {
      throw new Error(
        "Collection mysql_id is required on deleting offline collection"
      );
    }

    const exists = await checkOfflineCollectionExists(collection_id);

    if (!exists) {
      return true;
    }

    const response = await deleteOfflineCollection(collection_id);
    return response;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("error", err);
    return false;
  }
};

export const getOfflineCollection = async (collection_id: number | string) => {
  try {
    if (!collection_id) {
      throw new Error(
        "Collection mysql_id is required on getting offline collection"
      );
    }

    const exists = await db.collections
      .where("mysql_id")
      .equals(Number(collection_id))
      .first();

    return exists;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("error", err);
    return null;
  }
};

export const syncCollections = async () => {
  try {
    // first get all the collections that are not synced by checking
    const pushed = await pushCollectionUpdates();

    if (!pushed) {
      throw new Error("Could not push offline collection updates");
    }

    const pulled = await pullOnlineCollections();

    if (!pulled) {
      throw new Error("Could not pull online collections");
    }

    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const pushOfflineCollections = async () => {
  try {
    const collections = await db.collections.toArray();

    const filteredCollections = collections.filter(
      (collection) => !collection.updated_offline_at
    );

    if (!collections.length) {
      return true;
    }

    const promises = filteredCollections.map(async (collection) => {
      const {
        mysql_id,
        updated_offline_at,
        organization_id,
        matter_id,
        custodian_id,
        ...rest
      } = collection;

      const response = await axios.put(
        `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/collections/${mysql_id}`,
        {
          ...rest,
          updated_at: updated_offline_at,
        }
      );

      if (response.status !== 200) {
        throw new Error(`Collection could not be updated. Collection id: ${mysql_id}`);
      }

      const updated = response.data.data as Collection;

      const newCollection: DBCollection = {
        ...updated,
        mysql_id: Number(updated.id),
        updated_offline_at: null,
        organization_id: Number(organization_id),
        matter_id: Number(matter_id),
        custodian_id: Number(custodian_id),
        last_synced_at: new Date(),
      };

      const update = await db.collections.update(collection, newCollection);

      if (!update) {
        throw new Error(`Collection could not be updated. Collection id: ${mysql_id}`);
      }

      return newCollection;
    });

    const responses = await Promise.all(promises);

    console.info("Pushed offline collections");
    return responses;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const pullOnlineCollections = async () => {
  try {
    // all collections where updated_offline_at is null
    const collections = await db.collections.toArray();

    const filteredCollections = collections.filter(
      (collection) => !collection.updated_offline_at
    );

    if (!collections.length) {
      return true;
    }

    const promises = filteredCollections.map(async (collection) => {
      const {
        mysql_id,
        organization_id,
        matter_id,
        custodian_id,
      } = collection;

      const response = await axios.get(
        `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/collections/${mysql_id}`
      );

      if (response.status !== 200) {
        throw new Error(`Collection could not be updated. Collection id: ${mysql_id}`);
      }

      const updated = response.data.data as Collection;

      const newCollection: DBCollection = {
        ...updated,
        mysql_id: Number(mysql_id),
        organization_id: Number(organization_id),
        matter_id: Number(matter_id),
        custodian_id: Number(custodian_id),
        last_synced_at: new Date(),
      };

      const update = await db.collections.update(collection, newCollection);

      if (!update) {
        throw new Error(`Collection could not be updated. Collection id: ${mysql_id}`);
      }

      console.info("Pulled online collections");
      return response;
    });

    const responses = await Promise.all(promises);

    return responses;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const addCollectionUpdate = async (drilldown: {
    organization_id: number;
    matter_id: number;
    custodian_id: number;
    collection_id: number;
  }, data: Partial<Collection>) => {
  try {
    if (!data) {
      throw new Error("Data is required to update collection");
    }

    const update = await db.collection_updates.add({
      data: JSON.stringify(data),
      organization_id: drilldown.organization_id,
      matter_id: drilldown.matter_id,
      custodian_id: drilldown.custodian_id,
      mysql_id: drilldown.collection_id,
    });

    if (!update) {
      throw new Error("Could not add collection update");
    }

    return update;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const addCollectionUpdateFromCollection = async (collection: DBCollection) => {
  try {
    if (!collection) {
      throw new Error("Collection is required to update collection");
    }

    const update = await db.collection_updates.add({
      data: JSON.stringify(collection),
      organization_id: collection.organization_id,
      matter_id: collection.matter_id,
      custodian_id: collection.custodian_id,
      mysql_id: collection.mysql_id,
    });

    if (!update) {
      throw new Error("Could not add collection update");
    }

    return update;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const pushCollectionUpdates = async () => {
  try {
    const updates = await db.collection_updates.toArray();

    const promises = updates.map(async (update) => {
      const {
        mysql_id,
        organization_id,
        matter_id,
        custodian_id,
        data,
      } = update;
      const existingCollection = await db.collections.where("mysql_id").equals(mysql_id).first();

      if (!existingCollection) {
        throw new Error("Could not find collection to update");
      }

      if (!update.id) {
        throw new Error("Collection id is required to update collection");
      }

      const response = await axios.put(
        `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/collections/${mysql_id}`,
        JSON.parse(data)
      );

      if (response.status !== 200) {
        throw new Error(`Collection could not be updated. Collection id: ${mysql_id}`);
      }

      const updated = response.data.data as Collection;

      const newCollection: DBCollection = {
        ...updated,
        mysql_id: Number(updated.id),
        organization_id: Number(organization_id),
        matter_id: Number(matter_id),
        custodian_id: Number(custodian_id),
        last_synced_at: new Date(),
      };

      const updateCollection = await db.collections.update(existingCollection, newCollection);

      if (!updateCollection) {
        throw new Error(`Collection could not be updated. Collection id: ${mysql_id}`);
      }

      await db.collection_updates.delete(update.id);

      return true;
    });

    const responses = await Promise.all(promises);

    console.info("Pushed collection updates");

    return responses;
  } catch (err) {
    console.error(err);
    return false;
  }
};
