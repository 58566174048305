import React, { useEffect, useState, } from "react";
import { axios, } from "libs";
import { useNavigate, useParams, } from "react-router-dom";
import DetailsView from "components/Views/DetailsView";
import { Loading, } from "components";
import { useAlert, useSettings, useUser, } from "hooks";
import Notes from "components/Notes/Notes";
import {
  Note,
  Task,
  Collection as CollectionDefinition,
} from "interfaces/main";
import Tasks from "components/Tasks/Tasks";
import { parseJsonFields, } from "utils/fetching";
import DetailsSection from "components/Details/Details";
import CredentialsSection from "components/Credentials/Credentials";
import { isEmptyObject, } from "utils/methods";
import { resolveTheme, } from "themes/main";
import TextDisplay from "components/Display/TextDisplay";
import { Grid, } from "@mantine/core";
import { Default, } from "components/Views";
import InfoCard from "components/InfoCard/InfoCard";

function Collection() {
  const [collection, setCollection] = useState<CollectionDefinition | null>(
    null
  );
  const [collectionTasks, setCollectionTasks] = useState<Task[]>([]);
  const [loading, setLoading] = useState(false);
  const {
    organization_id, matter_id, custodian_id, collection_id,
  } =
    useParams();
  const alertContext = useAlert();
  const navigate = useNavigate();
  const { hasRole, } = useUser();

  const getCollection = async () => axios
    .get(
      `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/collections/${collection_id}`
    )
    .then((res) => {
      const parsed = parseJsonFields(res.data.data);
      setCollection(parsed);
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      alertContext.setAlert({
        type: "danger",
        message: "Error fetching collection",
      });
    });

  const getTasks = () => {
    axios
      .get(
        `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/collections/${collection_id}/tasks`
      )
      .then((res) => {
        setCollectionTasks(res.data.data);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        alertContext.setAlert({
          type: "danger",
          message: "Error fetching tasks",
        });
      });
  };

  useEffect(() => {
    setLoading(true);
    getCollection().finally(() => setLoading(false));
    getTasks();
  }, [organization_id, matter_id, custodian_id]);

  const handleDelete = () => {
    alertContext.setConfirmation(
      "Are you sure you want to delete this collection?",
      () => {
        setLoading(true);
        axios
          .delete(
            `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/collections/${collection_id}`
          )
          .then(() => {
            alertContext.setAlert({
              type: "success",
              message: "Collection deleted successfully",
            });
            navigate(
              `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}`
            );
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.error(err);
            alertContext.setAlert({
              type: "danger",
              message: "Error deleting collection",
            });
          })
          .finally(() => setLoading(false));
      }
    );
  };

  const submitNotes = async (notes: Note[]) => {
    setLoading(true);
    try {
      try {
        const res = await axios.put(
          `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/collections/${collection_id}`,
          {
            ...collection,
            notes,
          }
        );
        const parsed = parseJsonFields(res.data.data);
        setCollection({
          ...collection,
          ...parsed,
        });
        alertContext.setAlert({
          type: "success",
          message: "Notes updated successfully",
        });
        return (parsed as CollectionDefinition).notes;
      } catch (err) {
        alertContext.setAlert({
          type: "danger",
          message: "Error updating notes",
        });
        // eslint-disable-next-line no-console
        console.error(err);
        return undefined;
      }
    } finally {
      setLoading(false);
    }
  };

  const refreshTasks = () => {
    axios
      .get(
        `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/collections/${collection_id}/tasks`,
        { cache: false, }
      )
      .then((res) => {
        setCollectionTasks(res.data.data);
        getCollection();
      })
      .catch((err) => {
        alertContext.setAlert({
          type: "danger",
          message: "Error refreshing tasks",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  const tasksWithLinks = collectionTasks.map((task) => ({
    ...task,
    link: `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/collections/${collection_id}/tasks/${task.id}`,
    endpoint: `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/collections/${collection_id}/tasks/${task.id}`,
  }));

  const submitDetails = (details: CollectionDefinition["details"]) => {
    axios
      .put(
        `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/collections/${collection_id}`,
        {
          details,
        }
      )
      .then((res) => {
        alertContext.setAlert({
          type: "success",
          message: "Collection details updated successfully",
        });
        const parsed = parseJsonFields(res.data.data);
        setCollection({
          ...collection,
          ...parsed,
        });
      })
      .catch((err) => {
        alertContext.setAlert({
          type: "danger",
          message: "Error updating collection details",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  const submitCredentials = (
    credentials: CollectionDefinition["credentials"]
  ) => {
    axios
      .put(
        `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/collections/${collection_id}`,
        {
          credentials,
        }
      )
      .then((res) => {
        alertContext.setAlert({
          type: "success",
          message: "Collection credentials updated successfully",
        });
        const parsed = parseJsonFields(res.data.data);
        setCollection({
          ...collection,
          ...parsed,
        });
      })
      .catch((err) => {
        alertContext.setAlert({
          type: "danger",
          message: "Error updating collection credentials",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  const closeCollection = () => {
    alertContext.setConfirmation(
      "Are you sure you want to close this collection? Doing so will affect current task statuses and prevent any further changes to the collection.",
      () => {
        axios
          .post(
            `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/collections/${collection_id}/close`
          )
          .then((res) => {
            alertContext.setAlert({
              type: "success",
              message: "Collection completed successfully",
            });
            const parsed = parseJsonFields(res.data.data);
            setCollection({
              ...collection,
              ...parsed,
            });
            refreshTasks();
          })
          .catch((err) => {
            alertContext.setAlert({
              type: "danger",
              message: "Error completing collection",
            });
            // eslint-disable-next-line no-console
            console.error(err);
          });
      }
    );
  };

  const openCollection = () => {
    axios
      .post(
        `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/collections/${collection_id}/open`
      )
      .then((res) => {
        alertContext.setAlert({
          type: "success",
          message: "Collection opened successfully",
        });

        const parsed = parseJsonFields(res.data.data);
        setCollection({
          ...collection,
          ...parsed,
        });
      })
      .catch((err) => {
        alertContext.setAlert({
          type: "danger",
          message: "Error opening collection",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  const cancelTasks = () => {
    axios
      .post(
        `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/collections/${collection_id}/tasks/cancel`
      )
      .then((res) => {
        alertContext.setAlert({
          type: "success",
          message: "Collection tasks cancelled successfully",
        });
        const parsed = parseJsonFields(res.data.data);
        setCollection({
          ...collection,
          ...parsed,
        });
        refreshTasks();
      })
      .catch((err) => {
        alertContext.setAlert({
          type: "danger",
          message: "Error cancelling collection tasks",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  const completeTasks = () => {
    axios
      .post(
        `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/collections/${collection_id}/tasks/complete`
      )
      .then((res) => {
        alertContext.setAlert({
          type: "success",
          message: "Collection tasks completed successfully",
        });
        const parsed = parseJsonFields(res.data.data);
        setCollection({
          ...collection,
          ...parsed,
        });
        refreshTasks();
      })
      .catch((err) => {
        alertContext.setAlert({
          type: "danger",
          message: "Error completing collection tasks",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  const {
    theme: { current: theme, },
  } = useSettings();
  const currentTheme = resolveTheme(theme);

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <DetailsView
        title={collection?.name || "Collection"}
        loading={loading}
        buttons={[
          {
            text: "Display Only",
            handleClick: () => {
              navigate("display-only");
            },
            color: currentTheme.colors.text,
            variant: "filled",
          },
          {
            text: "Close Collection",
            handleClick: closeCollection,
            color: currentTheme.colors.primary,
            variant: "filled",
            invisible: !!collection?.completed_at,
          },
          {
            text: "Open Collection",
            handleClick: openCollection,
            color: currentTheme.colors.primary,
            variant: "filled",
            invisible: !collection?.completed_at,
          },
          {
            text: "Edit",
            handleClick: () => {
              navigate("edit");
            },
            color: currentTheme.colors.primary,
            variant: "default",
            disabled: !hasRole("restricted"),
          },
          {
            text: "Delete",
            handleClick: handleDelete,
            color: currentTheme.colors.danger,
            variant: "filled",
            disabled: !hasRole("poweruser"),
          }
        ]}
      >
        <Grid>
          <Grid.Col sm={12} md={6}>
            <TextDisplay
              label="Scheduled At"
              data={collection?.scheduled_at || "N/A"}
            />
          </Grid.Col>
        </Grid>
      </DetailsView>
      {!isEmptyObject(collection?.details) && (
        <DetailsSection
          details={collection?.details || {}}
          submitDetails={submitDetails}
          disabled={!!collection?.completed_at}
        />
      )}
      {!isEmptyObject(collection?.credentials) && (
        <CredentialsSection
          credentials={collection?.credentials || {}}
          submitCredentials={submitCredentials}
          disabled={!!collection?.completed_at}
        />
      )}
      {collection?.devices && (
        <Default title="Assigned Devices" collapsable>
          <Grid>
            {collection.devices.map((device) => (
              <Grid.Col span={12} key={device.id}>
                <InfoCard
                  title={device.asset_tag}
                  subtitle={`${device.make} ${device.model}`}
                  endpoint={`/organizations/${organization_id}/devices/${device.id}`}
                />
              </Grid.Col>
            ))}
          </Grid>
        </Default>
      )}
      <Notes
        notes={collection?.notes || []}
        submitNotes={submitNotes}
        photo_endpoint={`/organizations/${organization_id}/photos`}
        refreshNotes={getCollection}
        disabled={!!collection?.completed_at}
      />
      <Tasks
        tasks={tasksWithLinks || []}
        editable
        setTasks={(tasks) => {
          setCollectionTasks(tasks);
        }}
        refreshTasks={refreshTasks}
        endpoint={`/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/collections/${collection_id}/tasks`}
        showProgress
        disabled={!!collection?.completed_at}
        buttons={[
          {
            text: "Mark Complete",
            handleClick: completeTasks,
            color: currentTheme.colors.primary,
            variant: "outline",
            invisible: !!collection?.completed_at,
          },
          {
            text: "Mark Canceled",
            handleClick: cancelTasks,
            color: currentTheme.colors.danger,
            variant: "outline",
            invisible: !!collection?.completed_at,
          }
        ]}
      />
    </div>
  );
}

export default Collection;
