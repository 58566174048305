import React, { useEffect, useState, } from "react";
import axios from "libs/axiosInstance";
import { EmailTemplate as EmailTemplateType, } from "interfaces/main";
import { getModuleFields, } from "utils/methods";
import { useNavigate, useParams, } from "react-router-dom";
import { useSearch, useSettings, } from "hooks";
import { Loading, } from "components";
import { resolveTheme, } from "themes/main";
import IntegratedListView from "components/Views/IntegratedListView";

function EmailTemplate() {
  const { organization_id, } = useParams();
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplateType[]>([]);
  const { getTable, } = useSearch();
  const { sort, } = getTable("email_templates");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    axios.get(`/organizations/${organization_id}/email-templates`).then((res) => {
      setEmailTemplates(res.data.data);
    }).catch((err) => {
      console.error(err);
    }).finally(() => {
      setLoading(false);
    });
  }, [
    organization_id
  ]);

  const allFields: (keyof EmailTemplateType)[] | undefined =
    emailTemplates?.[0] &&
    (Object.keys(emailTemplates[0]) as unknown as (keyof EmailTemplateType)[]);

  const allowedFields = new Map([
    ["id", 0],
    ["name", 0],
    ["global", 0],
    ["created_at", 0],
    ["updated_at", 0]
  ]);

  const fields = getModuleFields(allowedFields, allFields);

  const getSubtitle = (t: EmailTemplateType) => {
    if (!sort.field) {
      return `${t.id}`;
    }
    return `${sort.field}: ${t[sort.field as keyof EmailTemplateType]}`;
  };

  const listItems = emailTemplates.map((temp) => ({
    id: temp.id,
    title: temp.name,
    subtitle: getSubtitle(temp),
    endpoint: `/organizations/${organization_id}/email-templates/${temp.id}`,
    full_record: temp,
  }));

  const { theme: { current, }, } = useSettings();
  const currentTheme = resolveTheme(current);
  const { colors, } = currentTheme;

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <IntegratedListView
        title="Email Templates"
        with_separator={false}
        buttons={[
          {
            text: "",
            handleClick: () => {
              navigate("create");
            },
            icon: "add",
            variant: "default",
            color: colors.primary,
          }
        ]}
        search
        table_name="email_templates"
        fields={fields}
        full_record_list={emailTemplates}
        list_items={listItems}
      />
    </div>
  );
}

export default EmailTemplate;
