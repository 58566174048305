import IntegratedListView from "components/Views/IntegratedListView";
import { useAlert, useSearch, useSettings, } from "hooks";
import { Device, } from "interfaces/main";
import axios from "libs/axiosInstance";
import React, { useEffect, useState, } from "react";
import { useNavigate, useParams, } from "react-router-dom";
import { resolveTheme, } from "themes/main";
import { getFormattedDeviceName, getFormattedTimeString, } from "utils/formatting";
import { getModuleFields, } from "utils/methods";

function List() {
  const [devices, setDevices] = useState<Device[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { organization_id, } = useParams();
  const { getTable, } = useSearch();
  const { sort, } = getTable("devices");

  // const { user } = useUser();
  const { setAlert, } = useAlert();
  //   const { hasRole } = useUser();

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/organizations/${organization_id}/devices`)
      .then((res) => {
        setDevices(res.data.data);
      })
      .catch((err: any) => {
        // eslint-disable-next-line no-console
        console.error(err);
        setAlert({
          type: "danger",
          message: "Error fetching devices",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    organization_id
  ]);

  const allFields: (keyof Device)[] | undefined =
    devices?.[0] && (Object.keys(devices[0]) as unknown as (keyof Device)[]);

  const allowedFields = new Map([
    ["id", 0],
    ["make", 0],
    ["model", 0],
    ["serial", 0],
    ["storage", 0],
    ["asset_tag", 0],
    ["organization_id", 0],
    ["global", 1],
    ["weight", 0],
    ["checked_out_at", 0],
    ["checked_in_at", 0],
    ["kit_id", 0],
    ["device_type_id", 1],
    ["created_at", 1],
    ["updated_at", 1]
  ]);

  const fields = getModuleFields(allowedFields, allFields);

  const getSubtitle = (d: Device) => {
    if (!sort.field) {
      return d.updated_at ?
        `Last update at ${getFormattedTimeString(d.updated_at)}` :
        `Created at ${getFormattedTimeString(d.created_at)}`;
    }
    return `${sort.field}: ${d[sort.field as keyof Device]}`;
  };

  const listItems = devices.map((device) => ({
    id: device.id,
    title: getFormattedDeviceName(device),
    subtitle: getSubtitle(device),
    endpoint: `/organizations/${organization_id}/devices/${device.id}`,
    full_record: device,
  }));

  const { theme: { current, }, } = useSettings();
  const currentTheme = resolveTheme(current);
  const { colors, } = currentTheme;

  return (
    <div>
      <IntegratedListView
        title="Devices"
        loading={loading}
        search
        buttons={[
          {
            text: "",
            icon: "add",
            handleClick: () => {
              navigate(`/organizations/${organization_id}/devices/create`);
            },
            color: colors.primary,
            variant: "default",
            // invisible: !hasRole("user")
          }
        ]}
        table_name="devices"
        fields={fields}
        full_record_list={devices}
        list_items={listItems}
        with_separator={false}
        with_title={false}
      />
    </div>
  );
}

export default List;
