import * as DOMPurify from "dompurify";

const unsafeHTMLTags = [
  "script",
  "iframe",
  "object",
  "embed",
  "applet",
  "form",
  "img",
  "base",
  "link",
  "style"
];

export const containsUnsafeTags = (html: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  const unsafeTags = unsafeHTMLTags.filter((tag) => doc.getElementsByTagName(tag).length > 0);
  return unsafeTags.length > 0;
};

export const containsUnsafeAttributes = (html: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  const unsafeAttributes = Array.from(doc.querySelectorAll("*")).filter((el) => Array.from(el.attributes).some((attr) => attr.name.startsWith("on")));
  return unsafeAttributes.length > 0;
};

export const sanitizeHTML = (html: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  const unsafeTags = unsafeHTMLTags.map((tag) => Array.from(doc.getElementsByTagName(tag))).flat();
  unsafeTags.forEach((el) => el.remove());
  const unsafeAttributes = Array.from(doc.querySelectorAll("*")).filter((el) => Array.from(el.attributes).some((attr) => attr.name.startsWith("on")));
  unsafeAttributes.forEach((el) => Array.from(el.attributes).forEach((attr) => attr.name.startsWith("on") && el.removeAttribute(attr.name)));
  return doc.body.innerHTML;
};

export const sanitizeHTMLWithDOMPurify = (html: string) => DOMPurify.sanitize(html);

export const getSanitizedHTML = (html: string) => {
  const sanitizedHTML = sanitizeHTML(html);
  const purifiedHTML = sanitizeHTMLWithDOMPurify(sanitizedHTML);
  return purifiedHTML;
};
