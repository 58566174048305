import React from "react";
import { RichTextEditor, } from "@mantine/tiptap";
import { useEditor, } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";

function index({
  placeHolder = "Enter text here",
  onBlur,
  initialContent,
  outputType = "html",
  disabled = false,
}: {
  placeHolder: string;
  initialContent?: string;
  outputType?: "html" | "json";
  // eslint-disable-next-line no-unused-vars
  onBlur?: (value: string) => void;
  disabled?: boolean;
}) {
  const content = initialContent || "";

  const editor = useEditor(
    {
      extensions: [
        StarterKit,
        Placeholder.configure({ placeholder: placeHolder, })
      ],
      content,
      onBlur: ({ editor: e, }) => {
        if (onBlur) {
          const output =
            outputType === "html" ? e.getHTML() : JSON.stringify(e.getJSON());
          onBlur(output);
        }
      },
    },
    [content]
  );

  if (disabled) {
    return (
      <div
        css={{
          border: "1px solid #e3e3e3",
          borderRadius: "4px",
          padding: "10px",
          minHeight: "100px",
        }}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: content, }}
      />
    );
  }

  return (
    <div>
      <RichTextEditor editor={editor}>
        <RichTextEditor.Toolbar sticky stickyOffset={60}>
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Bold />
            <RichTextEditor.Italic />
            <RichTextEditor.Code />
          </RichTextEditor.ControlsGroup>
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.OrderedList />
            <RichTextEditor.BulletList />
          </RichTextEditor.ControlsGroup>
        </RichTextEditor.Toolbar>
        <RichTextEditor.Content />
      </RichTextEditor>
    </div>
  );
}

export default index;
