import { Address, Device, Matter, } from "interfaces/main";

const calculateStorageType = (storage: number) => {
  if (!storage) {
    return {
      value: 0,
      unit: "MB",
    };
  }

  if (storage < 1000) {
    return {
      value: storage,
      unit: "MB",
    };
  }

  if (storage < 1000000) {
    return {
      value: storage / 1000,
      unit: "GB",
    };
  }

  return {
    value: storage / 1000000,
    unit: "TB",
  };
};

export const getFormattedMatterName = (matter: Matter, org_prefix?: string) => {
  const { name, code, } = matter;
  const code_prefix = matter?.organization?.code_prefix || org_prefix;
  const formattedCodePrefix = String(code_prefix);
  const formattedCode = String(code);
  const toReturn = `${formattedCodePrefix.toLocaleUpperCase()}${formattedCode.toLocaleUpperCase()}-${name}`;
  return toReturn;
};

export const getFormattedTimeString = (time: string) => {
  // format 2022-10-04T21:34:41.000000Z to 10/04/2022 21:34:41
  const date = new Date(time);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const toReturn = `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
  return toReturn;
};

export const getFormattedCustodianName = (custodian?: {
  first_name: string | undefined;
  last_name: string | undefined;
  id: number | string | undefined;
}) => {
  if (!custodian) return "";

  if (custodian.first_name && custodian.last_name) {
    return `${custodian.first_name} ${custodian.last_name}`;
  }
  return (
    custodian.first_name || custodian.last_name || `Custodian (${custodian.id})`
  );
};

export const getFormattedDeviceName = (device?: Partial<Device> | null) => {
  if (!device) return "";
  const name = device.asset_tag || `${device.make} ${device.model}`;
  return name;
};

export const getMoreDetailedFormattedDeviceName = (
  device?: Partial<Device> | null
) => {
  if (!device) return "";
  const storage = calculateStorageType(device.storage || 0);
  const name =
    `${device.asset_tag} - ${storage.value} ${storage.unit}` ||
    `${device.make} ${device.model}`;
  return name;
};

export const getCondensedEmail = (email: string, length = 10) => {
  if (!email || !email.length) return "";
  const [first, last] = email.split("@");
  const firstPart = first.slice(0, length);
  const lastPart = last.slice(0, length);
  return `${firstPart}...@${lastPart}`;
};

export const getPrettyTableName = (tableName: string) => {
  // table name will look like "active_kits", break into words and capitalize
  const words = tableName.split("_");
  const capitalizedWords = words.map((word) => {
    const firstLetter = word[0].toUpperCase();
    const restOfWord = word.slice(1);
    return `${firstLetter}${restOfWord}`;
  });
  return capitalizedWords.join(" ");
};

export const getFormattedAddress = (address: Address) => {
  const {
    street1, city, state, postalCode, country,
  } = address;
  return `${street1}, ${city}, ${state}, ${postalCode}, ${country}`;
};
