import {
  Button,
  Flex,
  Grid,
  Group,
  SegmentedControl,
  Select,
  Text,
  TextInput,
} from "@mantine/core";
import InlineLoader from "components/Loading/Inline";
import { useAlert, useSettings, useUser, } from "hooks";
import {
  EmailTemplate,
  Kit as KitDefinition,
  Matter as MatterDefinition,
  Template,
} from "interfaces/main";
import { Validation, } from "interfaces/responses";
import { axios, } from "libs";
import { ArrowSquareOut, } from "phosphor-react";
import React, { useEffect, useState, } from "react";
import { Link, } from "react-router-dom";
import { resolveTheme, } from "themes/main";
import { parseJsonFields, } from "utils/fetching";
import { validateEmail, } from "utils/methods";

function Kit({
  matter,
  markComplete,
  invalidate,
}: {
  matter: MatterDefinition;
  // eslint-disable-next-line no-unused-vars
  markComplete: (data: KitDefinition) => void;
  invalidate: () => void;
}) {
  const { user, } = useUser();
  const [FormData, setFormData] = useState<Partial<KitDefinition>>({
    name: "",
    notification_emails: [user.email],
    email_template_id: undefined,
  });
  const [requiredFields, setRequiredFields] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [template_id, setTemplateId] = useState<number | null>(null);
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([]);
  const [existingKits, setExistingKits] = useState<KitDefinition[]>([]);

  const [createdKit, setCreatedKit] = useState<KitDefinition | null>(null);
  const [selectedKit, setSelectedKit] = useState<KitDefinition | null>(null);

  const isRequired = (field: string): boolean => requiredFields.includes(field);

  const { setAlert, } = useAlert();

  useEffect(() => {
    axios
      .get(`/organizations/${matter.organization_id}/matters/${matter.id}/kits`)
      .then((res) => {
        setExistingKits(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (!matter) {
      return;
    }

    setLoading(true);
    axios
      .get(`/organizations/${matter.organization_id}/email-templates`)
      .then((res) => {
        setEmailTemplates(res.data.data);
      })
      .catch((err) => {
        console.error(err);
        setAlert({
          type: "danger",
          message: "Error fetching email templates",
        });
      });
    axios
      .get(
        `/organizations/${matter.organization_id}/matters/${matter.id}/kits/create`
      )
      .then((res) => {
        const { validation, } = res.data.data as { validation: Validation };
        setRequiredFields(
          Object.keys(validation.rules).filter((rule) => {
            if (!validation.rules[rule].includes("required")) {
              return false;
            }
            return true;
          })
        );
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error("error", err);
      })
      .finally(() => {
        setLoading(false);
      });
    axios
      .get(`/organizations/${matter.organization_id}/templates/type/kits`)
      .then((res) => {
        setTemplates(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [matter]);

  const onSubmit = () => {
    if (!matter) {
      return;
    }

    // validation
    const errors = requiredFields.filter((field) => {
      if (
        FormData[field as keyof Partial<KitDefinition>] === undefined ||
        FormData[field as keyof Partial<KitDefinition>] === ""
      ) {
        return true;
      }
      return false;
    });

    if (errors.length > 0) {
      setAlert({
        type: "danger",
        heading: "Error",
        message: "Please fill out all required fields",
      });
      return;
    }

    setLoading(true);
    axios
      .post(
        `/organizations/${matter.organization_id}/matters/${matter.id}/kits`,
        {
          ...FormData,
        }
      )
      .then((res) => {
        const newKit = res.data.data;
        setAlert({
          type: "success",
          heading: "Success",
          message: "Kit created successfully",
        });
        if (template_id) {
          assignTemplate(newKit.id);
        }
        const parsedKit = parseJsonFields(newKit);
        setCreatedKit(parsedKit);
        markComplete(parsedKit);
      })
      .catch((err) => {
        console.error(err);
        setAlert({
          type: "danger",
          heading: "Error",
          message: "Error creating kit",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const assignTemplate = (kit_id: number) => {
    if (!matter) {
      return;
    }

    setLoading(true);
    axios
      .post(
        `/organizations/${matter.organization_id}/matters/${matter.id}/kits/${kit_id}/template`,
        {
          template_id,
        }
      )
      .then(() => {
        setAlert({
          type: "success",
          heading: "Success",
          message: "Template assigned successfully",
        });
      })
      .catch((err) => {
        console.error(err);
        setAlert({
          type: "danger",
          heading: "Error",
          message: "Error assigning template",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const { name, value, } = e.target;
    setFormData({ ...FormData, [name]: value, });
  };

  const formattedTemplates = templates.map((temp) => ({
    label: temp.name,
    value: temp.id.toString(),
  }));

  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);

  const onUpdate = () => {
    if (!matter || !createdKit) {
      return;
    }

    setLoading(true);
    axios
      .put(
        `/organizations/${matter.organization_id}/matters/${matter.id}/kits/${createdKit.id}`,
        {
          ...createdKit,
        }
      )
      .then((res) => {
        const newKit = res.data.data;
        setAlert({
          type: "success",
          heading: "Success",
          message: "Kit updated successfully",
        });
        const parsedKit = parseJsonFields(newKit);
        setCreatedKit(parsedKit);
        markComplete(parsedKit);
      })
      .catch((err) => {
        console.error(err);
        setAlert({
          type: "danger",
          heading: "Error",
          message: "Error updating kit",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onUpdateExisting = () => {
    if (!matter || !selectedKit) {
      return;
    }

    setLoading(true);
    axios
      .put(
        `/organizations/${matter.organization_id}/matters/${matter.id}/kits/${selectedKit.id}`,
        {
          ...selectedKit,
        }
      )
      .then((res) => {
        const newKit = res.data.data;
        setAlert({
          type: "success",
          heading: "Success",
          message: "Kit updated successfully",
        });
        const parsedKit = parseJsonFields(newKit);
        setSelectedKit(parsedKit);
        markComplete(parsedKit);
      })
      .catch((err) => {
        console.error(err);
        setAlert({
          type: "danger",
          heading: "Error",
          message: "Error updating kit",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [useExisting, setUseExisting] = useState(false);

  useEffect(() => {
    invalidate();
    if (!useExisting) {
      setSelectedKit(null);
    }
  }, [useExisting]);

  const selectExisting = async (kit_id: KitDefinition["id"]) => {
    axios
      .get(
        `/organizations/${matter.organization_id}/matters/${matter.id}/kits/${kit_id}`
      )
      .then((res) => {
        const kit = res.data.data;
        const parsedKit = parseJsonFields(kit);
        setSelectedKit(parsedKit);
        markComplete(parsedKit);
      })
      .catch((err) => {
        console.error(err);
        setAlert({
          type: "danger",
          heading: "Error",
          message: "Error selecting existing kit",
        });
      });
  };

  return (
    <Grid>
      <Grid.Col span={12}>
        <SegmentedControl
          data={[
            { label: "Create New Kit", value: "create", },
            { label: "Use Existing Kit", value: "existing", }
          ]}
          value={useExisting ? "existing" : "create"}
          onChange={(value) => {
            if (value === "existing") {
              setUseExisting(true);
            } else {
              setUseExisting(false);
            }
          }}
        />
      </Grid.Col>
      {useExisting ? (
        <Grid.Col sm={12} md={6} lg={4}>
          <Select
            label="Existing Kit"
            data={existingKits.map((kit) => ({
              label: kit.name,
              value: kit.id.toString(),
            }))}
            onChange={(v) => {
              if (!v) {
                return;
              }
              const found = existingKits.find((kit) => kit.id === Number(v));
              if (!found) {
                return;
              }
              selectExisting(found.id);
            }}
            placeholder="Select an existing kit..."
          />
        </Grid.Col>
      ) : (
        !selectedKit &&
        !createdKit && (
          <>
            <Grid.Col sm={12} md={6} lg={4}>
              <TextInput
                label="Name"
                name="name"
                required={isRequired("name")}
                onChange={handleChange}
                placeholder="Enter a name for the kit"
                value={FormData.name || ""}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Select
                label="Template"
                name="template_id"
                data={formattedTemplates}
                onChange={(value) => {
                  if (value) {
                    setTemplateId(Number(value));
                  }
                }}
                searchable
                placeholder="Select a template"
                value={String(template_id) || ""}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <TextInput
                label="Notification Emails"
                name="notification_emails"
                required={isRequired("notification_emails")}
                onChange={(e) => {
                  setFormData({
                    ...FormData,
                    notification_emails: e.target.value ?
                      e.target.value.split(",").map((email) => email.trim()) :
                      [],
                  });
                }}
                placeholder="Enter a comma separated list of emails"
                value={FormData.notification_emails?.join(", ") || ""}
              />
              {FormData.notification_emails?.map((email) => {
                if (!validateEmail(email) && email.length > 0) {
                  return (
                    <Text
                      color={currentTheme.colors.danger}
                      size="xs"
                      weight={300}
                      key={email}
                    >
                      &quot;
                      {email}
                      &quot; is not a valid email address
                    </Text>
                  );
                }
                return null;
              })}
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <Select
                label="Email Template"
                name="email_template_id"
                data={emailTemplates.map((t) => ({
                  label: t.name,
                  value: t.id.toString(),
                }))}
                searchable
                onChange={(value) => {
                  if (value) {
                    setFormData({
                      ...FormData,
                      email_template_id: Number(value),
                    });
                  }
                }}
                type="text"
                placeholder="Select an email template"
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <Group position="right">
                <Button onClick={onSubmit} variant="default">
                  Create New Kit
                </Button>
                {loading && <InlineLoader />}
              </Group>
            </Grid.Col>
          </>
        )
      )}
      {createdKit && (
        <>
          <Grid.Col sm={12} md={6} lg={4}>
            <TextInput
              label="Name"
              name="name"
              required={isRequired("name")}
              onChange={(e) => {
                setCreatedKit({
                  ...createdKit,
                  name: e.target.value,
                });
              }}
              placeholder="Enter a name for the kit"
              value={FormData.name || ""}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6} lg={4}>
            <TextInput
              label="Notification Emails"
              name="notification_emails"
              required={isRequired("notification_emails")}
              onChange={(e) => {
                setCreatedKit({
                  ...createdKit,
                  notification_emails: e.target.value ?
                    e.target.value.split(",").map((email) => email.trim()) :
                    [],
                });
              }}
              placeholder="Enter a comma separated list of emails"
              value={createdKit.notification_emails?.join(", ") || ""}
            />
            {createdKit.notification_emails?.map((email) => {
              if (!validateEmail(email) && email.length > 0) {
                return (
                  <Text
                    color={currentTheme.colors.danger}
                    size="xs"
                    weight={300}
                    key={email}
                  >
                    &quot;
                    {email}
                    &quot; is not a valid email address
                  </Text>
                );
              }
              return null;
            })}
          </Grid.Col>
          <Grid.Col span={12}>
            <Group position="right">
              <Button onClick={onUpdate} variant="default">
                Update Kit
              </Button>
              {loading && <InlineLoader />}
            </Group>
          </Grid.Col>
        </>
      )}
      {selectedKit && (
        <>
          <Grid.Col sm={12} md={6} lg={4}>
            <TextInput
              label="Name"
              name="name"
              required={isRequired("name")}
              onChange={(e) => {
                setSelectedKit({
                  ...selectedKit,
                  name: e.target.value,
                });
              }}
              placeholder="Enter a name for the kit"
              value={selectedKit.name || ""}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6} lg={4}>
            <TextInput
              label="Notification Emails"
              name="notification_emails"
              required={isRequired("notification_emails")}
              onChange={(e) => {
                setSelectedKit({
                  ...selectedKit,
                  notification_emails: e.target.value ?
                    e.target.value.split(",").map((email) => email.trim()) :
                    [],
                });
              }}
              placeholder="Enter a comma separated list of emails"
              value={selectedKit.notification_emails?.join(", ") || ""}
            />
            {selectedKit.notification_emails?.map((email) => {
              if (!validateEmail(email) && email.length > 0) {
                return (
                  <Text
                    color={currentTheme.colors.danger}
                    size="xs"
                    weight={300}
                    key={email}
                  >
                    &quot;
                    {email}
                    &quot; is not a valid email address
                  </Text>
                );
              }
              return null;
            })}
          </Grid.Col>
          <Grid.Col span={12}>
            <Group position="right">
              <Link
                to={`/organizations/${matter.organization_id}/matters/${matter.id}/kits/${selectedKit.id}`}
                target="_blank"
              >
                <Button variant="subtle">
                  <Flex justify="space-around" gap={8}>
                    Go to Kit
                    {" "}
                    <ArrowSquareOut weight="bold" />
                  </Flex>
                </Button>
              </Link>
              <Button onClick={onUpdateExisting} variant="default">
                Update Kit
              </Button>
              {loading && <InlineLoader />}
            </Group>
          </Grid.Col>
        </>
      )}
    </Grid>
  );
}

export default Kit;
