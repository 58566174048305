import React, { useEffect, useState, } from "react";
import { Create, } from "components/Views";
import { Loading, } from "components";
import { axios, } from "libs";
import { useParams, useNavigate, } from "react-router-dom";
import { Validation, } from "interfaces/responses";
import {
  Grid, TextInput, Title,
} from "@mantine/core";
import { useAlert, useSettings, } from "hooks";
import { EmailFragment, } from "interfaces/main";
import { resolveTheme, } from "themes/main";
import TextEditor from "components/RichTextEditor";

function CreateEmailFragment() {
  const { organization_id, email_template_id, } = useParams();
  const [formState, setFormState] = useState<Partial<EmailFragment>>({
    name: "",
    content: "",
  });
  const [loading, setLoading] = useState(false);
  const [validationRules, setValidationRules] = useState<Validation>();
  const [requiredFields, setRequiredFields] = useState<string[]>([]);
  const [validated, setValidated] = useState(false);
  // const { hasRole, } = useUser();
  const { setAlert, } = useAlert();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/organizations/${organization_id}/email-templates/${email_template_id}/fragments/create`)
      .then((res) => {
        const validation = res.data.data.validation as Validation;
        setValidationRules(validation);
        setRequiredFields(
          Object.entries(validation.rules)
            .filter(([, value]) => value.includes("required"))
            .map(([key]) => key)
        );
      })
      .catch((err) => {
        console.error(err);
        setAlert({
          type: "danger",
          message: "Error loading email fragment validation rules",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    organization_id
  ]);

  const submitTemplate = () => {
    setLoading(true);
    axios
      .post(`/organizations/${organization_id}/email-templates/${email_template_id}/fragments`, formState)
      .then((res) => {
        setAlert({
          type: "success",
          message: "Email fragment created successfully",
        });
        const newTemplate = res.data.data as EmailFragment;
        setLoading(false);
        // if (template_id) {
        //   assignTemplate(newTemplate.id);
        //   return;
        // }
        navigate(
          `/organizations/${organization_id}/email-templates/${email_template_id}/fragments/${newTemplate.id}`
        );
      })
      .catch((err) => {
        console.error(err);
        setAlert({
          type: "danger",
          message: "Error creating email fragment",
        });
        setLoading(false);
      });
  };

  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);
  const { colors, } = currentTheme;

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <Create
        title="Create Email Fragment"
        buttons={[
          {
            text: "Submit",
            handleClick: () => {
              submitTemplate();
            },
            variant: "filled",
            color: colors.primary,
            disabled: !validated || formState.content === "<p></p>",
          }
        ]}
        formState={formState}
        requiredFields={requiredFields}
        setValidated={setValidated}
      >
        <Grid>
          <Grid.Col sm={12} md={6}>
            <TextInput
              label="Name"
              name="name"
              onChange={(e) => {
                setFormState({ ...formState, name: e.currentTarget.value, });
              }}
              required={validationRules?.rules?.name?.includes("required")}
              placeholder="Template name..."
            />
          </Grid.Col>
          <Grid.Col span={12} />
          <Grid.Col sm={12}>
            <Title
              weight={400}
              color={colors.text}
              size="sm"
            >
              Content
            </Title>
          </Grid.Col>
          <Grid.Col sm={12}>
            <TextEditor
              placeHolder="Enter text here"
              onBlur={(value) => {
                setFormState({ ...formState, content: value, });
              }}
              outputType="html"
            />
          </Grid.Col>
        </Grid>
      </Create>
    </div>
  );
}

export default CreateEmailFragment;
