import React, { useEffect, useState, } from "react";
import { Default, Edit, } from "components/Views";
import { Loading, } from "components";
import { axios, } from "libs";
import { useParams, useNavigate, } from "react-router-dom";
import { Validation, } from "interfaces/responses";
import {
  Button, Checkbox, Grid, TextInput,
} from "@mantine/core";
import { useUser, useAlert, useSettings, } from "hooks";
import { DeviceType, } from "interfaces/main";
import { parseJsonFields, } from "utils/fetching";
import JSONForm from "components/Inputs/JsonForm";
import { resolveTheme, } from "themes/main";

function EditDeviceType() {
  const { organization_id, device_type_id, } = useParams();
  const [formState, setFormState] = useState<Partial<DeviceType>>();
  const [loading, setLoading] = useState(false);
  const [validationRules, setValidationRules] = useState<Validation>();
  const [requiredFields, setRequiredFields] = useState<string[]>([]);
  const [validated, setValidated] = useState(false);
  const { hasRole, } = useUser();
  const { setAlert, } = useAlert();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/organizations/${organization_id}/device-types/create`)
      .then((res) => {
        const validation = res.data.data.validation as Validation;
        setValidationRules(validation);
        setRequiredFields(
          Object.entries(validation.rules)
            .filter(([, value]) => value.includes("required"))
            .map(([key]) => key)
        );
      })
      .catch((err) => {
        console.error(err);
        setAlert({
          type: "danger",
          message: "Error loading device validation rules",
        });
      })
      .finally(() => {
        setLoading(false);
      });

    setLoading(true);
    axios
      .get(`/organizations/${organization_id}/device-types/${device_type_id}`)
      .then((res) => {
        const parsed = parseJsonFields(res.data.data);
        setFormState({
          ...parsed,
        });
      })
      .catch((err) => {
        console.error(err);
        setAlert({
          type: "danger",
          message: "Error loading device",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    organization_id,
    device_type_id
  ]);

  const submitDevice = () => {
    setLoading(true);
    axios
      .put(
        `/organizations/${organization_id}/device-types/${device_type_id}`,
        formState
      )
      .then(() => {
        setAlert({
          type: "success",
          message: "Device updated successfully",
        });
        setLoading(false);
        navigate(
          `/organizations/${organization_id}/device-types/${device_type_id}`
        );
      })
      .catch((err) => {
        console.error(err);
        setAlert({
          type: "danger",
          message: "Error updating device",
        });
        setLoading(false);
      });
  };

  const setPeripherals = (peripherals: DeviceType["peripherals"]) => {
    setFormState({ ...formState, peripherals, });
  };

  const { theme: { current, }, } = useSettings();
  const currentTheme = resolveTheme(current);
  const { colors, } = currentTheme;

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <Edit
        title={`Edit "${formState?.name}"`}
        buttons={[
          // {
          //   text: "Submit",
          //   handleClick: submitDevice,
          //   disabled: !validated,
          //   color: "digitialPurple",
          //   variant: "filled"
          // }
        ]}
        formState={formState}
        requiredFields={requiredFields}
        setValidated={setValidated}
      >
        <Grid>
          <Grid.Col sm={12} md={6}>
            <TextInput
              label="Name"
              name="Name"
              placeholder="Enter a name..."
              required={validationRules?.rules?.name?.includes("required")}
              onChange={(e) => {
                setFormState({ ...formState, name: e.currentTarget.value, });
              }}
              value={formState?.name || ""}
            />
          </Grid.Col>
          {hasRole("hyperuser") && (
            <Grid.Col sm={12} md={12}>
              <Checkbox
                label="Available Globally"
                name="global"
                onChange={(e) => {
                  setFormState({
                    ...formState,
                    global: e.currentTarget.checked ? 1 : 0,
                  });
                }}
                required={validationRules?.rules?.global?.includes("required")}
                checked={!!formState?.global}
              />
            </Grid.Col>
          )}
        </Grid>
      </Edit>
      <Default>
        <JSONForm
          title="Peripherals"
          setFields={setPeripherals}
          fields={formState?.peripherals || {}}
        />

        <hr />
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
          }}
        >
          <Button
            color={colors.primary}
            onClick={submitDevice}
            disabled={!validated}
          >
            Submit
          </Button>
        </div>
      </Default>
    </div>
  );
}

export default EditDeviceType;
