import {
  Collection,
  Custodian,
  Kit,
  Matter,
  Organization,
} from "interfaces/main";
import { isEqual, } from "lodash";

export const asyncFilter = async (
  arr: any[],
  // eslint-disable-next-line no-unused-vars
  predicate: (arg0: any) => Promise<boolean>
) => {
  const results = await Promise.all(arr.map(predicate));

  return arr.filter((_v, index) => results[index]);
};

export const htmlAppend = (appendee: string, appender: string) => {
  const dontAddIfAfter = ["<p>", "</p>", "<ul>", "</ul>", "<li>", "</li>"];
  // if the last part of the string is one of the above, dont add the appender
  const dontAdd = dontAddIfAfter.some((item) => appendee.endsWith(item));
  return dontAdd ? appendee : `${appendee} ${appender}`;
};

export const filterDangerousHtml = (html: string) => {
  // remove all html tags except <br>, <p>, <ul>, <li>, <strong>, <em>, <a>
  const allowedTags = [
    "br",
    "p",
    "ul",
    "li",
    "strong",
    "em",
    "a",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6"
  ];
  const regex = new RegExp(`<(?!/?(${allowedTags.join("|")})\\b)[^>]+>`, "g");
  return html.replace(regex, "");
};

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};

export const isEmptyObject = (obj: any) => {
  if (!obj) return true;
  const isEmpty = Object.keys(obj).length === 0 && obj.constructor === Object;
  const isArrayAndEmpty = Array.isArray(obj) && obj.length === 0;
  return isEmpty || isArrayAndEmpty;
};

export const deepObjectCompare = (obj1: any, obj2: any) => {
  const equal = isEqual(obj1, obj2);
  return equal;
};

export const getModuleCompleteness = (
  module: Matter | Custodian | Collection | Organization | any
) => {
  if ("completed_at" in module && module.completed_at) return 100;
  if (!("completed_tasks" in module) || !("total_tasks" in module)) return null;
  const { completed_tasks, total_tasks, } = module;
  if (!total_tasks) return 0;
  const percentage = (completed_tasks / total_tasks) * 100;
  return Math.round(percentage);
};

export const getModuleExclusiveCompleteness = (
  module: Matter | Custodian | Collection | Kit
) => {
  const { tasks_count, completed_tasks_count, } = module;
  if ("completed_at" in module && module.completed_at) return 100;
  if (!tasks_count) return 0;
  const percentage = (completed_tasks_count / tasks_count) * 100;
  return Math.round(percentage);
};

export const getModuleFieldApplicationLogic = (a: any, b: any) => {
  // values can be strings, numbers, dates, arrays, or objects
  // if they are arrays or objects, we won't be able to sort them
  // so we'll just return 0
  if (typeof a === "object" || typeof a === "object") {
    return 0;
  }

  // if they are dates, we'll subtract them
  if (a instanceof Date && b instanceof Date) {
    return a.getTime() - b.getTime();
  }

  // if they are strings, we'll lowercase them, so that we can sort them
  // alphabetically
  if (typeof a === "string" && typeof b === "string") {
    if (a.toLowerCase() < b.toLowerCase()) {
      return -1;
    }
    if (a.toLowerCase() > b.toLowerCase()) {
      return 1;
    }
    return 0;
  }

  // if they are numbers, we'll just subtract them
  if (typeof a === "number" && typeof b === "number") {
    return a - b;
  }

  // if we get here, we don't know how to sort them, so we'll just return 0
  return 0;
};

export const getModuleFields = (
  allowedFields: Map<string, number>,
  allFields: string[]
) => {
  if (!allowedFields) return [];
  if (!allFields) return [];
  return allFields
    .filter((field) => allowedFields.has(field))
    .map((field) => ({
      name: field,
      priority: allowedFields.get(field) || 0,
    }));
};

export const validateEmail = (email: string) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};
