import React, { useEffect, useState, } from "react";
import TabsView from "components/Views/TabsView";
import { DeviceList, DeviceTypeList, } from "pages";
import { useSearchParams, } from "react-router-dom";

type Tabs = "library" | "types"

function index() {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryTab = (searchParams.get("tab") || "library") as Tabs;
  const [tab, setTab] = useState<Tabs>(queryTab);

  useEffect(() => {
    setSearchParams({
      tab,
    });
  }, [tab]);

  const getTabContent = () => {
    switch (tab) {
      case "library":
        return (
          <div>
            <DeviceList />
          </div>
        );
      case "types":
        return (
          <div>
            <DeviceTypeList />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <TabsView
        title="Devices"
        subtitle="Manage your devices and their types"
        tabs={[
          { text: "Library", id: "library", },
          { text: "Types", id: "types", }
        ]}
        setUpperTab={(t: Tabs) => setTab(t)}
        defaultTab={tab}
      >
        {getTabContent()}
      </TabsView>
    </div>
  );
}

export default index;
