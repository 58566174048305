import React, {
  FormEvent, useEffect, useState,
} from "react";
import { AxiosResponse, } from "axios";
import { AlertContextProps, } from "contexts/AlertContext";
import { useAlert, useSettings, useUser, } from "hooks";
import { axios, } from "libs";
import { Validation, } from "interfaces/responses";
import { useNavigate, useParams, } from "react-router-dom";
import { Grid, Select, TextInput, } from "@mantine/core";
import EditView from "components/Views/EditView";
import { User, } from "interfaces/user";
import { resolveTheme, } from "themes/main";
import { UserRoles, } from "contexts/UserContext";
import { Role, } from "../../interfaces/main";
import { UserForm, } from "./users";

function CreateUser(): JSX.Element {
  const alertContext : AlertContextProps = useAlert();

  const [dropdowns, setDropdowns] = useState<{
      roles: Array<Role>;
    }>({
      roles: [],
    });

  const [formData, setFormData] = useState<UserForm>({
    name: "",
    email: "",
    role_id: 0,
  });
  const [editingUser, setEditingUser] = useState<User>();

  const { organization_id, user_id, } = useParams();

  const [validated, setValidated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [validationRules, setValidationRules] = useState<Validation>();
  const [requiredFields, setRequiredFields] = useState<string[]>([]);
  const [editable, setEditable] = useState<boolean>(false);

  const { hasRole, user: currentUser, } = useUser();

  const navigate = useNavigate();
  const onSubmit = (e: FormEvent): void => {
    const form = e.currentTarget as HTMLFormElement;
    if (!form.checkValidity()) {
      setValidated(true);
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    e.preventDefault();
    e.stopPropagation();

    setLoading(true);
    axios.put(`/organizations/${organization_id}/users/${user_id}`, formData).then((res) => {
      const newUser = res.data.data;
      alertContext.setAlert({
        type: "success",
        heading: "Success",
        message: "User edited successfully",
      });
      navigate(`/organizations/${organization_id}/users/${newUser.id}`);
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      alertContext.setAlert({
        type: "danger",
        heading: "Error",
        message: "Error editing user",
      });
      setLoading(false);
    }).finally(() => {
      setLoading(false);
    });
  };

  const onError: Function = (err: AxiosResponse): void => {
    // eslint-disable-next-line no-console
    console.error(err);
    alertContext.setAlert({
      type: "danger",
      heading: "Error",
      message: "Error creating user",
    });
  };

  useEffect(() => {
    setLoading(true);
    axios.get(`/organizations/${organization_id}/users/${user_id}`).then((res) => {
      setFormData(res.data.data);
      setEditingUser(res.data.data);
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      alertContext.setAlert({
        type: "danger",
        heading: "Error",
        message: "Error getting user",
      });
    }).finally(() => {
      setLoading(false);
    });

    setLoading(true);
    axios.get(`/organizations/${organization_id}/users/create`).then((res) => {
      const { validation, } = res.data.data as { validation: Validation };
      setRequiredFields(
        Object.keys(validation.rules).filter((rule) => {
          if (!validation.rules[rule].includes("required")) {
            return false;
          }
          return true;
        })
      );
      setValidationRules(validation);
    }).catch((err) => {
      onError(err);
    });

    setLoading(true);
    axios.get("/roles").then((res) => {
      setDropdowns({
        ...dropdowns,
        roles: res.data.data,
      });
    }).catch((err) => {
      onError(err);
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (editingUser && hasRole(editingUser?.role.name as UserRoles)) {
      setEditable(true);
    }
  }, [editingUser]);

  const { theme: { current, }, } = useSettings();
  const currentTheme = resolveTheme(current);
  const { colors, } = currentTheme;

  return (
    <EditView
      title="User"
      loading={loading}
      formState={formData}
      requiredFields={requiredFields}
      setValidated={setValidated}
      buttons={[
        {
          text: "Submit",
          handleClick: onSubmit,
          color: colors.primary,
          variant: "filled",
          disabled: !validated,
        }
      ]}
    >
      <Grid>
        <Grid.Col sm={12} md={6}>
          <TextInput
            label="Name"
            placeholder="Name"
            required={validationRules?.rules.name.includes("required")}
            value={formData.name}
            onChange={(e) => {
              setFormData({
                ...formData,
                name: e.currentTarget.value,
              });
            }}
            disabled={!editable}
          />
        </Grid.Col>
        <Grid.Col sm={12} md={6}>
          <TextInput
            label="Email"
            placeholder="Email"
            required={validationRules?.rules.email.includes("required")}
            value={formData.email}
            onChange={(e) => {
              setFormData({
                ...formData,
                email: e.currentTarget.value,
              });
            }}
            disabled={!editable}
          />
        </Grid.Col>
        <Grid.Col sm={12} md={6}>
          <Select
            label="Role"
            placeholder="Role"
            required={validationRules?.rules.role_id.includes("required")}
            data={
                dropdowns.roles.map((role) => ({
                  label: role.name,
                  value: role.id?.toString() || "",
                }))
              }
            searchable
            onChange={(value) => {
              setFormData({
                ...formData,
                role_id: Number(value),
              });
            }}
            nothingFound="No roles found"
            value={formData.role_id.toString()}
            disabled={!editable || currentUser.id === editingUser?.id}
          />
        </Grid.Col>
      </Grid>
    </EditView>
  );
}
export default CreateUser;
