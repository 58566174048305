import React, { useState, } from "react";
import TabsView from "components/Views/TabsView";
import Profile from "pages/Profile/Profile";
import Settings from "pages/Profile/Settings";
import YourOrganization from "pages/Organizations/YourOrganization";

type Tabs = "profile" | "organization" | "app"

function index() {
  const [tab, setTab] = useState<Tabs>("profile");

  const getCurrentContent = () => {
    switch (tab) {
      case "profile":
        return (
          <div>
            <Profile />
          </div>
        );
      case "organization":
        return (
          <div>
            <YourOrganization />
          </div>
        );
      case "app":
        return (
          <div>
            <Settings />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <TabsView
        title="Settings"
        subtitle="Manage your profile, organization, and app settings"
        tabs={[
          {
            text: "Profile",
            id: "profile",
          },
          {
            text: "Your Organization",
            id: "organization",
          },
          {
            text: "App",
            id: "app",
          }
        ]}
        defaultTab={tab}
        setUpperTab={(t: Tabs) => setTab(t)}
      >
        {getCurrentContent()}
      </TabsView>
    </div>
  );
}

export default index;
