import React from "react";
import { useLiveQuery, } from "dexie-react-hooks";
import { List, } from "components/Views";
import InfoCard from "components/InfoCard/InfoCard";
import { Grid, Text, } from "@mantine/core";
import { db, } from "db/db";
import { useSettings, } from "hooks";
import { resolveTheme, } from "themes/main";

function Offline() {
  const collections = useLiveQuery(() => db.collections.toArray());

  const { theme: { current: theme, }, } = useSettings();
  const currentTheme = resolveTheme(theme);

  return (
    <div>
      <List
        title="Collections"
        table_name="collections"
      >
        <Grid>
          {collections?.length ? (
            collections.map((collection) => (
              <Grid.Col
                span={12}
                key={collection.id}
              >
                <InfoCard
                  title={collection.name}
                  subtitle={`Matter ID: ${collection.matter_id}`}
                  endpoint={`/collections/${collection.id}`}
                />
              </Grid.Col>
            ))
          ) : (
            <Grid.Col span={12}>
              <Text
                size="md"
                weight="300"
                color={currentTheme.colors.textLight}
                align="center"
              >
                You have no collections.
              </Text>
            </Grid.Col>
          )}
        </Grid>
      </List>
    </div>
  );
}

export default Offline;
