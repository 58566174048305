import {
  Button, Grid, Group, Select, TextInput,
} from "@mantine/core";
import React, { useEffect, useState, } from "react";
import {
  Matter as MatterDefinition,
  Organization,
  Template,
} from "interfaces/main";
import { axios, } from "libs";
import { useAlert, useUser, } from "hooks";
import { getFormattedMatterName, } from "utils/formatting";
import { Validation, } from "interfaces/responses";
import InlineLoader from "components/Loading/Inline";

function Matter({
  markComplete,
  invalidate,
}: {
  // eslint-disable-next-line no-unused-vars
  markComplete: (data: MatterDefinition) => void;
  invalidate: () => void;
}) {
  const [matters, setMatters] = useState<MatterDefinition[]>([]);
  const [selectedMatter, setSelectedMatter] = useState<string | null>(null);
  const isNew = selectedMatter === "new";

  const { setAlert, } = useAlert();

  const loadMatters = async () => axios
    .get("/matters")
    .then((res) => {
      setMatters(res.data.data);
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      setAlert({
        type: "danger",
        message: "Error fetching matters",
      });
    });

  useEffect(() => {
    loadMatters();
  }, []);

  const mattersMapped = matters.map((matter) => ({
    label: getFormattedMatterName(matter),
    value: String(matter.id),
  }));

  const afterNewMatterSubmit = async (newMatter: MatterDefinition) => {
    await loadMatters();
    setSelectedMatter(String(newMatter.id));
    markComplete(newMatter);
  };

  useEffect(() => {
    if (selectedMatter === "new" || !selectedMatter) {
      invalidate();
      return;
    }
    const matter = matters.find((m) => String(m.id) === selectedMatter);
    if (!matter) {
      setAlert({
        type: "danger",
        message: "Please select a valid matter",
      });
      return;
    }
    markComplete(matter);
  }, [selectedMatter]);

  return (
    <div>
      <Grid>
        <Grid.Col sm={12} md={6} lg={4}>
          <Select
            label="Select a Matter"
            data={[
              {
                label: "Create Matter",
                value: "new",
              },
              ...mattersMapped
            ]}
            value={selectedMatter}
            onChange={(value) => setSelectedMatter(value)}
            placeholder="Select a matter..."
            searchable
          />
        </Grid.Col>
        {isNew && <NewMatter afterSubmit={afterNewMatterSubmit} />}
      </Grid>
    </div>
  );
}

export default Matter;

interface NewMatterProps {
  // eslint-disable-next-line no-unused-vars
  afterSubmit: (newMatter: MatterDefinition) => void;
}

function NewMatter({ afterSubmit, }: NewMatterProps) {
  const [FormData, setFormData] = useState({
    name: "",
    code: "",
  });
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [validationRules, setValidationRules] = useState<Validation>();
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [template_id, setTemplateId] = useState<number | null>(null);

  const { getUserOrganization, hasRole, } = useUser();
  const { setAlert, } = useAlert();

  const [organizationIdToUse, setOrganizationIdToUse] = useState<
    string | undefined
  >(String(getUserOrganization()?.id));

  useEffect(() => {
    setLoading(true);
    axios
      .get("/organizations")
      .then((res) => {
        setOrganizations(res.data.data);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        setAlert({
          type: "danger",
          message: "Error fetching organizations",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!organizationIdToUse) {
      return;
    }

    setLoading(true);
    axios
      .get(`/organizations/${organizationIdToUse}/templates/type/matters`)
      .then((res) => {
        setTemplates(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));

    setLoading(true);
    axios
      .get(`/organizations/${organizationIdToUse}/matters/create`)
      .then((res) => {
        const { validation, } = res.data.data as { validation: Validation };
        setValidationRules(validation);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [organizationIdToUse]);

  const onSubmit = () => {
    setLoading(true);
    axios
      .post(`/organizations/${organizationIdToUse}/matters`, {
        name: FormData.name,
        code: FormData.code,
      })
      .then((res) => {
        const newMatter = res.data.data;
        setAlert({
          type: "success",
          heading: "Success",
          message: "Matter created successfully",
        });
        if (template_id) {
          assignTemplate(newMatter.id);
        }
        afterSubmit(newMatter);
      })
      .catch((err) => {
        console.error(err);
        setAlert({
          type: "danger",
          heading: "Error",
          message: "Error creating matter",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const assignTemplate = (matter_id: number) => {
    setLoading(true);
    axios
      .post(
        `/organizations/${organizationIdToUse}/matters/${matter_id}/template`,
        {
          template_id,
        }
      )
      .then(() => {
        setAlert({
          type: "success",
          heading: "Success",
          message: "Template assigned successfully",
        });
      })
      .catch((err) => {
        console.error(err);
        setAlert({
          type: "danger",
          heading: "Error",
          message: "Error assigning template",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formattedTemplates = templates.map((temp) => ({
    label: temp.name,
    value: temp.id.toString(),
  }));

  return (
    <>
      {hasRole("superuser") && (
        <Grid.Col sm={12} md={6} lg={4}>
          <Select
            label="Organization"
            name="organization_id"
            required={validationRules?.rules?.template_id?.includes("required")}
            placeholder="Select Organization"
            data={[
              ...organizations.map((o) => ({
                label: o.name,
                value: o.id.toString(),
              }))
            ]}
            onChange={(value) => {
              if (value) {
                setOrganizationIdToUse(value);
              }
            }}
            value={organizationIdToUse?.toString()}
            searchable
          />
        </Grid.Col>
      )}

      <Grid.Col sm={12} md={6} lg={4}>
        <TextInput
          label="Name"
          name="name"
          onChange={(e) => {
            setFormData({ ...FormData, name: e.currentTarget.value, });
          }}
          required={validationRules?.rules?.name?.includes("required")}
          disabled={!organizationIdToUse}
          placeholder="Enter Matter Name"
        />
      </Grid.Col>
      <Grid.Col sm={12} md={6} lg={4}>
        <TextInput
          label="Code"
          name="code"
          onChange={(e) => {
            setFormData({ ...FormData, code: e.currentTarget.value, });
          }}
          required={validationRules?.rules?.code?.includes("required")}
          disabled={!organizationIdToUse}
          placeholder="Enter Matter Code"
        />
      </Grid.Col>
      <Grid.Col sm={12} md={6} lg={4}>
        <Select
          label="Template"
          name="template_id"
          required={validationRules?.rules?.template_id?.includes("required")}
          data={formattedTemplates}
          disabled={!organizationIdToUse}
          placeholder="Select Template"
          onChange={(value) => {
            if (value) {
              setTemplateId(parseInt(value, 10));
            }
          }}
          searchable
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <Group position="right">
          <Button onClick={onSubmit} variant="default">Create New Matter</Button>
          {loading && <InlineLoader />}
        </Group>
      </Grid.Col>
    </>
  );
}
