import React, { useEffect, useState, } from "react";
import { useNavigate, useParams, } from "react-router-dom";
import { Loading, } from "components";
import { AxiosResponse, } from "axios";
import { axios, } from "libs";
import { useUser, useSearch, } from "hooks";
import { User, } from "interfaces/user";
import IntegratedListView from "components/Views/IntegratedListView";
import { getFormattedTimeString, } from "utils/formatting";
import { getModuleFields, } from "utils/methods";

function UserList(): JSX.Element {
  const [users, setUsers] = useState<Array<User>>([]);
  const [loading, setLoading] = useState(true);
  const onSuccess = (res: AxiosResponse): void => {
    setUsers(res.data.data);
  };
  const { organization_id, } = useParams();
  const { hasRole, } = useUser();
  const { getTable, } = useSearch();
  const { sort, } = getTable("users");

  const onError = (err: any): void => {
    // eslint-disable-next-line no-console
    console.error(err);
  };

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/organizations/${organization_id}/users`)
      .then(onSuccess)
      .catch(onError)
      .finally(() => setLoading(false));
  }, []);

  const allFields: (keyof User)[] | undefined =
    users?.[0] && (Object.keys(users[0]) as unknown as (keyof User)[]);

  const allowedFields = new Map([
    ["id", 0],
    ["name", 0],
    ["email", 0],
    ["email_verified_at", 0],
    ["role_id", 0],
    ["password_updated_at", 0],
    ["created_at", 0],
    ["updated_at", 0]
  ]);

  const fields = getModuleFields(allowedFields, allFields);

  const getSubtitle = (u: User) => {
    if (!sort.field) {
      return u.updated_at ?
        `Last update at ${getFormattedTimeString(u.updated_at)}` :
        `Created at ${getFormattedTimeString(u.created_at)}`;
    }
    return `${sort.field}: ${u[sort.field as keyof User]}`;
  };

  const listItems = users.map((usr) => ({
    id: usr.id,
    title: usr.name,
    subtitle: getSubtitle(usr),
    endpoint: `/organizations/${organization_id}/users/${usr.id}`,
    full_record: usr,
  }));

  if (loading) {
    return <Loading />;
  }

  return (
    <IntegratedListView
      title="Users"
      loading={loading}
      buttons={[
        {
          text: "",
          icon: "add",
          handleClick: () => navigate(`/organizations/${organization_id}/users/create`),
          color: "primary",
          variant: "default",
          invisible: !hasRole("poweruser"),
        }
      ]}
      search
      table_name="users"
      fields={fields}
      full_record_list={users}
      list_items={listItems}
    />
  );
}

export default UserList;
