import React, { useState, } from "react";
/** @jsxImportSource @emotion/react */
import { css, } from "@emotion/react";
import { useNavigate, } from "react-router-dom";
import { LoadingOverlay, SegmentedControl, NativeSelect, } from "@mantine/core";
import { useAlert, useSettings, } from "hooks";
import { StatusOptions, Task as TaskDefinition, } from "interfaces/main";
import { axios, } from "libs";
import { resolveTheme, } from "themes/main";

interface TaskCardTask extends TaskDefinition {
  link: string;
  endpoint?: string;
}
interface SpecialTask extends TaskDefinition {
  link: string;
  endpoint: string;
}
interface TaskCardProps {
  task: TaskCardTask;
  editable?: boolean;
  handleDragStart?: (
    // eslint-disable-next-line no-unused-vars
    e: React.DragEvent<HTMLDivElement>,
    // eslint-disable-next-line no-unused-vars
    position: number,
  ) => void;
  handleDragEnd?: (
    // eslint-disable-next-line no-unused-vars
    e: React.DragEvent<HTMLDivElement>,
    // eslint-disable-next-line no-unused-vars
    position: number,
  ) => void;
  handleDragEnter?: (
    // eslint-disable-next-line no-unused-vars
    e: React.DragEvent<HTMLDivElement>,
    // eslint-disable-next-line no-unused-vars
    position: number,
  ) => void;
  // eslint-disable-next-line react/require-default-props
  refreshTasks?: () => void;
  // eslint-disable-next-line no-unused-vars
  handleReorder?: (from: number, to: number) => void;
  disabled?: boolean;
  handleContextMenu?: (
    // eslint-disable-next-line no-unused-vars
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    // eslint-disable-next-line no-unused-vars
    task: SpecialTask,
  ) => void;
}

TaskCard.defaultProps = {
  editable: false,
  handleDragStart: undefined,
  handleDragEnd: undefined,
  handleDragEnter: undefined,
  refreshTasks: undefined,
  handleReorder: undefined,
  disabled: false,
  handleContextMenu: undefined,
};

function TaskCard({
  task,
  editable,
  handleDragStart,
  handleDragEnd,
  handleDragEnter,
  refreshTasks,
  handleReorder,
  disabled,
  handleContextMenu,
}: TaskCardProps) {
  const [loading, setLoading] = useState(false);
  const { description, status, } = task;
  const navigate = useNavigate();
  const { setAlert, } = useAlert();
  const [over, setOver] = useState(false);

  const handleClick = () => {
    navigate(task.link);
  };

  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);

  const isMobile = window.innerWidth < 768;

  const statusOptions: StatusOptions[] = [
    {
      label: "N/A",
      value: 4,
    },
    {
      label: "Cancelled",
      value: 3,
    },
    {
      label: "Open",
      value: 0,
    },
    {
      label: "In Progress",
      value: 1,
    },
    {
      label: "Completed",
      value: 2,
    }
  ];

  const statusOptionsMap = statusOptions.map((option) => ({
    label: option.label,
    value: option.value.toString(),
  }));

  const setTaskStatus = (stat: number) => {
    if (!task.endpoint) {
      return;
    }
    setLoading(true);
    axios
      .put(task.endpoint, { status: stat, })
      .then(() => {
        if (refreshTasks) {
          refreshTasks();
        }
      })
      .catch((err) => {
        console.error(err);
        setAlert({
          message: "There was a problem completing the task",
          type: "danger",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const dragStart = (
    e: React.DragEvent<HTMLDivElement>,
    position: number | string
  ) => {
    if (handleDragStart) {
      handleDragStart(e, Number(position));
    }
  };

  const dragEnd = (
    e: React.DragEvent<HTMLDivElement>,
    position: number | string
  ) => {
    if (handleDragEnd) {
      handleDragEnd(e, Number(position));
    }
  };

  const dragEnter = (
    e: React.DragEvent<HTMLDivElement>,
    position: number | string
  ) => {
    if (handleDragEnter) {
      handleDragEnter(e, Number(position));
      setOver(true);
    }
  };

  const dragLeave = () => {
    setOver(false);
  };

  const statusToColor: {
    [key: number]: string;
  } = {
    0: currentTheme.colors.primary,
    1: `${currentTheme.colors.primary}90`,
    2: currentTheme.colors.background,
    3: `${currentTheme.colors.textLight}90`,
    4: `${currentTheme.colors.background}90`,
  };

  const statusToBorderWidth: {
    [key: number]: number;
  } = {
    0: 1,
    1: 0.5,
    2: 0,
    3: 0,
    4: 0,
  };

  const statusToBackgroundColor: {
    [key: number]: string;
  } = {
    0: `${currentTheme.colors.background}`,
    1: `${currentTheme.colors.background}`,
    2: `${currentTheme.colors.background}`,
    3: `${currentTheme.colors.backgroundAlt}`,
    4: `${currentTheme.colors.background}90`,
  };

  const recentNote = task.notes ? task.notes[task.notes.length - 1] : undefined;

  const getTrimmedRecentNote = (str: string) => {
    if (str.length > 25 && isMobile) {
      return `${str.substring(0, 25)}...`;
    }
    if (str.length > 50 && !isMobile) {
      return `${str.substring(0, 50)}...`;
    }
    return str;
  };

  return (
    <div
      style={
        over ?
          {
            padding: "10px",
            border: `1px solid ${currentTheme.colors.primary}90`,
            backgroundColor: `${currentTheme.colors.primary}70`,
            borderRadius: "4px",
          } :
          {}
      }
      css={css`
        transition: all 0.4s ease-in-out;
      `}
      onDragLeave={dragLeave}
    >
      <div
        css={css`
          background-color: ${currentTheme.colors.background};
          width: 100%;
          border-radius: 10px;
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
          padding: 14px 18px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          transition: all 0.2s ease-in-out;
          // margin: 14px 0;
          position: relative;

          @media (min-width: 768px) {
            flex-direction: row;
          }

          &:hover {
            box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);
            transform: scale(0.992);
          }

          .title {
            font-size: 15px;
            font-weight: 400;
            font-family: "Roboto", sans-serif;
          }

          @keyframes loading {
            0% {
              background-color: ${currentTheme.colors.background};
            }
            50% {
              background-color: ${currentTheme.colors.backgroundContrast};
            }
            100% {
              background-color: ${currentTheme.colors.background};
            }
          }
        `}
        style={{
          outline: `${statusToBorderWidth[status] || 1}px solid ${
            statusToColor[status] || currentTheme.colors.primary
          }`,
          animation: loading ? "loading 1s infinite" : "none",
          cursor: editable ? "grab" : "pointer",
          backgroundColor: statusToBackgroundColor[status],
        }}
        role="button"
        tabIndex={0}
        onKeyDown={handleClick}
        onClick={handleClick}
        draggable={
          editable && !!handleDragStart && !!handleDragEnd && !!handleDragEnter
        }
        onDragStart={(e) => {
          if (disabled) return;
          dragStart(e, task.sort_order);
        }}
        onDragEnd={(e) => {
          if (disabled) return;
          dragEnd(e, task.sort_order);
        }}
        onDragEnter={(e) => {
          if (disabled) return;
          dragEnter(e, task.sort_order);
        }}
        onContextMenu={(e) => {
          if (disabled) return;
          if (handleContextMenu) {
            if (!task.endpoint) return;
            handleContextMenu(e, task as SpecialTask);
          }
        }}
      >
        <LoadingOverlay visible={loading} overlayBlur={2} />
        <div
          css={css`
            display: flex;
            align-items: center;
            flex-direction: row ${handleReorder ? "-reverse" : ""};
            justify-content: space-between;
            width: 100%;
            color: ${currentTheme.colors.text};

            @media (min-width: 768px) {
              flex-direction: row;
              width: initial;
            }
          `}
        >
          {handleReorder && (
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                margin-bottom: 10px;

                @media (min-width: 768px) {
                  margin-bottom: 14px;
                  margin-right: 13px;
                  margin-bottom: 0;
                }

                .sort-button {
                  padding: 4px;
                  border-radius: 5px;
                  font-size: 16px;
                  transition: all 0.2s ease-in-out;
                  ${disabled ? "cursor: not-allowed;" : "cursor: pointer;"}
                  ${disabled && "opacity: 0.5;"}

                &:hover {
                    ${!disabled &&
                    `background-color: ${currentTheme.colors.primary};`}
                  }
                }
              `}
            >
              <i
                className="material-icons sort-button"
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (disabled) return;
                  if (e.key === "Enter") {
                    handleReorder(task.sort_order, task.sort_order - 1);
                  }
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (disabled) return;
                  handleReorder(task.sort_order, task.sort_order - 1);
                }}
              >
                expand_less
              </i>
              <i
                className="material-icons sort-button"
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (disabled) return;
                  if (e.key === "Enter") {
                    handleReorder(task.sort_order, task.sort_order + 1);
                  }
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (disabled) return;
                  handleReorder(task.sort_order, task.sort_order + 1);
                }}
              >
                expand_more
              </i>
            </div>
          )}
          <div className="text">
            <div
              className="sort-buttons"
              css={css`
                display: flex;
                flex-direction: column;
              `}
            >
              <div
                className="title"
                style={
                  {
                    // textDecoration: task.status === 2 ? "line-through 1.2px" : "none"
                  }
                }
              >
                {description}
              </div>
              <div
                css={css`
                  font-size: 12px;
                  color: ${currentTheme.colors.textLight};
                `}
              >
                {recentNote && getTrimmedRecentNote(recentNote.note)}
              </div>
            </div>
          </div>
        </div>

        {editable && (
          <div
            css={css`
              cursor: pointer;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 100%;

              @media (min-width: 768px) {
                flex-direction: row;
                width: initial;
              }
            `}
          >
            {isMobile ? (
              <NativeSelect
                data={statusOptions.map((option) => ({
                  value: option.value.toString(),
                  label: option.label,
                }))}
                defaultValue={status}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={(e) => {
                  setTaskStatus(Number(e.target.value));
                }}
                style={{
                  width: "100%",
                }}
                disabled={disabled}
              />
            ) : (
              <SegmentedControl
                data={statusOptionsMap}
                value={task?.status?.toString() || "0"}
                onChange={(val) => {
                  setTaskStatus(parseInt(val, 10));
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                size="xs"
                disabled={disabled}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default TaskCard;
