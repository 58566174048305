import React from "react";
import { X, Question, } from "phosphor-react";
import { useSettings, } from "hooks";
import { resolveTheme, } from "themes/main";
/** @jsxImportSource @emotion/react */
import { css, } from "@emotion/react";

interface ToolTipCardProps {
  title: string;
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
}

function ToolTipCard({
  title,
  children,
  open,
  onClose,
  onOpen,
}: ToolTipCardProps) {
  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);
  const { colors, } = currentTheme;

  return (
    <div css={css`
        position: relative;
        width: 100%;
    `}
    >
      <button
        css={css`
          background-color: transparent;
          border: none;
          outline: none;
          cursor: pointer;
          transition: all 0.2s ease-in-out;
          height: 24px;
          width: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: ${currentTheme.colors.textLight}80;

          &:hover {
            color: ${currentTheme.colors.textLight};
          }
        `}
        type="button"
        title={title}
        onClick={onOpen}
      >
        <Question />
      </button>
      {open && (
        <div
          css={css`
            color: ${colors.text};
            background-color: ${colors.backgroundAlt};
            padding: 24px;
            box-shadow: 0.2px 0.2px 10px rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            position: absolute;
            top: 100%;
            right: 0;
            width: 100vw;

            @media (min-width: 768px) {
                width: 400px;
            }

            .header {
              font-size: 24px;
              font-weight: 400;
              padding-bottom: 14px;
              border-bottom: 1px solid ${colors.backgroundContrast};
              margin-bottom: 24px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 24px;

              h3 {
                font-size: 24px;
                margin: 0;
                padding: 0;
              }

              button {
                background-color: transparent;
                border: none;
                outline: none;
                cursor: pointer;
                transition: all 0.2s ease-in-out;
                height: 36px;
                width: 36px;
                font-size: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: ${currentTheme.colors.textLight}80;

                &:hover {
                  color: ${currentTheme.colors.textLight};
                }
              }
            }
          `}
        >
          <div className="header">
            <h3>{title}</h3>
            <button type="button" onClick={onClose}>
              <X />
            </button>
          </div>
          <div>{children}</div>
        </div>
      )}
    </div>
  );
}

export default ToolTipCard;
