import React, { useEffect, useRef, useState, } from "react";
/** @jsxImportSource @emotion/react */
import { css, } from "@emotion/react";
import {
  Burger, Button, Menu, useMantineTheme,
} from "@mantine/core";
import {
  Link, useLocation, useNavigate, useParams,
} from "react-router-dom";
import { useSettings, useUser, } from "hooks";
import { Online, Offline, Detector, } from "components/Conditionals";
import { DotsThree, WifiHigh, WifiSlash, } from "phosphor-react";
import DsLogo from "assets/digital-strata-header-logo.png";
import { resolveTheme, } from "themes/main";

function Sidebar() {
  const { pathname, } = useLocation();
  // eslint-disable-next-line no-unused-vars
  const [open, setOpen] = useState(false);
  const navbarRef = useRef<HTMLDivElement>(null);
  const { colors, } = useMantineTheme();
  const {
    user: { organizations, },
  } = useUser();
  const organization_id = organizations[0]?.id || "";
  const {
    theme: { current: theme, },
  } = useSettings();
  const { organization_id: org_id, } = useParams();

  type allowedActive =
    | "your-org"
    | "users"
    | "organizations"
    | "home"
    | "templates"
    | "devices"
    | "matters"
    | "email-templates";

  const extractActive = (path: string): allowedActive => {
    if (org_id && String(org_id) !== String(organization_id)) {
      return "organizations";
    }

    if (path.includes("users")) {
      return "users";
    }

    if (path.includes("email-templates")) {
      return "email-templates";
    }

    if (path.includes("templates")) {
      return "templates";
    }

    if (path.includes("devices") || path.includes("device-types")) {
      return "devices";
    }

    if (path.includes("matters")) {
      return "matters";
    }

    if (path.includes(`organizations/${organization_id}`)) {
      return "your-org";
    }

    if (path.includes("organizations")) {
      return "organizations";
    }

    return "home";
  };

  const [active, setActive] = useState<allowedActive>(extractActive(pathname));

  // eslint-disable-next-line no-unused-vars
  const { user, hasRole, removeUser, } = useUser();

  const main_org_id = user?.organizations[0]?.id || "";

  useEffect(() => {
    if (navbarRef.current && window.innerWidth < 768) {
      navbarRef.current.style.display = open ? "flex" : "none";
    }
  }, [open]);

  useEffect(() => {
    setActive(extractActive(pathname));
  }, [pathname]);

  // eslint-disable-next-line no-unused-vars
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  window.onresize = () => {
    setScreenWidth(window.innerWidth);
    setScreenHeight(window.innerHeight);
  };

  const isSmallerHeight = screenHeight < 800;

  const navigate = useNavigate();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div
      css={css`
        position: ${open && "fixed"};
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 5;

        .navbar-toggle {
          position: fixed;
          top: 0;
          right: 0;
          width: 56px;
          height: 56px;
          padding: 14px 10px;
          z-index: 31;
          display: block;
          text-align: right;

          @media (min-width: 768px) {
            display: none;
          }
        }

        .navbar {
          display: flex;
          position: fixed;
          left: 0;
          top: 56px;
          right: 0;
          background-color: #fff;
          padding-block: 36px;
          padding-inline: 14px;
          z-index: 30;
          flex-direction: column;
          background-color: #fff;
          height: calc(100vh - 56px);
          justify-content: flex-start;

          @media (min-width: 768px) {
            // border-right: 1px solid #eaeaea;
            top: 0;
            width: 200px;
            left: 0;
            height: 100vh;
            box-shadow: 0.2px 0 56px rgba(0, 0, 0, 0.1);
          }

          @media (min-width: 1024px) {
            width: 250px;
          }
        }

        .section {
          width: 100%;
          // margin-bottom: 36px;

          &:last-of-type {
            margin-bottom: 0;
            margin-top: auto;
          }
        }

        .header {
          position: fixed;
          top: 14px;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 0 24px;
          font-family: "Roboto", sans-serif;
          font-weight: 400;
          font-size: 18px;
          background-color: ${resolveTheme(theme).colors.background};

          @media (min-width: 768px) {
            position: initial;
            margin-bottom: 36px;
          }
        }
      `}
    >
      <div className="navbar-toggle">
        <Burger
          opened={open}
          onClick={() => {
            setOpen(!open);
          }}
        />
      </div>

      <div
        className="navbar"
        ref={navbarRef}
        style={{
          backgroundColor: resolveTheme(theme).colors.background,
        }}
      >
        <div className="section header">
          <div>
            <a href="https://digital-strata.com/">
              <img
                src={DsLogo}
                alt="Digital Strata"
                css={css`
                  width: 35%;
                  background-color: ${resolveTheme(theme).colors.background};

                  @media (min-width: 768px) {
                    width: 100%;
                  }
                `}
              />
            </a>
          </div>
        </div>
        {/* <hr style={{ width: "100%" }} /> */}
        <Online className="section">
          <NavItem
            label="Home"
            to="/home"
            onClick={() => setOpen(false)}
            active={pathname === "/home"}
            icon="home"
          />
          {hasRole("poweruser") && (
            <NavItem
              label="Users"
              onClick={() => {
                setOpen(false);
              }}
              to={`/organizations/${main_org_id}/users`}
              active={active === "users"}
              icon="group"
            />
          )}
          <NavItem
            label="Matters"
            onClick={() => {
              setOpen(false);
            }}
            to={`/organizations/${main_org_id}/matters`}
            active={active === "matters"}
            icon="inventory_2"
          />
          <NavItem
            label="Templates"
            onClick={() => {
              setOpen(false);
            }}
            to={`/organizations/${main_org_id}/templates`}
            active={active === "templates"}
            icon="assignment"
          />
          <NavItem
            label="Devices"
            onClick={() => {
              setOpen(false);
            }}
            to={`/organizations/${main_org_id}/devices`}
            active={active === "devices"}
            icon="devices"
          />
          <NavItem
            label="Emails"
            onClick={() => {
              setOpen(false);
            }}
            to={`/organizations/${main_org_id}/email-templates`}
            active={active === "email-templates"}
            icon="mail"
          />
          {hasRole("superuser") && (
            <NavItem
              label="Organizations"
              onClick={() => {
                setOpen(false);
              }}
              to="/organizations"
              active={active === "organizations"}
              icon="domain"
            />
          )}
        </Online>
        <Offline className="section">
          <NavItem
            label="Home"
            to="/home"
            onClick={() => setOpen(false)}
            active={pathname === "/home"}
            icon="home"
          />
          <NavItem
            label="Collections"
            onClick={() => {
              setOpen(false);
            }}
            to="/collections/"
            active={pathname === "/collections/"}
            icon="library_add"
          />
        </Offline>
        <div
          className="section"
          style={{
            position: "relative",
          }}
        >
          {isSmallerHeight ? (
            <Menu
              width="100%"
              shadow="md"
              position="top-start"
              opened={dropdownOpen}
              onChange={setDropdownOpen}
              closeOnClickOutside
              closeOnEscape
              closeOnItemClick
              withArrow
            >
              <Menu.Target>
                <Button
                  variant="subtle"
                  style={{
                    marginLeft: "24px",
                    boxSizing: "border-box",
                    width: "calc(100% - 48px)",
                  }}
                  leftIcon={(
                    <DotsThree
                      size={24}
                      style={{
                        position: "absolute",
                        left: "0px",
                        top: "50%",
                        transform: "translateY(-50%)",
                      }}
                    />
                  )}
                  styles={{
                    inner: {
                      position: "relative",
                    },
                  }}
                >
                  More
                </Button>
              </Menu.Target>
              <Menu.Dropdown>
                <Detector
                  render={(online) => (!online ? (
                    <Menu.Item icon={<WifiSlash size={24} />}>
                      Offline
                    </Menu.Item>
                  ) : null)}
                />
                <Menu.Item
                  icon={<i className="material-symbols-outlined">settings</i>}
                  onClick={() => {
                    setDropdownOpen(false);
                    navigate("/settings");
                  }}
                >
                  Settings
                </Menu.Item>
                <Menu.Item
                  icon={<i className="material-symbols-outlined">logout</i>}
                  onClick={() => {
                    setDropdownOpen(false);
                    removeUser();
                  }}
                >
                  Logout
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          ) : (
            <>
              <Detector
                render={(online) => (online ? (
                  <div
                    css={css`
                        display: none;

                        @media (min-width: 768px) {
                          display: flex;
                          font-size: 16px;
                          font-weight: 300;
                          color: ${colors.green[6]};
                          padding: 0 24px;
                          align-items: center;

                          span {
                            margin-left: 8px;
                          }
                        }
                      `}
                  >
                    <WifiHigh size={24} weight="regular" />
                    <span>Online</span>
                  </div>
                ) : (
                  <div
                    className="ph-wifi-slash"
                    css={css`
                        display: none;

                        @media (min-width: 768px) {
                          display: flex;
                          font-size: 16px;
                          font-weight: 300;
                          color: ${colors.red[6]};
                          padding: 0 24px;
                          align-items: center;

                          span {
                            margin-left: 8px;
                          }
                        }
                      `}
                  >
                    <WifiSlash size={24} />
                    <span>Offline</span>
                  </div>
                ))}
              />
              <hr />
              <NavItem
                label="Settings"
                to="/settings"
                onClick={() => setOpen(false)}
                active={pathname === "/settings"}
                icon="settings"
              />
              <NavItem
                onClick={() => {
                  removeUser();
                }}
                to="/"
                label="Logout"
                icon="logout"
                active={false}
              />
            </>
          )}
          <div>
            <p
              css={css`
                font-size: 12px;
                font-weight: 300;
                text-align: center;
                margin-top: 36px;
                margin-bottom: 0;
                padding: 0;
              `}
              style={{
                color: resolveTheme(theme).colors.text,
              }}
            >
              Powered by
              {" "}
              <a
                href="https://www.nwappworks.com/"
                target="_blank"
                rel="noreferrer"
                style={{
                  color: resolveTheme(theme).colors.link,
                }}
              >
                Northwest Appworks
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;

interface NavItemProps {
  label: string;
  onClick: () => void;
  active: boolean;
  to: string;
  icon?: string | JSX.Element | null;
  iconProvider?: "material" | "phosphor";
}

NavItem.defaultProps = {
  icon: null,
  iconProvider: "material",
};

function NavItem({
  label,
  onClick,
  active,
  to,
  icon,
  iconProvider,
}: NavItemProps) {
  const { colors, } = useMantineTheme();
  const {
    theme: { current: theme, },
  } = useSettings();

  const styles = css`
    text-decoration: none;
    display: flex;
    margin-bottom: 14px;
    box-sizing: border-box;
    border-radius: 10px;

    .nav-link {
      display: flex;
      align-items: center;
      padding: 8px 24px;
      width: 100%;
      font-family: "Inter", sans-serif;
      font-weight: 300;
      box-sizing: border-box;
      font-size: 16px;
      border-radius: 10px;
      transition: all 0.4s ease;
      &:hover {
        transform: scale(0.98);
      }

      &.light {
        color: ${colors.dark[9]};

        &:hover {
          background-color: ${resolveTheme(theme).colors.backgroundAlt};
        }
      }

      &.dark {
        color: white;

        &:hover {
          background-color: ${resolveTheme(theme).colors.backgroundAlt};
        }
      }

      &:active {
        transform: scale(0.96) translateY(1px);
      }

      &.active {
        transition: background-color 0.2s ease-in;

        &.light {
          background-color: #f2f2f2;
          outline: 1px solid #eaeaea;
        }

        &.dark {
          background-color: #2d2d2d;
          outline: 1px solid #3d3d3d;
        }
      }

      .icon {
        margin-right: 8px;
        font-size: 16px;

        &.light {
          color: ${colors.digitalPurple[6]};
        }

        &.dark {
          color: white;
        }
      }
    }
  `;

  return (
    <div css={styles} className={`${theme}`}>
      <Link
        to={to}
        onClick={onClick}
        className={`nav-link ${active && "active"} ${theme}`}
        tabIndex={0}
      >
        {icon && iconProvider === "material" ? (
          <span className={`icon material-icons ${theme}`}>{icon || null}</span>
        ) : (
          icon
        )}
        <span className={`label ${theme}`}>{label}</span>
      </Link>
    </div>
  );
}
