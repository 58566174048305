/* eslint-disable no-unused-vars */
import { Grid, } from "@mantine/core";
import { Device, Kit, Matter, } from "interfaces/main";
import React, { useEffect, useState, } from "react";
import KitManager from "components/KitManager/KitManager";
import { axios, } from "libs";
import { useAlert, } from "hooks";

function DeviceAssignment({
  markComplete,
  invalidate,
  matter,
  kit,
}: {
  markComplete: (args: undefined) => void;
  invalidate: () => void;
  matter: Matter;
  kit: Kit;
}) {
  const [data, setData] = useState<Kit | null>(null);
  const [devices, setDevices] = useState<Device[]>([]);
  const disabled = !!kit?.shipments.some((s) => !!s.tracking_number);

  const { setAlert, } = useAlert();

  const getKit = () => axios
    .get(
      `/organizations/${matter.organization_id}/matters/${matter.id}/kits/${kit.id}`
    )
    .then((res) => {
      setData(res.data.data);
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      setAlert({
        type: "danger",
        message: "Error fetching kit",
      });
    });

  useEffect(() => {
    setData(kit);
  }, [kit]);

  const assignDevicesToKit = (device_ids: number[]) => axios
    .post(
      `/organizations/${matter.organization_id}/matters/${matter.id}/kits/${kit.id}/assign/devices`,
      {
        attach: [...device_ids],
      }
    )
    .then(() => {
      setAlert({
        type: "success",
        message: "Device assigned to kit successfully",
      });
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      setAlert({
        type: "danger",
        message: "Error assigning device to kit",
      });
    });

  const unassignDevicesFromKit = (device_ids: number[]) => axios
    .post(
      `/organizations/${matter.organization_id}/matters/${matter.id}/kits/${kit.id}/assign/devices`,
      {
        detach: [...device_ids],
      }
    )
    .then(() => {
      setAlert({
        type: "success",
        message: "Device unassigned from kit successfully",
      });
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      setAlert({
        type: "danger",
        message: "Error unassigning device from kit",
      });
    });

  const getDevices = () => axios
    .get(`/organizations/${matter.organization_id}/devices/available`)
    .then((res) => {
      setDevices(res.data.data);
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      setAlert({
        type: "danger",
        message: "Error fetching devices",
      });
    });

  useEffect(() => {
    markComplete(undefined);
    getKit();
    getDevices();
  }, []);

  return (
    <div>
      <Grid>
        <Grid.Col span={12}>
          <KitManager
            kit={data}
            devices={devices}
            assignDevicesToKit={assignDevicesToKit}
            unassignDevicesFromKit={unassignDevicesFromKit}
            refreshKit={async () => {
              await getDevices();
              await getKit();
              markComplete(undefined);
              return true;
            }}
            disabled={disabled}
            organization_id={matter.organization_id}
          />
        </Grid.Col>
      </Grid>
    </div>
  );
}

export default DeviceAssignment;
