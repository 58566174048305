import React, {
  ChangeEvent, FormEvent, useEffect, useState,
} from "react";
import { Navigate, } from "react-router-dom";
import { useUser, useAlert, useSettings, } from "hooks";
import { AxiosError, } from "axios";
/** @jsxImportSource @emotion/react */
import { css, } from "@emotion/react";
import { axios, } from "libs";
import {
  Button, Card, Grid, TextInput, Title,
} from "@mantine/core";
import { resolveTheme, } from "themes/main";
import { UserContextProps, } from "../../contexts/UserContext";
import { AlertContextProps, } from "../../contexts/AlertContext";

function Login(): JSX.Element {
  const userContext: UserContextProps = useUser();
  const alertContext: AlertContextProps = useAlert();

  if (userContext.isLoggedIn) {
    const redirect = new URLSearchParams(window.location.search).get("redirect");
    if (redirect) {
      return <Navigate to={redirect} />;
    }

    return <Navigate to="/home" />;
  }

  const [formData, setFormData] = useState<{ email: string; password: string }>(
    {
      email: "",
      password: "",
    }
  );

  const [validated, setValidated] = useState<boolean>(false);

  // eslint-disable-next-line no-unused-vars
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { email, password, } = formData;

  const onSuccess = async () => {
    alertContext.setAlert({
      type: "success",
      message: "Login successful",
    });

    userContext.setIsLoggedIn(true);
  };

  const onError = (error: AxiosError): void => {
    setLoading(false);
    console.error(error);
    const { response, } = error;

    if (!response) {
      alertContext.setAlert({
        type: "danger",
        message: "There was an error logging in",
      });

      // setTimeout(() => {
      //   window.location.reload();
      // }, 2000);
    }

    if (response?.data) {
      alertContext.setAlert({
        type: "danger",
        message: response.data.message,
      });
    }
  };

  useEffect(() => {
    userContext.setIsLoading(loading);
  }, [loading]);

  const onChange = (e: ChangeEvent): void => {
    const target = e.target as HTMLInputElement;
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  };

  const onSubmit = (e: FormEvent): void => {
    const form = e.currentTarget as HTMLFormElement;
    if (!form.checkValidity()) {
      setValidated(true);
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    e.preventDefault();
    e.stopPropagation();

    setLoading(true);
    axios
      .post("/auth/login", {
        email,
        password,
      })
      .then(onSuccess)
      .catch(onError);
  };

  // eslint-disable-next-line no-unused-vars
  const rememberUser = (event: ChangeEvent): void => {
    const target = event.target as HTMLInputElement;
    setRememberMe(target.checked);
  };

  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);

  const styles = css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding-block: 24px;
    background-color: ${currentTheme.colors.background};
    height: 100vh;

    @media (min-width: 768px) {
      height: 100vh;
    }

    .card {
      width: 90%;
      border: none;
      box-shadow: 0 0 56px 0 rgba(0, 0, 0, 0.2);
      background-color: ${currentTheme.colors.backgroundAlt};
      border-radius: 10px;

      @media (min-width: 768px) {
        width: 50%;
      }
    }
  `;

  return (
    <div css={styles}>
      <Card shadow="lg" radius={0} className="card">
        <Card.Section
          css={css`
            padding: 24px;
          `}
        >
          <Title order={1} weight={400}>
            Login
          </Title>
        </Card.Section>
        <hr />
        <Card.Section
          css={css`
            padding: 24px;
          `}
        >
          <Grid>
            <Grid.Col span={12}>
              <TextInput
                required
                label="Email"
                name="email"
                type="email"
                value={email}
                onChange={onChange}
                error={validated && !email}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput
                required
                label="Password"
                name="password"
                type="password"
                value={password}
                onChange={onChange}
                error={validated && !password}
              />
            </Grid.Col>
          </Grid>
        </Card.Section>
        <hr />
        <Card.Section
          css={css`
            padding: 24px;
          `}
        >
          <div
            css={css`
              display: flex;
              justify-content: flex-end;
            `}
          >
            <Button
              color={currentTheme.colors.primary}
              onClick={onSubmit}
              css={css`
                display: flex;
                align-items: center;
                justify-content: flex-end;
              `}
            >
              Submit
            </Button>
          </div>
        </Card.Section>
      </Card>
    </div>
  );
}

export default Login;
