import React from "react";
import { Grid, Text, } from "@mantine/core";
import InfoCard from "components/InfoCard/InfoCard";
import { useSearch, useSettings, } from "hooks";
import { resolveTheme, } from "themes/main";
import { getPrettyTableName, } from "utils/formatting";

interface ListItem {
  id: number;
  title: string;
  subtitle: string;
  endpoint: string;
  completeness?: number | null;
}

interface SimpleTableProps {
  list_items: ListItem[];
  table_name: string;
}

function SimpleTable({ list_items, table_name, }: SimpleTableProps) {
  const {
    theme: { current: theme, },
  } = useSettings();
  const currentTheme = resolveTheme(theme);

  const { getTable, } = useSearch();
  const { query, } = getTable(table_name);

  return (
    <Grid>
      {list_items.length > 0 ? (
        list_items.map((item) => (
          <Grid.Col key={item.title + item.subtitle + item.id}>
            <InfoCard
              title={item.title}
              subtitle={item.subtitle}
              endpoint={item.endpoint}
              completeness={item.completeness}
            />
          </Grid.Col>
        ))
      ) : (
        <Grid.Col span={12}>
          <Text
            size="md"
            weight="300"
            color={currentTheme.colors.textLight}
            align="center"
          >
            There are no
            {" "}
            {getPrettyTableName(table_name)}
            {query && " that match your search"}
            .
          </Text>
        </Grid.Col>
      )}
    </Grid>
  );
}

export default SimpleTable;
