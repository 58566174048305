import React, { useState, useEffect, } from "react";
import CreateView from "components/Views/CreateView";
import { axios, } from "libs";
import { Validation, } from "interfaces/responses";
import { useParams, useNavigate, } from "react-router-dom";
import {
  Grid, Select, TextInput,
} from "@mantine/core";
import { useAlert, useSettings, } from "hooks";
import { Collection, EmailTemplate, Template, } from "interfaces/main";
import { resolveTheme, } from "themes/main";

function CreateCollection() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState<Partial<Collection>>({
    name: "",
    email_template_id: undefined,
  });
  const [validationRules, setValidationRules] = useState<Validation | null>(
    null
  );
  const [requiredFields, setRequiredFields] = useState<string[]>([]);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [template_id, setTemplateId] = useState<number | null>(null);
  const [validated, setValidated] = useState(false);
  const { organization_id, matter_id, custodian_id, } = useParams();
  const alertContext = useAlert();
  // eslint-disable-next-line no-unused-vars
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([]);

  const onError = (err: any) => {
    // eslint-disable-next-line no-console
    console.error(err);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/collections/create`
      )
      .then((res) => {
        const { validation, } = res.data.data as { validation: Validation };
        setRequiredFields(
          Object.keys(validation.rules).filter((rule) => {
            if (!validation.rules[rule].includes("required")) {
              return false;
            }
            return true;
          })
        );
        setValidationRules(validation);
        setLoading(false);
      })
      .catch(onError)
      .finally(() => setLoading(false));

    axios
      .get(`/organizations/${organization_id}/templates/type/collections`)
      .then((res) => {
        setTemplates(res.data.data);
      })
      .catch(onError)
      .finally(() => setLoading(false));

    axios
      .get(`/organizations/${organization_id}/email-templates`)
      .then((res) => {
        setEmailTemplates(res.data.data);
      })
      .catch(onError);
  }, []);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/collections`,
        formState
      )
      .then((res) => {
        const newCollection = res.data.data;
        alertContext.setAlert({
          type: "success",
          heading: "Success",
          message: "Collection created successfully",
        });
        if (template_id) {
          assignTemplate(newCollection.id);
        } else {
          navigate(
            `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/collections/${newCollection.id}`
          );
        }
      })
      .catch(onError);
  };

  const assignTemplate = (collection_id: number) => {
    setLoading(true);
    axios
      .post(
        `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/collections/${collection_id}/template`,
        {
          template_id,
        }
      )
      .then(() => {
        alertContext.setAlert({
          type: "success",
          heading: "Success",
          message: "Template assigned successfully",
        });
        navigate(
          `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/collections/${collection_id}`
        );
      })
      .catch(onError);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, } = e.target;
    setFormState({ ...formState, [name]: value, });
  };

  // eslint-disable-next-line arrow-body-style
  const formattedTemplates = templates.map((temp) => {
    return {
      label: temp.name,
      value: temp.id.toString(),
    };
  });

  const sortedTemplates = formattedTemplates.sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });

  const {
    theme: { current: theme, },
  } = useSettings();
  const currentTheme = resolveTheme(theme);

  return (
    <CreateView
      title="Create Collection"
      buttons={[
        {
          text: "Submit",
          handleClick: onSubmit,
          color: currentTheme.colors.primary,
          disabled: !validated,
        }
      ]}
      loading={loading}
      requiredFields={requiredFields}
      setValidated={setValidated}
      formState={formState}
    >
      <Grid>
        <Grid.Col sm={12} md={6}>
          <TextInput
            label="Name"
            name="name"
            value={formState.name}
            onChange={handleChange}
            required={validationRules?.rules?.name?.includes("required")}
            placeholder="Enter a name for the collection"
          />
        </Grid.Col>
        <Grid.Col sm={12} md={6}>
          <TextInput
            label="Scheduled At"
            name="scheduled_at"
            type="datetime-local"
            value={formState.scheduled_at}
            onChange={handleChange}
            required={validationRules?.rules?.scheduled_at?.includes(
              "required"
            )}
          />
        </Grid.Col>
        <Grid.Col sm={12} md={6}>
          <Select
            label="Template"
            name="template_id"
            required={validationRules?.rules?.template_id?.includes("required")}
            data={sortedTemplates}
            onChange={(value) => {
              if (value) {
                setTemplateId(parseInt(value, 10));
              }
            }}
            searchable
            placeholder="Select a template"
          />
        </Grid.Col>
        {/* <Grid.Col sm={12} md={6}>
          <Autocomplete
            label="Email Template"
            name="email_template_id"
            data={emailTemplates.map((t) => ({
              label: t.name,
              value: t.id.toString(),
            }))}
            onChange={(value) => {
              if (value) {
                setFormState({
                  ...formState,
                  email_template_id: Number(value),
                });
              }
            }}
            placeholder="Select an email template"
          />
        </Grid.Col> */}
      </Grid>
    </CreateView>
  );
}

export default CreateCollection;
