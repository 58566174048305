/* eslint-disable jsx-a11y/control-has-associated-label */
import {
  Button,
  Flex,
  Grid,
  NumberInput,
  Select,
  Table,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { Field, Fields, FieldTypes, } from "interfaces/main";
import { fieldTypes, } from "vars/constants";
import React, { useEffect, } from "react";
/** @jsxImportSource @emotion/react */
import { css, } from "@emotion/react";
import { useAlert, useSettings, } from "hooks";
import { resolveTheme, } from "themes/main";
import { X, } from "phosphor-react";

interface JSONFormProps {
  title: string;
  // eslint-disable-next-line no-unused-vars
  setFields: (fields: Fields) => void;
  fields: Fields;
}

function JSONForm({ title, setFields, fields, }: JSONFormProps) {
  const { setAlert, } = useAlert();

  const nextSortOrder = Object.keys(fields).length + 1;

  const [newField, setNewField] = React.useState<Field>({
    label: "",
    value: "",
    type: "text",
    name: "",
    sort_order: nextSortOrder,
  });

  useEffect(() => {
    // if fields update, newfield's sort order should update
    setNewField({
      ...newField,
      sort_order: nextSortOrder,
    });
  }, [fields]);

  const [editing, setEditing] = React.useState(false);

  const addNewField = () => {
    if (!newField.label && !newField.name) {
      setAlert({
        message: "Please enter a label and name for the new detail",
        type: "danger",
      });

      return;
    }
    setFields({
      ...fields,
      [newField.name]: newField,
    });
    setNewField({
      label: "",
      value: "",
      type: "text",
      name: "",
      sort_order: nextSortOrder,
    });
    if (editing) {
      setEditing(false);
    }
  };

  const formattedFormType = () => {
    const lastChar = title[title.length - 1];
    const lastTwoChars = title[title.length - 2] + lastChar;

    if (lastTwoChars === "es") {
      return title.slice(0, title.length - 2);
    }

    if (lastChar === "s") {
      return title.slice(0, title.length - 1);
    }

    return title;
  };

  const { theme: { current, }, } = useSettings();
  const currentTheme = resolveTheme(current);

  return (
    <div>
      <Grid>
        <Grid.Col span={12}>
          <Title order={2} weight={300} color={currentTheme.colors.text}>
            {title}
          </Title>
        </Grid.Col>
        <Grid.Col span={12}>
          <CurrentFields
            fields={fields}
            setFields={setFields}
            setEditing={setEditing}
            setEditField={setNewField}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <br />
          <Text size="xl" weight={400} color={currentTheme.colors.text}>
            {editing ? `Edit ${formattedFormType()}` : `Add ${formattedFormType()} `}
          </Text>
        </Grid.Col>
        <Grid.Col span={12}>
          <Grid>
            <Grid.Col sm={12} md={3}>
              <TextInput
                label="Name"
                placeholder="Name"
                value={newField.name || ""}
                onChange={(e) => {
                  setNewField({
                    ...newField,
                    name: e.currentTarget.value,
                    label: e.currentTarget.value,
                  });
                  if (editing) {
                    const newFields = { ...fields, };
                    delete newFields[newField.name];
                    delete newFields[newField.label];
                    setFields(newFields);
                  }
                }}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={2}>
              <Select
                label="Type"
                placeholder="Type"
                value={newField.type || "text"}
                onChange={(e: FieldTypes | null) => {
                  if (!e) return;
                  setNewField({
                    ...newField,
                    type: e,
                  });
                }}
                data={fieldTypes}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={3}>
              <TextInput
                label="Default Value"
                placeholder="Default value"
                value={newField.value || ""}
                onChange={(e) => {
                  setNewField({
                    ...newField,
                    value: e.currentTarget.value,
                  });
                }}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={2}>
              <NumberInput
                label="Sort Order"
                placeholder="Sort Order"
                value={newField.sort_order || 1}
                onChange={(e) => {
                  setNewField({
                    ...newField,
                    sort_order: e || 1,
                  });
                }}
                type="number"
              />
            </Grid.Col>
            <Grid.Col
              sm={12}
              md={2}
              style={{
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
              <Flex
                align="center"
                justify="space-between"
                style={{
                  width: "100%",
                }}
              >
                <Button
                  style={{ marginTop: "24px", }}
                  title="Cancel"
                  variant="default"
                  onClick={() => {
                    setEditing(false);
                    setNewField({
                      label: "",
                      value: "",
                      type: "text",
                      name: "",
                      sort_order: 1,
                    });
                  }}
                >
                  <X size={18} />
                </Button>
                <Button
                  onClick={addNewField}
                  variant="default"
                  color={currentTheme.colors.textLight}
                  style={{ marginTop: "24px", }}
                >
                  Save
                </Button>
              </Flex>
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>
    </div>
  );
}

export default JSONForm;

export function CurrentFields({
  fields,
  setFields,
  setEditField,
  setEditing,
}: {
  fields: Fields;
  // eslint-disable-next-line no-unused-vars
  setFields: (fd: Fields) => void;
  // eslint-disable-next-line no-unused-vars
  setEditing: (e: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  setEditField: (fd: Field) => void;
}) {
  const deleteField = (label: string) => {
    const newFields = { ...fields, };
    delete newFields[label];
    setFields(newFields);
  };

  const sortedFields = Object.keys(fields).sort((a, b) => {
    const aVal = fields[a].sort_order || 1;
    const bVal = fields[b].sort_order || 1;
    // lower sort order should come first
    return aVal - bVal;
  });

  const { theme: { current, }, } = useSettings();
  const currentTheme = resolveTheme(current);

  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Sort Order</th>
          <th>Default Value</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {sortedFields.map((key) => {
          const field = fields[key];
          return (
            <tr key={key}>
              <td>{field.name}</td>
              <td>{field.type}</td>
              <td>{field.sort_order}</td>
              <td>{field.value}</td>
              <td
                css={css`
                  .icon {
                    cursor: pointer;
                    font-size: 15px;
                    text-shadow: 0 0 1px ${currentTheme.colors.backgroundContrast}70;
                    transition: all 0.2s ease-in-out;
                    margin-right: 8px;
                    padding: 4px;

                    &:hover {
                      text-shadow: 0 0 1px ${currentTheme.colors.backgroundContrast},
                        0 0 10px ${currentTheme.colors.backgroundContrast};
                      border-radius: 3px;
                    }
                  }

                  .delete {
                    color: ${currentTheme.colors.danger};
                  }

                  .edit {
                    color: ${currentTheme.colors.info};
                  }
                `}
              >
                <i
                  className="material-icons delete icon"
                  onClick={() => deleteField(key)}
                  onKeyDown={() => {
                    if (key === "Enter") deleteField(key);
                  }}
                  role="button"
                  tabIndex={0}
                  title="Delete"
                >
                  delete
                </i>
                <i
                  className="material-icons edit icon"
                  onClick={() => {
                    setEditing(true);
                    setEditField(field);
                  }}
                  onKeyDown={() => {
                    if (key === "Enter") {
                      setEditing(true);
                      setEditField(field);
                    }
                  }}
                  role="button"
                  tabIndex={0}
                  title="Edit"
                >
                  edit
                </i>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}
