import React, { useEffect, useState, } from "react";
import { useParams, } from "react-router-dom";
import EditView from "components/Views/EditView";
import { axios, } from "libs";
import { Validation, } from "interfaces/responses";
import {
  Checkbox, Grid, NumberInput, Select, TextInput, Title,
} from "@mantine/core";
import { useAlert, useSettings, } from "hooks";
import { resolveTheme, } from "themes/main";
import { Organization, } from "interfaces/main";
import { stateOptions, } from "vars/constants";
import { countries, } from "vars/countries";

function Edit() {
  const [organization, setOrganization] = useState<Partial<Organization>>({
    name: "",
    code_prefix: "",
    address: {
      street1: "",
      street2: "",
      street3: "",
      postalCode: "",
      city: "",
      state: "",
      country: "",
      company: "",
      phone: "",
      name: "",
    },
  });
  const [loading, setLoading] = useState(true);
  const [validated, setValidated] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [validationRules, setValidationRules] = useState<Validation>();
  const [requiredFields, setRequiredFields] = useState<string[]>([]);
  const { organization_id, } = useParams();
  const { setAlert, } = useAlert();

  useEffect(() => {
    axios
      .get(`/organizations/${organization_id}`)
      .then((response) => {
        setOrganization(response.data.data);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });

    axios.get("/organizations/create").then((response) => {
      const { validation, } = response.data.data;
      setRequiredFields(
        Object.keys(validation.rules).filter((rule) => {
          if (!validation.rules[rule].includes("required")) {
            return false;
          }
          return true;
        })
      );
      setValidationRules(validation);
    });
  }, []);

  const handleSubmit = () => {
    setLoading(true);
    axios
      .put(`/organizations/${organization_id}`, organization)
      .then((response) => {
        setValidated(false);
        setOrganization(response.data.data);
        setAlert({
          type: "success",
          message: "Organization updated successfully",
        });
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        setAlert({
          type: "danger",
          message: "Error updating organization",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);
  const { colors, } = currentTheme;

  return (
    <EditView
      title="Organization"
      buttons={[
        {
          text: "Submit",
          handleClick: handleSubmit,
          color: colors.primary,
          variant: "filled",
          disabled: !validated,
        }
      ]}
      loading={loading}
      formState={organization}
      requiredFields={requiredFields}
      setValidated={setValidated}
    >
      <Grid>
        <Grid.Col sm={12} md={6}>
          <TextInput
            label="Name"
            name="name"
            placeholder="Name"
            value={organization?.name || ""}
            onChange={(e) => {
              setOrganization({
                ...organization,
                name: e.target.value,
              });
            }}
            required={validationRules?.rules?.name?.includes("required")}
          />
        </Grid.Col>
        <Grid.Col sm={12} md={6}>
          <TextInput
            label="Client Code"
            name="code_prefix"
            placeholder="Client Code"
            value={organization.code_prefix}
            onChange={(e) => {
              setOrganization({
                ...organization,
                code_prefix: e.target.value,
              });
            }}
            required={validationRules?.rules?.code_prefix?.includes("required")}
          />
        </Grid.Col>
        <Grid.Col sm={12} span={6}>
          <Title order={3} weight={300} color={currentTheme.colors.text}>
            Default Kit Dimensions
          </Title>
        </Grid.Col>
        <Grid.Col sm={12} md={6}>
          <NumberInput
            label="Length"
            name="length"
            placeholder="Length"
            onChange={(e) => {
              setOrganization({ ...organization, length: e || 1, });
            }}
            required={validationRules?.rules?.length?.includes("required")}
            value={organization.length}
          />
        </Grid.Col>
        <Grid.Col sm={12} md={6}>
          <NumberInput
            label="Width"
            name="width"
            placeholder="Width"
            onChange={(e) => {
              setOrganization({ ...organization, width: e || 1, });
            }}
            required={validationRules?.rules?.width?.includes("required")}
            value={organization.width}
          />
        </Grid.Col>
        <Grid.Col sm={12} md={6}>
          <NumberInput
            label="Height"
            name="height"
            placeholder="Height"
            onChange={(e) => {
              setOrganization({ ...organization, height: e || 1, });
            }}
            required={validationRules?.rules?.height?.includes("required")}
            value={organization.height}
          />
        </Grid.Col>
        <Grid.Col span={12} />
        <Grid.Col sm={12} span={6}>
          <Title order={3} weight={300} color={currentTheme.colors.text}>
            Organization Address
          </Title>
        </Grid.Col>
        <Grid.Col md={6} sm={12}>
          <TextInput
            label="Street 1"
            name="street1"
            placeholder="Street 1"
            onChange={(e) => {
              setOrganization({
                ...organization,
                address: {
                  ...organization.address,
                  street1: e.target.value,
                },
              });
            }}
            required={validationRules?.rules?.street1?.includes("required")}
            value={organization.address?.street1 || ""}
          />
        </Grid.Col>
        <Grid.Col md={6} sm={12}>
          <TextInput
            label="Street 2"
            name="street2"
            placeholder="Street 2"
            onChange={(e) => {
              setOrganization({
                ...organization,
                address: {
                  ...organization.address,
                  street2: e.target.value,
                },
              });
            }}
            required={validationRules?.rules?.street2?.includes("required")}
            value={organization?.address?.street2 || ""}
          />
        </Grid.Col>
        <Grid.Col md={6} sm={12}>
          <TextInput
            label="Street 3"
            name="street3"
            placeholder="Street 3"
            onChange={(e) => {
              setOrganization({
                ...organization,
                address: {
                  ...organization.address,
                  street3: e.target.value,
                },
              });
            }}
            required={validationRules?.rules?.street3?.includes("required")}
            value={organization.address?.street3 || ""}
          />
        </Grid.Col>
        <Grid.Col md={6} sm={12}>
          <TextInput
            label="City"
            name="city"
            placeholder="City"
            onChange={(e) => {
              setOrganization({
                ...organization,
                address: {
                  ...organization.address,
                  city: e.target.value,
                },
              });
            }}
            required={validationRules?.rules?.city?.includes("required")}
            value={organization.address?.city || ""}
          />
        </Grid.Col>
        <Grid.Col md={6} sm={12}>
          <Select
            label="State"
            name="state"
            placeholder="State"
            data={stateOptions.map((state) => ({
              label: state.name,
              value: state.abbreviation,
            }))}
            onChange={(value): void => {
              if (!value) {
                return;
              }
              setOrganization({
                ...organization,
                address: {
                  ...organization.address,
                  state: value,
                },
              });
            }}
            required={validationRules?.rules?.state?.includes("required")}
            value={organization.address?.state || ""}
            searchable
          />
        </Grid.Col>
        <Grid.Col md={6} sm={12}>
          <TextInput
            label="Postal Code/Zip Code"
            name="postalCode"
            placeholder="Postal Code/Zip Code"
            onChange={(e) => {
              setOrganization({
                ...organization,
                address: {
                  ...organization.address,
                  postalCode: e.target.value,
                },
              });
            }}
            required={validationRules?.rules?.postalCode?.includes("required")}
            value={organization.address?.postalCode || ""}
          />
        </Grid.Col>
        <Grid.Col md={6} sm={12}>
          <Select
            label="Country"
            name="country"
            placeholder="Country"
            onChange={(e) => {
              if (!e) {
                return;
              }
              setOrganization({
                ...organization,
                address: {
                  ...organization.address,
                  country: e,
                },
              });
            }}
            required={validationRules?.rules?.country?.includes("required")}
            value={organization.address?.country || ""}
            data={countries.map((country) => ({
              label: country.name,
              value: country.code,
            }))}
            searchable
          />
        </Grid.Col>
        <Grid.Col md={6} sm={12}>
          <TextInput
            label="Phone"
            name="phone"
            placeholder="Phone"
            onChange={(e) => {
              setOrganization({
                ...organization,
                address: {
                  ...organization.address,
                  phone: e.target.value,
                },
              });
            }}
            required={validationRules?.rules?.phone?.includes("required")}
            value={organization.address?.phone || ""}
          />
        </Grid.Col>
        <Grid.Col md={6} sm={12}>
          <TextInput
            label="Company"
            name="company"
            placeholder="Company"
            onChange={(e) => {
              setOrganization({
                ...organization,
                address: {
                  ...organization.address,
                  company: e.target.value,
                },
              });
            }}
            required={validationRules?.rules?.company?.includes("required")}
            value={organization.address?.company || ""}
          />
        </Grid.Col>
        <Grid.Col md={6} sm={12}>
          <TextInput
            label="Name"
            name="name"
            placeholder="Name"
            onChange={(e) => {
              setOrganization({
                ...organization,
                address: {
                  ...organization.address,
                  name: e.target.value,
                },
              });
            }}
            required={validationRules?.rules?.name?.includes("required")}
            value={organization.address?.name || ""}
          />
        </Grid.Col>
        <Grid.Col sm={12} md={6}>
          <Checkbox
            label="Residential"
            name="residential"
            onChange={(e) => {
              setOrganization({
                ...organization,
                address: {
                  ...organization.address,
                  residential: e.target.checked ? 1 : 0,
                },
              });
            }}
            checked={!!organization.address?.residential || false}
            styles={{
              root: {
                marginTop: "40px",
              },
            }}
          />
        </Grid.Col>
      </Grid>
    </EditView>
  );
}

export default Edit;
