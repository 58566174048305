import React, { useEffect, useState, } from "react";
import {
  useAlert, useUser, useSearch, useSettings,
} from "hooks";
import { AxiosResponse, } from "axios";
import { axios, } from "libs";
import { useNavigate, } from "react-router";
/** @jsxImportSource @emotion/react */
// import { css } from "@emotion/react";
import { useParams, } from "react-router-dom";
import { Matter, } from "interfaces/main";
import {
  getFormattedMatterName,
  getFormattedTimeString,
} from "utils/formatting";
import { getModuleCompleteness, getModuleFields, } from "utils/methods";
import IntegratedListView from "components/Views/IntegratedListView";
import { resolveTheme, } from "themes/main";

ClientList.defaultProps = {
  org_prefix: "",
  collapseable: true,
  with_separator: true,
  with_title: true,
  with_padding_y: true,
};

function ClientList({
  org_prefix,
  collapseable,
  with_separator,
  with_title,
  with_padding_y,
}: {
  org_prefix?: string;
  collapseable?: boolean;
  with_separator?: boolean;
  with_title?: boolean;
  with_padding_y?: boolean;
}) {
  const [matters, setMatters] = useState<Matter[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { organization_id, } = useParams();
  const { getTable, } = useSearch();
  const { sort, } = getTable("matters");

  // const { user } = useUser();
  const { setAlert, } = useAlert();
  const { hasRole, } = useUser();

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/organizations/${organization_id}/matters`)
      .then((res: AxiosResponse) => {
        setMatters(res.data.data);
      })
      .catch((err: any) => {
        // eslint-disable-next-line no-console
        console.error(err);
        setAlert({
          type: "danger",
          message: "Error fetching matters",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [organization_id]);

  const allFields: (keyof Matter)[] | undefined =
    matters?.[0] && (Object.keys(matters[0]) as unknown as (keyof Matter)[]);

  const allowedFields = new Map([
    ["id", 0],
    ["name", 0],
    ["code", 0],
    ["organization_id", 0],
    ["created_at", 0],
    ["updated_at", 0]
  ]);

  const fields = getModuleFields(allowedFields, allFields);

  const getSubtitle = (m: Matter) => {
    if (!sort.field) {
      return m.updated_at ?
        `Last update at ${getFormattedTimeString(m.updated_at)}` :
        `Created at ${getFormattedTimeString(m.created_at)}`;
    }
    return `${sort.field}: ${m[sort.field as keyof Matter]}`;
  };

  const listItems = matters.map((matter) => ({
    id: matter.id,
    title: getFormattedMatterName(matter, org_prefix),
    subtitle: getSubtitle(matter),
    endpoint: `/organizations/${organization_id}/matters/${matter.id}`,
    completeness: getModuleCompleteness(matter) || 0,
    full_record: matter,
  }));

  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);
  const { colors, } = currentTheme;

  return (
    <div>
      <IntegratedListView
        title="Matters"
        buttons={[
          {
            text: "",
            icon: "add",
            handleClick: () => {
              navigate(`/organizations/${organization_id}/matters/create`);
            },
            color: colors.primary,
            variant: "default",
            invisible: !hasRole("user"),
          }
        ]}
        loading={loading}
        search
        collapsable={collapseable}
        table_name="matters"
        fields={fields}
        full_record_list={matters}
        list_items={listItems}
        with_separator={with_separator}
        with_title={with_title}
        with_padding_y={with_padding_y}
      />
    </div>
  );
}

export default ClientList;
