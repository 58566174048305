import axios from "libs/axiosInstance";
import {
  RecentModule, Collection, Custodian, Matter,
} from "interfaces/main";
import { getFormattedCustodianName, } from "utils/formatting";

type RecentModuleForm = Partial<RecentModule>;

export const getRecentModules = async () => axios.get("/visits").then((res) => res.data.data);

export const getRecentMatters = async () => axios.get("/visits/matters").then((res) => res.data.data);

export const addRecentModule = async (module: RecentModuleForm) => {
  axios.post("/visits", module).catch((err) => {
    console.error("Error adding visit", err);
  });
};

export const getEndpointFromDrilldown = (
  drilldown: RecentModule["drilldown"]
) => {
  let formatted = drilldown;
  if (typeof drilldown === "string") {
    formatted = JSON.parse(drilldown);
  }
  const {
    organization_id, matter_id, custodian_id, collection_id,
  } = formatted;
  let endpoint = "/";
  if (organization_id) {
    endpoint += `organizations/${organization_id}`;
  }
  if (matter_id) {
    endpoint += `/matters/${matter_id}`;
  }
  if (custodian_id) {
    endpoint += `/custodians/${custodian_id}`;
  }
  if (collection_id) {
    endpoint += `/collections/${collection_id}`;
  }
  return endpoint;
};

export const addRecentModuleFromCollection = async (collection: Collection) => {
  const completeness =
    collection.total_tasks > 0 ?
      Math.round((collection.completed_tasks / collection.total_tasks) * 100) :
      0;
  const module: RecentModuleForm = {
    name: collection.name,
    module: "collections",
    drilldown: {
      organization_id: Number(collection.custodian.matter.organization_id),
      matter_id: Number(collection.custodian.matter_id),
      custodian_id: Number(collection.custodian_id),
      collection_id: Number(collection.id),
    },
    completed_tasks: collection.completed_tasks,
    total_tasks: collection.total_tasks,
    other: {
      completeness,
    },
  };
  await addRecentModule(module);
};

export const addRecentModuleFromCustodian = async (custodian: Custodian) => {
  const completeness =
    custodian.total_tasks > 0 ?
      Math.round((custodian.completed_tasks / custodian.total_tasks) * 100) :
      0;
  const module: RecentModuleForm = {
    name: getFormattedCustodianName(custodian),
    module: "custodians",
    drilldown: {
      organization_id: Number(custodian.matter.organization_id),
      matter_id: Number(custodian.matter_id),
      custodian_id: Number(custodian.id),
    },
    completed_tasks: custodian.completed_tasks,
    total_tasks: custodian.total_tasks,
    other: {
      completeness,
    },
  };
  await addRecentModule(module);
};

export const addRecentModuleFromMatter = async (matter: Matter) => {
  const completeness =
    matter.total_tasks > 0 ?
      Math.round((matter.completed_tasks / matter.total_tasks) * 100) :
      0;
  const module: RecentModuleForm = {
    name: matter.name,
    module: "matters",
    drilldown: {
      organization_id: Number(matter.organization_id),
      matter_id: Number(matter.id),
    },
    completed_tasks: matter.completed_tasks,
    total_tasks: matter.total_tasks,
    other: {
      completeness,
    },
  };
  await addRecentModule(module);
};

export const addRecentModuleFromOrganization = async (organization: any) => {
  const module: RecentModuleForm = {
    name: organization.name,
    module: "organizations",
    drilldown: {
      organization_id: Number(organization.id),
    },
  };
  await addRecentModule(module);
};
