import {
  Button,
  Flex,
  Grid,
  MultiSelect,
  Select,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import InfoCard from "components/InfoCard/InfoCard";
import InlineLoader from "components/Loading/Inline";
import { useAlert, useSettings, } from "hooks";
import {
  Collection,
  Custodian,
  DeviceType,
  Matter,
  Template,
} from "interfaces/main";
import { Validation, } from "interfaces/responses";
import { axios, } from "libs";
import React, { useEffect, useState, } from "react";
import { resolveTheme, } from "themes/main";
import {
  getFormattedCustodianName,
  getFormattedTimeString,
} from "utils/formatting";
/** @jsxImportSource @emotion/react */
import { css, } from "@emotion/react";

function Collections({
  matter,
  custodians,
  // eslint-disable-next-line no-unused-vars
  markComplete,
  invalidate,
}: {
  matter: Matter;
  custodians: Custodian[];
  // eslint-disable-next-line no-unused-vars
  markComplete: (data: Record<number, Collection[]>) => void;
  invalidate: () => void;
}) {
  const [custodianCollections, setCustodianCollections] = React.useState<
    Record<number, Collection[]>
  >({});
  const [deviceTypes, setDeviceTypes] = useState<DeviceType[]>([]);

  const { setAlert, } = useAlert();

  // eslint-disable-next-line no-unused-vars
  const getDeviceTypes = () => axios
    .get(`/organizations/${matter.organization_id}/device-types`)
    .then((res) => {
      setDeviceTypes(res.data.data);
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      setAlert({
        type: "danger",
        message: "Error fetching device types",
      });
    });

  useEffect(() => {
    getDeviceTypes();
    return () => {
      invalidate();
    };
  }, []);

  useEffect(() => {
    // if at least one collection exists, mark complete
    const collections = Object.values(custodianCollections).flat();
    if (collections.length) {
      markComplete(custodianCollections);
    }
  }, [custodianCollections]);

  const numCollections = Object.values(custodianCollections).flat().length;

  const {
    theme: { current: theme, },
  } = useSettings();
  const currentTheme = resolveTheme(theme);

  return (
    <div>
      <Grid>
        {!numCollections && (
          <Grid.Col span={12}>
            <Text size="xs" color={currentTheme.colors.danger}>
              No collections will be available to this kit.
            </Text>
          </Grid.Col>
        )}
        <Grid.Col span={12} />
        {custodians?.map((c) => (
          <Grid.Col span={12} key={c.id}>
            <Grid>
              <Grid.Col span={12}>
                <Title
                  order={3}
                  weight={400}
                  color={currentTheme.colors.textLight}
                >
                  {getFormattedCustodianName(c)}
                </Title>
              </Grid.Col>
              <Grid.Col span={12} />
              <CustodianCollections
                custodian={c}
                matter={matter}
                setCustodianCollections={(custodian_id, collections) => {
                  setCustodianCollections((prev) => ({
                    ...prev,
                    [custodian_id]: collections,
                  }));
                }}
                deviceTypes={deviceTypes}
              />
            </Grid>
          </Grid.Col>
        ))}
      </Grid>
    </div>
  );
}

export default Collections;

function CustodianCollections({
  custodian,
  matter,
  setCustodianCollections,
  deviceTypes,
}: {
  custodian: Custodian;
  matter: Matter;
  setCustodianCollections: (
    // eslint-disable-next-line no-unused-vars
    custodian_id: number,
    // eslint-disable-next-line no-unused-vars
    collections: Collection[]
  ) => void;
  deviceTypes: DeviceType[];
}) {
  const { setAlert, } = useAlert();

  const [collections, setCollections] = React.useState<Collection[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [formState, setFormState] = React.useState<Partial<Collection>>({
    name: "",
    email_template_id: undefined,
  });
  const [validationRules, setValidationRules] =
    React.useState<Validation | null>(null);
  const [templates, setTemplates] = React.useState<Template[]>([]);
  const [template_id, setTemplateId] = React.useState<number | null>(null);

  useEffect(() => {
    setCustodianCollections(custodian.id, collections);
  }, [collections]);

  const loadValidation = () => {
    setLoading(true);
    axios
      .get(
        `/organizations/${matter.organization_id}/matters/${matter.id}/custodians/${custodian.id}/collections/create`
      )
      .then((res) => {
        const { validation, } = res.data.data as { validation: Validation };
        setValidationRules(validation);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setAlert({
          heading: "Error",
          message: "Failed to load validation rules.",
          type: "danger",
        });
      })
      .finally(() => setLoading(false));
  };

  const loadCollections = () => {
    setLoading(true);
    return axios
      .get(
        `/organizations/${matter.organization_id}/matters/${matter.id}/custodians/${custodian.id}/collections`
      )
      .then((res) => {
        setCollections(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  };

  const loadTemplates = () => {
    setLoading(true);
    return axios
      .get(
        `/organizations/${matter.organization_id}/templates/type/collections`
      )
      .then((res) => {
        setTemplates(res.data.data);
      })
      .catch((err) => {
        console.error(err);
        setAlert({
          heading: "Error",
          message: "Failed to load templates.",
          type: "danger",
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadCollections();
    loadValidation();
    loadTemplates();
  }, [matter, custodian]);

  const refresh = () => {
    loadCollections();
    loadValidation();
    loadTemplates();
  };

  const {
    theme: { current: theme, },
  } = useSettings();
  const currentTheme = resolveTheme(theme);

  const [adding, setAdding] = React.useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, } = e.target;
    setFormState({ ...formState, [name]: value, });
  };

  const formattedTemplates = templates.map((temp) => ({
    label: temp.name,
    value: temp.id.toString(),
  }));

  const onSubmit = () => {
    setLoading(true);
    axios
      .post(
        `/organizations/${matter.organization_id}/matters/${matter.id}/custodians/${custodian.id}/collections`,
        formState
      )
      .then((res) => {
        const newCollection = res.data.data;
        setAlert({
          type: "success",
          heading: "Success",
          message: "Collection created successfully",
        });
        if (template_id) {
          assignTemplate(newCollection.id);
        } else {
          refresh();
        }
      })
      .catch((err) => {
        console.error(err);
        setAlert({
          type: "danger",
          heading: "Error",
          message: "Failed to create collection",
        });
      })
      .finally(() => setLoading(false));
  };

  const assignTemplate = (collection_id: number) => {
    axios
      .post(
        `/organizations/${matter.organization_id}/matters/${matter.id}/custodians/${custodian.id}/collections/${collection_id}/template`,
        {
          template_id,
        }
      )
      .then(() => {
        setAlert({
          type: "success",
          heading: "Success",
          message: "Template assigned successfully",
        });
        refresh();
      })
      .catch((err) => {
        console.error(err);
        setAlert({
          type: "danger",
          heading: "Error",
          message: "Failed to assign template",
        });
      });
  };

  if (loading) {
    return <InlineLoader />;
  }

  return (
    <>
      {collections.length ? (
        collections.map((c) => (
          <div key={c.id} style={{ width: "100%", }}>
            <CollectionAndDeviceTypes
              matter={matter}
              custodian={custodian}
              collection={c}
              refresh={refresh}
              deviceTypes={deviceTypes}
            />
          </div>
        ))
      ) : (
        <Grid.Col span={12}>
          <Text size="xs" color={currentTheme.colors.text}>
            No collections on this custodian.
          </Text>
        </Grid.Col>
      )}
      <Grid.Col span={12} />
      {adding && (
        <>
          <Grid.Col sm={12} md={6} lg={4}>
            <TextInput
              label="Name"
              name="name"
              value={formState.name}
              onChange={handleChange}
              required={validationRules?.rules?.name?.includes("required")}
              placeholder="Enter a name for the collection"
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6} lg={4}>
            <TextInput
              label="Scheduled At"
              name="scheduled_at"
              type="datetime-local"
              value={formState.scheduled_at}
              onChange={handleChange}
              required={validationRules?.rules?.scheduled_at?.includes(
                "required"
              )}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6} lg={4}>
            <Select
              label="Template"
              name="template_id"
              required={validationRules?.rules?.template_id?.includes(
                "required"
              )}
              data={formattedTemplates}
              onChange={(value) => {
                if (value) {
                  setTemplateId(parseInt(value, 10));
                }
              }}
              searchable
              placeholder="Select a template"
            />
          </Grid.Col>
          <Grid.Col span={12} />
        </>
      )}
      <Grid.Col span={12}>
        <Flex justify="end" gap={18}>
          {adding && (
            <Button variant="default" onClick={() => setAdding(false)}>
              Cancel
            </Button>
          )}
          <Button
            variant="default"
            onClick={() => {
              if (!adding) {
                setAdding(true);
              } else {
                setAdding(false);
                onSubmit();
              }
            }}
          >
            {adding ? "Submit" : "Add Collection"}
            {loading && (
              <>
                {" "}
                <InlineLoader />
              </>
            )}
          </Button>
        </Flex>
      </Grid.Col>
    </>
  );
}

function CollectionAndDeviceTypes({
  matter,
  custodian,
  collection,
  // eslint-disable-next-line no-unused-vars
  refresh,
  deviceTypes,
}: {
  matter: Matter;
  custodian: Custodian;
  collection: Collection;
  refresh: () => void;
  deviceTypes: DeviceType[];
}) {
  const {
    theme: { current: theme, },
  } = useSettings();
  const currentTheme = resolveTheme(theme);

  const { setAlert, } = useAlert();

  const formattedDeviceTypes = deviceTypes.map((dt) => ({
    label: dt.name,
    value: dt.id.toString(),
  }));

  const formattedExistingDeviceTypes = collection.device_types.map((dt) => dt.id.toString());

  const assignDeviceTypesToCollection = (
    attach: number[] | string[],
    detach: number[] | string[]
  ) => {
    axios
      .post(
        `/organizations/${matter.organization_id}/matters/${matter.id}/custodians/${custodian.id}/collections/${collection.id}/assign`,
        {
          attach: attach.map((a) => parseInt(a.toString(), 10)),
          detach: detach.map((d) => parseInt(d.toString(), 10)),
        }
      )
      .then(() => {
        setAlert({
          type: "success",
          message: "Device types changed successfully",
        });
        refresh();
      })
      .catch((err) => {
        setAlert({
          type: "danger",
          message: "Error changing device types",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  const handleChangeDeviceTypes = (dts: number[]) => {
    const toAdd = dts.filter(
      (dt) => !formattedExistingDeviceTypes.includes(dt.toString())
    );
    const toRemove = formattedExistingDeviceTypes.filter(
      (dt) => !dts.includes(parseInt(dt, 10))
    );

    assignDeviceTypesToCollection(toAdd, toRemove);
  };

  return (
    <div
      css={css`
        border-left: 2px solid ${currentTheme.colors.text};
        padding-left: 24px;
        margin-bottom: 24px;
      `}
    >
      <InfoCard
        title={collection.name}
        subtitle={`Last Updated: ${getFormattedTimeString(
          collection.updated_at
        )}`}
        endpoint={`/organizations/${matter.organization_id}/matters/${matter.id}/custodians/${custodian.id}/collections/${collection.id}`}
      />
      <br />
      <MultiSelect
        label="Device Types"
        data={formattedDeviceTypes}
        value={formattedExistingDeviceTypes}
        onChange={(v) => {
          const newDeviceTypes = v.map((dt) => parseInt(dt, 10));
          handleChangeDeviceTypes(newDeviceTypes);
        }}
        style={{
          width: "50%",
          marginLeft: "auto",
        }}
      />
    </div>
  );
}
