import axios, { AxiosRequestConfig, } from "axios";
import { api_url, } from "vars/server";
import { setupCache, } from "axios-cache-interceptor";

const axiosConfig: AxiosRequestConfig = {
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    Accept: "application/json",
  },
  baseURL: api_url,
  timeout: 30000,
  withCredentials: true,
};

const axiosInstance = setupCache(axios.create(axiosConfig), {
  ttl: 2 * 1000,
  interpretHeader: false,
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.error({ error, });
    // if the /me route returns a 401, then the user is not logged in
    if (
      error.response &&
      error.response.status === 401 &&
      window.location.pathname !== "/login"
    ) {
      const currentEndpoint = window.location.pathname;
      if (currentEndpoint !== "/") {
        window.location.href = `/login?redirect=${currentEndpoint}`;
      } else {
        window.location.href = "/login";
      }
    }
    if (error.response?.data?.errors === "CSRF token mismatch.") {
      // CRSF token mismatch - try to refecth the token then retry the request
      // eslint-disable-next-line no-console
      console.error("CSRF token mismatch - trying to refresh the token");
      const data = error.config.data ? JSON.parse(error.config.data) : {};
      const { url, } = error.config;
      const { method, } = error.config;
      return new Promise((resolve, reject) => {
        //  First, try to refresh the token
        axiosInstance({ url: "/sanctum/csrf-cookie", }).then(() => {
          //  Retry the request
          axiosInstance({
            url,
            method,
            data,
          })
            .then((response) => {
              resolve(response);
            })
            .catch((err) => {
              reject(err);
            });
        });
      });
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
