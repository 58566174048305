/* eslint-disable no-unused-vars */
import React, { useEffect, useState, } from "react";
import {
  Button, Flex, Grid, Text,
} from "@mantine/core";
import { Device, Kit, Matter, } from "interfaces/main";
import KitManager from "components/KitManager/KitManager";
import { axios, } from "libs";
import { useAlert, useSettings, } from "hooks";
import { getFormattedMatterName, } from "utils/formatting";
import InlineLoader from "components/Loading/Inline";
import TextDisplay from "components/Display/TextDisplay";
import { resolveTheme, } from "themes/main";
import { Link, } from "react-router-dom";

function LabelGeneration({
  markComplete,
  invalidate,
  matter,
  kit,
}: {
  markComplete: (args: undefined) => void;
  invalidate: () => void;
  matter: Matter;
  kit: Kit;
}) {
  const [data, setData] = useState<Kit | null>(kit);
  const alertContext = useAlert();

  useEffect(() => {
    setData(kit);
  }, [kit]);

  const getKit = () => {
    axios
      .get(
        `/organizations/${matter.organization_id}/matters/${matter.id}/kits/${kit.id}`
      )
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        alertContext.setAlert({
          type: "danger",
          message: "Error fetching kit",
        });
      });
  };

  const getShipment = (bound: "inbound" | "outbound") => {
    if (!data) {
      return null;
    }
    return data?.shipments.find((s) => s.type === bound);
  };

  const outboundShipment = getShipment("outbound");
  const inboundShipment = getShipment("inbound");

  const [outboundErrors, setOutboundErrors] = useState<string[]>([]);
  const [outboundLoading, setOutboundLoading] = useState<boolean>(false);
  const handleCreateOutboundLabel = () => {
    alertContext.setConfirmation(
      "Are you sure you want to create an outbound label? This will lock the kit and prevent any further changes.",
      () => {
        setOutboundLoading(true);
        axios
          .post(
            `/organizations/${matter.organization_id}/matters/${matter.id}/kits/${kit.id}/label/create/outbound`
          )
          .then(() => {
            alertContext.setAlert({
              type: "success",
              message: "Outbound label created successfully",
            });
            getKit();
          })
          .catch((err) => {
            console.error(err);
            setOutboundErrors([
              err.response?.data?.message ||
                "There was an error generating the label."
            ]);
            alertContext.setAlert({
              type: "danger",
              message: "Error creating outbound label",
            });
          })
          .finally(() => {
            setOutboundLoading(false);
          });
      }
    );
  };

  const [inboundErrors, setInboundErrors] = useState<string[]>([]);
  const [inboundLoading, setInboundLoading] = useState<boolean>(false);
  const handleCreateInboundLabel = () => {
    alertContext.setConfirmation(
      "Are you sure you want to create an inbound label? This will lock the kit and prevent any further changes.",
      () => {
        setInboundLoading(true);
        axios
          .post(
            `/organizations/${matter.organization_id}/matters/${matter.id}/kits/${kit.id}/label/create/inbound`
          )
          .then(() => {
            alertContext.setAlert({
              type: "success",
              message: "Inbound label created successfully",
            });
            getKit();
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.error(err);
            setInboundErrors(err.response.data.errors);
            alertContext.setAlert({
              type: "danger",
              message: "Error creating inbound label",
            });
          })
          .finally(() => {
            setInboundLoading(false);
          });
      }
    );
  };

  const [cancellingOutboundLabel, setCancellingOutboundLabel] =
    useState<boolean>(false);
  const handleCancelOutboundLabel = () => {
    alertContext.setConfirmation(
      "Are you sure you want to cancel the outbound label?",
      () => {
        setCancellingOutboundLabel(true);
        axios
          .post(
            `/organizations/${matter.organization_id}/matters/${matter.id}/kits/${kit.id}/label/cancel/outbound`
          )
          .then(() => {
            alertContext.setAlert({
              type: "success",
              message: "Outbound label cancelled successfully",
            });
            getKit();
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.error(err);
            alertContext.setAlert({
              type: "danger",
              message: "Error cancelling outbound label",
            });
          })
          .finally(() => {
            setCancellingOutboundLabel(false);
          });
      }
    );
  };

  const [cancellingInboundLabel, setCancellingInboundLabel] =
    useState<boolean>(false);
  const handleCancelInboundLabel = () => {
    alertContext.setConfirmation(
      "Are you sure you want to cancel the inbound label?",
      () => {
        setCancellingInboundLabel(true);
        axios
          .post(
            `/organizations/${matter.organization_id}/matters/${matter.id}/kits/${kit.id}/label/cancel/inbound`
          )
          .then(() => {
            alertContext.setAlert({
              type: "success",
              message: "Inbound label cancelled successfully",
            });
            getKit();
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.error(err);
            alertContext.setAlert({
              type: "danger",
              message: "Error cancelling inbound label",
            });
          })
          .finally(() => {
            setCancellingInboundLabel(false);
          });
      }
    );
  };

  const formatErrorString = (error: string) => {
    // transform "outbound_name is invalid" to "Outbound Name is invalid"
    // basically split the first word by "_", capitalize it, and then join it back together
    const split = error.split(" ");
    const firstWord = split[0].split("_");
    const capitalized = firstWord.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    const joined = capitalized.join(" ");
    split[0] = joined;
    return split.join(" ");
  };

  const getTrackingNumber = (bound: "inbound" | "outbound") => {
    if (!data) {
      return "";
    }
    const shipment = kit.shipments.find((s) => s.type === bound);
    if (!shipment) {
      return "";
    }
    return shipment.tracking_number;
  };

  const getDownloadName = (bound: "inbound" | "outbound") => {
    const matterName = matter ? getFormattedMatterName(matter) : "";
    const kitName = data ? data.name : "";
    const trackingID = data ? getTrackingNumber(bound) : "";
    const name = `${matterName} - ${kitName} - ${trackingID} (${bound})`;
    return name;
  };

  const handleDownloadOutboundLabel = () => {
    // get axios baseURL
    const { baseURL, } = axios.defaults;
    if (!baseURL) {
      throw new Error("axios baseURL is not set");
    }
    const downloadLink = document.createElement("a");
    downloadLink.href = `${baseURL}/organizations/${matter.organization_id}/matters/${matter.id}/kits/${kit.id}/label/download/outbound`;
    downloadLink.download = getDownloadName("outbound");
    downloadLink.click();
  };

  const handleDownloadInboundLabel = () => {
    const { baseURL, } = axios.defaults;
    if (!baseURL) {
      throw new Error("axios baseURL is not set");
    }
    const downloadLink = document.createElement("a");
    downloadLink.href = `${baseURL}/organizations/${matter.organization_id}/matters/${matter.id}/kits/${kit.id}/label/download/inbound`;
    downloadLink.download = getDownloadName("inbound");
    downloadLink.click();
  };

  const hasOutboundLabel = !!outboundShipment?.tracking_number;
  const hasInboundLabel = !!inboundShipment?.tracking_number;

  useEffect(() => {
    if (hasOutboundLabel && hasInboundLabel) {
      markComplete(undefined);
    } else {
      invalidate();
    }
  }, [hasOutboundLabel, hasInboundLabel]);

  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);
  const { colors, } = currentTheme;

  return (
    <div>
      <Grid>
        {outboundLoading ? (
          <Grid.Col span={12}>
            <InlineLoader text="Generating Outbound Label..." />
          </Grid.Col>
        ) : (
          <>
            {!outboundShipment?.tracking_number ? (
              <Grid.Col sm={12} md={8}>
                <Button
                  onClick={handleCreateOutboundLabel}
                  variant="default"
                  disabled={!!outboundShipment?.labelData}
                >
                  Generate Outbound Label
                </Button>
              </Grid.Col>
            ) : (
              <>
                <Grid.Col sm={12} md={3}>
                  <TextDisplay
                    label="Outbound Tracking Number"
                    data={outboundShipment.tracking_number}
                  />
                </Grid.Col>
                <Grid.Col sm={12} md={3}>
                  <TextDisplay
                    label="Inbound Label Cost"
                    data={outboundShipment?.shipment_cost || "None provided"}
                  />
                </Grid.Col>
                <Grid.Col sm={12} md={3}>
                  {!cancellingOutboundLabel ? (
                    <Button
                      style={{
                        marginTop: "32px",
                        width: "100%",
                      }}
                      onClick={handleCancelOutboundLabel}
                      color={colors.textLight}
                      variant="default"
                      disabled={!kit?.status || kit?.status >= 3}
                    >
                      Cancel Label
                    </Button>
                  ) : (
                    <div
                      style={{
                        marginTop: "32px",
                      }}
                    >
                      <InlineLoader text="Cancelling Outbound Label..." />
                    </div>
                  )}
                </Grid.Col>
                <Grid.Col sm={12} md={3}>
                  <Button
                    style={{
                      marginTop: "32px",
                      width: "100%",
                    }}
                    onClick={handleDownloadOutboundLabel}
                    variant="outline"
                    disabled={!outboundShipment?.labelData}
                  >
                    Download Label
                  </Button>
                </Grid.Col>
              </>
            )}
            {outboundErrors.length > 0 && (
              <>
                <Grid.Col sm={12} md={4}>
                  <Flex justify="flex-end">
                    <Link
                      to={`/organizations/${matter.organization_id}/matters/${matter.id}/kits/${kit?.id}/edit`}
                      style={{
                        color: colors.text,
                      }}
                    >
                      Edit Kit
                    </Link>
                  </Flex>
                </Grid.Col>
                <Grid.Col>
                  <Text size="md" color={colors.danger}>
                    Errors generating outbound label:
                  </Text>
                </Grid.Col>
                <Grid.Col sm={12}>
                  <Text size="sm" weight="300" color={colors.textLight}>
                    {outboundErrors.map((e) => formatErrorString(e)).join(", ")}
                  </Text>
                </Grid.Col>
              </>
            )}
          </>
        )}
        <Grid.Col span={12} />
        {inboundLoading ? (
          <Grid.Col span={12}>
            <InlineLoader text="Generating Inbound Label..." />
          </Grid.Col>
        ) : (
          <>
            {!inboundShipment?.tracking_number ? (
              <Grid.Col sm={12} md={8}>
                <Button
                  onClick={handleCreateInboundLabel}
                  variant="default"
                  disabled={!!inboundShipment?.labelData}
                >
                  Generate Inbound Label
                </Button>
              </Grid.Col>
            ) : (
              <>
                <Grid.Col sm={12} md={3}>
                  <TextDisplay
                    label="Inbound Tracking Number"
                    data={inboundShipment?.tracking_number}
                  />
                </Grid.Col>
                <Grid.Col sm={12} md={3}>
                  <TextDisplay
                    label="Inbound Label Cost"
                    data={inboundShipment?.shipment_cost || "None provided"}
                  />
                </Grid.Col>
                <Grid.Col sm={12} md={3}>
                  {!cancellingInboundLabel ? (
                    <Button
                      style={{
                        marginTop: "32px",
                        width: "100%",
                      }}
                      onClick={handleCancelInboundLabel}
                      color={colors.textLight}
                      variant="default"
                      disabled={!kit?.status || kit?.status >= 3}
                    >
                      Cancel Label
                    </Button>
                  ) : (
                    <div
                      style={{
                        marginTop: "32px",
                      }}
                    >
                      <InlineLoader text="Cancelling Inbound Label..." />
                    </div>
                  )}
                </Grid.Col>
                <Grid.Col sm={12} md={3}>
                  <Button
                    style={{
                      marginTop: "32px",
                      width: "100%",
                    }}
                    onClick={handleDownloadInboundLabel}
                    variant="outline"
                    disabled={!inboundShipment?.labelData}
                  >
                    Download Label
                  </Button>
                </Grid.Col>
              </>
            )}
            {inboundErrors.length > 0 && (
              <>
                <Grid.Col sm={12} md={4}>
                  <Flex justify="flex-end">
                    <Link
                      to={`/organizations/${matter.organization_id}/matters/${matter.id}/kits/${kit?.id}/edit`}
                      style={{
                        color: colors.text,
                      }}
                    >
                      Edit Kit
                    </Link>
                  </Flex>
                </Grid.Col>
                <Grid.Col>
                  <Text size="md" color={colors.danger}>
                    Errors generating inbound label:
                  </Text>
                </Grid.Col>
                <Grid.Col sm={12}>
                  <Text size="sm" weight="300" color={colors.textLight}>
                    {inboundErrors.map((e) => formatErrorString(e)).join(", ")}
                  </Text>
                </Grid.Col>
              </>
            )}
          </>
        )}
      </Grid>
    </div>
  );
}

export default LabelGeneration;
