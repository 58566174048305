import { useEffect, useState, } from "react";
import { useAlert, useSettings, useUser, } from "hooks";
import { Validation, } from "interfaces/responses";
import { axios, } from "libs";
import {
  Button,
  Checkbox,
  Grid,
  NumberInput,
  SegmentedControl,
  Select,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useParams, useNavigate, } from "react-router-dom";
import { parseJsonFields, } from "utils/fetching";
import {
  Address,
  EmailTemplate,
  Kit,
  Organization,
  Shipment,
} from "interfaces/main";
import EditView from "components/Views/EditView";
import { resolveTheme, } from "themes/main";
import states from "vars/stateTitleCase";
import { countries, } from "vars/countries";
import { Loading, } from "components";
import { validateEmail, } from "utils/methods";
import DatePicker from "components/Inputs/DatePicker";
import { shipmentConfirmationTypes, } from "vars/constants";

function EditKit() {
  const { setAlert, } = useAlert();
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [validationRules, setValidationRules] = useState<Validation>();
  const [requiredFields, setRequiredFields] = useState<string[]>([]);
  const [validated, setValidated] = useState(false);
  const {
    organization_id, matter_id, kit_id, step,
  } = useParams();
  const navigate = useNavigate();
  const { getUserOrganization, } = useUser();
  const [carriers, setCarriers] = useState<
    {
      name: string;
      code: string;
    }[]
  >([]);

  const [FormData, setFormData] = useState<Partial<Kit>>();

  const kitInitialized = () => {
    if (!FormData) {
      return false;
    }

    const outboundShipment = getShipment("outbound");
    const inboundShipment = getShipment("inbound");

    if (!outboundShipment || !inboundShipment) {
      return false;
    }

    return true;
  };

  const setFormDataIfInitialized = (newFormData: Partial<Kit>) => {
    if (kitInitialized()) {
      setFormData(newFormData);
      return;
    }
    console.error("Tried to set form data before kit was initialized");
  };

  const onError = (response: any): void => {
    // eslint-disable-next-line no-console
    console.error("On error", response);
    setAlert({
      type: "danger",
      heading: "Error",
      message: response.Error,
    });
  };
  const [organization, setOrganization] = useState<Organization>();
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([]);

  const getOrganization = () => {
    axios
      .get(`/organizations/${getUserOrganization()?.id}`)
      .then((res) => {
        setOrganization(res.data.data);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error("error", err);
      });
  };

  useEffect(() => {
    axios
      .get(`/organizations/${organization_id}/matters/${matter_id}/kits/create`)
      .then((res) => {
        const { validation, } = res.data.data as { validation: Validation };
        setRequiredFields(
          Object.keys(validation.rules).filter((rule) => {
            if (!validation.rules[rule].includes("required")) {
              return false;
            }
            return true;
          })
        );
        setValidationRules(validation);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error("error", err);
      })
      .finally(() => {
        setLoading(false);
      });

    axios
      .get(
        `/organizations/${organization_id}/matters/${matter_id}/kits/${kit_id}`
      )
      .then((res) => {
        const kit = res.data.data as Kit;
        const parsed = parseJsonFields(kit);
        setFormData(parsed);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error("error", err);
      })
      .finally(() => {
        setLoading(false);
      });

    axios
      .get("/shipping/carriers")
      .then((res) => {
        setCarriers(res.data.data || []);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error("error", err);
      });

    axios
      .get(`/organizations/${organization_id}/email-templates`)
      .then((res) => {
        setEmailTemplates(res.data.data);
      })
      .catch(onError);

    getOrganization();
  }, []);

  const onSubmit = (): void => {
    setLoading(true);
    axios
      .put(
        `/organizations/${organization_id}/matters/${matter_id}/kits/${kit_id}`,
        FormData
      )
      .then((res) => {
        const newKit = res.data.data as Kit;
        setAlert({
          type: "success",
          heading: "Success",
          message: "Kit edited successfully",
        });
        navigate(
          `/organizations/${organization_id}/matters/${matter_id}/kits/${newKit.id}`
        );
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        setAlert({
          type: "danger",
          heading: "Error",
          message: "Error editing kit: ",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);
  const { colors, } = currentTheme;

  const isRequired = (field: string): boolean => requiredFields.includes(field);

  const formattedStates = states ?
    states.map((state) => ({
      label: state.name,
      value: state.abbreviation,
    })) :
    [];

  const formattedCountries = countries ?
    countries.map((country) => ({
      label: country.name,
      value: country.code,
    })) :
    [];

  const formatDate = (date: Date) => {
    const ISO = date.toISOString();
    return ISO.replace("T", " ").replace("Z", "").split(".")[0];
  };

  const updateShipment = (
    bound: "outbound" | "inbound",
    update: Partial<Shipment>
  ) => {
    const newShipments = FormData?.shipments?.map((shipment) => {
      if (shipment.type === bound) {
        return { ...shipment, ...update, };
      }
      return shipment;
    });
    setFormDataIfInitialized({ ...FormData, shipments: newShipments, });
  };

  const getShipment = (bound: "outbound" | "inbound") => {
    if (FormData?.shipments) {
      return FormData.shipments.find((shipment) => shipment.type === bound);
    }
    return undefined;
  };

  const updateAddress = (
    bound: "outbound" | "inbound",
    type: "from" | "to",
    update: Partial<Address>
  ) => {
    const newShipments = FormData?.shipments?.map((shipment) => {
      if (shipment.type === bound) {
        const newAddress = { ...shipment[`${type}_address`], ...update, };
        return { ...shipment, [`${type}_address`]: newAddress, };
      }
      return shipment;
    });
    setFormDataIfInitialized({ ...FormData, shipments: newShipments, });
  };

  const updateBatchAddresses = (
    addresses: { bound: "outbound" | "inbound"; type: "from" | "to" }[],
    update: Partial<Address>
  ) => {
    const newShipments = [...(FormData?.shipments || [])];
    addresses.forEach((address) => {
      const shipment = newShipments.find((s) => s.type === address.bound);
      if (shipment) {
        const newAddress = {
          ...shipment[`${address.type}_address`],
          ...update,
        };
        (shipment[`${address.type}_address`] as Partial<Address>) = newAddress;
      }
    });
    setFormDataIfInitialized({ ...FormData, shipments: newShipments, });
  };

  const getAddress = (bound: "outbound" | "inbound", type: "from" | "to") => {
    if (FormData?.shipments) {
      const shipment = FormData.shipments.find((s) => s.type === bound);
      if (shipment) {
        return shipment[`${type}_address`];
      }
    }
    return undefined;
  };

  const steps: {
    [key: string]: { title: string };
  } = {
    2: {
      title: "Step 2: Add Shipment Information",
    },
  };

  const [outboundCarrierServices, setOutboundCarrierServices] = useState<
    {
      name: string;
      code: string;
    }[]
  >([]);
  const [inboundCarrierServices, setInboundCarrierServices] = useState<
    {
      name: string;
      code: string;
    }[]
  >([]);

  const formattedCarriers = carriers ?
    carriers.map((carrier) => ({
      label: carrier.name,
      value: carrier.code,
    })) :
    [];

  const formattedOutboundCarrierServices = outboundCarrierServices ?
    outboundCarrierServices.map((service) => ({
      label: service.name,
      value: service.code,
    })) :
    [];

  const formattedInboundCarrierServices = inboundCarrierServices ?
    outboundCarrierServices.map((service) => ({
      label: service.name,
      value: service.code,
    })) :
    [];

  const addressIsEmpty = (address: Address | null | undefined) => {
    if (address === undefined) {
      return false;
    }
    if (address === null) {
      return true;
    }
    return (
      !address.street1 &&
      !address.street2 &&
      !address.street3 &&
      !address.city &&
      !address.state &&
      !address.postalCode &&
      !address.country
    );
  };

  const getOrganizationDimensions = () => (organization ?
    {
      length: organization?.length,
      width: organization?.width,
      height: organization?.height,
    } :
    undefined);

  const getShipmentDimensions = (bound: "outbound" | "inbound") => {
    const shipment = getShipment(bound);
    if (shipment) {
      return {
        length: shipment.length,
        width: shipment.width,
        height: shipment.height,
        weight: shipment.weight,
      };
    }
    return undefined;
  };

  const shipmentNeedsDimensions = (bound: "outbound" | "inbound") => {
    const shipment = getShipment(bound);
    if (!shipment) return false;
    const { width, height, length, } = shipment;
    return !width && !height && !length;
  };

  const outboundNeedsDimensions = shipmentNeedsDimensions("outbound");

  const inboundNeedsDimensions = shipmentNeedsDimensions("inbound");

  useEffect(() => {}, [
    getShipmentDimensions("inbound"),
    getShipmentDimensions("outbound")
  ]);

  const [showMore, setShowMore] = useState(false);

  const formattedWeightUnits = [
    {
      label: "Pounds",
      value: "pounds",
    },
    {
      label: "Ounces",
      value: "ounces",
    },
    {
      label: "Grams",
      value: "grams",
    }
  ];

  const formattedDimensionsUnits = [
    {
      label: "Inches",
      value: "inches",
    },
    {
      label: "Centimeters",
      value: "centimeters",
    }
  ];

  const [outboundShipmentType, setOutboundShipmentType] = useState<
    "new" | "existing"
  >("new");

  const [inboundShipmentType, setInboundShipmentType] = useState<
    "new" | "existing"
  >("new");

  const initialOutboundShipmentType = getShipment("outbound")?.tracking_number;
  const initialInboundShipmentType = getShipment("inbound")?.tracking_number;

  useEffect(() => {
    if (initialOutboundShipmentType) {
      setOutboundShipmentType("existing");
    }
  }, [initialOutboundShipmentType]);

  useEffect(() => {
    if (initialInboundShipmentType) {
      setInboundShipmentType("existing");
    }
  }, [initialInboundShipmentType]);

  useEffect(() => {
    if (getShipment("outbound")?.carrier_code) {
      axios
        .get(
          `/shipping/carriers/${getShipment("outbound")?.carrier_code}/services`
        )
        .then((res) => {
          setOutboundCarrierServices(res.data.data);
        });
    }
    if (getShipment("inbound")?.carrier_code) {
      axios
        .get(
          `/shipping/carriers/${getShipment("inbound")?.carrier_code}/services`
        )
        .then((res) => {
          setInboundCarrierServices(res.data.data);
        });
    }
  }, [
    getShipment("outbound")?.carrier_code,
    getShipment("inbound")?.carrier_code
  ]);

  useEffect(() => {
    if (!kitInitialized()) return;
    if (!getShipment("outbound")?.carrier_code && carriers) {
      updateShipment("outbound", {
        carrier_code: "ups_walleted",
      });
    }
    if (!getShipment("inbound")?.carrier_code && carriers) {
      updateShipment("inbound", {
        carrier_code: "ups_walleted",
      });
    }
  }, [
    carriers,
    getShipment("outbound")?.carrier_code,
    getShipment("inbound")?.carrier_code
  ]);

  useEffect(() => {
    if (!kitInitialized()) return;
    if (
      getShipment("outbound")?.carrier_code === "ups_walleted" &&
      !getShipment("outbound")?.service_code
    ) {
      updateShipment("outbound", {
        service_code: "ups_2nd_day_air",
      });
    } else if (
      getShipment("outbound")?.carrier_code !== "ups_walleted" &&
      !getShipment("outbound")?.service_code
    ) {
      updateShipment("outbound", {
        service_code: "",
      });
    }
  }, [outboundCarrierServices]);

  useEffect(() => {
    if (!kitInitialized()) return;
    if (
      getShipment("inbound")?.carrier_code === "ups_walleted" &&
      !getShipment("inbound")?.service_code
    ) {
      updateShipment("inbound", {
        service_code: "ups_2nd_day_air",
      });
    } else if (getShipment("inbound")?.carrier_code !== "ups_walleted") {
      updateShipment("inbound", {
        service_code: "",
      });
    }
  }, [inboundCarrierServices]);
  const autoFillFields = () => {
    if (!kitInitialized()) {
      return;
    }

    // * Address Handling
    if (
      addressIsEmpty(getAddress("outbound", "from")) &&
      addressIsEmpty(getAddress("inbound", "to")) &&
      organization?.address
    ) {
      updateBatchAddresses(
        [
          { bound: "outbound", type: "from", },
          { bound: "inbound", type: "to", }
        ],
        { ...organization.address, }
      );
    } else if (
      addressIsEmpty(getAddress("inbound", "to")) &&
      organization?.address
    ) {
      updateAddress("inbound", "to", { ...organization.address, });
    } else if (
      addressIsEmpty(getAddress("outbound", "from")) &&
      organization?.address
    ) {
      updateAddress("outbound", "from", { ...organization.address, });
    }

    // * Shipment Stuff
    if (inboundNeedsDimensions) {
      const orgDimensions = getOrganizationDimensions();
      if (!orgDimensions) return;
      updateShipment("inbound", {
        width: orgDimensions.width,
        height: orgDimensions.height,
        length: orgDimensions.length,
      });
    }
    if (outboundNeedsDimensions) {
      const orgDimensions = getOrganizationDimensions();
      if (!orgDimensions) return;
      updateShipment("outbound", {
        width: orgDimensions.width,
        height: orgDimensions.height,
        length: orgDimensions.length,
      });
    }

    // * Other
    if (!getShipment("inbound")?.dimension_units) {
      updateShipment("inbound", {
        dimension_units: "inches",
      });
    }
    if (!getShipment("outbound")?.dimension_units) {
      updateShipment("outbound", {
        dimension_units: "inches",
      });
    }
    if (!getShipment("inbound")?.weight_units) {
      updateShipment("inbound", {
        weight_units: "pounds",
      });
    }
    if (!getShipment("outbound")?.weight_units) {
      updateShipment("outbound", {
        weight_units: "pounds",
      });
    }
    if (!getAddress("outbound", "to")?.country) {
      updateAddress("outbound", "to", {
        country: "US",
      });
    }
    if (!getAddress("inbound", "to")?.country) {
      updateAddress("inbound", "to", {
        country: "US",
      });
    }
  };

  useEffect(() => {
    if (!kitInitialized()) return;
    autoFillFields();
  }, [FormData]);

  if (loading) {
    return <Loading />;
  }

  return (
    <EditView
      title={step ? steps[step].title : "Edit Kit"}
      loading={loading}
      buttons={[
        {
          text: "Submit",
          handleClick: onSubmit,
          color: colors.primary,
          disabled: !validated,
        }
      ]}
      requiredFields={requiredFields}
      formState={FormData}
      setValidated={setValidated}
    >
      <Grid gutterMd={56}>
        <Grid.Col span={12}>
          <Grid>
            <Grid.Col sm={12} md={6}>
              <TextInput
                label="Name"
                name="name"
                required={isRequired("name")}
                onChange={(e) => {
                  setFormDataIfInitialized({
                    ...FormData,
                    name: e.target.value,
                  });
                }}
                placeholder="Enter a name for the kit"
                value={FormData?.name || ""}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={6}>
              <TextInput
                label="Notification Emails"
                name="notification_emails"
                required={isRequired("notification_emails")}
                onChange={(e) => {
                  setFormDataIfInitialized({
                    ...FormData,
                    notification_emails: e.target.value ?
                      e.target.value.split(",").map((email) => email.trim()) :
                      [],
                  });
                }}
                placeholder="Enter a comma separated list of emails"
                value={FormData?.notification_emails?.join(", ") || ""}
              />
              {FormData?.notification_emails?.map((email) => {
                if (!validateEmail(email) && email.length > 0) {
                  return (
                    <Text
                      color={currentTheme.colors.danger}
                      size="xs"
                      weight={300}
                      key={email}
                    >
                      &quot;
                      {email}
                      &quot; is not a valid email address
                    </Text>
                  );
                }
                return null;
              })}
            </Grid.Col>
            <Grid.Col sm={12} md={6}>
              <Select
                label="Email Template"
                name="email_template_id"
                data={emailTemplates.map((t) => ({
                  label: t.name,
                  value: t.id.toString(),
                }))}
                onChange={(value) => {
                  if (value) {
                    setFormData({
                      ...FormData,
                      email_template_id: Number(value),
                    });
                  }
                }}
                placeholder="Select an email template"
                value={FormData?.email_template_id?.toString() || ""}
                searchable
              />
            </Grid.Col>
          </Grid>
        </Grid.Col>
        <Grid.Col span={12} />
        <Grid.Col span={12}>
          <Title order={1} color={currentTheme.colors.text} weight={300}>
            Shipments
          </Title>
        </Grid.Col>
        <Grid.Col
          sm={12}
          md={6}
          style={{
            borderRight: `1px solid ${currentTheme.colors.backgroundContrast}`,
          }}
        >
          <Grid>
            <Grid.Col span={12}>
              <Title order={2} color={currentTheme.colors.text} weight={300}>
                Outbound
              </Title>
            </Grid.Col>
            <Grid.Col span={12}>
              <SegmentedControl
                data={[
                  {
                    label: "New",
                    value: "new",
                  },
                  {
                    label: "Existing",
                    value: "existing",
                  }
                ]}
                onChange={(e) => {
                  setOutboundShipmentType(e as "new" | "existing");
                }}
                value={outboundShipmentType}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={6}>
              <TextInput
                label="Tracking Number"
                name="tracking_number"
                required={isRequired("tracking_number")}
                onChange={(e) => {
                  updateShipment("outbound", {
                    tracking_number: e.target.value,
                  });
                }}
                placeholder="Enter a tracking number"
                value={getShipment("outbound")?.tracking_number || ""}
                disabled={outboundShipmentType === "new"}
              />
            </Grid.Col>
            <Grid.Col span={12} />
            <Grid.Col sm={12}>
              <Title order={3} color={currentTheme.colors.text} weight={300}>
                {showMore ? "To Address" : "Address"}
              </Title>
            </Grid.Col>
            <Grid.Col sm={12} md={4}>
              <TextInput
                label="Name"
                name="name"
                required={isRequired("name")}
                onChange={(e) => {
                  updateAddress("outbound", "to", {
                    name: e.target.value,
                  });
                }}
                placeholder="Enter a name"
                value={getAddress("outbound", "to")?.name || ""}
                disabled={outboundShipmentType === "existing"}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={4}>
              <TextInput
                label="Company"
                name="company"
                required={isRequired("company")}
                onChange={(e) => {
                  updateAddress("outbound", "to", {
                    company: e.target.value,
                  });
                }}
                placeholder="Enter a company name"
                value={getAddress("outbound", "to")?.company || ""}
                disabled={
                  outboundShipmentType === "existing" ||
                  !!getAddress("outbound", "to")?.residential
                }
              />
            </Grid.Col>
            <Grid.Col sm={12} md={4}>
              <TextInput
                label="Phone"
                name="phone"
                required={isRequired("phone")}
                onChange={(e) => {
                  updateAddress("outbound", "to", {
                    phone: e.target.value,
                  });
                }}
                placeholder="Enter a phone number"
                value={getAddress("outbound", "to")?.phone || ""}
                disabled={outboundShipmentType === "existing"}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={4}>
              <TextInput
                label="Street1"
                name="street1"
                required={isRequired("street1")}
                onChange={(e) => {
                  updateAddress("outbound", "to", {
                    street1: e.target.value,
                  });
                }}
                placeholder="Enter a street address"
                value={getAddress("outbound", "to")?.street1 || ""}
                disabled={outboundShipmentType === "existing"}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={4}>
              <TextInput
                label="Street2"
                name="street2"
                onChange={(e) => {
                  updateAddress("outbound", "to", {
                    street2: e.target.value,
                  });
                }}
                placeholder="Enter a street address"
                value={getAddress("outbound", "to")?.street2 || ""}
                disabled={outboundShipmentType === "existing"}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={4}>
              <TextInput
                label="Street3"
                name="street3"
                onChange={(e) => {
                  updateAddress("outbound", "to", {
                    street3: e.target.value,
                  });
                }}
                placeholder="Enter a street address"
                value={getAddress("outbound", "to")?.street3 || ""}
                disabled={outboundShipmentType === "existing"}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={4}>
              <TextInput
                label="City"
                name="city"
                required={isRequired("city")}
                onChange={(e) => {
                  updateAddress("outbound", "to", {
                    city: e.target.value,
                  });
                }}
                placeholder="Enter a city"
                value={getAddress("outbound", "to")?.city || ""}
                disabled={outboundShipmentType === "existing"}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={4}>
              <Select
                label="State"
                name="state"
                required={isRequired("state")}
                data={formattedStates}
                onChange={(value) => {
                  if (value) {
                    updateAddress("outbound", "to", {
                      state: value as string,
                    });
                  }
                }}
                searchable
                placeholder="Select a state"
                value={getAddress("outbound", "to")?.state || ""}
                disabled={outboundShipmentType === "existing"}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={4}>
              <TextInput
                label="Zip"
                name="zip"
                required={isRequired("zip")}
                onChange={(e) => {
                  updateAddress("outbound", "to", {
                    postalCode: e.target.value,
                  });
                }}
                placeholder="Enter a zip code"
                value={getAddress("outbound", "to")?.postalCode || ""}
                disabled={outboundShipmentType === "existing"}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={6}>
              <Select
                label="Country"
                name="country"
                required={isRequired("country")}
                data={formattedCountries}
                onChange={(value) => {
                  if (value) {
                    updateAddress("outbound", "to", {
                      country: value as string,
                    });
                  }
                }}
                searchable
                placeholder="Select a country"
                value={getAddress("outbound", "to")?.country || "US"}
                disabled={outboundShipmentType === "existing"}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={6}>
              <Checkbox
                label="Residential"
                name="residential"
                onChange={(e) => {
                  updateAddress("outbound", "to", {
                    residential: e.target.checked ? 1 : 0,
                  });
                }}
                checked={!!getAddress("outbound", "to")?.residential || false}
                disabled={
                  outboundShipmentType === "existing" ||
                  !!getAddress("outbound", "to")?.company
                }
                styles={{
                  root: {
                    marginTop: "40px",
                  },
                }}
              />
            </Grid.Col>
            <Grid.Col span={12} />
            <Grid.Col span={12}>
              <Title order={3} color={currentTheme.colors.text} weight={300}>
                Shipping Information
              </Title>
            </Grid.Col>
            <Grid.Col sm={12} md={4}>
              <Select
                label="Carrier"
                name="carrier"
                required={isRequired("carrier")}
                data={formattedCarriers}
                onChange={(e) => {
                  if (!e) return;
                  updateShipment("outbound", {
                    carrier_code: e,
                  });
                }}
                placeholder="Select a carrier"
                value={getShipment("outbound")?.carrier_code || ""}
                searchable
                disabled={outboundShipmentType === "existing"}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={4}>
              <Select
                label="Service"
                name="service"
                required={isRequired("service")}
                data={formattedOutboundCarrierServices}
                onChange={(value) => {
                  if (value) {
                    updateShipment("outbound", {
                      service_code: value,
                    });
                  }
                }}
                disabled={
                  !getShipment("outbound")?.carrier_code ||
                  outboundShipmentType === "existing"
                }
                placeholder={
                  getShipment("outbound")?.carrier_code ?
                    `${
                      formattedOutboundCarrierServices.length > 0 ?
                        "Select a services" :
                        "No services available"
                    }` :
                    "Select a carrier first"
                }
                value={getShipment("outbound")?.service_code || ""}
                searchable
              />
            </Grid.Col>
            <Grid.Col sm={12} md={4}>
              <DatePicker
                label="Ship Date"
                name="ship_date"
                required={isRequired("ship_date")}
                onChange={(value) => {
                  if (value) {
                    updateShipment("outbound", {
                      ship_date: formatDate(value),
                    });
                  }
                }}
                placeholder="Select a ship date"
                value={
                  getShipment("outbound")?.ship_date ?
                    new Date(getShipment("outbound")?.ship_date || "") :
                    new Date()
                }
                disabled={outboundShipmentType === "existing"}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={3}>
              <NumberInput
                label="Weight"
                name="weight"
                required={isRequired("weight")}
                onChange={(e) => {
                  updateShipment("outbound", {
                    weight: Number(e || 0),
                  });
                }}
                placeholder="Enter a weight"
                value={Number(getShipment("outbound")?.weight) || 0}
                disabled={outboundShipmentType === "existing"}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={3}>
              <NumberInput
                label="Length"
                name="length"
                required={isRequired("length")}
                onChange={(e) => {
                  updateShipment("outbound", {
                    length: Number(e || 0),
                  });
                }}
                placeholder="Enter a length"
                value={Number(getShipment("outbound")?.length) || 0}
                disabled={outboundShipmentType === "existing"}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={3}>
              <NumberInput
                label="Width"
                name="width"
                required={isRequired("width")}
                onChange={(e) => {
                  updateShipment("outbound", {
                    width: Number(e || 0),
                  });
                }}
                placeholder="Enter a width"
                value={Number(getShipment("outbound")?.width) || 0}
                disabled={outboundShipmentType === "existing"}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={3}>
              <NumberInput
                label="Height"
                name="height"
                required={isRequired("height")}
                onChange={(e) => {
                  updateShipment("outbound", {
                    height: Number(e || 0),
                  });
                }}
                placeholder="Enter a height"
                value={Number(getShipment("outbound")?.height) || 0}
                disabled={outboundShipmentType === "existing"}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={6}>
              <Select
                label="Weight Units"
                name="weight_units"
                required={isRequired("weight_units")}
                data={formattedWeightUnits}
                onChange={(value) => {
                  if (value) {
                    updateShipment("outbound", {
                      weight_units: value as Shipment["weight_units"],
                    });
                  }
                }}
                disabled={outboundShipmentType === "existing"}
                placeholder="Select a weight unit"
                value={getShipment("outbound")?.weight_units || ""}
                searchable
              />
            </Grid.Col>
            <Grid.Col sm={12} md={6}>
              <Select
                label="Dimension Units"
                name="dimension_units"
                required={isRequired("dimension_units")}
                data={formattedDimensionsUnits}
                onChange={(value) => {
                  if (value) {
                    updateShipment("outbound", {
                      dimension_units: value as Shipment["dimension_units"],
                    });
                  }
                }}
                disabled={outboundShipmentType === "existing"}
                placeholder="Select a dimension unit"
                value={getShipment("outbound")?.dimension_units || ""}
                searchable
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Select
                name="confirmation"
                label="Confirmation"
                data={shipmentConfirmationTypes(
                  getShipment("outbound")?.carrier_code === "fedex"
                )}
                onChange={(value) => {
                  if (value) {
                    updateShipment("outbound", {
                      confirmation: value as Shipment["confirmation"],
                    });
                  }
                }}
                value={getShipment("outbound")?.confirmation || "none"}
              />
            </Grid.Col>
            <Grid.Col span={12} />
            {showMore ? (
              <>
                <Grid.Col span={12}>
                  <Button onClick={() => setShowMore(false)} variant="default">
                    Show Less
                  </Button>
                </Grid.Col>
                <Grid.Col span={12} />
                <Grid.Col sm={12}>
                  <Title
                    order={3}
                    color={currentTheme.colors.text}
                    weight={300}
                  >
                    From Address
                  </Title>
                </Grid.Col>
                <Grid.Col sm={12} md={4}>
                  <TextInput
                    label="Name"
                    name="name"
                    required={isRequired("name")}
                    onChange={(e) => {
                      updateAddress("outbound", "from", {
                        name: e.target.value,
                      });
                    }}
                    placeholder="Enter a name"
                    value={getAddress("outbound", "from")?.name || ""}
                    disabled={outboundShipmentType === "existing"}
                  />
                </Grid.Col>
                <Grid.Col sm={12} md={4}>
                  <TextInput
                    label="Company"
                    name="company"
                    required={isRequired("company")}
                    onChange={(e) => {
                      updateAddress("outbound", "from", {
                        company: e.target.value,
                      });
                    }}
                    placeholder="Enter a company name"
                    value={getAddress("outbound", "from")?.company || ""}
                    disabled={
                      outboundShipmentType === "existing" ||
                      !!getAddress("outbound", "from")?.residential
                    }
                  />
                </Grid.Col>
                <Grid.Col sm={12} md={4}>
                  <TextInput
                    label="Phone"
                    name="phone"
                    required={isRequired("phone")}
                    onChange={(e) => {
                      updateAddress("outbound", "from", {
                        phone: e.target.value,
                      });
                    }}
                    placeholder="Enter a phone number"
                    value={getAddress("outbound", "from")?.phone || ""}
                    disabled={outboundShipmentType === "existing"}
                  />
                </Grid.Col>
                <Grid.Col sm={12} md={4}>
                  <TextInput
                    label="Street1"
                    name="street1"
                    required={isRequired("street1")}
                    onChange={(e) => {
                      updateAddress("outbound", "from", {
                        street1: e.target.value,
                      });
                    }}
                    placeholder="Enter a street address"
                    value={getAddress("outbound", "from")?.street1 || ""}
                    disabled={outboundShipmentType === "existing"}
                  />
                </Grid.Col>
                <Grid.Col sm={12} md={4}>
                  <TextInput
                    label="Street2"
                    name="street2"
                    onChange={(e) => {
                      updateAddress("outbound", "from", {
                        street2: e.target.value,
                      });
                    }}
                    placeholder="Enter a street address"
                    value={getAddress("outbound", "from")?.street2 || ""}
                    disabled={outboundShipmentType === "existing"}
                  />
                </Grid.Col>
                <Grid.Col sm={12} md={4}>
                  <TextInput
                    label="Street3"
                    name="street3"
                    onChange={(e) => {
                      updateAddress("outbound", "from", {
                        street3: e.target.value,
                      });
                    }}
                    placeholder="Enter a street address"
                    value={getAddress("outbound", "from")?.street3 || ""}
                    disabled={outboundShipmentType === "existing"}
                  />
                </Grid.Col>
                <Grid.Col sm={12} md={6}>
                  <TextInput
                    label="City"
                    name="city"
                    required={isRequired("city")}
                    onChange={(e) => {
                      updateAddress("outbound", "from", {
                        city: e.target.value,
                      });
                    }}
                    placeholder="Enter a city"
                    value={getAddress("outbound", "from")?.city || ""}
                    disabled={outboundShipmentType === "existing"}
                  />
                </Grid.Col>
                <Grid.Col sm={12} md={6}>
                  <Select
                    label="State"
                    name="state"
                    required={isRequired("state")}
                    data={formattedStates}
                    onChange={(value) => {
                      if (value) {
                        updateAddress("outbound", "from", {
                          state: value as string,
                        });
                      }
                    }}
                    searchable
                    placeholder="Select a state"
                    value={getAddress("outbound", "from")?.state || ""}
                    disabled={outboundShipmentType === "existing"}
                  />
                </Grid.Col>
                <Grid.Col sm={12} md={6}>
                  <TextInput
                    label="Zip"
                    name="zip"
                    required={isRequired("zip")}
                    onChange={(e) => {
                      updateAddress("outbound", "from", {
                        postalCode: e.target.value,
                      });
                    }}
                    placeholder="Enter a zip code"
                    value={getAddress("outbound", "from")?.postalCode || ""}
                    disabled={outboundShipmentType === "existing"}
                  />
                </Grid.Col>
                <Grid.Col sm={12} md={6}>
                  <Select
                    label="Country"
                    name="country"
                    required={isRequired("country")}
                    data={formattedCountries}
                    onChange={(value) => {
                      if (value) {
                        updateAddress("outbound", "from", {
                          country: value as string,
                        });
                      }
                    }}
                    searchable
                    placeholder="Select a country"
                    value={getAddress("outbound", "from")?.country || ""}
                    disabled={outboundShipmentType === "existing"}
                  />
                </Grid.Col>
                <Grid.Col sm={12} md={12}>
                  <Checkbox
                    label="Residential"
                    name="residential"
                    onChange={(e) => {
                      updateAddress("outbound", "from", {
                        residential: e.target.checked ? 1 : 0,
                      });
                    }}
                    checked={
                      !!getAddress("outbound", "from")?.residential || false
                    }
                    disabled={
                      outboundShipmentType === "existing" ||
                      !!getAddress("outbound", "from")?.company
                    }
                  />
                </Grid.Col>
                {" "}
              </>
            ) : (
              <Grid.Col sm={12} md={12}>
                <Button onClick={() => setShowMore(true)} variant="default">
                  Show More
                </Button>
              </Grid.Col>
            )}
          </Grid>
        </Grid.Col>
        <Grid.Col sm={12} md={6}>
          <Grid>
            <Grid.Col span={12}>
              <Title order={2} color={currentTheme.colors.text} weight={300}>
                Inbound
              </Title>
            </Grid.Col>
            <Grid.Col span={12}>
              <SegmentedControl
                name="shipment_type"
                onChange={(e) => {
                  setInboundShipmentType(e as "new" | "existing");
                }}
                data={[
                  { label: "New", value: "new", },
                  { label: "Existing", value: "existing", }
                ]}
                value={inboundShipmentType}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={6}>
              <TextInput
                label="Tracking Number"
                name="tracking_number"
                required={isRequired("tracking_number")}
                onChange={(e) => {
                  updateShipment("inbound", {
                    tracking_number: e.target.value,
                  });
                }}
                placeholder="Enter a tracking number"
                value={getShipment("inbound")?.tracking_number || ""}
                disabled={inboundShipmentType === "new"}
              />
            </Grid.Col>
            <Grid.Col span={12} />
            <Grid.Col span={12}>
              <Title order={3} color={currentTheme.colors.text} weight={300}>
                Address
              </Title>
            </Grid.Col>
            <Grid.Col sm={12} md={4}>
              <TextInput
                label="Name"
                name="name"
                required={isRequired("name")}
                onChange={(e) => {
                  updateAddress("inbound", "to", {
                    name: e.target.value,
                  });
                }}
                placeholder="Enter a name"
                value={getAddress("inbound", "to")?.name || ""}
                disabled={inboundShipmentType === "existing"}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={4}>
              <TextInput
                label="Company"
                name="company"
                required={isRequired("company")}
                onChange={(e) => {
                  updateAddress("inbound", "to", {
                    company: e.target.value,
                  });
                }}
                placeholder="Enter a company name"
                value={getAddress("inbound", "to")?.company || ""}
                disabled={
                  outboundShipmentType === "existing" ||
                  !!getAddress("inbound", "to")?.residential
                }
              />
            </Grid.Col>
            <Grid.Col sm={12} md={4}>
              <TextInput
                label="Phone"
                name="phone"
                required={isRequired("phone")}
                onChange={(e) => {
                  updateAddress("inbound", "to", {
                    phone: e.target.value,
                  });
                }}
                placeholder="Enter a phone number"
                value={getAddress("inbound", "to")?.phone || ""}
                disabled={inboundShipmentType === "existing"}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={4}>
              <TextInput
                label="Street1"
                name="street1"
                required={isRequired("street1")}
                onChange={(e) => {
                  updateAddress("inbound", "to", {
                    street1: e.target.value,
                  });
                }}
                placeholder="Enter a street address"
                value={getAddress("inbound", "to")?.street1 || ""}
                disabled={inboundShipmentType === "existing"}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={4}>
              <TextInput
                label="Street2"
                name="street2"
                onChange={(e) => {
                  updateAddress("inbound", "to", {
                    street2: e.target.value,
                  });
                }}
                placeholder="Enter a street address"
                value={getAddress("inbound", "to")?.street2 || ""}
                disabled={inboundShipmentType === "existing"}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={4}>
              <TextInput
                label="Street3"
                name="street3"
                onChange={(e) => {
                  updateAddress("inbound", "to", {
                    street3: e.target.value,
                  });
                }}
                placeholder="Enter a street address"
                value={getAddress("inbound", "to")?.street3 || ""}
                disabled={inboundShipmentType === "existing"}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={4}>
              <TextInput
                label="City"
                name="city"
                required={isRequired("city")}
                onChange={(e) => {
                  updateAddress("inbound", "to", {
                    city: e.target.value,
                  });
                }}
                placeholder="Enter a city"
                value={getAddress("inbound", "to")?.city || ""}
                disabled={inboundShipmentType === "existing"}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={4}>
              <Select
                label="State"
                name="state"
                required={isRequired("state")}
                data={formattedStates}
                onChange={(value) => {
                  if (value) {
                    updateAddress("inbound", "to", {
                      state: value as string,
                    });
                  }
                }}
                searchable
                placeholder="Select a state"
                value={getAddress("inbound", "to")?.state || ""}
                disabled={inboundShipmentType === "existing"}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={4}>
              <TextInput
                label="Zip"
                name="zip"
                required={isRequired("zip")}
                onChange={(e) => {
                  updateAddress("inbound", "to", {
                    postalCode: e.target.value,
                  });
                }}
                placeholder="Enter a zip code"
                value={getAddress("inbound", "to")?.postalCode || ""}
                disabled={inboundShipmentType === "existing"}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={6}>
              <Select
                label="Country"
                name="country"
                required={isRequired("country")}
                data={formattedCountries}
                onChange={(value) => {
                  if (value) {
                    updateAddress("inbound", "to", {
                      country: value as string,
                    });
                  }
                }}
                searchable
                placeholder="Select a country"
                value={getAddress("inbound", "to")?.country || ""}
                disabled={inboundShipmentType === "existing"}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={6}>
              <Checkbox
                label="Residential"
                name="residential"
                onChange={(e) => {
                  updateAddress("inbound", "to", {
                    residential: e.target.checked ? 1 : 0,
                  });
                }}
                checked={!!getAddress("inbound", "to")?.residential || false}
                disabled={
                  inboundShipmentType === "existing" ||
                  !!getAddress("inbound", "to")?.company
                }
                styles={{
                  root: {
                    marginTop: "40px",
                  },
                }}
              />
            </Grid.Col>
            <Grid.Col span={12} />
            <Grid.Col span={12}>
              <Title order={3} color={currentTheme.colors.text} weight={300}>
                Shipping Information
              </Title>
            </Grid.Col>
            <Grid.Col sm={12} md={4}>
              <Select
                label="Carrier"
                name="carrier"
                required={isRequired("carrier")}
                data={formattedCarriers}
                onChange={(value) => {
                  if (value) {
                    updateShipment("inbound", {
                      carrier_code: value as string,
                    });
                  }
                }}
                searchable
                placeholder="Select a carrier"
                value={getShipment("inbound")?.carrier_code || ""}
                disabled={inboundShipmentType === "existing"}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={4}>
              <Select
                label="Service"
                name="service"
                required={isRequired("service")}
                data={formattedInboundCarrierServices}
                onChange={(value) => {
                  if (value) {
                    updateShipment("inbound", {
                      service_code: value,
                    });
                  }
                }}
                disabled={
                  !getShipment("inbound")?.carrier_code ||
                  inboundShipmentType === "existing"
                }
                placeholder={
                  getShipment("inbound")?.carrier_code ?
                    `${
                      formattedInboundCarrierServices.length > 0 ?
                        "Select a services" :
                        "No services available"
                    }` :
                    "Select a carrier first"
                }
                value={getShipment("inbound")?.service_code || ""}
                searchable
              />
            </Grid.Col>
            <Grid.Col sm={12} md={4}>
              <DatePicker
                label="Ship Date"
                name="ship_date"
                required={isRequired("ship_date")}
                onChange={(value) => {
                  if (value) {
                    updateShipment("inbound", {
                      ship_date: formatDate(value),
                    });
                  }
                }}
                placeholder="Select a ship date"
                value={
                  getShipment("inbound")?.ship_date ?
                    new Date(getShipment("inbound")?.ship_date || "") :
                    new Date()
                }
                disabled={inboundShipmentType === "existing"}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={3}>
              <NumberInput
                label="Weight"
                name="weight"
                required={isRequired("weight")}
                onChange={(e) => {
                  updateShipment("inbound", {
                    weight: Number(e || 0),
                  });
                }}
                placeholder="Enter a weight"
                value={Number(getShipment("inbound")?.weight) || 0}
                disabled={inboundShipmentType === "existing"}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={3}>
              <NumberInput
                label="Length"
                name="length"
                required={isRequired("length")}
                onChange={(e) => {
                  updateShipment("inbound", {
                    length: Number(e || 0),
                  });
                }}
                placeholder="Enter a length"
                value={Number(getShipment("inbound")?.length) || 0}
                disabled={inboundShipmentType === "existing"}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={3}>
              <NumberInput
                label="Width"
                name="width"
                required={isRequired("width")}
                onChange={(e) => {
                  updateShipment("inbound", {
                    width: Number(e || 0),
                  });
                }}
                placeholder="Enter a width"
                value={Number(getShipment("inbound")?.width) || 0}
                disabled={inboundShipmentType === "existing"}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={3}>
              <NumberInput
                label="Height"
                name="height"
                required={isRequired("height")}
                onChange={(e) => {
                  updateShipment("inbound", {
                    height: Number(e || 0),
                  });
                }}
                placeholder="Enter a height"
                value={Number(getShipment("inbound")?.height) || 0}
                disabled={inboundShipmentType === "existing"}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={6}>
              <Select
                label="Weight Units"
                name="weight_units"
                required={isRequired("weight_units")}
                data={formattedWeightUnits}
                onChange={(value) => {
                  if (value) {
                    updateShipment("inbound", {
                      weight_units: value as Shipment["weight_units"],
                    });
                  }
                }}
                disabled={inboundShipmentType === "existing"}
                placeholder="Select a weight unit"
                value={getShipment("inbound")?.weight_units || ""}
                searchable
              />
            </Grid.Col>
            <Grid.Col sm={12} md={6}>
              <Select
                label="Dimension Units"
                name="dimension_units"
                required={isRequired("dimension_units")}
                data={formattedDimensionsUnits}
                onChange={(value) => {
                  if (value) {
                    updateShipment("inbound", {
                      dimension_units: value as Shipment["dimension_units"],
                    });
                  }
                }}
                disabled={inboundShipmentType === "existing"}
                placeholder="Select a dimension unit"
                value={getShipment("inbound")?.dimension_units || ""}
                searchable
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Select
                name="confirmation"
                label="Confirmation"
                data={shipmentConfirmationTypes(
                  getShipment("inbound")?.carrier_code === "fedex"
                )}
                onChange={(value) => {
                  if (value) {
                    updateShipment("inbound", {
                      confirmation: value as Shipment["confirmation"],
                    });
                  }
                }}
                value={getShipment("inbound")?.confirmation || "none"}
              />
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>
    </EditView>
  );
}

export default EditKit;
