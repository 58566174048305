import React from "react";
import Row from "react-bootstrap/Row";

interface LandingBodyType {
  children: JSX.Element;
}

function Body(props: LandingBodyType): JSX.Element {
  return (
    <Row>
      {props.children}
    </Row>
  );
}

export default Body;
