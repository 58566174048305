import React, {
  FormEventHandler,
  useEffect,
  useState,
  SyntheticEvent,
} from "react";
import { useNavigate, Params, useParams, } from "react-router-dom";
import { AxiosError, AxiosResponse, } from "axios";
import {
  useAlert, useSearch, useSettings, useUser,
} from "hooks";
import { AlertContextProps, } from "contexts/AlertContext";
import { axios, } from "libs";
import { Button, Grid, TextInput, } from "@mantine/core";
import { Loading, } from "components";
import { User as UserType, } from "interfaces/user";
import TextDisplay from "components/Display/TextDisplay";
import { Details as DetailsView, Default, } from "components/Views";
import IntegratedListView from "components/Views/IntegratedListView";
import { getModuleCompleteness, } from "utils/methods";
import { Organization, } from "interfaces/main";
import { getFormattedTimeString, } from "utils/formatting";
import { resolveTheme, } from "themes/main";
import { UserRoles, } from "contexts/UserContext";

function User(): JSX.Element {
  const { organization_id, user_id, }: Params = useParams();

  const [loading, setLoading] = useState(true);

  const [user, setUser] = useState<UserType>();

  // eslint-disable-next-line no-unused-vars
  const userContext = useUser();
  const navigate = useNavigate();

  const { hasRole, } = userContext;

  const alertContext: AlertContextProps = useAlert();
  const { getTable, } = useSearch();
  const { sort, } = getTable("organizations");

  useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
    //  Get user configuration
    setLoading(true);
    axios
      .get(`/organizations/${organization_id}/users/${user_id}`)
      .then(onSuccess)
      .catch(onError)
      .finally(() => setLoading(false));
  };

  const onSuccess = (response: AxiosResponse) => {
    setUser(response.data.data);
  };

  const onError = (axiosError: AxiosError) => {
    if (axiosError.response) {
      alertContext.setAlert({
        type: "danger",
        message: axiosError.response.data.message,
      });
    }
  };

  const deleteUser: FormEventHandler = (
    event: SyntheticEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    alertContext.setConfirmation(
      "Are you sure you want to delete this user?",
      () => {
        setLoading(true);
        axios
          .delete(`/organizations/${organization_id}/users/${user_id}`)
          .then(() => {
            alertContext.setAlert({
              type: "success",
              heading: "Success",
              message: "User deleted successfully",
            });
            navigate(`/organizations/${organization_id}/users`);
          })
          .catch(onError)
          .finally(() => setLoading(false));
      }
    );
  };

  const [newPassword, setNewPassword] = useState({
    password: "",
    password_confirmation: "",
  });

  const submitPasswordReset = () => {
    setLoading(true);
    axios
      .put(`/organizations/${organization_id}/users/${user_id}`, {
        password: newPassword.password,
        password_confirmation: newPassword.password_confirmation,
      })
      .then(() => {
        alertContext.setAlert({
          type: "success",
          heading: "Success",
          message: "Password reset successfully",
        });
        setNewPassword({
          password: "",
          password_confirmation: "",
        });
      })
      .catch(onError)
      .finally(() => setLoading(false));
  };

  const getSubtitle = (org: Organization) => {
    if (!sort.field) {
      return org.updated_at ?
        `Last update at ${getFormattedTimeString(org.updated_at)}` :
        `Created at ${getFormattedTimeString(org.created_at)}`;
    }
    return `${sort.field}: ${org[sort.field as keyof Organization]}`;
  };

  const organizationList = user?.organizations.map((org) => ({
    id: org.id,
    title: org.name,
    subtitle: getSubtitle(org),
    completeness: getModuleCompleteness(org) || 0,
    endpoint: `/organizations/${org.id}`,
    full_record: org,
  }));

  const { theme: { current, }, } = useSettings();
  const currentTheme = resolveTheme(current);
  const { colors, } = currentTheme;

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <DetailsView
        loading={loading}
        title={user?.name || "User"}
        buttons={[
          {
            text: "Edit",
            handleClick: () => {
              navigate("edit");
            },
            color: colors.primary,
            variant: "default",
            invisible: !userContext.hasRole("poweruser"),
          },
          {
            text: "Delete",
            handleClick: deleteUser,
            color: colors.danger,
            variant: "filled",
            invisible: !userContext.hasRole("poweruser"),
          }
        ]}
      >
        <Grid>
          <Grid.Col sm={12} md={6}>
            <TextDisplay label="Email" data={user?.email} />
          </Grid.Col>
          <Grid.Col sm={12} md={6}>
            <TextDisplay label="Role" data={user?.role.name} />
          </Grid.Col>
        </Grid>
      </DetailsView>
      <IntegratedListView
        title="Assigned Organizations"
        search
        loading={loading}
        collapsable
        table_name="assigned_organizations"
        full_record_list={user?.organizations || []}
        list_items={organizationList || []}
      />
      {
        user?.role.name && hasRole(user?.role.name as UserRoles) && (
        <Default
          title="Password Reset"
        >
          <Grid>
            <Grid.Col sm={12} md={6}>
              <TextInput
                label="New Password"
                type="password"
                value={newPassword.password}
                required
                onChange={(event) => {
                  setNewPassword({
                    ...newPassword,
                    password: event.currentTarget.value,
                  });
                }}
              />
            </Grid.Col>
            <Grid.Col sm={12} md={6}>
              <TextInput
                label="Confirm New Password"
                type="password"
                value={newPassword.password_confirmation}
                onChange={(event) => {
                  setNewPassword({
                    ...newPassword,
                    password_confirmation: event.currentTarget.value,
                  });
                }}
                required
              />
              {
                newPassword.password !== newPassword.password_confirmation && (
                <span style={{ fontSize: "13px", }} className="text-danger">
                  Passwords do not match
                </span>
                )
              }
            </Grid.Col>
            <Grid.Col sm={12}>
              <Button
                color="digitalPurple"
                variant="filled"
                onClick={submitPasswordReset}
              >
                Reset Password
              </Button>
            </Grid.Col>
          </Grid>
        </Default>
        )
      }
    </>
  );
}

export default User;
