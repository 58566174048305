import React from "react";
import ShipAKit from "./ShipAKit";

export const AllWizards: {
  "ship-a-kit": React.FC;
} = {
  "ship-a-kit": ShipAKit,
};

function index() {
  return (
    <div>
      Wizards
    </div>
  );
}

export default index;
