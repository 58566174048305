/* eslint-disable react/no-danger */
import React, { useEffect, useState, } from "react";
import { Default, } from "components/Views";
import {
  Grid,
} from "@mantine/core";
import { Note, NotePhoto, } from "interfaces/main";
/** @jsxImportSource @emotion/react */
import { css, } from "@emotion/react";
import { filterDangerousHtml, } from "utils/methods";
import { axios, } from "libs";
import { useSettings, } from "hooks";
import { api_url, } from "vars/server";
import { resolveTheme, } from "themes/main";

interface NotesProps {
  notes: Note[];
  // eslint-disable-next-line no-unused-vars
  disabled?: boolean;
}

Notes.defaultProps = {
  disabled: false,
};

function Notes({
  notes,
  disabled = false,
}: NotesProps) {
  const today = new Date();

  const sortedNotes = notes.sort((a: Note, b: Note) => {
    const aDate = new Date(a.updated_at || a.created_at || today);
    const bDate = new Date(b.updated_at || a.created_at || today);
    return bDate.getTime() - aDate.getTime();
  });

  return (
    <Default title="Notes" collapsable>
      <Grid>
        {!!sortedNotes.length && (
          <Grid.Col span={12}>
            <ul
              css={css`
                list-style: none;
                padding: 0;
              `}
            >
              {sortedNotes?.map((note, index) => (
                <NoteItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={note.note + index}
                  note={note}
                  disabled={disabled}
                />
              ))}
            </ul>
          </Grid.Col>
        )}
      </Grid>
    </Default>
  );
}

export default Notes;

interface NoteItemProps {
  note: Note;
  disabled?: boolean;
}

NoteItem.defaultProps = {
  disabled: false,
};

function NoteItem({
  note,
  disabled,
}: NoteItemProps) {
  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);

  const formatTimestamp = (timestamp: string) => {
    // change 2022-10-03T22:04:38.000000Z to 10/03/2022 10:04 PM
    const date = new Date(timestamp);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${month}/${day}/${year} ${formattedHours}:${formattedMinutes} ${ampm}`;
  };

  const timestamp =
    (note.updated_at ? formatTimestamp(note.updated_at) : null) ||
    (note.created_at ? formatTimestamp(note.created_at) : null);

  const edited = !(note.updated_at === note.created_at);

  return (
    <div
      css={css`
        border-left: 3px solid #eaeaea;
        margin-bottom: 14px;
        padding: 22px 14px 8px 14px;
        font-family: "Roboto", sans-serif;
        position: relative;
        transition: all 0.2s ease-in-out;
        border-radius: 2px;

        .editing {
          padding-bottom: 24px;
        }

        &:hover {
          background-color: ${currentTheme.colors.background};
        }

        p {
          margin: 0;
          padding: 0;
          color: ${currentTheme.colors.text};
        }

        .buttons {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          position: absolute;
          right: 14px;
          top: 5px;

          &-editing {
            top: initial;
            bottom: 5px;
            right: 14px;
          }

          .icon {
            font-size: 16px;
            margin-left: 4px;
            text-shadow: 0.2px 0.2px 4px rgba(0, 0, 0, 0.1);
            padding: 4px;
            transition: all 0.2s ease-in-out;
            opacity: ${disabled ? 0.5 : 1};

            &:hover {
            text-shadow: ${disabled ?
      // eslint-disable-next-line indent
          "" :
      /* eslint-disable-next-line indent */
          "0.2px 0.2px 10px rgba(0, 0, 0, 0.2)"};
            }
          }
        }

        .edit {
          color: ${currentTheme.colors.primary}80;

          &:hover {
            color: ${disabled ? "" : currentTheme.colors.primary};
          }
        }

        .delete {
          color: ${currentTheme.colors.danger};
          filter: brightness(0.8);

          &:hover {
            color: ${disabled ? "" : currentTheme.colors.danger};
            filter: brightness(1);
          }
        }

        .add-photo {
          color: ${currentTheme.colors.info}80;

          &:hover {
            color: ${disabled ? "" : currentTheme.colors.info};
          }
        }

        .timestamp {
          font-size: 10px;
          color: ${currentTheme.colors.textLight};
          position: absolute;
          left: 14px;
          top: 4px;
          padding: 0;
        }

        .photos {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 10px;
          // grid-auto-rows: 80px;
          margin-top: 10px;
          list-style: none;
          padding: 0;
        }

        .photo {
          width: 100%;
          aspect-ratio: 1/1;
        }
      `}
      key={note.note}
    >
      <div className="note-text">
        <span className="timestamp">
          {timestamp}
          {"   "}
          {edited && <span>(edited)</span>}
        </span>
        <p
          dangerouslySetInnerHTML={{
            __html: filterDangerousHtml(note.note),
          }}
        />
      </div>
      <ul className="photos">
        {note.photos?.map((p) => {
          const key = `${note.note}-${p.id}`;
          return (
            <li key={key} className="photo">
              <Photo
                photo={p}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
}

interface PhotoProps {
  photo: NotePhoto;
}

function Photo({ photo, }: PhotoProps) {
  const isSVG = () => photo.file_name.endsWith(".svg");
  const [svg, setSvg] = useState<string>("");
  useEffect(() => {
    if (isSVG()) {
      axios.get(photo.path).then((res) => {
        setSvg(res.data);
      });
    }
  }, []);
  const [showPhotoOverlay, setShowPhotoOverlay] = useState(false);

  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);

  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
        cursor: pointer;
        border-radius: 4px;
        outline: 1px solid #e0e0e0;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        box-shadow: 0.2px 0.2px 15px 0 rgba(0, 0, 0, 0.1);

        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0;
          transition: 0.5s ease;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 5;

          &:hover {
            background-color: ${currentTheme.colors.background};
            opacity: 0.9;
          }

          p {
            color: ${currentTheme.colors.text};
            font-size: 12px;
            text-align: center;
            margin: 0;
            padding: 0;
          }

          .icon {
            position: absolute;
            padding: 5px;
            color: ${currentTheme.colors.text};
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            border: none;
            box-sizing: border-box;

            i {
              font-size: 16px;
            }
          }

          .delete {
            top: 2px;
            right: 2px;
            cursor: pointer;
            transition: 0.3s ease;
            opacity: 0.8;

            &:hover {
              opacity: 1;
            }
          }
        }
      `}
    >
      {isSVG() ? (
        <div
          css={css`
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
          dangerouslySetInnerHTML={{ __html: svg, }}
        />
      ) : (
        <img
          src={`${api_url}${photo.path}`}
          alt={photo.file_name}
          css={css`
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            font-weight: 300;
          `}
        />
      )}
      <div
        className="overlay"
        onClick={() => {
          setShowPhotoOverlay(true);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setShowPhotoOverlay(true);
          }
        }}
        role="button"
        tabIndex={0}
      >
        <p>{photo.file_name}</p>
      </div>
      {showPhotoOverlay && (
        <PhotoOverlay
          photo={photo}
          setShowPhotoOverlay={setShowPhotoOverlay}
        />
      )}
    </div>
  );
}

interface PhotoOverlayProps {
  photo: NotePhoto;
  // eslint-disable-next-line no-unused-vars
  setShowPhotoOverlay: (show: boolean) => void;
}

function PhotoOverlay({
  photo,
  setShowPhotoOverlay,
}: PhotoOverlayProps) {
  const isSVG = () => photo.file_name.endsWith(".svg");
  const [svg, setSvg] = useState<string>("");

  useEffect(() => {
    if (isSVG()) {
      axios.get(photo.path).then((res) => {
        setSvg(res.data);
      });
    }
  }, []);

  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);

  return (
    <div
      css={css`
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${currentTheme.colors.background}90;
        z-index: 5000;
        display: flex;
        justify-content: center;
        align-items: center;

        .icons {
          position: absolute;
          top: 14px;
          right: 36px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px;
          z-index: 5001;
          cursor: pointer;
          background-color: ${currentTheme.colors.background}90;
          border-radius: 10px;

          button {
            background-color: transparent;
            border: none;
            color: ${currentTheme.colors.text};
            transition: 0.3s ease;
            opacity: 0.8;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
              opacity: 1;
            }
          }
        }
      `}
      onClick={() => {
        setShowPhotoOverlay(false);
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          setShowPhotoOverlay(false);
        }
      }}
      role="button"
      tabIndex={0}
    >
      <div
        css={css`
          padding-block: 36px;
          width: 90%;
          height: 80%;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: scroll;
          scrollbar-width: none;
        `}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
        role="button"
        tabIndex={0}
      >
        {isSVG() ? (
          <div
            css={css`
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            `}
            dangerouslySetInnerHTML={{ __html: svg, }}
          />
        ) : (
          <img
            src={`${api_url}${photo.path}`}
            alt={photo.file_name}
            css={css`
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 12px;
              font-weight: 300;
            `}
          />
        )}
      </div>
      <div className="icons">
        <button
          type="button"
          onClick={() => {
            setShowPhotoOverlay(false);
          }}
          title="Close photo preview"
        >
          <i className="material-symbols-outlined">close</i>
        </button>
      </div>
    </div>
  );
}
