import React, { useEffect, } from "react";
import { useOfflineServices, } from "hooks";

interface OfflineProps {
  children: React.ReactNode;
  // eslint-disable-next-line no-unused-vars
  onChange?: (isOnline: boolean) => void;
  className?: string;
  style?: React.CSSProperties;
}

Offline.defaultProps = {
  onChange: () => {},
  className: "",
  style: {},
};

function Offline({
  children, onChange, className, style,
}: OfflineProps) {
  const { isOnline, } = useOfflineServices();

  useEffect(() => {
    if (onChange) {
      onChange(isOnline);
    }
  }, [isOnline]);

  return isOnline ? null : <div className={className} style={style}>{children}</div>;
}

export default Offline;
