/* eslint-disable react/no-danger */
import React, { useEffect, useState, } from "react";
import { Default, } from "components/Views";
import {
  Button,
  Dialog,
  FileInput,
  Flex,
  Grid,
  Group,
  Text,
} from "@mantine/core";
import { Note, NotePhoto, } from "interfaces/main";
/** @jsxImportSource @emotion/react */
import { css, } from "@emotion/react";
import { filterDangerousHtml, } from "utils/methods";
import { axios, } from "libs";
import { useAlert, useSettings, } from "hooks";
import { api_url, } from "vars/server";
import { resolveTheme, } from "themes/main";
import { handleRequestError, } from "utils/requests";
import TextEditor from "components/RichTextEditor";

interface NotesProps {
  notes: Note[];
  // eslint-disable-next-line no-unused-vars
  submitNotes: (notes: Note[]) => Promise<Note[] | undefined>;
  // eslint-disable-next-line no-unused-vars
  photo_endpoint: string;
  refreshNotes: () => void;
  disabled?: boolean;
}

Notes.defaultProps = {
  disabled: false,
};

function Notes({
  notes,
  submitNotes,
  photo_endpoint,
  refreshNotes,
  disabled = false,
}: NotesProps) {
  const alertContext = useAlert();
  const [newNote, setNewNote] = useState<Note>({
    note: "",
    photos: [],
  });

  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);

  const [photosToAttach, setPhotosToAttach] = useState<File[]>([]);

  const sortedNotesByID = (nts: Note[]) => {
    const sorted = nts.sort((a, b) => {
      if (!a.id || !b.id) return 0;
      return a.id - b.id;
    });
    return sorted;
  };

  const handleSubmit = async () => {
    const realNewNote = {
      ...newNote,
      // eslint-disable-next-line no-nested-ternary
      note: newNote.note ? newNote.note : photosToAttach.length ? "image" : "",
    };
    const newNotes = [
      ...notes,
      {
        ...realNewNote,
      }
    ];
    const fromSubmit = await submitNotes(newNotes);
    setNewNote({
      note: "",
      photos: [],
    });
    if (fromSubmit) {
      const sorted = sortedNotesByID(fromSubmit);
      photosToAttach.forEach((photo) => {
        addPhotoToNoteByID(
          (sorted[sorted.length - 1] as Note).id as number,
          photo
        );
      });
      setPhotosToAttach([]);
    }
  };

  const removeNote = (index: number) => {
    alertContext.setConfirmation(
      "Are you sure you want to delete this note?",
      () => {
        const newNotes = [...notes];
        newNotes.splice(index, 1);
        submitNotes(newNotes);
      }
    );
  };

  const updateNote = (index: number, note: string) => {
    const newNotes = [...notes];
    newNotes[index].note = note;
    submitNotes(newNotes);
  };

  const addPhotoToNote = async (index: number, photo: File) => {
    const note = notes[index];
    if (!note.id) {
      alertContext.setAlert({
        type: "danger",
        message: "Error adding photo",
      });
      return;
    }
    const formData = new FormData();
    formData.append("photo", photo);
    formData.append("parent_id", note.id.toString());
    formData.append("module", "notes");

    axios
      .post(photo_endpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        alertContext.setAlert({
          type: "success",
          message: "Photo added successfully",
        });
        refreshNotes();
      })
      .catch((err) => {
        handleRequestError(err, alertContext.setAlert);
      });
  };

  const addPhotoToNoteByID = async (id: number, photo: File) => {
    if (!id) {
      alertContext.setAlert({
        type: "danger",
        message: "Error adding photo",
      });
      return;
    }
    const formData = new FormData();
    formData.append("photo", photo);
    formData.append("parent_id", id.toString());
    formData.append("module", "notes");

    axios
      .post(photo_endpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        alertContext.setAlert({
          type: "success",
          message: "Photo added successfully",
        });
        refreshNotes();
      })
      .catch((err) => {
        handleRequestError(err, alertContext.setAlert);
      });
  };

  const removePhotoFromNote = (index: number, photo: NotePhoto) => {
    const note = notes[index];
    if (!note.id) {
      alertContext.setAlert({
        type: "danger",
        message: "Error removing photo",
      });
      return;
    }
    axios
      .delete(`${photo_endpoint}/${photo.id}`)
      .then(() => {
        alertContext.setAlert({
          type: "success",
          message: "Photo removed successfully",
        });
      })
      .catch((err) => {
        handleRequestError(err, alertContext.setAlert);
      });

    const newNotes = [...notes];
    newNotes[index].photos = newNotes[index].photos.filter(
      (p) => p.id !== photo.id
    );
    submitNotes(newNotes);
  };

  const today = new Date();

  const sortedNotes = notes.sort((a: Note, b: Note) => {
    const aDate = new Date(a.updated_at || a.created_at || today);
    const bDate = new Date(b.updated_at || a.created_at || today);
    return bDate.getTime() - aDate.getTime();
  });

  const getUserPhoto = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (e) => {
      const { files, } = e.target as HTMLInputElement;
      if (!files) return;
      setPhotosToAttach([...photosToAttach, files[0]]);
      input.remove();
    };
    input.click();
  };

  return (
    <Default title="Notes" collapsable>
      <Grid>
        {!!sortedNotes.length && (
          <Grid.Col span={12}>
            <ul
              css={css`
                list-style: none;
                padding: 0;
              `}
            >
              {sortedNotes?.map((note, index) => (
                <NoteItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={note.note + index}
                  note={note}
                  index={index}
                  removeNote={removeNote}
                  updateNote={updateNote}
                  addPhotoToNote={addPhotoToNote}
                  removePhotoFromNote={removePhotoFromNote}
                  disabled={disabled}
                />
              ))}
            </ul>
          </Grid.Col>
        )}
        <Grid.Col span={12}>
          <Flex direction="column">
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .icon {
                  font-size: 16px;
                  margin-left: 4px;
                  text-shadow: 0.2px 0.2px 4px rgba(0, 0, 0, 0.1);
                  padding: 4px;
                  transition: all 0.2s ease-in-out;
                  opacity: ${disabled ? 0.5 : 1};

                  &:hover {
                    text-shadow: ${disabled ?
                "" :
                "0.2px 0.2px 10px rgba(0, 0, 0, 0.2)"};
                  }
                }

                .add-photo {
                  color: ${currentTheme.colors.info}80;

                  &:hover {
                    color: ${disabled ? "" : currentTheme.colors.info};
                  }
                }
              `}
            >
              <i
                onClick={() => {
                  if (disabled) return;
                  getUserPhoto();
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (disabled) return;
                    getUserPhoto();
                  }
                }}
                className="material-icons icon add-photo"
                title="Add a photo"
                role="button"
                tabIndex={0}
              >
                photo
              </i>
            </div>
            <TextEditor
              placeHolder="Add note"
              onBlur={(v) => {
                setNewNote({
                  ...newNote,
                  note: v,
                });
              }}
              initialContent={newNote.note}
            />
          </Flex>
        </Grid.Col>
        <Grid.Col span={12}>
          <Flex justify="flex-end">
            <ul>
              {photosToAttach.map((photo) => (
                <li
                  css={css`
                    display: inline-block;
                    margin-right: 10px;
                    color: ${currentTheme.colors.text};
                  `}
                  key={photo.name}
                >
                  <span>{photo.name}</span>
                </li>
              ))}
            </ul>
          </Flex>
        </Grid.Col>
        <Grid.Col span={12}>
          <Flex justify="flex-end">
            <Button
              color={currentTheme.colors.primary}
              onClick={handleSubmit}
              disabled={disabled}
            >
              Add
            </Button>
          </Flex>
        </Grid.Col>
      </Grid>
    </Default>
  );
}

export default Notes;

interface NoteItemProps {
  note: Note;
  index: number;
  // eslint-disable-next-line no-unused-vars
  removeNote: (index: number) => void;
  // eslint-disable-next-line no-unused-vars
  updateNote: (index: number, note: string) => void;
  // eslint-disable-next-line no-unused-vars
  addPhotoToNote: (index: number, photo: File) => void;
  // eslint-disable-next-line no-unused-vars
  removePhotoFromNote: (index: number, photo: NotePhoto) => void;
  disabled?: boolean;
}

NoteItem.defaultProps = {
  disabled: false,
};

function NoteItem({
  note,
  index,
  removeNote,
  updateNote,
  addPhotoToNote,
  removePhotoFromNote,
  disabled,
}: NoteItemProps) {
  const [editing, setEditing] = useState(false);
  const [editedNote, setEditedNote] = useState(note.note);
  const [addingPhoto, setAddingPhoto] = useState(false);
  const [photo, setPhoto] = useState<File | null>(null);

  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);

  const formatTimestamp = (timestamp: string) => {
    // change 2022-10-03T22:04:38.000000Z to 10/03/2022 10:04 PM
    const date = new Date(timestamp);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${month}/${day}/${year} ${formattedHours}:${formattedMinutes} ${ampm}`;
  };

  const timestamp =
    (note.updated_at ? formatTimestamp(note.updated_at) : null) ||
    (note.created_at ? formatTimestamp(note.created_at) : null);

  const edited = !(note.updated_at === note.created_at);

  const submitNewPhoto = () => {
    setAddingPhoto(false);
    if (!photo) return;
    addPhotoToNote(index, photo);
  };

  return (
    <div
      css={css`
        border-left: 3px solid #eaeaea;
        margin-bottom: 14px;
        padding: 22px 14px 8px 14px;
        font-family: "Roboto", sans-serif;
        position: relative;
        transition: all 0.2s ease-in-out;
        border-radius: 2px;

        .editing {
          padding-bottom: 24px;
        }

        &:hover {
          background-color: ${currentTheme.colors.background};
        }

        p {
          margin: 0;
          padding: 0;
          color: ${currentTheme.colors.text};
        }

        .buttons {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          position: absolute;
          right: 14px;
          top: 5px;

          &-editing {
            top: initial;
            bottom: 5px;
            right: 14px;
          }

          .icon {
            font-size: 16px;
            margin-left: 4px;
            text-shadow: 0.2px 0.2px 4px rgba(0, 0, 0, 0.1);
            padding: 4px;
            transition: all 0.2s ease-in-out;
            opacity: ${disabled ? 0.5 : 1};

            &:hover {
              text-shadow: ${disabled ?
        "" :
        "0.2px 0.2px 10px rgba(0, 0, 0, 0.2)"};
            }
          }
        }

        .edit {
          color: ${currentTheme.colors.primary}80;

          &:hover {
            color: ${disabled ? "" : currentTheme.colors.primary};
          }
        }

        .delete {
          color: ${currentTheme.colors.danger};
          filter: brightness(0.8);

          &:hover {
            color: ${disabled ? "" : currentTheme.colors.danger};
            filter: brightness(1);
          }
        }

        .add-photo {
          color: ${currentTheme.colors.info}80;

          &:hover {
            color: ${disabled ? "" : currentTheme.colors.info};
          }
        }

        .timestamp {
          font-size: 10px;
          color: ${currentTheme.colors.textLight};
          position: absolute;
          left: 14px;
          top: 4px;
          padding: 0;
        }

        .photos {
          display: grid;
          grid-template-columns: repeat(8, 1fr);
          grid-gap: 10px;
          // grid-auto-rows: 80px;
          margin-top: 10px;
          list-style: none;
          padding: 0;
        }

        .photo {
          width: 100%;
          aspect-ratio: 1/1;
        }
      `}
      key={note.note}
    >
      <Dialog
        opened={addingPhoto}
        withCloseButton
        onClose={() => setAddingPhoto(false)}
      >
        <Text size="sm" style={{ marginBottom: 10, }} weight={500}>
          Upload a photo
        </Text>

        <Group align="flex-end">
          <FileInput
            placeholder="Upload your file"
            style={{ flex: 1, }}
            onChange={(f) => {
              setPhoto(f);
            }}
          />
          <Button
            onClick={() => {
              submitNewPhoto();
            }}
          >
            Add
          </Button>
        </Group>
      </Dialog>
      {!editing ? (
        <>
          <div className="note-text">
            <span className="timestamp">
              {timestamp}
              {"   "}
              {edited && <span>(edited)</span>}
            </span>
            <p
              dangerouslySetInnerHTML={{
                __html: filterDangerousHtml(note.note),
              }}
              css={css`
                cursor: pointer;
                white-space: pre-wrap;
              `}
              title={disabled ? "" : "Double click to edit"}
              onDoubleClick={() => {
                if (disabled) return;
                setEditing(true);
              }}
            />
          </div>
          {!disabled && (
            <div className="buttons">
              <i
                onClick={() => {
                  if (disabled) return;
                  removeNote(index);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (disabled) return;
                    removeNote(index);
                  }
                }}
                className="material-icons icon delete"
                title="Delete note"
                role="button"
                tabIndex={0}
              >
                delete
              </i>
              <i
                onClick={() => {
                  if (disabled) return;
                  setEditing(!editing);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (disabled) return;
                    setEditing(!editing);
                  }
                }}
                className="material-icons icon edit"
                title="Edit note"
                role="button"
                tabIndex={0}
              >
                edit
              </i>
              <i
                onClick={() => {
                  if (disabled) return;
                  setAddingPhoto(!addingPhoto);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (disabled) return;
                    setAddingPhoto(!addingPhoto);
                  }
                }}
                className="material-icons icon add-photo"
                title="Add a photo"
                role="button"
                tabIndex={0}
              >
                photo
              </i>
            </div>
          )}
          <ul className="photos">
            {note.photos?.map((p) => {
              const key = `${note.note}-${p.id}`;
              return (
                <li key={key} className="photo">
                  <Photo
                    photo={p}
                    index={index}
                    removePhoto={() => {
                      if (disabled) return;
                      removePhotoFromNote(index, p);
                    }}
                  />
                </li>
              );
            })}
          </ul>
        </>
      ) : (
        <div className="editing">
          <TextEditor
            placeHolder="Edit note"
            onBlur={(v) => {
              setEditedNote(v);
            }}
            initialContent={editedNote}
          />
          <div className="buttons buttons-editing">
            <i
              onClick={() => {
                if (disabled) return;
                setEditing(false);
                setEditedNote(note.note);
              }}
              onKeyDown={(e) => {
                if (disabled) return;
                if (e.key === "Enter") {
                  setEditing(false);
                  setEditedNote(note.note);
                }
              }}
              className="material-icons icon delete"
              title="Cancel"
              role="button"
              tabIndex={0}
            >
              cancel
            </i>
            <i
              onClick={() => {
                if (disabled) return;
                setEditing(false);
                updateNote(index, editedNote);
              }}
              onKeyDown={(e) => {
                if (disabled) return;
                if (e.key === "Enter") {
                  setEditing(false);
                  updateNote(index, editedNote);
                }
              }}
              className="material-icons icon edit"
              title="Submit edit"
              role="button"
              tabIndex={0}
            >
              check
            </i>
          </div>
        </div>
      )}
    </div>
  );
}

interface PhotoProps {
  photo: NotePhoto;
  index: number;
  // eslint-disable-next-line no-unused-vars
  removePhoto: (index: number, photo: NotePhoto) => void;
}

function Photo({ photo, index, removePhoto, }: PhotoProps) {
  const isSVG = () => photo.file_name.endsWith(".svg");
  const [svg, setSvg] = useState<string>("");
  useEffect(() => {
    if (isSVG()) {
      axios.get(photo.path).then((res) => {
        setSvg(res.data);
      });
    }
  }, []);
  const [showPhotoOverlay, setShowPhotoOverlay] = useState(false);

  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);

  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
        cursor: pointer;
        border-radius: 4px;
        outline: 1px solid #e0e0e0;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        box-shadow: 0.2px 0.2px 15px 0 rgba(0, 0, 0, 0.1);

        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0;
          transition: 0.5s ease;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 5;

          &:hover {
            background-color: ${currentTheme.colors.background};
            opacity: 0.9;
          }

          p {
            color: ${currentTheme.colors.text};
            font-size: 12px;
            text-align: center;
            margin: 0;
            padding: 0;
          }

          .icon {
            position: absolute;
            padding: 5px;
            color: ${currentTheme.colors.text};
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            border: none;
            box-sizing: border-box;

            i {
              font-size: 16px;
            }
          }

          .delete {
            top: 2px;
            right: 2px;
            cursor: pointer;
            transition: 0.3s ease;
            opacity: 0.8;

            &:hover {
              opacity: 1;
            }
          }
        }
      `}
    >
      {isSVG() ? (
        <div
          css={css`
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
          dangerouslySetInnerHTML={{ __html: svg, }}
        />
      ) : (
        <img
          src={`${api_url}${photo.path}`}
          alt={photo.file_name}
          css={css`
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            font-weight: 300;
          `}
        />
      )}
      <div
        className="overlay"
        onClick={() => {
          setShowPhotoOverlay(true);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setShowPhotoOverlay(true);
          }
        }}
        role="button"
        tabIndex={0}
      >
        <p>{photo.file_name}</p>
        <button
          onClick={(e) => {
            e.stopPropagation();
            removePhoto(index, photo);
          }}
          type="button"
          className="icon delete"
          title="Delete photo"
        >
          <i className="material-symbols-outlined">delete</i>
        </button>
      </div>
      {showPhotoOverlay && (
        <PhotoOverlay
          photo={photo}
          index={index}
          setShowPhotoOverlay={setShowPhotoOverlay}
          removePhoto={removePhoto}
        />
      )}
    </div>
  );
}

interface PhotoOverlayProps {
  photo: NotePhoto;
  index: number;
  // eslint-disable-next-line no-unused-vars
  setShowPhotoOverlay: (show: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  removePhoto: (index: number, photo: NotePhoto) => void;
}

function PhotoOverlay({
  photo,
  index,
  setShowPhotoOverlay,
  removePhoto,
}: PhotoOverlayProps) {
  const isSVG = () => photo.file_name.endsWith(".svg");
  const [svg, setSvg] = useState<string>("");

  useEffect(() => {
    if (isSVG()) {
      axios.get(photo.path).then((res) => {
        setSvg(res.data);
      });
    }
  }, []);

  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);

  return (
    <div
      css={css`
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${currentTheme.colors.background}90;
        z-index: 5000;
        display: flex;
        justify-content: center;
        align-items: center;

        .icons {
          position: absolute;
          top: 14px;
          right: 36px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px;
          z-index: 5001;
          cursor: pointer;
          background-color: ${currentTheme.colors.background}90;
          border-radius: 10px;

          button {
            background-color: transparent;
            border: none;
            color: ${currentTheme.colors.text};
            transition: 0.3s ease;
            opacity: 0.8;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
              opacity: 1;
            }
          }
        }
      `}
      onClick={() => {
        setShowPhotoOverlay(false);
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          setShowPhotoOverlay(false);
        }
      }}
      role="button"
      tabIndex={0}
    >
      <div
        css={css`
          padding-block: 36px;
          width: 90%;
          height: 80%;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: scroll;
          scrollbar-width: none;
        `}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
        role="button"
        tabIndex={0}
      >
        {isSVG() ? (
          <div
            css={css`
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            `}
            dangerouslySetInnerHTML={{ __html: svg, }}
          />
        ) : (
          <img
            src={`${api_url}${photo.path}`}
            alt={photo.file_name}
            css={css`
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 12px;
              font-weight: 300;
            `}
          />
        )}
      </div>
      <div className="icons">
        <button
          type="button"
          onClick={() => {
            setShowPhotoOverlay(false);
          }}
          title="Close photo preview"
        >
          <i className="material-symbols-outlined">close</i>
        </button>
        <button
          type="button"
          onClick={() => {
            removePhoto(index, photo);
          }}
          title="Delete photo"
        >
          <i className="material-symbols-outlined">delete</i>
        </button>
      </div>
    </div>
  );
}
