import React, { useEffect, useState, } from "react";
import { Loading, } from "components";
import { useNavigate, useParams, } from "react-router";
import { axios, } from "libs";
import { useUser, useSearch, useSettings, } from "hooks";
import { Custodian, } from "interfaces/main";
import IntegratedListView from "components/Views/IntegratedListView";
import { getFormattedCustodianName, getFormattedTimeString, } from "utils/formatting";
import { getModuleCompleteness, getModuleFields, } from "utils/methods";
import { resolveTheme, } from "themes/main";

function CustodianList() {
  const [custodians, setCustodians] = useState<Custodian[]>([]);
  const [loading, setLoading] = useState(false);
  const onSuccess = (res: any) => {
    setCustodians(res.data.data);
  };
  const onError = (err: any) => {
    // eslint-disable-next-line no-console
    console.error(err);
  };
  const { organization_id, matter_id, } = useParams();
  const { hasRole, } = useUser();
  const { getTable, } = useSearch();
  const { sort, } = getTable("custodians");

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/organizations/${organization_id}/matters/${matter_id}/custodians`)
      .then(onSuccess)
      .catch(onError)
      .finally(() => setLoading(false));
  }, [
    organization_id,
    matter_id
  ]);

  const allFields: (keyof Custodian)[] | undefined =
    custodians?.[0] &&
    (Object.keys(custodians[0]) as unknown as (keyof Custodian)[]);

  const allowedFields = new Map([
    ["id", 0],
    ["matter_id", 0],
    ["first_name", 0],
    ["last_name", 0],
    ["email", 0],
    ["created_at", 0],
    ["updated_at", 0]
  ]);

  const fields = getModuleFields(allowedFields, allFields);

  const getSubtitle = (Cus: Custodian) => {
    if (!sort.field) {
      return Cus.updated_at ?
        `Last update at ${getFormattedTimeString(Cus.updated_at)}` :
        `Created at ${getFormattedTimeString(Cus.created_at)}`;
    }
    return `${sort.field}: ${Cus[sort.field as keyof Custodian]}`;
  };

  const listItems = custodians.map((custodian) => ({
    id: custodian.id,
    title: getFormattedCustodianName(custodian),
    subtitle: getSubtitle(custodian),
    endpoint: `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian.id}`,
    completeness: getModuleCompleteness(custodian) || 0,
    full_record: custodian,
  }));

  const { theme: { current, }, } = useSettings();
  const currentTheme = resolveTheme(current);
  const { colors, } = currentTheme;

  if (loading) {
    return <Loading />;
  }

  return (
    <IntegratedListView
      title="Custodians"
      loading={loading}
      buttons={[
        {
          text: "",
          icon: "add",
          handleClick: () => navigate(
            `/organizations/${organization_id}/matters/${matter_id}/custodians/create`
          ),
          color: colors.primary,
          variant: "default",
          invisible: !hasRole("user"),
        }
      ]}
      search
      collapsable
      fields={fields}
      table_name="custodians"
      list_items={listItems}
      full_record_list={custodians}
    />
  );
}

export default CustodianList;
