import React, { useEffect, useState, } from "react";
import { Button, Modal, } from "@mantine/core";
import { useAlert, useSettings, } from "hooks";
/** @jsxImportSource @emotion/react */
import { css, } from "@emotion/react";
import { resolveTheme, } from "themes/main";

function Confirm() {
  const alertContext = useAlert();
  const { confirmation, removeConfirmation, } = alertContext;
  const [opened, setOpened] = useState(confirmation.show);

  const onClose = () => {
    setOpened(false);
    removeConfirmation();
  };

  const onConfirm = () => {
    confirmation.onConfirm();
    onClose();
  };

  useEffect(() => {
    setOpened(confirmation.show);
  }, [alertContext]);

  const { theme: { current, }, } = useSettings();
  const currentTheme = resolveTheme(current);

  return (
    <Modal opened={opened} onClose={onClose} title="Are you sure?">
      <div className="body">
        <div>{confirmation.message}</div>
      </div>
      <div css={css`
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;

        &>* {
            margin-left: 10px;
        }
      `}
      >
        <Button onClick={onClose} color={currentTheme.colors.info} variant="outline">
          No
        </Button>
        <Button onClick={onConfirm} color={currentTheme.colors.primary}>
          Yes
        </Button>
      </div>
    </Modal>
  );
}

export default Confirm;
