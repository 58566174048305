import React, { useEffect, useRef, useState, } from "react";
import { axios, } from "libs";
import { useParams, useNavigate, Link, } from "react-router-dom";
import DetailsView from "components/Views/DetailsView";
import { Loading, } from "components";
import { useAlert, useSettings, useUser, } from "hooks";
import {
  Custodian,
  Device,
  Kit as KitDefinition,
  Matter,
  Shipment,
  Task,
  Template,
} from "interfaces/main";
import {
  getFormattedAddress,
  getFormattedCustodianName,
  getFormattedMatterName,
} from "utils/formatting";
import TextDisplay from "components/Display/TextDisplay";
import {
  Button,
  Dialog,
  Flex,
  Grid,
  Modal,
  Select,
  Text,
  TextInput,
} from "@mantine/core";
import Tasks from "components/Tasks/Tasks";
import { getMatter, } from "utils/fetching";
import { kitStatusTypes, } from "vars/constants";
import { Default, } from "components/Views";
import KitManager from "components/KitManager/KitManager";
import { resolveTheme, } from "themes/main";
import InlineLoader from "components/Loading/Inline";
import AssignmentCard from "components/InfoCard/AssignmentCard";
/** @jsxImportSource @emotion/react */
import { css, } from "@emotion/react";
import { Copy, } from "phosphor-react";
import { copyToClipboard, } from "utils/methods";

function Kit() {
  const [kit, setKit] = useState<KitDefinition>();
  const [loading, setLoading] = useState(false);
  const { organization_id, matter_id, kit_id, } = useParams();
  const navigate = useNavigate();
  const alertContext = useAlert();
  const { hasRole, } = useUser();
  const [matter, setMatter] = useState<Matter>();
  const [devices, setDevices] = useState<Device[]>([]);
  // eslint-disable-next-line no-unused-vars
  const [assigningDevice, setAssigningDevice] = useState<boolean>(false);
  const [kitTasks, setKitTasks] = useState<Task[]>([]);
  const [custodians, setCustodians] = useState<Custodian[]>([]);
  const [assigningCustodian, setAssigningCustodian] = useState<boolean>(false);
  const [newCustodian, setNewCustodian] = useState<number>();

  const getKit = () => axios
    .get(
      `/organizations/${organization_id}/matters/${matter_id}/kits/${kit_id}`
    )
    .then((res) => {
      setKit(res.data.data);
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      alertContext.setAlert({
        type: "danger",
        message: "Error fetching kit",
      });
    });

  const getDevices = () => axios
    .get(`/organizations/${organization_id}/devices/available`)
    .then((res) => {
      setDevices(res.data.data);
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      alertContext.setAlert({
        type: "danger",
        message: "Error fetching devices",
      });
    });

  const getTasks = () => axios
    .get(
      `/organizations/${organization_id}/matters/${matter_id}/kits/${kit_id}/tasks`,
      { cache: false, }
    )
    .then((res) => {
      const tasks = [...res.data.data];
      setKitTasks(tasks);
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      alertContext.setAlert({
        type: "danger",
        message: "Error fetching tasks",
      });
    });

  const getCustodians = () => axios
    .get(`/organizations/${organization_id}/matters/${matter_id}/custodians`)
    .then((res) => {
      setCustodians(res.data.data);
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      alertContext.setAlert({
        type: "danger",
        message: "Error fetching custodians",
      });
    });

  const [emailTemplateHTML, setEmailTemplateHTML] = useState<string>(
    "<p>There was an error fetching the email template</p>"
  );

  const getEmailTemplate = () => {
    const endpoint = kit?.recallable ? "recall" : "dispatch";
    axios
      .get(
        `/organizations/${organization_id}/matters/${matter_id}/kits/${kit_id}/email/${endpoint}`
      )
      .then((res) => {
        setEmailTemplateHTML(res.data);
      })
      .catch((err) => {
        console.error(err);
        alertContext.setAlert({
          type: "danger",
          message: "Error getting email template",
        });
        setEmailTemplateHTML(
          "<p>There was an error fetching the email template</p>"
        );
      });
  };

  useEffect(() => {
    setLoading(true);
    getKit().finally(() => setLoading(false));
    getDevices();
    getTasks();
    getCustodians();
    if (organization_id && matter_id) {
      getMatter(organization_id, matter_id).then((res) => {
        setMatter(res);
      });
      getEmailTemplate();
    }
  }, [organization_id, matter_id, kit_id]);

  const handleDelete = () => {
    alertContext.setConfirmation(
      "Are you sure you want to delete this kit?",
      () => {
        setLoading(true);
        axios
          .delete(
            `/organizations/${organization_id}/matters/${matter_id}/kits/${kit?.id}`
          )
          .then(() => {
            alertContext.setAlert({
              type: "success",
              message: "Kit deleted successfully",
            });
            navigate(`/organizations/${organization_id}/matters/${matter_id}`);
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.error(err);
            alertContext.setAlert({
              type: "danger",
              message: "Error deleting kit",
            });
            setLoading(false);
          });
      }
    );
  };

  const tasksWithLink = kitTasks?.map((task) => ({
    ...task,
    link: `/organizations/${organization_id}/matters/${matter_id}/kits/${kit_id}/tasks/${task.id}`,
    endpoint: `/organizations/${organization_id}/matters/${matter_id}/kits/${kit_id}/tasks/${task.id}`,
  }));

  // const existingDevices = devices?.filter((device) => {
  //   const passed = kit?.id === device.kit_id;
  //   return passed;
  // });

  // const availableDevices = devices?.filter((device) => {
  //   const passed = !device.kit_id;
  //   return passed;
  // });

  const assignDevicesToKit = (device_ids: number[]) => axios
    .post(
      `/organizations/${organization_id}/matters/${matter_id}/kits/${kit_id}/assign/devices`,
      {
        attach: [...device_ids],
      }
    )
    .then(() => {
      alertContext.setAlert({
        type: "success",
        message: "Device assigned to kit successfully",
      });
      setAssigningDevice(false);
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      alertContext.setAlert({
        type: "danger",
        message: "Error assigning device to kit",
      });
    });

  const unassignDevicesFromKit = (device_ids: number[]) => axios
    .post(
      `/organizations/${organization_id}/matters/${matter_id}/kits/${kit_id}/assign/devices`,
      {
        detach: [...device_ids],
      }
    )
    .then(() => {
      alertContext.setAlert({
        type: "success",
        message: "Device unassigned from kit successfully",
      });
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      alertContext.setAlert({
        type: "danger",
        message: "Error unassigning device from kit",
      });
    });
  const assignCustodiansToKit = (custodian_ids?: number[]) => axios
    .post(
      `/organizations/${organization_id}/matters/${matter_id}/kits/${kit_id}/assign/custodians`,
      {
        attach: custodian_ids || [newCustodian],
      }
    )
    .then(() => {
      alertContext.setAlert({
        type: "success",
        message: "Custodian assigned to kit successfully",
      });
      getCustodians();
      setAssigningCustodian(false);
      getKit();
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      alertContext.setAlert({
        type: "danger",
        message: "Error assigning custodian to kit",
      });
    });
  const unassignCustodianFromKit = (custodian_id: number) => {
    // use this route organizations/{organization}/matters/{matter}/kits/{kit}/assign
    axios
      .post(
        `/organizations/${organization_id}/matters/${matter_id}/kits/${kit_id}/assign/custodians`,
        {
          detach: [custodian_id],
        }
      )
      .then(() => {
        alertContext.setAlert({
          type: "success",
          message: "Custodian unassigned from kit successfully",
        });
        getCustodians();
        getKit();
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        alertContext.setAlert({
          type: "danger",
          message: "Error unassigning custodian from kit",
        });
      });
  };

  const custodianIDs = kit?.custodians?.map((custodian) => custodian.id);
  const addableCustodians = custodians?.filter((custodian) => {
    const passed = !custodianIDs?.includes(custodian.id);
    return passed;
  });

  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);
  const { colors, } = currentTheme;

  const disabled = !!kit?.shipments.some((s) => !!s.tracking_number);

  const [outboundErrors, setOutboundErrors] = useState<string[]>([]);
  const [outboundLoading, setOutboundLoading] = useState<boolean>(false);
  const handleCreateOutboundLabel = () => {
    alertContext.setConfirmation(
      "Are you sure you want to create an outbound label? This will lock the kit and prevent any further changes.",
      () => {
        setOutboundLoading(true);
        axios
          .post(
            `/organizations/${organization_id}/matters/${matter_id}/kits/${kit_id}/label/create/outbound`
          )
          .then(() => {
            alertContext.setAlert({
              type: "success",
              message: "Outbound label created successfully",
            });
            getKit();
          })
          .catch((err) => {
            console.error(err);
            setOutboundErrors([
              err.response?.data?.errors?.join(", ") ||
                err.response?.data?.message ||
                "There was an error generating the label."
            ]);
            alertContext.setAlert({
              type: "danger",
              message: "Error creating outbound label",
            });
          })
          .finally(() => {
            setOutboundLoading(false);
          });
      }
    );
  };

  const [inboundErrors, setInboundErrors] = useState<string[]>([]);
  const [inboundLoading, setInboundLoading] = useState<boolean>(false);
  const handleCreateInboundLabel = () => {
    alertContext.setConfirmation(
      "Are you sure you want to create an inbound label? This will lock the kit and prevent any further changes.",
      () => {
        setInboundLoading(true);
        axios
          .post(
            `/organizations/${organization_id}/matters/${matter_id}/kits/${kit_id}/label/create/inbound`
          )
          .then(() => {
            alertContext.setAlert({
              type: "success",
              message: "Inbound label created successfully",
            });
            getKit();
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.error(err);
            setInboundErrors([
              err.response?.data?.errors?.join(", ") ||
                err.response?.data?.message ||
                "There was an error generating the label."
            ]);
            alertContext.setAlert({
              type: "danger",
              message: "Error creating inbound label",
            });
          })
          .finally(() => {
            setInboundLoading(false);
          });
      }
    );
  };

  const [cancellingOutboundLabel, setCancellingOutboundLabel] =
    useState<boolean>(false);
  const handleCancelOutboundLabel = () => {
    alertContext.setConfirmation(
      "Are you sure you want to cancel the outbound label?",
      () => {
        setCancellingOutboundLabel(true);
        axios
          .post(
            `/organizations/${organization_id}/matters/${matter_id}/kits/${kit_id}/label/cancel/outbound`
          )
          .then(() => {
            alertContext.setAlert({
              type: "success",
              message: "Outbound label cancelled successfully",
            });
            getKit();
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.error(err);
            alertContext.setAlert({
              type: "danger",
              message: "Error cancelling outbound label",
            });
          })
          .finally(() => {
            setCancellingOutboundLabel(false);
          });
      }
    );
  };

  const [cancellingInboundLabel, setCancellingInboundLabel] =
    useState<boolean>(false);
  const handleCancelInboundLabel = () => {
    alertContext.setConfirmation(
      "Are you sure you want to cancel the inbound label?",
      () => {
        setCancellingInboundLabel(true);
        axios
          .post(
            `/organizations/${organization_id}/matters/${matter_id}/kits/${kit_id}/label/cancel/inbound`
          )
          .then(() => {
            alertContext.setAlert({
              type: "success",
              message: "Inbound label cancelled successfully",
            });
            getKit();
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.error(err);
            alertContext.setAlert({
              type: "danger",
              message: "Error cancelling inbound label",
            });
          })
          .finally(() => {
            setCancellingInboundLabel(false);
          });
      }
    );
  };

  const formatErrorString = (error: string) => {
    // transform "outbound_name is invalid" to "Outbound Name is invalid"
    // basically split the first word by "_", capitalize it, and then join it back together
    const split = error.split(" ");
    const firstWord = split[0].split("_");
    const capitalized = firstWord.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    const joined = capitalized.join(" ");
    split[0] = joined;
    return split.join(" ");
  };

  const getTrackingNumber = (bound: "inbound" | "outbound") => {
    if (!kit) {
      return "";
    }
    const shipment = kit.shipments.find((s) => s.type === bound);
    if (!shipment) {
      return "";
    }
    return shipment.tracking_number;
  };

  const getDownloadName = (bound: "inbound" | "outbound") => {
    const matterName = matter ? getFormattedMatterName(matter) : "";
    const kitName = kit ? kit.name : "";
    const trackingID = kit ? getTrackingNumber(bound) : "";
    const name = `${matterName} - ${kitName} - ${trackingID} (${bound})`;
    return name;
  };

  const handleDownloadOutboundLabel = () => {
    // get axios baseURL
    const { baseURL, } = axios.defaults;
    if (!baseURL) {
      throw new Error("axios baseURL is not set");
    }
    const downloadLink = document.createElement("a");
    downloadLink.href = `${baseURL}/organizations/${organization_id}/matters/${matter_id}/kits/${kit_id}/label/download/outbound`;
    downloadLink.download = getDownloadName("outbound");
    downloadLink.click();
  };

  const handleDownloadInboundLabel = () => {
    const { baseURL, } = axios.defaults;
    if (!baseURL) {
      throw new Error("axios baseURL is not set");
    }
    const downloadLink = document.createElement("a");
    downloadLink.href = `${baseURL}/organizations/${organization_id}/matters/${matter_id}/kits/${kit_id}/label/download/inbound`;
    downloadLink.download = getDownloadName("inbound");
    downloadLink.click();
  };

  const getShipment = (bound: "inbound" | "outbound") => {
    if (!kit) {
      return null;
    }
    return kit.shipments.find((s) => s.type === bound);
  };

  const outboundShipment = getShipment("outbound");
  const inboundShipment = getShipment("inbound");

  const getBoundTrackingStatus = (bound: "inbound" | "outbound") => {
    if (!kit) {
      return null;
    }
    const shipment = kit.shipments.find((s) => s.type === bound);
    if (!shipment) {
      return null;
    }
    return shipment.tracking_status;
  };

  const getAddress = (bound: "inbound" | "outbound", type: "from" | "to") => {
    if (!kit) {
      return null;
    }
    const shipment = kit.shipments.find((s) => s.type === bound);
    if (!shipment) {
      return null;
    }
    return shipment[`${type}_address`];
  };

  const updateShipment = (
    bound: "outbound" | "inbound",
    update: Partial<Shipment>
  ) => {
    if (!kit) {
      return;
    }
    const newShipments = kit?.shipments?.map((shipment) => {
      if (shipment.type === bound) {
        return { ...shipment, ...update, };
      }
      return shipment;
    });
    if (!newShipments) {
      return;
    }
    setKit({ ...kit, shipments: newShipments, });
  };

  const getUpdatedTrackingInfo = (bound: "outbound" | "inbound") => {
    axios
      .post(
        `/organizations/${organization_id}/matters/${matter_id}/kits/${kit_id}/shipments/${
          getShipment(bound)?.id
        }/track`
      )
      .then((res) => {
        updateShipment(bound, {
          ...res.data.data,
        });
      })
      .catch((err) => {
        console.error(err);
        alertContext.setAlert({
          type: "danger",
          message: "Error updating tracking info",
        });
      });
  };

  const handleCompleteKit = () => {
    if (!kit?.closable) {
      alertContext.setAlert({
        type: "danger",
        message: "Kit is not closable",
      });
    }
    axios
      .post(
        `/organizations/${organization_id}/matters/${matter_id}/kits/${kit_id}/complete`
      )
      .then(() => {
        getKit();
        alertContext.setAlert({
          type: "success",
          message: "Kit completed successfully",
        });
      })
      .catch((err) => {
        console.error(err);
        alertContext.setAlert({
          type: "danger",
          message: "Error completing kit",
        });
      });
  };

  const handleRecallKit = () => {
    if (!kit?.recallable) {
      alertContext.setAlert({
        type: "danger",
        message: "Kit is not recallable",
      });
    }
    axios
      .post(
        `/organizations/${organization_id}/matters/${matter_id}/kits/${kit_id}/recall`
      )
      .then(() => {
        getKit();
        alertContext.setAlert({
          type: "success",
          message: "Kit recalled successfully",
        });
      })
      .catch((err) => {
        console.error(err);
        alertContext.setAlert({
          type: "danger",
          message: "Error recalling kit",
        });
      });
  };

  const [showEmailTemplate, setShowEmailTemplate] = useState(false);

  const templateRef = useRef<HTMLDivElement>(null);

  const tasksDisabled = kit?.status === 8;

  const getDateValue = (date: string | null | undefined) => {
    if (!date) {
      return "None provided";
    }
    return new Date(date).toDateString();
  };

  const [creatingCustodian, setCreatingCustodian] = useState<boolean>(false);
  const [createdCustodian, setCreatedCustodian] = useState<Custodian>();
  const [custodianTemplates, setCustodianTemplates] = useState<Template[]>([]);
  const [custodianTemplateId, setCustodianTemplateId] = useState<number | null>(
    null
  );

  useEffect(() => {
    axios
      .get(`/organizations/${organization_id}/templates/type/custodians`)
      .then((res) => {
        setCustodianTemplates(res.data.data);
      })
      .catch((err) => {
        console.error(err);
        alertContext.setAlert({
          type: "danger",
          message: "Error fetching custodian templates",
        });
      })
      .finally(() => setLoading(false));
  }, []);

  const formattedCustodianTemplates = custodianTemplates.map((temp) => ({
    label: temp.name,
    value: temp.id.toString(),
  }));

  const createCustodian = (): void => {
    setLoading(true);
    axios
      .post(
        `/organizations/${organization_id}/matters/${matter_id}/custodians`,
        createdCustodian
      )
      .then((res) => {
        const cus = res.data.data as Custodian;
        alertContext.setAlert({
          type: "success",
          heading: "Success",
          message: "Custodian created successfully",
        });
        if (custodianTemplateId) {
          assignTemplate(cus.id);
        } else {
          assignCustodiansToKit([cus.id]);
        }
      })
      .catch((err) => {
        console.error(err);
        alertContext.setAlert({
          type: "danger",
          message: "Error creating custodian",
        });
      })
      .finally(() => {
        setLoading(false);
        setCreatingCustodian(false);
      });
  };

  const assignTemplate = (custodian_id: number) => {
    setLoading(true);
    axios
      .post(
        `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/template`,
        {
          template_id: custodianTemplateId,
        }
      )
      .then(() => {
        alertContext.setAlert({
          type: "success",
          heading: "Success",
          message: "Template assigned successfully",
        });
        assignCustodiansToKit([custodian_id]);
      })
      .catch((err) => {
        console.error(err);
        alertContext.setAlert({
          type: "danger",
          message: "Error assigning template",
        });
      });
  };

  const allTasksComplete =
    kitTasks.length > 0 && kitTasks.every((task) => task.status === 2);

  useEffect(() => {
    if (allTasksComplete) {
      getKit();
    }
  }, [kitTasks]);

  const outboundToAddress = getAddress("outbound", "to");
  const inboundToAddress = getAddress("inbound", "to");

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <DetailsView
        title={kit?.name || "Kit"}
        loading={loading}
        buttons={[
          {
            text: "View Email Template",
            handleClick: () => {
              setShowEmailTemplate(true);
            },
            color: "digitalPurple",
            variant: "subtle",
          },
          {
            text: "Recall",
            handleClick: handleRecallKit,
            color: colors.danger,
            variant: "outline",
            invisible: !kit?.recallable,
          },
          {
            text: "Check In",
            handleClick: handleCompleteKit,
            color: colors.success,
            variant: "filled",
            invisible: !kit?.closable,
          },
          {
            text: "Edit",
            handleClick: () => {
              navigate("edit");
            },
            color: colors.primary,
            variant: "default",
            disabled: !hasRole("restricted"),
          },
          {
            text: "Delete",
            handleClick: handleDelete,
            color: colors.danger,
            variant: "filled",
            disabled: !hasRole("poweruser"),
          }
        ]}
      >
        <Modal
          opened={showEmailTemplate}
          onClose={() => setShowEmailTemplate(false)}
          title="Email Template"
          size="lg"
        >
          <div
            css={css`
              padding: 24px;
              border: 1px solid ${colors.backgroundContrast};
              border-radius: 10px;
            `}
          >
            <div
              ref={templateRef}
              dangerouslySetInnerHTML={{ __html: emailTemplateHTML, }}
            />
          </div>
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 24px;
              margin-top: 24px;
              justify-content: flex-end;

              p {
                margin: 0;
              }

              button {
                height: 36px;
                width: 36px;
                border: 1px solid ${colors.backgroundContrast};
                background: transparent;
                color: ${colors.textLight};
                border-radius: 5px;
                transition: all 0.2s ease-in-out;
                cursor: pointer;
                box-sizing: border-box;

                &:hover {
                  border-color: ${colors.text};
                  color: ${colors.text};
                  transform: scale(1.02);
                }

                &:active {
                  transform: scale(0.98) translateY(2px);
                }
              }

              .option {
                display: flex;
                align-items: center;
                gap: 14px;
              }
            `}
          >
            <div className="option">
              <p>Copy Text</p>
              <button
                type="button"
                onClick={() => {
                  if (!templateRef.current) return;
                  // use select to copy text using selection
                  const selection = window.getSelection();
                  const range = document.createRange();
                  range.selectNodeContents(templateRef.current);
                  selection?.removeAllRanges();
                  selection?.addRange(range);
                  document.execCommand("copy"); // ! This is deprecated and we should find an alternative ASAP

                  alertContext.setAlert({
                    type: "success",
                    message: "Copied text to clipboard",
                  });
                }}
              >
                <Copy />
              </button>
            </div>
            <div className="option">
              <p>Copy HTML</p>
              <button
                type="button"
                onClick={() => {
                  copyToClipboard(emailTemplateHTML);
                  alertContext.setAlert({
                    type: "success",
                    message: "Copied html to clipboard",
                  });
                }}
              >
                <Copy />
              </button>
            </div>
          </div>
        </Modal>
        <Grid>
          <Grid.Col sm={12} md={6}>
            <TextDisplay
              label="Status"
              data={
                kit?.status ?
                  kitStatusTypes[kit?.status].label :
                  "None provided"
              }
            />
          </Grid.Col>
          <Grid.Col sm={12} md={3}>
            <Button
              onClick={() => {
                getUpdatedTrackingInfo("outbound");
              }}
              variant="default"
              style={{
                width: "100%",
                marginTop: "32px",
              }}
            >
              Update Outbound
            </Button>
          </Grid.Col>
          <Grid.Col sm={12} md={3}>
            <Button
              onClick={() => {
                getUpdatedTrackingInfo("inbound");
              }}
              variant="default"
              style={{
                width: "100%",
                marginTop: "32px",
              }}
            >
              Update Inbound
            </Button>
          </Grid.Col>
          <Grid.Col sm={12} md={3}>
            <TextDisplay
              label="Outbound Tracking ID"
              data={outboundShipment?.tracking_number || "None provided"}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={3}>
            <TextDisplay
              label="Outbound Status"
              data={getBoundTrackingStatus("outbound") || "None provided"}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={3}>
            <TextDisplay
              label="Outbound Address"
              data={
                outboundToAddress ?
                  getFormattedAddress(outboundToAddress) :
                  "None provided"
              }
            />
          </Grid.Col>
          <Grid.Col sm={12} md={3}>
            <TextDisplay
              label="Delivered At"
              data={getDateValue(getShipment("outbound")?.delivered_at)}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={3}>
            <TextDisplay
              label="Inbound Tracking ID"
              data={inboundShipment?.tracking_number || "None provided"}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={3}>
            <TextDisplay
              label="Inbound Status"
              data={getBoundTrackingStatus("inbound") || "None provided"}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={3}>
            <TextDisplay
              label="Inbound Address"
              data={
                inboundToAddress ?
                  getAddress("inbound", "to")?.street1 :
                  "None provided"
              }
            />
          </Grid.Col>
          <Grid.Col sm={12} md={3}>
            <TextDisplay
              label="Delivered At"
              data={getDateValue(getShipment("inbound")?.delivered_at)}
            />
          </Grid.Col>
        </Grid>
      </DetailsView>

      <Default
        title="Custodians"
        collapsable
        buttons={[
          {
            text: "Create Custodian",
            handleClick: () => {
              setCreatingCustodian(true);
            },
            color: colors.primary,
            variant: "filled",
          },
          {
            text: "Assign Custodian",
            handleClick: () => {
              setAssigningCustodian(true);
            },
            color: colors.primary,
            variant: "filled",
            disabled,
          }
        ]}
      >
        <Modal
          opened={creatingCustodian}
          onClose={() => {
            setCreatingCustodian(false);
          }}
          title="Create Custodian"
          size="lg"
        >
          <Grid>
            <Grid.Col span={6}>
              <TextInput
                label="First Name"
                name="first_name"
                onChange={(e) => {
                  if (!e.currentTarget.value) return;
                  setCreatedCustodian({
                    ...createdCustodian,
                    first_name: e.currentTarget.value,
                  } as Custodian);
                }}
                placeholder="First name..."
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="Last Name"
                name="last_name"
                onChange={(e) => {
                  if (!e.currentTarget.value) return;
                  setCreatedCustodian({
                    ...createdCustodian,
                    last_name: e.currentTarget.value,
                  } as Custodian);
                }}
                placeholder="Last name..."
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="Email"
                name="email"
                onChange={(e) => {
                  if (!e.currentTarget.value) return;
                  setCreatedCustodian({
                    ...createdCustodian,
                    email: e.currentTarget.value,
                  } as Custodian);
                }}
                placeholder="Email..."
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="Phone Number"
                name="phone_number"
                onChange={(e) => {
                  if (!e.currentTarget.value) return;
                  setCreatedCustodian({
                    ...createdCustodian,
                    phone_number: e.currentTarget.value,
                  } as Custodian);
                }}
                placeholder="Phone number..."
              />
            </Grid.Col>
            <Grid.Col sm={12} md={6}>
              <Select
                label="Template"
                name="template_id"
                data={formattedCustodianTemplates}
                onChange={(value) => {
                  if (value) {
                    setCustodianTemplateId(parseInt(value, 10));
                  }
                }}
                searchable
                placeholder="Select a template..."
              />
            </Grid.Col>
            <Grid.Col
              span={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                onClick={() => {
                  createCustodian();
                }}
                variant="filled"
                color={colors.primary}
              >
                Create
              </Button>
            </Grid.Col>
          </Grid>
        </Modal>
        <Dialog
          opened={assigningCustodian}
          withCloseButton
          onClose={() => setAssigningCustodian(false)}
          style={{ width: "100%", }}
          size="xs"
        >
          <Grid>
            {addableCustodians.length > 0 ? (
              <Grid.Col sm={12} md={12}>
                <Select
                  placeholder="Search for a custodian"
                  data={addableCustodians.map((custodian) => ({
                    value: String(custodian.id),
                    label: getFormattedCustodianName(custodian),
                  }))}
                  label="Custodian"
                  dropdownPosition="top"
                  value={newCustodian?.toString() || null}
                  onChange={(val) => {
                    setNewCustodian(Number(val));
                  }}
                  searchable
                />
              </Grid.Col>
            ) : (
              <Grid.Col sm={12}>
                <span>No custodians to add...</span>
              </Grid.Col>
            )}
            <Grid.Col sm={12}>
              <Flex justify="flex-end">
                <Button
                  onClick={() => {
                    assignCustodiansToKit();
                  }}
                  variant="filled"
                  color={colors.primary}
                  disabled={disabled}
                >
                  Assign
                </Button>
              </Flex>
            </Grid.Col>
          </Grid>
        </Dialog>
        <Grid>
          {kit?.custodians?.length ? (
            kit?.custodians?.map((cus) => (
              <Grid.Col key={cus.id + cus.email} sm={12}>
                <AssignmentCard
                  key={cus.id + cus.email}
                  record={{
                    id: cus.id,
                    title: getFormattedCustodianName(cus),
                    subtitle: cus.email || "No email provided",
                    link: `/organizations/${organization_id}/matters/${matter_id}/custodians/${cus.id}`,
                  }}
                  removeRecord={unassignCustodianFromKit}
                  editable={hasRole("poweruser") && !disabled}
                />
              </Grid.Col>
            ))
          ) : (
            <Grid.Col span={12}>
              <Text
                size="md"
                weight="300"
                color={colors.textLight}
                align="center"
              >
                This kit has no assigned custodians.
              </Text>
            </Grid.Col>
          )}
        </Grid>
      </Default>
      <Default title="Devices" collapsable>
        <KitManager
          kit={kit as KitDefinition}
          devices={devices}
          assignDevicesToKit={assignDevicesToKit}
          unassignDevicesFromKit={unassignDevicesFromKit}
          refreshKit={async () => {
            await getDevices();
            await getKit();
            return true;
          }}
          disabled={disabled}
          organization_id={Number(organization_id)}
        />
        <br />
        <Flex justify="end">
          <Button
            onClick={() => navigate(
              `/organizations/${organization_id}/matters/${matter_id}/kits/${kit_id}/picklist`
            )}
            variant="default"
          >
            Go to Picklist
          </Button>
        </Flex>
      </Default>
      <Default title="Labels" collapsable>
        <Grid>
          {outboundLoading ? (
            <Grid.Col span={12}>
              <InlineLoader text="Generating Outbound Label..." />
            </Grid.Col>
          ) : (
            <>
              {!outboundShipment?.tracking_number ? (
                <Grid.Col sm={12} md={8}>
                  <Button
                    onClick={handleCreateOutboundLabel}
                    variant="default"
                    disabled={!!outboundShipment?.labelData}
                  >
                    Generate Outbound Label
                  </Button>
                </Grid.Col>
              ) : (
                <>
                  <Grid.Col sm={12} md={3}>
                    <TextDisplay
                      label="Outbound Tracking Number"
                      data={outboundShipment?.tracking_number}
                    />
                  </Grid.Col>
                  <Grid.Col sm={12} md={3}>
                    <TextDisplay
                      label="Inbound Label Cost"
                      data={outboundShipment?.shipment_cost || "None provided"}
                    />
                  </Grid.Col>
                  <Grid.Col sm={12} md={3}>
                    <Button
                      style={{
                        marginTop: "32px",
                        width: "100%",
                      }}
                      onClick={handleDownloadOutboundLabel}
                      variant="default"
                      disabled={!outboundShipment?.labelData}
                    >
                      Download Label
                    </Button>
                  </Grid.Col>
                  <Grid.Col sm={12} md={3}>
                    {!cancellingOutboundLabel ? (
                      <Button
                        style={{
                          marginTop: "32px",
                          width: "100%",
                        }}
                        onClick={handleCancelOutboundLabel}
                        color={colors.danger}
                        variant="outline"
                        disabled={!kit?.status || kit?.status >= 3}
                      >
                        Cancel Label
                      </Button>
                    ) : (
                      <div
                        style={{
                          marginTop: "32px",
                        }}
                      >
                        <InlineLoader text="Cancelling Outbound Label..." />
                      </div>
                    )}
                  </Grid.Col>
                </>
              )}
              {outboundErrors.length > 0 && (
                <>
                  <Grid.Col sm={12} md={4}>
                    <Flex justify="flex-end">
                      <Link
                        to={`/organizations/${organization_id}/matters/${matter_id}/kits/${kit?.id}/edit`}
                        style={{
                          color: colors.text,
                        }}
                      >
                        Edit Kit
                      </Link>
                    </Flex>
                  </Grid.Col>
                  <Grid.Col>
                    <Text size="md" color={colors.danger}>
                      Errors generating outbound label:
                    </Text>
                  </Grid.Col>
                  <Grid.Col sm={12}>
                    <Text size="sm" weight="300" color={colors.textLight}>
                      {outboundErrors
                        .map((e) => formatErrorString(e))
                        .join(", ")}
                    </Text>
                  </Grid.Col>
                </>
              )}
            </>
          )}
          <Grid.Col span={12} />
          {inboundLoading ? (
            <Grid.Col span={12}>
              <InlineLoader text="Generating Inbound Label..." />
            </Grid.Col>
          ) : (
            <>
              {!inboundShipment?.tracking_number ? (
                <Grid.Col sm={12} md={8}>
                  <Button
                    onClick={handleCreateInboundLabel}
                    variant="default"
                    disabled={!!inboundShipment?.labelData}
                  >
                    Generate Inbound Label
                  </Button>
                </Grid.Col>
              ) : (
                <>
                  <Grid.Col sm={12} md={3}>
                    <TextDisplay
                      label="Inbound Tracking Number"
                      data={inboundShipment?.tracking_number}
                    />
                  </Grid.Col>
                  <Grid.Col sm={12} md={3}>
                    <TextDisplay
                      label="Inbound Label Cost"
                      data={inboundShipment?.shipment_cost || "None provided"}
                    />
                  </Grid.Col>
                  <Grid.Col sm={12} md={3}>
                    <Button
                      style={{
                        marginTop: "32px",
                        width: "100%",
                      }}
                      onClick={handleDownloadInboundLabel}
                      variant="default"
                      disabled={!inboundShipment?.labelData}
                    >
                      Download Label
                    </Button>
                  </Grid.Col>
                  <Grid.Col sm={12} md={3}>
                    {!cancellingInboundLabel ? (
                      <Button
                        style={{
                          marginTop: "32px",
                          width: "100%",
                        }}
                        onClick={handleCancelInboundLabel}
                        color={colors.danger}
                        variant="outline"
                        disabled={!kit?.status || kit?.status >= 3}
                      >
                        Cancel Label
                      </Button>
                    ) : (
                      <div
                        style={{
                          marginTop: "32px",
                        }}
                      >
                        <InlineLoader text="Cancelling Inbound Label..." />
                      </div>
                    )}
                  </Grid.Col>
                </>
              )}
              {inboundErrors.length > 0 && (
                <>
                  <Grid.Col sm={12} md={4}>
                    <Flex justify="flex-end">
                      <Link
                        to={`/organizations/${organization_id}/matters/${matter_id}/kits/${kit?.id}/edit`}
                        style={{
                          color: colors.text,
                        }}
                      >
                        Edit Kit
                      </Link>
                    </Flex>
                  </Grid.Col>
                  <Grid.Col>
                    <Text size="md" color={colors.danger}>
                      Errors generating inbound label:
                    </Text>
                  </Grid.Col>
                  <Grid.Col sm={12}>
                    <Text size="sm" weight="300" color={colors.textLight}>
                      {inboundErrors
                        .map((e) => formatErrorString(e))
                        .join(", ")}
                    </Text>
                  </Grid.Col>
                </>
              )}
            </>
          )}
        </Grid>
      </Default>
      <Tasks
        tasks={tasksWithLink || []}
        editable
        endpoint={`/organizations/${organization_id}/matters/${matter_id}/kits/${kit_id}/tasks`}
        setTasks={(tasks) => {
          setKitTasks([...tasks]);
        }}
        refreshTasks={getTasks}
        showProgress
        disabled={tasksDisabled}
      />
    </div>
  );
}

export default Kit;
