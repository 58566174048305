import { useAlert, useSettings, } from "hooks";
import {
  Custodian, Kit, Matter, Template,
} from "interfaces/main";
import { axios, } from "libs";
import React, { useEffect, } from "react";
/** @jsxImportSource @emotion/react */
import { css, } from "@emotion/react";
import {
  Button,
  Checkbox,
  Flex,
  Grid,
  ScrollArea,
  Text,
  TextInput,
} from "@mantine/core";
import InfoCard from "components/InfoCard/InfoCard";
import {
  getFormattedCustodianName,
  getFormattedTimeString,
} from "utils/formatting";
import { resolveTheme, } from "themes/main";
import { Validation, } from "interfaces/responses";
import InlineLoader from "components/Loading/Inline";

function Custodians({
  matter,
  kit,
  markComplete,
  invalidate,
}: {
  matter: Matter;
  kit: Kit;
  // eslint-disable-next-line no-unused-vars
  markComplete: (data: Custodian[]) => void;
  invalidate: () => void;
}) {
  const [custodians, setCustodians] = React.useState<Custodian[]>([]);
  const [loading, setLoading] = React.useState(false);

  /* eslint-disable no-unused-vars */
  const [validationRules, setValidationRules] = React.useState<Validation>();
  const [requiredFields, setRequiredFields] = React.useState<string[]>([]);
  const [templates, setTemplates] = React.useState<Template[]>([]);
  /* eslint-disable no-unused-vars */
  const [template_id, setTemplateId] = React.useState<number | null>(null);

  const [kitData, setKitData] = React.useState<Kit>(kit);

  const { setAlert, } = useAlert();

  const loadKit = () => {
    if (!kit) return null;
    return axios
      .get(
        `/organizations/${matter.organization_id}/matters/${matter.id}/kits/${kit.id}`
      )
      .then((res) => {
        setKitData(res.data.data);
      })
      .catch((err) => {
      // eslint-disable-next-line no-console
        console.error(err);
        setAlert({
          type: "danger",
          message: "Error fetching kit",
        });
      });
  };

  const loadCustodians = () => {
    setLoading(true);
    return axios
      .get(
        `/organizations/${matter.organization_id}/matters/${matter.id}/custodians`
      )
      .then((res) => {
        setCustodians(res.data.data);
      })
      .catch((err) => {
        console.error(err);
        setAlert({
          heading: "Error",
          message: "There was an error fetching custodians.",
          type: "danger",
        });
      })
      .finally(() => setLoading(false));
  };

  const loadValidation = () => {
    setLoading(true);
    return axios
      .get(
        `/organizations/${matter.organization_id}/matters/${matter.id}/custodians/create`
      )
      .then((res) => {
        const { validation, } = res.data.data as { validation: Validation };
        setRequiredFields(
          Object.keys(validation.rules).filter((rule) => {
            if (!validation.rules[rule].includes("required")) {
              return false;
            }
            return true;
          })
        );
        setValidationRules(validation);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error("error", err);
        setAlert({
          heading: "Error",
          message: "There was an error fetching validation.",
          type: "danger",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadTemplates = () => {
    setLoading(true);
    return axios
      .get(`/organizations/${matter.organization_id}/templates/type/custodians`)
      .then((res) => {
        setTemplates(res.data.data);
      })
      .catch((err) => {
        console.error(err);
        setAlert({
          heading: "Error",
          message: "There was an error fetching templates.",
          type: "danger",
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadKit();
    setToAdd([]);
    loadCustodians();
    loadValidation();
    loadTemplates();

    if (!matter || !kit) {
      invalidate();
    }
  }, [matter, kit]);

  useEffect(() => () => {
    invalidate();
  }, []);

  const [toAdd, setToAdd] = React.useState<number[]>([]);

  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);

  // eslint-disable-next-line no-unused-vars
  const assignCustodiansToKit = (attach: number[], detach: number[]) => axios
    .post(
      `/organizations/${matter.organization_id}/matters/${matter.id}/kits/${kit.id}/assign/custodians`,
      {
        attach,
        detach,
      }
    )
    .then(() => {
      setAlert({
        type: "success",
        message: "Custodians assigned successfully",
      });
      loadKit();
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      setAlert({
        type: "danger",
        message: "Error assigning custodians to kit",
      });
    });

  const handleAssignment = () => {
    const toAttach = toAdd.filter((id) => !kitData?.custodians.find((c) => c.id === id));
    const toDetach = kitData?.custodians
      .filter((c) => !toAdd.includes(c.id))
      .map((c) => c.id);
    if (toAttach?.length || toDetach?.length) {
      assignCustodiansToKit(toAttach, toDetach);
    }
  };

  useEffect(() => {
    handleAssignment();
  }, [toAdd]);

  useEffect(() => {
    if (!kitData?.custodians.length) {
      invalidate();
    }
    if (kitData?.custodians.length) {
      markComplete(kitData.custodians);
    }
  }, [kitData?.custodians]);

  useEffect(() => {
    const onKit = kitData?.custodians.map((c) => c.id);
    if (onKit?.length) {
      setToAdd(onKit);
    }
  }, [kitData]);

  const refresh = () => {
    loadCustodians();
    loadValidation();
    loadTemplates();
  };

  const [adding, setAdding] = React.useState(false);

  const [formData, setFormData] = React.useState<Partial<Custodian>>({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const { name, value, } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  /* eslint-disable no-unused-vars */
  const formattedTemplates = templates.map((temp) => ({
    label: temp.name,
    value: temp.id.toString(),
  }));

  const onSubmit = (): void => {
    setLoading(true);
    axios
      .post(
        `/organizations/${matter.organization_id}/matters/${matter.id}/custodians`,
        {
          ...formData,
        }
      )
      .then((res) => {
        const newCustodian = res.data.data as Custodian;
        setAlert({
          type: "success",
          heading: "Success",
          message: "Custodian created successfully",
        });
        setToAdd((prev) => [...prev, newCustodian.id]);
        if (template_id) {
          assignTemplate(newCustodian.id);
        } else {
          refresh();
        }
      })
      .catch((err) => {
        console.error(err);
        setAlert({
          type: "danger",
          heading: "Error",
          message: "There was an error creating the custodian.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const assignTemplate = (custodian_id: number) => {
    setLoading(true);
    axios
      .post(
        `/organizations/${matter.organization_id}/matters/${matter.id}/custodians/${custodian_id}/template`,
        {
          template_id,
        }
      )
      .then(() => {
        setAlert({
          type: "success",
          heading: "Success",
          message: "Template assigned successfully",
        });
        refresh();
      })
      .catch((err) => {
        console.error(err);
        setAlert({
          type: "danger",
          heading: "Error",
          message: "There was an error assigning the template.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div css={css``}>
      <Grid>
        {loading && (
          <Grid.Col span={12}>
            <InlineLoader />
          </Grid.Col>
        )}
        <ScrollArea
          sx={() => ({
            maxHeight: "50vh",
            width: "100%",
          })}
          type="auto"
          offsetScrollbars
        >
          {custodians.map((custodian) => (
            <Grid.Col span={12} key={custodian.id}>
              <Flex gap={24} align="center" justify="space-between">
                <InfoCard
                  title={getFormattedCustodianName(custodian)}
                  subtitle={`Last Updated: ${getFormattedTimeString(
                    custodian.updated_at
                  )}`}
                  endpoint={`/organizations/${matter.organization_id}/matters/${matter.id}/custodians/${custodian.id}`}
                />
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setToAdd((prev) => [...prev, custodian.id]);
                    } else {
                      setToAdd((prev) => prev.filter((id) => id !== custodian.id));
                    }
                  }}
                  checked={toAdd.includes(custodian.id)}
                />
              </Flex>
            </Grid.Col>
          ))}
        </ScrollArea>
        {!toAdd.length && (
          <Grid.Col span={12}>
            <Text size="xs" color={currentTheme.colors.danger}>
              Please select at least one custodian.
              {" "}
              {!custodians.length && "You may need to add one first."}
            </Text>
          </Grid.Col>
        )}
        <Grid.Col span={12} />
        {adding && (
          <>
            <Grid.Col sm={12} md={6} lg={4}>
              <TextInput
                label="First Name"
                name="first_name"
                onChange={handleChange}
                required={requiredFields.includes("first_name")}
                placeholder="First name..."
              />
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <TextInput
                label="Last Name"
                name="last_name"
                onChange={handleChange}
                required={requiredFields.includes("last_name")}
                placeholder="Last name..."
              />
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <TextInput
                label="Email"
                name="email"
                onChange={handleChange}
                required={requiredFields.includes("email")}
                placeholder="Email..."
              />
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <TextInput
                label="Phone Number"
                name="phone_number"
                onChange={handleChange}
                required={requiredFields.includes("phone_number")}
                placeholder="Phone number..."
              />
            </Grid.Col>
            {/* <Grid.Col sm={12} md={6} lg={4}>
              <Select
                label="Template"
                name="template_id"
                required={validationRules?.rules?.template_id?.includes(
                  "required"
                )}
                data={formattedTemplates}
                onChange={(value) => {
                  if (value) {
                    setTemplateId(parseInt(value, 10));
                  }
                }}
                searchable
                placeholder="Select a template..."
              />
            </Grid.Col> */}
          </>
        )}
        <Grid.Col>
          <Flex justify="end" gap={18}>
            {adding && (
              <Button variant="default" onClick={() => setAdding(false)}>
                Cancel
              </Button>
            )}
            <Button
              variant="default"
              onClick={() => {
                if (!adding) {
                  setAdding(true);
                } else {
                  setAdding(false);
                  onSubmit();
                }
              }}
            >
              {adding ? "Submit" : "Add Custodian"}
              {loading && (
                <>
                  {" "}
                  <InlineLoader />
                </>
              )}
            </Button>
          </Flex>
        </Grid.Col>
      </Grid>
    </div>
  );
}

export default Custodians;
