import { Dashboard, } from "interfaces/responses";
import React, { useEffect, useState, } from "react";
/** @jsxImportSource @emotion/react */
import { css, } from "@emotion/react";
import { Button, Grid, Title, } from "@mantine/core";
import DataCard from "components/InfoCard/DataCard";
import { useSearch, useSettings, } from "hooks";
import { resolveTheme, } from "themes/main";
import { kitStatusTypes, } from "vars/constants";
import { useNavigate, } from "react-router-dom";
import RecentList from "./RecentMatters";
import { Tabs, } from "./Home";

interface OverviewProps {
  dashboard: Dashboard;
  // eslint-disable-next-line no-unused-vars
  setTab: (tab: Tabs) => void;
}

function Overview({ dashboard, setTab, }: OverviewProps) {
  const { setTable, } = useSearch();

  const getKitBreakdown = () => {
    const breakdown = {
      created: 0,
      ready_for_shipment: 0,
      outbound: 0,
      onsite: 0,
      inbound: 0,
      returned: 0,
    };
    dashboard.active_kits.forEach((k) => {
      switch (k.status) {
        case 1:
          breakdown.created += 1;
          break;
        case 2:
          breakdown.ready_for_shipment += 1;
          break;
        case 3:
          breakdown.outbound += 1;
          break;
        case 4:
        case 5:
          breakdown.onsite += 1;
          break;
        case 6:
          breakdown.inbound += 1;
          break;
        case 7:
          breakdown.returned += 1;
          break;
        default:
          break;
      }
    });
    return breakdown;
  };
  const [kitBreakdown, setKitBreakdown] = useState(getKitBreakdown());

  useEffect(() => {
    setKitBreakdown(getKitBreakdown());
  }, [dashboard.active_kits]);

  const {
    theme: { current, },
  } = useSettings();
  const { colors, } = resolveTheme(current);

  const filterByKitStatus = (status: number) => {
    setTab("active_kits");
    setTable("active_kits", {
      query: String(kitStatusTypes[status].label),
      sort: {
        field: "status_label",
        direction: "asc",
      },
    });
  };

  const navigate = useNavigate();

  return (
    <div css={css``}>
      <Grid>
        <Grid.Col span={12}>
          <div
            css={css`
              padding: 36px 24px;

              @media (min-width: 768px) {
                padding: 36px 48px;
              }

              @media (min-width: 1024px) {
                padding: 36px 64px;
              }

              .number {
                font-weight: 400;
                color: ${colors.primary};
              }

              .action-button {
                border: none;
                margin: 5px 0;
                background-color: ${colors.backgroundContrast};
                color: ${colors.text};
                font-size: 14px;
                padding: 5px 14px;
                border-radius: 5px;
                transition: all 0.2s ease-in-out;

                &:hover {
                  background-color: ${colors.primary};
                }
              }
            `}
          >
            <Grid>
              <Grid.Col sm={12} md={6}>
                <DataCard>
                  <Title order={3} weight={300}>
                    Kit Breakdown
                  </Title>
                  <hr />
                  <table
                    css={css`
                      width: 100%;

                      th {
                        text-align: center;
                        padding-bottom: 14px;
                      }

                      td {
                        text-align: center;
                      }
                    `}
                  >
                    <thead>
                      <tr>
                        <th>Status</th>
                        <th>Count</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Created</td>
                        <td className="number">{kitBreakdown.created}</td>
                        <td>
                          <button
                            className="action-button"
                            type="button"
                            onClick={() => filterByKitStatus(1)}
                            title="See all kits with a status of 'Created'"
                          >
                            See All
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>Outbound</td>
                        <td className="number">{kitBreakdown.ready_for_shipment}</td>
                        <td>
                          <button
                            className="action-button"
                            type="button"
                            onClick={() => filterByKitStatus(2)}
                            title="See all kits with a status of 'Ready for Shipment'"
                          >
                            See All
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>Ready for Shipment </td>
                        <td className="number">{kitBreakdown.outbound}</td>
                        <td>
                          <button
                            className="action-button"
                            type="button"
                            onClick={() => filterByKitStatus(3)}
                            title="See all kits with a status of 'Outbound'"
                          >
                            See All
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>Onsite</td>
                        <td className="number">{kitBreakdown.onsite}</td>
                        <td>
                          <button
                            className="action-button"
                            type="button"
                            onClick={() => {
                              setTab("active_kits");
                              setTable("active_kits", {
                                query: "(delivered or recalled)",
                                sort: {
                                  field: "status_label",
                                  direction: "asc",
                                },
                              });
                            }}
                            title="See all kits with a status of 'Onsite'"
                          >
                            See All
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>Inbound</td>
                        <td className="number">{kitBreakdown.inbound}</td>
                        <td>
                          <button
                            className="action-button"
                            type="button"
                            onClick={() => filterByKitStatus(6)}
                            title="See all kits with a status of 'Inbound'"
                          >
                            See All
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>Returned</td>
                        <td className="number">{kitBreakdown.returned}</td>
                        <td>
                          <button
                            className="action-button"
                            type="button"
                            onClick={() => filterByKitStatus(7)}
                            title="See all kits with a status of 'Returned'"
                          >
                            See All
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </DataCard>
              </Grid.Col>
              <Grid.Col sm={12} md={6}>
                <DataCard>
                  <Title order={3} weight={300}>
                    Quick Actions
                  </Title>
                  <hr />
                  <Grid>
                    <Grid.Col span={12}>
                      <Button
                        onClick={() => navigate("/wizards/ship-a-kit")}
                        variant="outline"
                      >
                        Ship a Kit
                      </Button>
                    </Grid.Col>
                  </Grid>
                </DataCard>
              </Grid.Col>
            </Grid>
          </div>
        </Grid.Col>
        <Grid.Col span={12}>
          <RecentList recent_matters={dashboard.recent_matters} />
        </Grid.Col>
      </Grid>
    </div>
  );
}

export default Overview;
