import { Task as TaskDefinition, Template, } from "interfaces/main";
import React, { useEffect, useState, } from "react";
import { useParams, useNavigate, } from "react-router-dom";
import { Details, } from "components/Views";
import { axios, } from "libs";
import { useAlert, useSettings, } from "hooks";
import { Loading, } from "components";
import { Grid, } from "@mantine/core";
import { resolveTheme, } from "themes/main";
import TextDisplay from "components/Display/TextDisplay";

interface TemplateTask extends TaskDefinition {
  template: Template;
}

function Task() {
  const { organization_id, template_id, task_id, } = useParams();
  const [task, setTask] = useState<TemplateTask>();
  const [loading, setLoading] = useState(true);
  const { setAlert, setConfirmation, } = useAlert();
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(
        `/organizations/${organization_id}/templates/${template_id}/tasks/${task_id}`
      )
      .then((res) => {
        setTask(res.data.data);
      })
      .catch((err) => {
        console.error(err);
        setAlert({
          message: "There was a problem fetching the task",
          type: "danger",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleDelete = () => {
    setConfirmation("Are you sure you want to delete this task?", () => {
      setLoading(true);
      axios
        .delete(
          `/organizations/${organization_id}/templates/${template_id}/tasks/${task_id}`
        )
        .then(() => {
          setAlert({
            message: "Task deleted successfully",
            type: "success",
          });
          navigate(
            `/organizations/${organization_id}/templates/${template_id}`
          );
        })
        .catch((err) => {
          console.error(err);
          setAlert({
            message: "There was a problem deleting the task",
            type: "danger",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);
  const { colors, } = currentTheme;

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <Details
        title={task?.description.toString() || "Task"}
        buttons={[
          {
            text: "Delete",
            handleClick: handleDelete,
            color: colors.danger,
            variant: "filled",
          },
          {
            text: "Edit",
            handleClick: () => {
              navigate("edit");
            },
            variant: "default",
            color: colors.primary,
          }
        ]}
      >
        <Grid>
          <Grid.Col sm={12} md={6}>
            <TextDisplay label="Description" data={task?.description} />
          </Grid.Col>
        </Grid>
      </Details>
    </div>
  );
}

export default Task;
