import axios from "libs/axiosInstance";

export const getOrganization = async (org_id: string) => {
  try {
    const response = await axios.get(`/organizations/${org_id}`);
    return response.data.data;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return null;
  }
};

export const getMatter = async (org_id: string, matter_id: string) => {
  try {
    const response = await axios.get(
      `/organizations/${org_id}/matters/${matter_id}`
    );
    return response.data.data;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return null;
  }
};

export const getCustodian = async (
  org_id: string,
  matter_id: string,
  custodian_id: string
) => {
  try {
    const response = await axios.get(
      `/organizations/${org_id}/matters/${matter_id}/custodians/${custodian_id}`
    );
    return response.data.data;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return null;
  }
};

export const getCollection = async (
  org_id: string,
  matter_id: string,
  custodian_id: string,
  collection_id: string
) => {
  try {
    const response = await axios.get(
      `/organizations/${org_id}/matters/${matter_id}/custodians/${custodian_id}/collections/${collection_id}`
    );
    return response.data.data;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return null;
  }
};

export const parseJsonFields = (data: any) => {
  if (!data) return data;
  const parsedData = { ...data, };
  Object.keys(parsedData).forEach((key) => {
    if (typeof parsedData[key] === "string" && isValidJson(parsedData[key])) {
      try {
        parsedData[key] = JSON.parse(parsedData[key]);
      } catch (err) {
        // do nothing
      }
    }
  });
  return parsedData;
};

export const parseIterableJsonFields = (data: any) => {
  if (!data) return data;
  const parsedData = [...data];
  parsedData.forEach((item: any) => {
    Object.keys(item).forEach((key) => {
      if (typeof item[key] === "string" && isValidJson(item[key])) {
        try {
          // eslint-disable-next-line no-param-reassign
          item[key] = JSON.parse(item[key]);
        } catch (err) {
          // do nothing
        }
      }
    });
  });
  return parsedData;
};

export const isValidJson = (json: string) => {
  try {
    if (
      /^[\],:{}\s]*$/.test(
        json
          .replace(/\\["\\/bfnrtu]/g, "@")
          .replace(
            /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?/g,
            "]"
          )
          .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
      ) &&
      json.length > 0 &&
      JSON.parse(json)
    ) {
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};
