/* eslint-disable no-unused-vars */
import { db, } from "./db";
import { syncCollections, } from "./modules/collections";

export const syncDB = async () => {
  try {
    // * The order does matter
    console.info("syncing db");
    syncCollections().then(() => {
      console.info("synced db");
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("error", error);
  }
};

export const clearDB = async () => {
  try {
    // check that collections exist
    console.info("clearing db");
    db?.collections?.clear().then(() => {
      console.info("cleared db");
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("error", error);
  }
};
