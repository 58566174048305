import React from "react";
/** @jsxImportSource @emotion/react */
import { css, } from "@emotion/react";
import { useNavigate, } from "react-router-dom";
import { useSettings, } from "hooks";
import { Task as TaskDefinition, } from "interfaces/main";
import { resolveTheme, } from "themes/main";

interface TaskCardTask extends TaskDefinition {
  link: string;
  endpoint?: string;
}
interface TaskCardProps {
  task: TaskCardTask;
}

TaskCard.defaultProps = {
};

function TaskCard({
  task,
}: TaskCardProps) {
  const { description, status, } = task;
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(task.link);
  };

  const { theme: { current, }, } = useSettings();
  const currentTheme = resolveTheme(current);

  const isMobile = window.innerWidth < 768;

  // const statusOptions: StatusOptions[] = [
  //   {
  //     label: "Cancelled",
  //     value: 3,
  //   },
  //   {
  //     label: "Open",
  //     value: 0,
  //   },
  //   {
  //     label: "In Progress",
  //     value: 1,
  //   },
  //   {
  //     label: "Completed",
  //     value: 2,
  //   }
  // ];

  // const statusOptionsMap = statusOptions.map((option) => ({
  //   label: option.label,
  //   value: option.value.toString(),
  // }));

  const statusToColor: {
    [key: number]: string;
  } = {
    0: currentTheme.colors.primary,
    1: `${currentTheme.colors.primary}90`,
    2: currentTheme.colors.background,
    3: `${currentTheme.colors.textLight}90`,
  };

  const statusToBorderWidth: {
    [key: number]: number;
  } = {
    0: 1,
    1: 0.5,
    2: 0,
  };

  const recentNote = task.notes ? task.notes[task.notes.length - 1] : undefined;

  const getTrimmedRecentNote = (str: string) => {
    if (str.length > 25 && isMobile) {
      return `${str.substring(0, 25)}...`;
    }
    if (str.length > 50 && !isMobile) {
      return `${str.substring(0, 50)}...`;
    }
    return str;
  };

  return (
    <div
      css={css`
        transition: all 0.4s ease-in-out;
      `}
    >
      <div
        css={css`
          background-color: ${currentTheme.colors.background};
          width: 100%;
          border-radius: 10px;
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
          padding: 14px 18px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          transition: all 0.2s ease-in-out;
          // margin: 14px 0;
          position: relative;

          @media (min-width: 768px) {
            flex-direction: row;
          }

          &:hover {
            box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);
            transform: scale(0.992);
          }

          .title {
            font-size: 15px;
            font-weight: 400;
            font-family: "Roboto", sans-serif;
          }

          @keyframes loading {
            0% {
              background-color: ${currentTheme.colors.background};
            }
            50% {
              background-color: ${currentTheme.colors.backgroundContrast};
            }
            100% {
              background-color: ${currentTheme.colors.background};
            }
          }
        `}
        style={{
          outline: `${statusToBorderWidth[status] || 1}px solid ${statusToColor[status] || currentTheme.colors.primary
          }`,
          cursor: "pointer",
          backgroundColor: status === 3 ? `${currentTheme.colors.backgroundAlt}` : currentTheme.colors.background,
        }}
        role="button"
        tabIndex={0}
        onKeyDown={handleClick}
        onClick={handleClick}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            color: ${currentTheme.colors.text};

            @media (min-width: 768px) {
              flex-direction: row;
              width: initial;
            }
          `}
        >
          <div className="text">
            <div
              className="sort-buttons"
              css={css`
                display: flex;
                flex-direction: column;
              `}
            >
              <div
                className="title"
                style={
                  {
                    // textDecoration: task.status === 2 ? "line-through 1.2px" : "none"
                  }
                }
              >
                {description}
              </div>
              <div
                css={css`
                  font-size: 12px;
                  color: ${currentTheme.colors.textLight};
                `}
              >
                {recentNote && getTrimmedRecentNote(recentNote.note)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TaskCard;
