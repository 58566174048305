import React from "react";
import { getEndpointFromDrilldown, } from "utils/recent";
import { useSearch, useSettings, } from "hooks";
import { Matter, RecentModule, } from "interfaces/main";
import { resolveTheme, } from "themes/main";
import { getFormattedTimeString, } from "utils/formatting";
import { getModuleCompleteness, getModuleFields, } from "utils/methods";
import IntegratedListView from "components/Views/IntegratedListView";

interface RecentMatter extends RecentModule {
  matter: Matter;
}

interface RecentMattersProps {
  recent_matters: RecentMatter[];
}

function RecentList({ recent_matters, }: RecentMattersProps) {
  const { getTable, } = useSearch();
  const { sort, } = getTable("recent");

  const allFields: (keyof RecentModule)[] | undefined =
    recent_matters?.[0] &&
    (Object.keys(recent_matters[0]) as unknown as (keyof RecentModule)[]);

  const allowedFields = new Map([
    ["id", 0],
    ["name", 1],
    ["updated_at", 2],
    ["created_at", 3]
  ]);

  const fields = getModuleFields(allowedFields, allFields);

  const getSubtitle = (m: RecentMatter) => {
    if (!sort.field) {
      return m.updated_at ?
        `Last update at ${getFormattedTimeString(m.updated_at)}` :
        `Created at ${getFormattedTimeString(m.created_at)}`;
    }
    return `${sort.field}: ${m[sort.field as keyof RecentModule]}`;
  };

  const getRecentMatterCompleteness = (module: RecentMatter) => {
    if (!module.matter) {
      return 0;
    }
    const completeness = getModuleCompleteness(module.matter);

    return completeness || 0;
  };

  const listItems = recent_matters.map((m) => ({
    id: m.id as number,
    title: m.name,
    subtitle: getSubtitle(m),
    endpoint: getEndpointFromDrilldown(m.drilldown),
    completeness: getRecentMatterCompleteness(m),
    full_record: m,
  }));

  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);
  // eslint-disable-next-line no-unused-vars
  const { colors, } = currentTheme;

  return (
    <div>
      <IntegratedListView
        title="Recent Matters"
        search
        table_name="recent"
        fields={fields}
        list_items={listItems}
        full_record_list={recent_matters}
        with_separator={false}
      />
    </div>
  );
}

export default RecentList;
