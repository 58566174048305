import React, { useEffect, useState, } from "react";
import { Create, } from "components/Views";
import { Loading, } from "components";
import { axios, } from "libs";
import { useParams, useNavigate, } from "react-router-dom";
import { Validation, } from "interfaces/responses";
import {
  Checkbox,
  Grid, TextInput,
} from "@mantine/core";
import { useAlert, useSettings, } from "hooks";
import { EmailTemplate, } from "interfaces/main";
import { resolveTheme, } from "themes/main";

function CreateEmailTemplate() {
  const { organization_id, } = useParams();
  const [formState, setFormState] = useState<Partial<EmailTemplate>>({
    name: "",
    global: 0,
  });
  const [loading, setLoading] = useState(false);
  const [validationRules, setValidationRules] = useState<Validation>();
  const [requiredFields, setRequiredFields] = useState<string[]>([]);
  const [validated, setValidated] = useState(false);
  // const { hasRole, } = useUser();
  const { setAlert, } = useAlert();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/organizations/${organization_id}/email-templates/create`)
      .then((res) => {
        const validation = res.data.data.validation as Validation;
        setValidationRules(validation);
        setRequiredFields(
          Object.entries(validation.rules)
            .filter(([, value]) => value.includes("required"))
            .map(([key]) => key)
        );
      })
      .catch((err) => {
        console.error(err);
        setAlert({
          type: "danger",
          message: "Error loading email template validation rules",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    organization_id
  ]);

  const submitTemplate = () => {
    setLoading(true);
    axios
      .post(`/organizations/${organization_id}/email-templates`, formState)
      .then((res) => {
        setAlert({
          type: "success",
          message: "Email template created successfully",
        });
        const newTemplate = res.data.data as EmailTemplate;
        setLoading(false);
        // if (template_id) {
        //   assignTemplate(newTemplate.id);
        //   return;
        // }
        navigate(
          `/organizations/${organization_id}/email-templates/${newTemplate.id}`
        );
      })
      .catch((err) => {
        console.error(err);
        setAlert({
          type: "danger",
          message: "Error creating email template",
        });
        setLoading(false);
      });
  };

  // const [templates, setTemplates] = useState<Template[]>([]);
  // const [template_id, setTemplateId] = useState<number | null>(null);

  // const assignTemplate = (t_id: number) => {
  //   setLoading(true);
  //   axios
  //     .post(
  //       `/organizations/${organization_id}/templates/${t_id}/template`,
  //       {
  //         template_id,
  //       }
  //     )
  //     .then(() => {
  //       setAlert({
  //         type: "success",
  //         heading: "Success",
  //         message: "Template assigned successfully",
  //       });
  //       navigate(`/organizations/${organization_id}/templates/${t_id}`);
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //       setAlert({
  //         type: "danger",
  //         heading: "Error",
  //         message: "Error assigning template",
  //       });
  //     });
  // };

  // eslint-disable-next-line arrow-body-style
  // const formattedTemplates = templates.map((temp) => {
  //   return {
  //     label: temp.name,
  //     value: temp.id.toString(),
  //   };
  // });

  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);
  const { colors, } = currentTheme;

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <Create
        title="Create Email Template"
        buttons={[
          {
            text: "Submit",
            handleClick: () => {
              submitTemplate();
            },
            variant: "filled",
            color: colors.primary,
            disabled: !validated,
          }
        ]}
        formState={formState}
        requiredFields={requiredFields}
        setValidated={setValidated}
      >
        <Grid>
          <Grid.Col sm={12} md={6}>
            <TextInput
              label="Name"
              name="name"
              onChange={(e) => {
                setFormState({ ...formState, name: e.currentTarget.value, });
              }}
              required={validationRules?.rules?.name?.includes("required")}
              placeholder="Template name..."
            />
          </Grid.Col>
          <Grid.Col sm={12} />
          <Grid.Col sm={12} md={6}>
            <Checkbox
              label="Global"
              name="global"
              onChange={(e) => {
                setFormState({ ...formState, global: e.currentTarget.checked ? 1 : 0, });
              }}
              checked={formState.global === 1}
            />
          </Grid.Col>
        </Grid>
      </Create>
    </div>
  );
}

export default CreateEmailTemplate;
