import React from "react";
import { useParams, useNavigate, } from "react-router-dom";
import { Details, } from "components/Views";
import { useLiveQuery, } from "dexie-react-hooks";
import { db, } from "db/db";
import PageNotFound from "pages/PageNotFound";
import { useSettings, } from "hooks";
import { resolveTheme, } from "themes/main";

export function OfflineDetails() {
  const { collection_id, } = useParams();
  const navigate = useNavigate();

  const collection = useLiveQuery(() => db.collections.get(parseInt(collection_id || "0")));

  const {
    theme: { current: theme, },
  } = useSettings();
  const currentTheme = resolveTheme(theme);

  if (!collection_id) {
    return <PageNotFound />;
  }
  return (
    <div>
      <Details
        title={collection?.name || "Collection"}
        buttons={[
          {
            text: "Edit",
            handleClick: () => {
              navigate(`/collections/${collection_id}/edit`);
            },
            color: currentTheme.colors.primary,
            variant: "default",
          }
        ]}
      >
        <div>
          <p>
            Name:
            {" "}
            {collection?.name}
          </p>
          <p>
            Matter ID:
            {" "}
            {collection?.matter_id}
          </p>
        </div>
      </Details>
    </div>
  );
}

export default OfflineDetails;
