import React, { useState, } from "react";
import Wizard, { Step, } from "components/Wizards/Wizard";
import { Default, } from "components/Views";
import {
  Matter as MatterDefinition,
  Custodian as CustodianDefinition,
  Kit as KitDefinition,
} from "interfaces/main";
import { Button, Group, } from "@mantine/core";
import { Link, } from "react-router-dom";
import Matter from "./Steps/Matter";
import Custodians from "./Steps/Custodians";
import Collections from "./Steps/Collections";
// import ShippingDetails from "./Steps/ShippingDetails";
import ShippingDetails from "./Steps/ShippingDetails";
import Kit from "./Steps/Kit";
import DeviceAssignment from "./Steps/DeviceAssignment";
import LabelGeneration from "./Steps/LabelGeneration";

function index() {
  const [allData, setAllData] = useState<Record<string, unknown | null>>({});

  const submitData = (id: string, data: any) => {
    setAllData((prev) => ({
      ...prev,
      [id]: data,
    }));
  };

  const [currentStep, setCurrentStep] = React.useState(0);

  const steps: Step[] = [
    {
      name: "Select a Matter",
      element: (args) => (
        <Matter
          markComplete={args.markComplete<MatterDefinition>}
          invalidate={args.invalidate}
        />
      ),
      id: "matter",
    },
    {
      name: "Create Kit",
      element: (args) => (
        <Kit
          markComplete={args.markComplete}
          matter={allData.matter as MatterDefinition}
          invalidate={args.invalidate}
        />
      ),
      id: "kit",
    },
    {
      name: "Select Custodians",
      element: (args) => (
        <Custodians
          markComplete={args.markComplete<CustodianDefinition[]>}
          invalidate={args.invalidate}
          matter={allData.matter as MatterDefinition}
          kit={allData.kit as KitDefinition}
        />
      ),
      id: "custodians",
    },
    {
      name: "Add Collections",
      element: (args) => (
        <Collections
          markComplete={args.markComplete}
          invalidate={args.invalidate}
          matter={allData.matter as MatterDefinition}
          custodians={allData.custodians as CustodianDefinition[]}
        />
      ),
      id: "collections",
    },
    {
      name: "Shipping Details",
      element: (args) => (
        <ShippingDetails
          markComplete={args.markComplete}
          invalidate={args.invalidate}
          matter={allData.matter as MatterDefinition}
          kit={allData.kit as KitDefinition}
          updateKit={(kit: KitDefinition) => {
            setAllData((prev) => ({
              ...prev,
              3: kit,
            }));
          }}
        />
      ),
      id: "shipping",
    },
    {
      name: "Device Assignment",
      element: (args) => (
        <DeviceAssignment
          markComplete={args.markComplete}
          invalidate={args.invalidate}
          matter={allData.matter as MatterDefinition}
          kit={allData.kit as KitDefinition}
        />
      ),
      id: "device-assignment",
    },
    {
      name: "Label Generation",
      element: (args) => (
        <LabelGeneration
          markComplete={args.markComplete}
          invalidate={args.invalidate}
          matter={allData.matter as MatterDefinition}
          kit={allData.kit as KitDefinition}
        />
      ),
      id: "label-generation",
    }
  ];

  const [stepCompleteness, setStepCompleteness] = React.useState<boolean[]>([]);

  const isComplete = stepCompleteness.every((s) => s);

  const matter = allData.matter as MatterDefinition | undefined;
  const kit = allData.kit as KitDefinition | undefined;

  return (
    <Default>
      <Wizard
        name="Ship A Kit"
        steps={steps}
        currentStep={currentStep}
        submitData={submitData}
        nextStep={() => {
          setCurrentStep((prev) => prev + 1);
        }}
        setStep={(step) => {
          setCurrentStep(step);
        }}
        onStepCompletenessChange={(s) => {
          setStepCompleteness(s);
        }}
      />
      {
        isComplete && allData.kit && (
        <Group>
          <Link to={`/organizations/${matter?.organization_id}/matters/${matter?.id}/kits/${kit?.id}`}>
            <Button variant="default">
              Go to Kit
            </Button>
          </Link>
        </Group>
        )
      }
    </Default>
  );
}

export default index;
