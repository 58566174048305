import React, { useEffect, useState, } from "react";
import { axios, } from "libs";
import { useParams, useNavigate, } from "react-router-dom";
import DetailsView from "components/Views/DetailsView";
import { Loading, } from "components";
import { useAlert, useSettings, useUser, } from "hooks";
import Notes from "components/Notes/Notes";
import { Note, Custodian as CustodianDefinition, } from "interfaces/main";
import CollectionList from "pages/Collections/List";
import {
  getFormattedCustodianName,
} from "utils/formatting";
import TextDisplay from "components/Display/TextDisplay";
import { Grid, } from "@mantine/core";
import Tasks from "components/Tasks/Tasks";
import DetailsSection from "components/Details/Details";
import { parseJsonFields, } from "utils/fetching";
import CredentialsSection from "components/Credentials/Credentials";
import { isEmptyObject, } from "utils/methods";
import { resolveTheme, } from "themes/main";

function Custodian() {
  const [custodian, setCustodian] = useState<CustodianDefinition | undefined>();
  const [loading, setLoading] = useState(false);
  const { organization_id, matter_id, custodian_id, } = useParams();
  const navigate = useNavigate();
  const alertContext = useAlert();
  const { hasRole, } = useUser();

  const getCustodian = () => axios
    .get(
      `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}`
    )
    .then((res) => {
      const parsed = parseJsonFields(res.data.data);
      setCustodian(parsed);
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      alertContext.setAlert({
        type: "danger",
        message: "Error fetching custodian",
      });
    });

  useEffect(() => {
    setLoading(true);
    getCustodian().finally(() => setLoading(false));
  }, [
    organization_id,
    matter_id,
    custodian_id
  ]);

  const handleDelete = () => {
    alertContext.setConfirmation(
      "Are you sure you want to delete this custodian? This will also delete all collections and tasks associated with this custodian.",
      () => {
        setLoading(true);
        axios
          .delete(
            `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}`
          )
          .then(() => {
            alertContext.setAlert({
              type: "success",
              message: "Custodian deleted successfully",
            });
            navigate(`/organizations/${organization_id}/matters/${matter_id}`);
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.error(err);
            alertContext.setAlert({
              type: "danger",
              message: "Error deleting custodian",
            });
            setLoading(false);
          });
      }
    );
  };

  const submitNotes = async (notes: Note[]) => {
    setLoading(true);
    try {
      try {
        const res = await axios
          .put(
            `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}`,
            {
              ...custodian,
              notes,
            }
          );
        const parsed = parseJsonFields(res.data.data);
        setCustodian({
          ...custodian,
          ...parsed,
        });
        alertContext.setAlert({
          type: "success",
          message: "Notes updated successfully",
        });
        return (parsed as CustodianDefinition).notes;
      } catch (err) {
        alertContext.setAlert({
          type: "danger",
          message: "Error updating notes",
        });
        // eslint-disable-next-line no-console
        console.error(err);
        return undefined;
      }
    } finally {
      setLoading(false);
    }
  };

  const tasksWithLink = custodian?.tasks.map((task) => ({
    ...task,
    link: `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/tasks/${task.id}`,
    endpoint: `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/tasks/${task.id}`,
  }));

  const getCustodianTasks = () => {
    axios
      .get(
        `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}`,
        { cache: false, }
      )
      .then((res) => {
        const parsed = parseJsonFields(res.data.data);
        setCustodian(parsed);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        alertContext.setAlert({
          type: "danger",
          message: "Error fetching custodian",
        });
      });
  };

  const submitDetails = (details: CustodianDefinition["details"]) => {
    axios
      .put(
        `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}`,
        {
          details,
        }
      )
      .then((res) => {
        alertContext.setAlert({
          type: "success",
          message: "Custodian details updated successfully",
        });
        const parsed = parseJsonFields(res.data.data);
        setCustodian({
          ...custodian,
          ...parsed,
        });
      })
      .catch((err) => {
        alertContext.setAlert({
          type: "danger",
          message: "Error updating collection details",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  const submitCredentials = (
    credentials: CustodianDefinition["credentials"]
  ) => {
    axios
      .put(
        `/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}`,
        {
          credentials,
        }
      )
      .then((res) => {
        alertContext.setAlert({
          type: "success",
          message: "Custodian credentials updated successfully",
        });
        const parsed = parseJsonFields(res.data.data);
        setCustodian(parsed);
      })
      .catch((err) => {
        alertContext.setAlert({
          type: "danger",
          message: "Error updating collection credentials",
        });
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  const { theme: { current: theme, }, } = useSettings();
  const currentTheme = resolveTheme(theme);

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <DetailsView
        title={getFormattedCustodianName(custodian)}
        loading={loading}
        buttons={[
          // {
          //   text: "Collections",
          //   handleClick: () => {
          //     navigate("collections");
          //   },
          //   color: "digitalPurple",
          //   variant: "subtle"
          // },
          {
            text: "Edit",
            handleClick: () => {
              navigate("edit");
            },
            color: currentTheme.colors.primary,
            variant: "default",
            disabled: !hasRole("restricted"),
          },
          {
            text: "Delete",
            handleClick: handleDelete,
            color: currentTheme.colors.danger,
            variant: "filled",
            disabled: !hasRole("poweruser"),
          }
        ]}
      >
        <Grid>
          <Grid.Col sm={12} md={6}>
            <TextDisplay
              label="Email"
              data={custodian?.email || "None provided"}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6}>
            <TextDisplay
              label="Phone"
              data={custodian?.phone_number || "None provided"}
            />
          </Grid.Col>
        </Grid>
      </DetailsView>
      {!isEmptyObject(custodian?.details) && (
        <DetailsSection
          details={custodian?.details || {}}
          submitDetails={submitDetails}
        />
      )}
      {!isEmptyObject(custodian?.credentials) && (
        <CredentialsSection
          credentials={custodian?.credentials || {}}
          submitCredentials={submitCredentials}
        />
      )}
      <CollectionList />
      <Notes
        notes={custodian?.notes || []}
        submitNotes={submitNotes}
        photo_endpoint={`/organizations/${organization_id}/photos`}
        refreshNotes={getCustodian}
      />
      <Tasks
        tasks={tasksWithLink || []}
        editable
        endpoint={`/organizations/${organization_id}/matters/${matter_id}/custodians/${custodian_id}/tasks`}
        setTasks={(tasks) => {
          setCustodian({
            ...(custodian as CustodianDefinition),
            tasks,
          });
        }}
        refreshTasks={getCustodianTasks}
        showProgress
      />
    </div>
  );
}

export default Custodian;
