/** @jsxImportSource @emotion/react */
import { css, } from "@emotion/react";
import { Button, Title, } from "@mantine/core";
import Loading from "components/Loading";
import React from "react";
import { useSettings, } from "hooks";
import { resolveTheme, } from "themes/main";

interface DefaultProps {
  children: React.ReactNode;
  buttons?: {
    text?: string;
    handleClick?: () => void;
    color?: string;
    variant?:
      | "outline"
      | "white"
      | "light"
      | "default"
      | "filled"
      | "subtle"
      | "gradient";
    disabled?: boolean;
    invisible?: boolean;
    icon?: string;
    raw?: JSX.Element;
  }[];
  title?: string;
  loading?: boolean;
  collapsable?: boolean;
  with_separator?: boolean;
}

Default.defaultProps = {
  buttons: [],
  title: "",
  loading: false,
  collapsable: false,
  with_separator: true,
};

function Default({
  children,
  buttons,
  title,
  loading,
  collapsable,
  with_separator,
}: DefaultProps) {
  const [collapsed, setCollapsed] = React.useState(false);

  const collapse = () => {
    setCollapsed(!collapsed);
  };

  const {
    theme: { current: theme, },
  } = useSettings();

  const currentTheme = resolveTheme(theme);

  if (loading) {
    return <Loading />;
  }

  return (
    <div
      css={css`
        padding: 36px 24px;
        border-top: 1px solid ${currentTheme.colors.backgroundContrast};
        ${!with_separator && "border-top: none;"}

        @media (min-width: 768px) {
          padding: 36px 48px;
        }

        @media (min-width: 1024px) {
          padding: 36px 64px;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;

          @media (min-width: 768px) {
            justify-content: space-between;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            gap: 0 14px;
          `}
        >
          {collapsable && (
            <button
              css={css`
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                border: 1px solid ${currentTheme.colors.backgroundContrast};
                height: 36px;
                width: 36px;
                background-color: ${currentTheme.colors.background};
                cursor: pointer;
                border-radius: 50%;
                box-shadow: inset 0.2px 0.2px 8px rgba(0, 0, 0, 0.1);
                transition: all 0.2s ease-in-out;

                &:hover {
                  transform: scale(0.95);
                  box-shadow: inset 0.2px 0.2px 8px rgba(0, 0, 0, 0.15);
                }

                &:active {
                  transform: scale(0.94);
                }
              `}
              onClick={collapse}
              tabIndex={0}
              type="button"
              style={{
                backgroundColor: currentTheme.colors.background,
              }}
            >
              <i
                className="material-symbols-outlined"
                style={{
                  color: currentTheme.colors.text,
                }}
              >
                {collapsed ? "chevron_right" : "expand_more"}
              </i>
            </button>
          )}

          {title && (
            <Title
              order={1}
              weight={300}
              style={{
                fontFamily: "\"Roboto\", sans-serif",
                fontSize: "32px",
                fontWeight: 300,
              }}
              color={currentTheme.colors.text}
            >
              {title}
            </Title>
          )}
        </div>
        <div
          css={css`
            display: flex;
            justify-content: space-around;
            width: 100%;
            margin-top: 24px;

            @media (min-width: 768px) {
              justify-content: flex-end;
              width: initial;
              margin-top: 0;
            }
          `}
        >
          {buttons?.map(
            (button, idx) => !button.invisible &&
              (!button.raw ? (
                <Button
                  // eslint-disable-next-line react/no-array-index-key
                  key={idx}
                  onClick={button.handleClick}
                  color={button.color}
                  variant={button.variant}
                  css={css`
                    @media (min-width: 768px) {
                      margin-left: 16px;
                    }
                  `}
                  disabled={button.disabled}
                >
                  {button.icon && (
                    <i className="material-symbols-outlined">{button.icon}</i>
                  )}
                  {button.text || ""}
                </Button>
              ) : (
                button.raw
              ))
          )}
        </div>
      </div>
      {!collapsed && (
        <div>
          {((title && title?.length > 0) ||
            (buttons && buttons.length > 0)) && <br />}
          <div>{children}</div>
        </div>
      )}
    </div>
  );
}

export default Default;
