import { Text, } from "@mantine/core";
import React, { useState, } from "react";
/** @jsxImportSource @emotion/react */
import { css, } from "@emotion/react";
import { copyToClipboard, } from "utils/methods";
import { useAlert, useSettings, } from "hooks";
import { useNavigate, } from "react-router-dom";
import ActionButtons from "components/ActionButtons/ActionButtons";
import { resolveTheme, } from "themes/main";

interface Record {
  id: number;
  link: string;
  title: string;
  subtitle: string;
}

interface AssignmentCardProps {
  record: Record;
  // eslint-disable-next-line no-unused-vars
  removeRecord?: (id: number) => void;
  editable?: boolean;
  disabled?: boolean;
}

AssignmentCard.defaultProps = {
  editable: false,
  removeRecord: () => {},
  disabled: false,
};

function AssignmentCard({
  record,
  removeRecord,
  editable,
  disabled,
}: AssignmentCardProps) {
  const [copied, setCopied] = useState(false);
  const { setAlert, setConfirmation, } = useAlert();
  const navigate = useNavigate();

  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0.2px 0.2px 15px 0 rgba(0, 0, 0, 0.1);
        // border: 1px solid #eaeaea;
        padding: 14px 18px;
        border-radius: 10px;
        // margin-bottom: 14px;
        transition: 0.3s ease-in-out all;
        cursor: pointer;
        background-color: ${currentTheme.colors.background};
        color: ${currentTheme.colors.text};

        &:hover {
          box-shadow: 0.2px 0.2px 10px 0 rgba(0, 0, 0, 0.2);
          transform: scale(0.99);
        }

        &:active {
          transform: scale(0.98);
        }
      `}
      onClick={() => navigate(record.link)}
      onKeyDown={({ key, }) => {
        if (key === "Enter") {
          navigate(record.link);
        }
      }}
      role="button"
      tabIndex={0}
    >
      <div>
        <Text size="md">{record.title}</Text>
        <div
          css={css`
            display: flex;
            align-items: center;
            margin-top: 4px;
          `}
        >
          <Text
            size="xs"
            color={currentTheme.colors.textLight}
            onClick={(e: any) => {
              e.stopPropagation();
              copyToClipboard(record.subtitle);
              setCopied(true);
              setAlert({
                type: "success",
                message: "Copied to clipboard",
              });
              setTimeout(() => {
                setCopied(false);
              }, 2000);
            }}
            css={css`
              cursor: pointer;

              &:hover {
                text-decoration: underline;
              }
            `}
            title={
              copied ?
                "Copied to clipboard" :
                "Click to copy email to clipboard"
            }
          >
            {record.subtitle}
          </Text>
          {!copied ? (
            <i
              className="material-icons"
              css={css`
                margin-left: 4px;
                font-size: 14px;
                color: ${currentTheme.colors.textLight};
              `}
              title="Click text to copy to clipboard"
              onClick={(e: any) => {
                e.stopPropagation();
                copyToClipboard(record.subtitle);
                setCopied(true);
                setAlert({
                  type: "success",
                  message: "Copied to clipboard",
                });
                setTimeout(() => {
                  setCopied(false);
                }, 2000);
              }}
              onKeyDown={({ key, }) => {
                if (key === "Enter") {
                  copyToClipboard(record.subtitle);
                  setCopied(true);
                  setAlert({
                    type: "success",
                    message: "Copied to clipboard",
                  });
                  setTimeout(() => {
                    setCopied(false);
                  }, 2000);
                }
              }}
              role="button"
              tabIndex={0}
            >
              content_copy
            </i>
          ) : (
            <i
              className="material-icons"
              css={css`
                margin-left: 4px;
                font-size: 14px;
                color: ${currentTheme.colors.success};
              `}
              title="Copied to clipboard"
            >
              check
            </i>
          )}
        </div>
      </div>
      <div
        className="icons"
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        {editable && (
          <i
            css={css`
              font-size: 14px;
              cursor: pointer;
              margin-left: 14px;
              background-color: ${currentTheme.colors.danger};
              color: ${currentTheme.colors.dangerContrast};
              padding: 6px;
              border-radius: 5px;
              text-shadow: 0.2px 0.2px 4px rgba(0, 0, 0, 0.1);
              opacity: 0.75;
              transition: 0.2s ease-in-out all;
              margin-right: 7px;

              &:hover {
                text-shadow: 0.2px 0.2px 10px rgba(0, 0, 0, 0.2);
                opacity: 1;
              }
            `}
            className="material-icons"
            role="button"
            tabIndex={0}
            onClick={(e) => {
              e.stopPropagation();
              if (record.id && removeRecord) {
                setConfirmation(
                  "Are you sure you want to remove this item?",
                  () => {
                    removeRecord(record.id);
                  }
                );
              }
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
              if (e.key === "Enter") {
                if (!!record.id && removeRecord) {
                  setConfirmation(
                    "Are you sure you want to remove this item?",
                    () => {
                      removeRecord(record.id);
                    }
                  );
                }
              }
            }}
            title={`Remove "${record.title}"`}
          >
            cancel
          </i>
        )}
        <ActionButtons edit view endpoint={record.link} disabled={disabled} />
      </div>
    </div>
  );
}

export default AssignmentCard;
