import React, { useEffect, useState, } from "react";
import { Default, Edit, } from "components/Views";
import { Loading, } from "components";
import { axios, } from "libs";
import { useParams, useNavigate, } from "react-router-dom";
import { Validation, } from "interfaces/responses";
import {
  Button,
  Checkbox,
  Grid,
  NumberInput,
  Select,
  TextInput,
} from "@mantine/core";
import { useUser, useAlert, useSettings, } from "hooks";
import { Device, DeviceType, } from "interfaces/main";
import { parseJsonFields, } from "utils/fetching";
import JSONForm from "components/Inputs/JsonForm";
import { resolveTheme, } from "themes/main";

function EditDevice() {
  const { organization_id, device_id, } = useParams();
  const [formState, setFormState] = useState<Partial<Device>>({
    device_type_id: undefined,
    global: false,
    size: undefined,
    weight: undefined,
    make: undefined,
    model: undefined,
    serial: undefined,
    storage: undefined,
    asset_tag: undefined,
  });
  const [loading, setLoading] = useState(false);
  const [validationRules, setValidationRules] = useState<Validation>();
  const [requiredFields, setRequiredFields] = useState<string[]>([]);
  const [validated, setValidated] = useState(false);
  const { hasRole, } = useUser();
  const { setAlert, } = useAlert();
  const navigate = useNavigate();
  const [deviceTypes, setDeviceTypes] = useState<DeviceType[]>([]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/organizations/${organization_id}/devices/create`)
      .then((res) => {
        const validation = res.data.data.validation as Validation;
        setValidationRules(validation);
        setRequiredFields(
          Object.entries(validation.rules)
            .filter(([, value]) => value.includes("required"))
            .map(([key]) => key)
        );
      })
      .catch((err) => {
        console.error(err);
        setAlert({
          type: "danger",
          message: "Error loading device validation rules",
        });
      })
      .finally(() => {
        setLoading(false);
      });

    setLoading(true);
    axios
      .get(`/organizations/${organization_id}/devices/${device_id}`)
      .then((res) => {
        const parsed = parseJsonFields(res.data.data);
        setFormState({
          ...parsed,
          storage: calculateInitialStorage(parsed.storage || 0),
        });
      })
      .catch((err) => {
        console.error(err);
        setAlert({
          type: "danger",
          message: "Error loading device",
        });
      })
      .finally(() => {
        setLoading(false);
      });

    axios.get(`/organizations/${organization_id}/device-types`).then((res) => {
      setDeviceTypes(res.data.data);
    });
  }, [
    organization_id,
    device_id
  ]);

  const submitDevice = () => {
    setLoading(true);
    axios
      .put(`/organizations/${organization_id}/devices/${device_id}`, {
        ...formState,
        storage: calculateStorage(formState.storage || 0),
      })
      .then(() => {
        setAlert({
          type: "success",
          message: "Device updated successfully",
        });
        setLoading(false);
        navigate(`/organizations/${organization_id}/devices/${device_id}`);
      })
      .catch((err) => {
        console.error(err);
        setAlert({
          type: "danger",
          message: "Error updating device",
        });
        setLoading(false);
      });
  };

  const setSpecifications = (specifications: Device["specifications"]) => {
    setFormState({ ...formState, specifications, });
  };

  const setPeripherals = (peripherals: Device["peripherals"]) => {
    setFormState({ ...formState, peripherals, });
  };

  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);
  const { colors, } = currentTheme;

  const [storageUnits, setStorageUnits] = useState<"MB" | "GB" | "TB">("MB");

  const calculateStorage = (storage: number) => {
    if (!storage) {
      return 0;
    }

    switch (storageUnits) {
      case "MB":
        return storage;
      case "GB":
        return storage * 1000;
      case "TB":
        return storage * 1000000;
      default:
        return storage;
    }
  };
  const calculateInitialStorage = (storage: number) => {
    if (!storage) {
      return 0;
    }

    if (storage < 1000) {
      setStorageUnits("MB");
      return storage;
    }

    if (storage < 1000000) {
      setStorageUnits("GB");
      return storage / 1000;
    }

    setStorageUnits("TB");
    return storage / 1000000;
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <Edit
        title={`Edit "${formState.asset_tag}"`}
        buttons={[]}
        formState={formState}
        requiredFields={requiredFields}
        setValidated={setValidated}
      >
        <Grid>
          <Grid.Col sm={12} md={6}>
            <TextInput
              label="Make"
              name="make"
              placeholder="Enter a make..."
              required={validationRules?.rules?.make?.includes("required")}
              onChange={(e) => {
                setFormState({ ...formState, make: e.currentTarget.value, });
              }}
              value={formState.make || ""}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6}>
            <TextInput
              label="Model"
              name="model"
              placeholder="Enter a model..."
              required={validationRules?.rules?.model?.includes("required")}
              onChange={(e) => {
                setFormState({ ...formState, model: e.currentTarget.value, });
              }}
              value={formState.model || ""}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6}>
            <NumberInput
              label="Storage"
              name="storage"
              placeholder="Enter a storage..."
              required={validationRules?.rules?.storage?.includes("required")}
              onChange={(val) => {
                if (!val) {
                  return;
                }
                setFormState({ ...formState, storage: val, });
              }}
              value={formState.storage || 0}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6}>
            <Select
              label="Storage Units"
              name="storage_units"
              placeholder="Select storage units..."
              required={validationRules?.rules?.storage_units?.includes(
                "required"
              )}
              data={[
                {
                  label: "MB",
                  value: "MB",
                },
                {
                  label: "GB",
                  value: "GB",
                },
                {
                  label: "TB",
                  value: "TB",
                }
              ]}
              onChange={(value) => {
                setStorageUnits(value as "MB" | "GB" | "TB");
              }}
              value={storageUnits}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6}>
            <TextInput
              label="Serial"
              name="serial"
              placeholder="Enter a serial..."
              required={validationRules?.rules?.serial?.includes("required")}
              onChange={(e) => {
                setFormState({ ...formState, serial: e.currentTarget.value, });
              }}
              value={formState.serial || ""}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6}>
            <TextInput
              label="Asset Tag"
              name="asset_tag"
              placeholder="Enter an asset tag..."
              required={validationRules?.rules?.asset_tag?.includes("required")}
              onChange={(e) => {
                setFormState({
                  ...formState,
                  asset_tag: e.currentTarget.value,
                });
              }}
              value={formState.asset_tag || ""}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6}>
            <Select
              label="Type"
              name="module"
              placeholder="Select a device type..."
              required={validationRules?.rules?.device_type_id?.includes(
                "required"
              )}
              data={deviceTypes.map((deviceType) => ({
                label: deviceType.name,
                value: String(deviceType.id),
              }))}
              onChange={(value) => {
                setFormState({ ...formState, device_type_id: Number(value), });
              }}
              searchable
              value={
                formState.device_type_id ?
                  String(formState.device_type_id) :
                  undefined
              }
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6}>
            <TextInput
              label="Weight"
              name="weight"
              placeholder="Enter a weight..."
              required={validationRules?.rules?.weight?.includes("required")}
              onChange={(e) => {
                setFormState({
                  ...formState,
                  weight: Number(e.currentTarget.value),
                });
              }}
              value={formState.weight || ""}
            />
          </Grid.Col>
          {hasRole("hyperuser") && (
            <Grid.Col sm={12} md={12}>
              <Checkbox
                label="Available Globally"
                name="global"
                onChange={(e) => {
                  setFormState({
                    ...formState,
                    global: e.currentTarget.checked,
                  });
                }}
                required={validationRules?.rules?.global?.includes("required")}
                checked={formState.global}
              />
            </Grid.Col>
          )}
        </Grid>
      </Edit>
      <Default>
        <JSONForm
          title="Specifications"
          setFields={setSpecifications}
          fields={formState?.specifications || {}}
        />
        <br />
        <br />
        <hr />
        <br />
        <br />
        <JSONForm
          title="Peripherals"
          setFields={setPeripherals}
          fields={formState?.peripherals || {}}
        />

        <hr />
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
          }}
        >
          <Button
            color={colors.primary}
            onClick={submitDevice}
            disabled={!validated}
          >
            Submit
          </Button>
        </div>
      </Default>
    </div>
  );
}

export default EditDevice;
