import React, { useState, } from "react";
import { SpotlightAction, SpotlightProvider, } from "@mantine/spotlight";
import {
  Buildings, Files, Gear, House, MagnifyingGlass, Palette, Rows, Table, User, Users,
} from "phosphor-react";
import { useNavigate, } from "react-router-dom";
import { useSettings, } from "hooks";
import Loading from "components/Loading";

function Spotlight({ children, }: {
    children: React.ReactNode;
}) {
  const navigate = useNavigate();
  const { theme, view: { list_views: { default_view_mode, }, }, } = useSettings();
  const [loading, setLoading] = useState(false);

  const actions: SpotlightAction[] = [
    {
      title: "Home",
      description: "Get to home page",
      onTrigger: () => {
        navigate("/");
      },
      icon: <House size={18} />,
    },
    {
      title: "Organizations",
      description: "View all organizations",
      onTrigger: () => {
        navigate("/organizations");
      },
      icon: <Buildings size={18} />,
    },
    {
      title: "Your Organization",
      description: "View your organization",
      onTrigger: () => {
        navigate("/organizations/1");
      },
      icon: <Buildings size={18} />,
    },
    {
      title: "Users",
      description: "View all users",
      onTrigger: () => {
        navigate("/organizations/1/users");
      },
      icon: <Users size={18} />,
    },
    {
      title: "Templates",
      description: "View all templates",
      onTrigger: () => {
        navigate("/organizations/1/templates");
      },
      icon: <Files size={18} />,
    },
    {
      title: "Profile",
      description: "View your profile",
      onTrigger: () => {
        navigate("/profile");
      },
      icon: <User size={18} />,
    },
    {
      title: "Settings",
      description: "View settings",
      onTrigger: () => {
        navigate("/settings");
      },
      icon: <Gear size={18} />,
    },
    {
      title: "Toggle Theme",
      description: "Toggle between themes",
      onTrigger: () => {
        setLoading(true);
        theme.toggle();
        setTimeout(
          () => {
            setLoading(false);
          },
          1000
        );
      },
      icon: <Palette size={18} />,
    },
    {
      title: "Toggle List View Mode",
      description: "Toggle whether list views are tables or cards by default",
      onTrigger: () => {
        default_view_mode.toggle();
        window.location.reload();
      },
      icon: default_view_mode.current === "list" ? <Table size={18} /> : <Rows size={18} />,
    }
  ];

  // // eslint-disable-next-line no-unused-vars
  // const [usingActions, setUsingActions] = useState<SpotlightAction[]>(actions);

  return (
    <div>
      <SpotlightProvider
        actions={actions}
        searchIcon={<MagnifyingGlass size={16} />}
      >
        {
            loading && (
            <Loading />
            )
        }
        {children}
      </SpotlightProvider>
    </div>
  );
}

export default Spotlight;
