import IntegratedListView from "components/Views/IntegratedListView";
import { useSearch, } from "hooks";
import { Kit, Matter, } from "interfaces/main";
import React from "react";
import { getModuleCompleteness, getModuleFields, } from "utils/methods";
import { kitStatusTypes, } from "vars/constants";

interface ActiveKit extends Kit {
  matter: Matter;
}

interface ActiveKitsProps {
  active_kits: ActiveKit[];
}

function ActiveKits({ active_kits, }: ActiveKitsProps) {
  const { getTable, } = useSearch();
  const { sort, } = getTable("active_kits");

  const allFields: (keyof Kit)[] =
    active_kits?.[0] &&
    (Object.keys(active_kits[0]) as unknown as (keyof Kit)[]);

  const allowedFields = new Map([
    ["id", 0],
    ["matter_id", 0],
    ["status", 0],
    ["name", 0],
    ["matter_name", 0],
    ["status_label", 0],
    ["completeness", 0],
    ["created_at", 0],
    ["updated_at", 0]
  ]);

  const fields = [
    ...getModuleFields(allowedFields, allFields),
    { name: "matter_name", priority: 0, }
  ];

  const getSubtitle = (k: Kit) => {
    if (!sort?.field) {
      return kitStatusTypes[k.status]?.label;
    }

    return `${sort?.field}: ${k[sort?.field as keyof Kit]}`;
  };

  const listItems = active_kits.map((kit) => ({
    id: kit.id,
    title: `${kit.matter?.name} / ${kit.name}`,
    subtitle: getSubtitle(kit),
    endpoint: `/organizations/${kit.matter?.organization_id}/matters/${
      kit.matter_id
    }/kits/${kit.id}`,
    full_record: {
      ...kit,
      matter_name: kit.matter?.name,
      status_text: kitStatusTypes[kit.status].label,
    },
    completeness: getModuleCompleteness(kit) || 0,
  }));

  return (
    <div>
      <IntegratedListView
        title="Active Kits"
        search
        collapsable={false}
        fields={fields}
        table_name="active_kits"
        full_record_list={active_kits}
        list_items={listItems}
        with_separator={false}
        with_title={false}
        with_padding_y={false}
      />
    </div>
  );
}

export default ActiveKits;
