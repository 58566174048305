import React, { useEffect, useState, } from "react";
import { List as ListView, } from "components/Views";
import { useNavigate, useParams, } from "react-router-dom";
import { axios, } from "libs";
import { useAlert, useSettings, } from "hooks";
import { Grid, Text, } from "@mantine/core";
import TaskCard from "components/InfoCard/TaskCard";
import { Task, } from "interfaces/main";
import { Loading, } from "components";
import { resolveTheme, } from "themes/main";

function List() {
  const navigate = useNavigate();
  const { organization_id, template_id, } = useParams();
  const [tasks, setTasks] = useState<Task[]>([]);
  const [loading, setLoading] = useState(true);
  const { setAlert, } = useAlert();

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/organizations/${organization_id}/templates/${template_id}/tasks`)
      .then((res) => {
        setTasks(res.data.data);
      })
      .catch((err) => {
        console.error(err);
        setAlert({ message: err.message, type: "danger", });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [organization_id]);

  const {
    theme: { current, },
  } = useSettings();
  const currentTheme = resolveTheme(current);

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <ListView
        title="Tasks"
        buttons={[
          {
            text: "",
            handleClick: () => {
              navigate(
                `/organizations/${organization_id}/templates/${template_id}/tasks/create`
              );
            },
            icon: "add",
            variant: "default",
            color: currentTheme.colors.primary,
          }
        ]}
        table_name="tasks"
      >
        <Grid gutter={0}>
          {tasks.length ? (
            tasks.map((task) => (
              <Grid.Col sm={12}>
                <TaskCard
                  key={task.id}
                  task={{
                    ...task,
                    link: `/organizations/${organization_id}/templates/${template_id}/tasks/${task.id}`,
                  }}
                />
              </Grid.Col>
            ))
          ) : (
            <Grid.Col span={12}>
              <Text
                size="md"
                weight="300"
                color={currentTheme.colors.textLight}
                align="center"
              >
                No tasks.
              </Text>
            </Grid.Col>
          )}
        </Grid>
      </ListView>
    </div>
  );
}

export default List;
