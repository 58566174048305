import React, { useEffect, useState, } from "react";
import { Task, } from "interfaces/main";
import { Default, } from "components/Views";
import TaskCard from "components/InfoCard/TaskCard";
import {
  Button, Dialog, Flex, Grid, Text, Textarea,
} from "@mantine/core";
import { TaskForm, } from "pages/Tasks";
// import { useAlert } from "hooks";
/** @jsxImportSource @emotion/react */
import { css, } from "@emotion/react";
import axios from "libs/axiosInstance";
import { useAlert, useSettings, } from "hooks";
import ProgressSection from "components/Progress/Progress";
import { ListPlus, Plus, } from "phosphor-react";
import { resolveTheme, } from "themes/main";

interface SpecialTask extends Task {
  link: string;
  endpoint: string;
}

interface TaskProps {
  tasks: SpecialTask[];
  endpoint: string;
  // eslint-disable-next-line no-unused-vars
  setTasks: (tasks: SpecialTask[]) => void;
  editable: boolean;
  refreshTasks: () => void;
  showProgress: boolean;
  disabled?: boolean;
  buttons?: {
    text: string;
    handleClick: () => void;
    color: string;
    variant?:
      | "outline"
      | "white"
      | "light"
      | "default"
      | "filled"
      | "subtle"
      | "gradient";
    disabled?: boolean;
    invisible?: boolean;
    icon?: string;
  }[];
}

Tasks.defaultProps = {
  disabled: false,
  buttons: [],
};

function Tasks({
  tasks,
  editable,
  setTasks,
  refreshTasks,
  endpoint,
  showProgress,
  disabled,
  buttons,
}: TaskProps) {
  const [newTask, setNewTask] = useState<TaskForm>({
    description: "",
  });
  const [adding, setAdding] = useState(false);

  const { setAlert, } = useAlert();

  const addNewTask = () => {
    if (!endpoint) {
      return;
    }
    axios
      .post(endpoint, {
        ...newTask,
        sort_order: tasks.length + 1,
        status: 0,
      })
      .then((res) => {
        setNewTask({ description: "", });
        setAdding(false);
        setTasks([...tasks, res.data.data as SpecialTask]);
      })
      .catch((err) => {
        console.error(err);
        setAlert({
          message: "Error adding task",
          type: "danger",
        });
      });
  };

  const handleReorder = async (from: number, to: number) => {
    if (from === to) {
      return;
    }
    const [fromIndex, toIndex] = [from - 1, to - 1];
    const newTasks = [...tasks];
    const moved = newTasks.splice(fromIndex, 1)[0];
    newTasks.splice(toIndex, 0, moved);
    const reOrdered = newTasks.map((task, index) => ({
      ...task,
      sort_order: index + 1,
    }));
    // reorder based on sort_order
    const reSorted = reOrdered.sort((a, b) => a.sort_order - b.sort_order);
    reOrderTasks(reSorted);
  };

  useEffect(() => {
    setNewTask({ description: "", });
  }, [adding]);

  // const { setAlert } = useAlert();

  const [drag, setDrag] = useState<{
    dragging: boolean;
    from: number | null;
    to: number | null;
  }>({
    dragging: false,
    from: null,
    to: null,
  });

  const reOrderTasks = (tsks: SpecialTask[]) => axios
    .post(`${endpoint}/sort`, {
      sorted_tasks: tsks,
    })
    .then(() => {
      setTasks(tsks);
      setAlert({
        type: "success",
        message: "Tasks reordered successfully",
      });
    })
    .catch((err) => {
      setAlert({
        type: "danger",
        message: "Error reordering tasks",
      });
      // eslint-disable-next-line no-console
      console.error(err);
    });

  const handleDragStart = (
    e: React.DragEvent<HTMLElement>,
    position: number
  ) => {
    setDrag({
      ...drag,
      dragging: true,
      from: position,
    });
  };

  const handleDragEnd = () => {
    setDrag({
      ...drag,
      dragging: false,
      from: null,
      to: null,
    });
    if (drag.from !== null && drag.to !== null && drag.from !== drag.to) {
      handleReorder(drag.from, drag.to);
    }
  };

  const handleDragEnter = (
    e: React.DragEvent<HTMLElement>,
    position: number
  ) => {
    if (drag.dragging && drag.from && drag.to !== position) {
      setDrag({
        ...drag,
        to: position,
      });
    }
  };

  const sortedTasks = tasks.sort((a, b) => a.sort_order - b.sort_order);

  const [contextMenu, setContextMenu] = useState<{
    x: number;
    y: number;
    visible: boolean;
    task: SpecialTask | null;
  }>({
    x: 0,
    y: 0,
    visible: false,
    task: null,
  });

  const handleTaskContextMenu = (
    e: React.MouseEvent<HTMLElement>,
    task: SpecialTask
  ) => {
    e.preventDefault();
    if (editable) {
      setContextMenu({
        x: e.clientX,
        y: e.clientY,
        visible: true,
        task,
      });
    }
  };

  useEffect(() => {
    const handleWindowClick = () => {
      setContextMenu({
        x: 0,
        y: 0,
        visible: false,
        task: null,
      });
    };

    window.addEventListener("click", handleWindowClick);

    return () => {
      window.removeEventListener("click", handleWindowClick);
    };
  }, []);

  const [addAfter, setAddAfter] = useState<SpecialTask | null>(null);
  const setAddTaskAfter = (task: SpecialTask) => {
    setAddAfter(task);
    setAdding(true);
  };

  const addTaskAfter = () => {
    if (!addAfter) {
      return;
    }

    const newTasks = [...tasks];

    const updatedTasks = newTasks.map((task) => {
      if (task.sort_order > addAfter.sort_order) {
        return {
          ...task,
          sort_order: task.sort_order,
        };
      }
      return task;
    });

    reOrderTasks(updatedTasks).then(() => {
      refreshTasks();
      axios
        .post(endpoint, {
          ...newTask,
          sort_order: addAfter.sort_order + 1,
          status: 0,
        })
        .then((res) => {
          setNewTask({ description: "", });
          setAdding(false);
          setTasks([...tasks, res.data.data as SpecialTask]);
          setAddAfter(null);
          refreshTasks();
        })
        .catch((err) => {
          console.error(err);
          setAlert({
            message: "Error adding task",
            type: "danger",
          });
        });
    });
  };

  const handleAddNewTask = () => {
    if (newTask.description) {
      if (addAfter) {
        addTaskAfter();
      } else {
        addNewTask();
      }
    }
    setAdding(false);
  };

  const {
    theme: { current: theme, },
  } = useSettings();
  const currentTheme = resolveTheme(theme);

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={() => {
        setContextMenu({
          x: 0,
          y: 0,
          visible: false,
          task: null,
        });
      }}
      onKeyDown={() => {
        setContextMenu({
          x: 0,
          y: 0,
          visible: false,
          task: null,
        });
      }}
    >
      <Default
        title="Tasks"
        buttons={[
          {
            text: "Add Task",
            handleClick: () => setAdding(!adding),
            variant: "filled",
            color: currentTheme.colors.primary,
            invisible: tasks.length > 2,
            disabled,
          },
          ...(buttons || [])
        ]}
        collapsable
      >
        {showProgress && tasks.length > 0 && (
          <ProgressSection tasks={sortedTasks} />
        )}
        {tasks.length > 0 ? (
          sortedTasks.map((task) => (
            <div
              key={task.id + task.description}
              css={css`
                display: flex;
                justify-content: space-between;
                margin: 14px 0;
                align-items: center;
              `}
            >
              <p
                css={css`
                  width: 24px;
                  margin: 0;
                  color: ${currentTheme.colors.textLight};
                `}
              >
                {task.sort_order}
              </p>
              <div
                css={css`
                  border-left: 2px solid ${currentTheme.colors.background};
                  padding-left: 14px;
                  width: 100%;
                `}
              >
                <TaskCard
                  task={task}
                  editable={editable}
                  handleDragStart={handleDragStart}
                  handleDragEnd={handleDragEnd}
                  handleDragEnter={handleDragEnter}
                  refreshTasks={refreshTasks}
                  handleReorder={handleReorder}
                  handleContextMenu={handleTaskContextMenu}
                  disabled={disabled}
                />
              </div>
            </div>
          ))
        ) : (
          <Text
            size="md"
            weight="300"
            color={currentTheme.colors.textLight}
            align="center"
          >
            No tasks.
          </Text>
        )}
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
            margin-top: 24px;
          `}
          style={{
            visibility: tasks && tasks.length > 2 ? "visible" : "hidden",
          }}
        >
          <Button
            variant="filled"
            color={currentTheme.colors.primary}
            onClick={() => setAdding(!adding)}
            disabled={disabled}
          >
            Add Task
          </Button>
        </div>
      </Default>
      <Dialog
        opened={adding}
        withCloseButton
        onClose={() => setAdding(false)}
        size="md"
      >
        <Grid>
          <Grid.Col sm={12}>
            <Textarea
              label="Description"
              name="description"
              value={newTask.description}
              onChange={(e) => setNewTask({ ...newTask, description: e.target.value, })}
              autoFocus
            />
          </Grid.Col>
          <Grid.Col sm={12}>
            <Flex justify="flex-end">
              <Button
                onClick={handleAddNewTask}
                variant="filled"
                color={currentTheme.colors.primary}
              >
                Save
              </Button>
            </Flex>
          </Grid.Col>
        </Grid>
      </Dialog>
      <ContextMenu
        x={contextMenu.x}
        y={contextMenu.y}
        visible={contextMenu.visible}
        task={contextMenu.task}
        functions={{
          handleAddAfter: setAddTaskAfter,
          setAddingTask: () => setAdding(true),
        }}
      />
    </div>
  );
}

export default Tasks;

function ContextMenu({
  x,
  y,
  visible,
  task,
  functions,
}: {
  x: number;
  y: number;
  visible: boolean;
  task: SpecialTask | null;
  functions: {
    // eslint-disable-next-line no-unused-vars
    handleAddAfter: (t: SpecialTask) => void;
    setAddingTask: () => void;
  };
}) {
  return (
    <div
      css={css`
        position: absolute;
        top: ${y}px;
        left: ${x}px;
        background: white;
        border: 1px solid #eaeaea;
        border-radius: 5px;
        padding: 8px;
        z-index: 100;
        visibility: ${visible ? "visible" : "hidden"};
        box-shadow: 0.2px 0.2px 10px rgba(0, 0, 0, 0.15);
        box-sizing: border-box;

        ul {
          list-style: none;
          padding: 0;
          cursor: pointer;
          margin: 0;

          li {
            margin-bottom: 8px;

            &:last-child {
              margin-bottom: 0;
            }

            button {
              background: none;
              border: none;
              padding: 0;
              text-align: left;
              font-size: 13px;
              font-weight: 400;
              padding: 4px 14px;
              margin: 0;
              border-radius: 4px;
              transition: all 0.2s ease-in-out;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              cursor: pointer;
              width: 100%;

              &:hover {
                background: #eaeaea;
              }

              span {
                margin-left: 8px;
              }
            }
          }
        }
      `}
    >
      <ul>
        <li>
          <button
            type="button"
            onClick={() => {
              if (!task) return;
              functions.setAddingTask();
            }}
          >
            <Plus size={14} />
            <span>Add Task</span>
          </button>
        </li>
        <li>
          <button
            type="button"
            onClick={() => {
              if (!task) return;
              functions.handleAddAfter(task);
            }}
          >
            <ListPlus size={14} />
            <span>Add Task After</span>
          </button>
        </li>
      </ul>
    </div>
  );
}
