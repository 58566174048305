import React, { useEffect, useState, } from "react";
import { useParams, useNavigate, } from "react-router-dom";
import { AxiosError, AxiosResponse, } from "axios";
import DetailsView from "components/Views/DetailsView";
import { axios, } from "libs";
import { useAlert, useSettings, useUser, } from "hooks";
import { MatterList, } from "pages";
import TextDisplay from "components/Display/TextDisplay";
import { Grid, } from "@mantine/core";
import { resolveTheme, } from "themes/main";
import { Organization as OrgProps, } from "./organizations";

/** @jsxImportSource @emotion/react */

function Organization() {
  const navigate = useNavigate();
  const { organization_id, } = useParams();

  const [organization, setOrganization] = useState<OrgProps>();
  const [loading, setLoading] = useState(true);
  const { setAlert, setConfirmation, } = useAlert();
  const { hasRole, } = useUser();

  const onSuccess = (response: AxiosResponse): void => {
    setOrganization(response.data.data);
    setLoading(false);
  };

  const onError = (err: AxiosError): void => {
    // eslint-disable-next-line no-console
    console.error(err);
    setLoading(false);
  };

  useEffect(() => {
    axios
      .get(`/organizations/${organization_id}`)
      .then(onSuccess)
      .catch(onError)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleDelete = () => {
    setConfirmation(
      "Are you sure you want to delete this organization?",
      () => {
        setLoading(true);
        axios
          .delete(`/organizations/${organization_id}`)
          .then(() => {
            setAlert({
              type: "success",
              message: "Organization deleted successfully",
            });
            setLoading(false);
            navigate("/organizations");
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.error(err);
            setLoading(false);
          });
      }
    );
  };

  const { theme: { current, }, } = useSettings();
  const currentTheme = resolveTheme(current);
  const { colors, } = currentTheme;

  return (
    <div>
      <DetailsView
        title={organization?.name || "Organization"}
        loading={loading}
        buttons={[
          {
            text: "Users",
            handleClick: () => navigate(`/organizations/${organization_id}/users`),
            color: colors.primary,
            variant: "subtle",
            invisible: !hasRole("poweruser"),
          },
          {
            text: "Templates",
            handleClick: () => {
              navigate(`/organizations/${organization_id}/templates`);
            },
            color: colors.primary,
            variant: "subtle",
          },
          {
            text: "Edit",
            handleClick: () => {
              navigate(`/organizations/${organization_id}/edit`);
            },
            color: colors.primary,
            variant: "default",
            invisible: !hasRole("superuser"),
          },
          {
            text: "Delete",
            handleClick: handleDelete,
            color: colors.danger,
            variant: "filled",
            invisible: !hasRole("superuser"),
          }
        ]}
      >
        <Grid>
          <Grid.Col sm={12} md={6}>
            <TextDisplay
              label="Name"
              data={organization?.name}
            />
          </Grid.Col>
          <Grid.Col sm={12} md={6}>
            <TextDisplay
              label="Client Code"
              data={organization?.code_prefix}
            />
          </Grid.Col>
        </Grid>
      </DetailsView>
      <MatterList org_prefix={organization?.code_prefix} />
    </div>
  );
}
export default Organization;
