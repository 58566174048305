import React, { useEffect, useState, } from "react";
import { SpotlightAction, SpotlightProvider, } from "@mantine/spotlight";
import { MagnifyingGlass, Question, } from "phosphor-react";
import { useNavigate, } from "react-router-dom";
import { useAlert, useSearch, } from "hooks";
import { axios, } from "libs";
import InlineLoader from "components/Loading/Inline";
import { SearchResult, } from "interfaces/responses";
import {
  parseSearchResultDrilldown,
  searchModelMappings,
} from "utils/search/global";

function GlobalSearch({ children, }: { children: React.ReactNode }) {
  const navigate = useNavigate();
  const { global_query, setGlobalQuery, } = useSearch();
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState("");

  const { setAlert, } = useAlert();

  const [actions, setActions] = useState<SpotlightAction[]>([]);

  const handleNoResults = () => {
    setActions([
      {
        title: "No results found",
        description: "Try searching for something else",
        icon: <Question size={14} />,
        onTrigger: () => {},
        closeOnTrigger: false,
      }
    ]);
  };

  const handleSearchActions = (results: SearchResult[]) => {
    const newActions: (SpotlightAction & { endpoint: string })[] = results.map(
      (result) => ({
        title: result.label,
        description: `${
          searchModelMappings[result.model].type
        } | ${parseSearchResultDrilldown(result)}`,
        icon: <MagnifyingGlass size={14} />,
        onTrigger: () => {
          const path = parseSearchResultDrilldown(result);
          navigate(path);
        },
        endpoint: parseSearchResultDrilldown(result),
      })
    );

    const cleanedActions = newActions.filter((action) => !!action.endpoint);

    if (cleanedActions.length > 0) {
      setActions(cleanedActions);
    } else {
      handleNoResults();
    }
  };

  const handleSearchQuery = async (query: string) => {
    setLoading(true);
    const res = await axios
      .post("/search", {
        terms: query,
      })
      .then((r) => r)
      .catch((err) => {
        console.error("Error searching:", err);
        setAlert({
          type: "danger",
          message: "Error searching",
        });
        return err.response;
      });
    setLoading(false);

    const found = res.data.data;

    if (found && found.length > 0) {
      handleSearchActions(found as SearchResult[]);
    } else {
      handleNoResults();
    }
  };

  useEffect(() => {
    if (global_query) {
      handleSearchQuery(global_query);
    }
    if (!global_query) {
      setActions([]);
    }
  }, [global_query]);

  useEffect(() => {
    const debouncedInput = setTimeout(() => {
      setGlobalQuery(input);
    }, 500);

    return () => clearTimeout(debouncedInput);
  }, [input]);

  return (
    <div>
      <SpotlightProvider
        actions={actions}
        searchIcon={loading ? <InlineLoader /> : <MagnifyingGlass size={16} />}
        shortcut={["ctrl + space"]}
        onQueryChange={(query) => {
          setInput(query);
        }}
        query={input}
        searchPlaceholder="Search for anything..."
        filter={(q, a) => a}
      >
        {children}
      </SpotlightProvider>
    </div>
  );
}

export default GlobalSearch;
