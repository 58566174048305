import React, { useEffect, useState, } from "react";
import { Edit, } from "components/Views";
import { Grid, TextInput, } from "@mantine/core";
import { useParams, useNavigate, } from "react-router-dom";
import { useLiveQuery, } from "dexie-react-hooks";
import { db, } from "db/db";
import PageNotFound from "pages/PageNotFound";
import { addCollectionUpdateFromCollection, updateOfflineCollection, } from "db/modules/collections";
import { useAlert, useSettings, } from "hooks";
import { resolveTheme, } from "themes/main";

function OfflineEdit() {
  const { collection_id, } = useParams();

  const navigate = useNavigate();
  const { setAlert, } = useAlert();

  const collection = useLiveQuery(() => db.collections.get(parseInt(collection_id || "0")));

  const [formState, setFormState] = useState({
    name: collection?.name,
  });
  const [validated, setValidated] = useState(false);
  const requiredFields = ["name"];

  useEffect(() => {
    if (collection) {
      setFormState({
        name: collection.name,
      });
    }
  }, [collection]);

  const submitOfflineCollection = async () => {
    try {
      if (!collection) {
        return;
      }

      if (!formState.name) {
        setAlert({
          message: "Please enter a name for the collection",
          type: "danger",
        });
        return;
      }

      const response = await addCollectionUpdateFromCollection({
        ...collection,
        name: formState.name,
      });
      updateOfflineCollection({
        ...collection,
        name: formState.name,
      });
      if (!response) {
        setAlert({
          message: "There was an error updating the collection",
          type: "danger",
        });
        return;
      }

      navigate("/collections");
      setAlert({
        message: "Collection updated successfully",
        type: "success",
      });
    } catch (error) {
      setAlert({
        message: "Error updating collection",
        type: "danger",
      });
    }
  };

  const { theme: { current: theme, }, } = useSettings();
  const currentTheme = resolveTheme(theme);

  if (!collection_id) {
    return <PageNotFound />;
  }

  return (
    <div>
      <Edit
        title={`Edit ${collection?.name}`}
        requiredFields={requiredFields}
        formState={formState}
        setValidated={setValidated}
        buttons={[
          {
            text: "Save",
            handleClick: () => {
              submitOfflineCollection();
            },
            color: currentTheme.colors.primary,
            variant: "filled",
            disabled: !validated,
          }
        ]}
      >
        <Grid>
          <Grid.Col span={12}>
            <TextInput
              label="Name"
              value={formState.name || ""}
              onChange={({
                currentTarget: { value, },
              }) => {
                setFormState({ ...formState, name: value, });
              }}
              required={requiredFields.includes("name")}
            />
          </Grid.Col>
        </Grid>
      </Edit>
    </div>
  );
}

export default OfflineEdit;
