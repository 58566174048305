import { AxiosError, } from "axios";
import { AlertContextProps, } from "contexts/AlertContext";

export const handleRequestError = (err: AxiosError, setAlert: AlertContextProps["setAlert"]) => {
  const error = err.response?.data?.error;
  const message = error?.message || "Something went wrong";
  const status = error?.status || err.response?.status || 500;
  const alertType = "danger";
  const title = `${status} Error`;
  setAlert({ message, heading: title, type: alertType, });
};
